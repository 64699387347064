import { default as api_45legacyJlo614hhskMeta } from "/codebuild/output/src2560279378/src/horizon/pages/api-legacy.vue?macro=true";
import { default as awardsuNM60k7HprMeta } from "/codebuild/output/src2560279378/src/horizon/pages/awards.vue?macro=true";
import { default as programsobAOUlGKglMeta } from "/codebuild/output/src2560279378/src/horizon/pages/benefits/programs.vue?macro=true";
import { default as _91slug_933yCvzXhoPlMeta } from "/codebuild/output/src2560279378/src/horizon/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93q4BEM3z45cMeta } from "/codebuild/output/src2560279378/src/horizon/pages/blog/category/[slug].vue?macro=true";
import { default as indexihyczTLGg8Meta } from "/codebuild/output/src2560279378/src/horizon/pages/blog/index.vue?macro=true";
import { default as call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta } from "/codebuild/output/src2560279378/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue?macro=true";
import { default as _91slug_93s7KvkdVov8Meta } from "/codebuild/output/src2560279378/src/horizon/pages/company/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93QB4NXGlJtvMeta } from "/codebuild/output/src2560279378/src/horizon/pages/company/[slug].vue?macro=true";
import { default as aboutAgnPuTwlpMMeta } from "/codebuild/output/src2560279378/src/horizon/pages/company/about.vue?macro=true";
import { default as indexPWCtfbiSdrMeta } from "/codebuild/output/src2560279378/src/horizon/pages/company/careers/benefits/index.vue?macro=true";
import { default as engineering_45and_45producteYIHOeQolwMeta } from "/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/engineering-and-product.vue?macro=true";
import { default as indexTe4y015656Meta } from "/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/index.vue?macro=true";
import { default as salesMavn4avyV5Meta } from "/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/sales.vue?macro=true";
import { default as emerging_45talent_45archivee4W2jz3yYQMeta } from "/codebuild/output/src2560279378/src/horizon/pages/company/careers/emerging-talent-archive.vue?macro=true";
import { default as indexbPpNHEC5vqMeta } from "/codebuild/output/src2560279378/src/horizon/pages/company/careers/index.vue?macro=true";
import { default as index5ST0Pi6jVQMeta } from "/codebuild/output/src2560279378/src/horizon/pages/company/careers/locations/index.vue?macro=true";
import { default as _91slug_9314EP0ff5GZMeta } from "/codebuild/output/src2560279378/src/horizon/pages/company/careers/roles/[slug].vue?macro=true";
import { default as indexzlLnaz7afTMeta } from "/codebuild/output/src2560279378/src/horizon/pages/company/careers/roles/index.vue?macro=true";
import { default as diversitypwCXgJ5gr3Meta } from "/codebuild/output/src2560279378/src/horizon/pages/company/diversity.vue?macro=true";
import { default as index5hZtGO5qiYMeta } from "/codebuild/output/src2560279378/src/horizon/pages/company/index.vue?macro=true";
import { default as efficiencyapMGm4NgycMeta } from "/codebuild/output/src2560279378/src/horizon/pages/company/mission/efficiency.vue?macro=true";
import { default as safetyFdFE9dRcCsMeta } from "/codebuild/output/src2560279378/src/horizon/pages/company/mission/safety.vue?macro=true";
import { default as sustainabilityipBUVymnbyMeta } from "/codebuild/output/src2560279378/src/horizon/pages/company/mission/sustainability.vue?macro=true";
import { default as in_45the_45newscfRQeRf4pkMeta } from "/codebuild/output/src2560279378/src/horizon/pages/company/news/in-the-news.vue?macro=true";
import { default as indexnBZgXo7BGzMeta } from "/codebuild/output/src2560279378/src/horizon/pages/company/news/index.vue?macro=true";
import { default as press_45releasesM9TPJ4WCdrMeta } from "/codebuild/output/src2560279378/src/horizon/pages/company/news/press-releases.vue?macro=true";
import { default as privacyrx0RWeyyUbMeta } from "/codebuild/output/src2560279378/src/horizon/pages/company/privacy.vue?macro=true";
import { default as cost_45assessment_45toolzcKgiadzkIMeta } from "/codebuild/output/src2560279378/src/horizon/pages/cost-assessment-tool.vue?macro=true";
import { default as _91slug_93tFtml4AdP8Meta } from "/codebuild/output/src2560279378/src/horizon/pages/customers/[slug].vue?macro=true";
import { default as _91slug_93tW9doUYruiMeta } from "/codebuild/output/src2560279378/src/horizon/pages/customers/category/[slug].vue?macro=true";
import { default as indexQ6Du04TbJYMeta } from "/codebuild/output/src2560279378/src/horizon/pages/customers/index.vue?macro=true";
import { default as _91slug_93tcsRwvEArzMeta } from "/codebuild/output/src2560279378/src/horizon/pages/customers/tips/[slug].vue?macro=true";
import { default as _91slug_93wj4X0sYRytMeta } from "/codebuild/output/src2560279378/src/horizon/pages/customers/tips/category/[slug].vue?macro=true";
import { default as indexzfNUyQO8HmMeta } from "/codebuild/output/src2560279378/src/horizon/pages/customers/tips/index.vue?macro=true";
import { default as _91_46_46_46slug_93BO8lHtSLh5Meta } from "/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue?macro=true";
import { default as above_45beyond0kPfraC3AMMeta } from "/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/above-beyond.vue?macro=true";
import { default as excellence_45performance0RWLCPvekCMeta } from "/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue?macro=true";
import { default as excellence_45service1zjUAemFzrMeta } from "/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/excellence-service.vue?macro=true";
import { default as indexc75nC8hOjKMeta } from "/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/index.vue?macro=true";
import { default as safest_45fleetRXtA4w14MmMeta } from "/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue?macro=true";
import { default as driver_45performancew721vUIdalMeta } from "/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/driver-performance.vue?macro=true";
import { default as excellence_45performanceRtIDOKE6YhMeta } from "/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue?macro=true";
import { default as fleet_45innovatorMvVh2EPMHGMeta } from "/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue?macro=true";
import { default as index1hOj7KzEibMeta } from "/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/index.vue?macro=true";
import { default as public_45fleetUgGnUMjbsEMeta } from "/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/public-fleet.vue?macro=true";
import { default as safest_45fleetrMfqk027LkMeta } from "/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue?macro=true";
import { default as indexmV65axE5QmMeta } from "/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/index.vue?macro=true";
import { default as _91slug_93sA9CJYHof2Meta } from "/codebuild/output/src2560279378/src/horizon/pages/events/[slug].vue?macro=true";
import { default as indexMUU0lirlPvMeta } from "/codebuild/output/src2560279378/src/horizon/pages/events/index.vue?macro=true";
import { default as fleet_45app7Hxoyu6CMIMeta } from "/codebuild/output/src2560279378/src/horizon/pages/fleet-app.vue?macro=true";
import { default as _91slug_93ifW7K2BEtcMeta } from "/codebuild/output/src2560279378/src/horizon/pages/fleet/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93WYMAGwh7ZdMeta } from "/codebuild/output/src2560279378/src/horizon/pages/fleet/[slug].vue?macro=true";
import { default as _91slug_937AnMKdFBJAMeta } from "/codebuild/output/src2560279378/src/horizon/pages/fleets/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93zFdCMNmMhZMeta } from "/codebuild/output/src2560279378/src/horizon/pages/fleets/[slug].vue?macro=true";
import { default as _91slug_93f7kQDhX61AMeta } from "/codebuild/output/src2560279378/src/horizon/pages/guides/[slug].vue?macro=true";
import { default as attract_45retain_45talent8xPLAG7DGlMeta } from "/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue?macro=true";
import { default as increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta } from "/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue?macro=true";
import { default as indexzZvRyHSU0VMeta } from "/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/index.vue?macro=true";
import { default as protect_45optimize_45assetsnbHrmhg336Meta } from "/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue?macro=true";
import { default as best_45dash_45camsaSFEIuMTM4Meta } from "/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue?macro=true";
import { default as index4yXXtHaoa3Meta } from "/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/index.vue?macro=true";
import { default as safety_45programfqjjnzXHKGMeta } from "/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/safety-program.vue?macro=true";
import { default as safety_45roiML5PuD0kWvMeta } from "/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/safety-roi.vue?macro=true";
import { default as indexr5eiT9bKzqMeta } from "/codebuild/output/src2560279378/src/horizon/pages/guides/index.vue?macro=true";
import { default as build_45for_45the_45futureFGWajw6k1AMeta } from "/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue?macro=true";
import { default as empower_45your_45teamsPCm8cM1OV9Meta } from "/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue?macro=true";
import { default as indexpGSrkE5V3tMeta } from "/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/index.vue?macro=true";
import { default as take_45action_45with_45dataUAk1dWHp2QMeta } from "/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue?macro=true";
import { default as _91slug_93CjB0RSetklMeta } from "/codebuild/output/src2560279378/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue?macro=true";
import { default as index2loFhsgk6eMeta } from "/codebuild/output/src2560279378/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue?macro=true";
import { default as indexRVID7FWdHoMeta } from "/codebuild/output/src2560279378/src/horizon/pages/index.vue?macro=true";
import { default as _91slug_931dOh0T6OR5Meta } from "/codebuild/output/src2560279378/src/horizon/pages/industrial/[slug].vue?macro=true";
import { default as _91slug_93QGIuat36BaMeta } from "/codebuild/output/src2560279378/src/horizon/pages/industries/[slug].vue?macro=true";
import { default as indexNXRmQROsctMeta } from "/codebuild/output/src2560279378/src/horizon/pages/industries/index.vue?macro=true";
import { default as citiesSBBThDHfUdMeta } from "/codebuild/output/src2560279378/src/horizon/pages/insights/cities.vue?macro=true";
import { default as indexSJXoeETKc0Meta } from "/codebuild/output/src2560279378/src/horizon/pages/integrations/index.vue?macro=true";
import { default as index217cyXTIlCMeta } from "/codebuild/output/src2560279378/src/horizon/pages/invite/index.vue?macro=true";
import { default as candidate_45privacy_45notice3h1eE1GSHbMeta } from "/codebuild/output/src2560279378/src/horizon/pages/legal/candidate-privacy-notice.vue?macro=true";
import { default as cookie_45consentZQoDyYq4cSMeta } from "/codebuild/output/src2560279378/src/horizon/pages/legal/cookie-consent.vue?macro=true";
import { default as cookie_45policyFXALTEHlGUMeta } from "/codebuild/output/src2560279378/src/horizon/pages/legal/cookie-policy.vue?macro=true";
import { default as data_45protection_45addendumCLcBvlJyDeMeta } from "/codebuild/output/src2560279378/src/horizon/pages/legal/data-protection-addendum.vue?macro=true";
import { default as express_45order_45termssc7QkQIEKtMeta } from "/codebuild/output/src2560279378/src/horizon/pages/legal/express-order-terms.vue?macro=true";
import { default as hardware_45warrantyWo3gHs7KBOMeta } from "/codebuild/output/src2560279378/src/horizon/pages/legal/hardware-warranty.vue?macro=true";
import { default as hosted_45software_45slaTX0B6si46ZMeta } from "/codebuild/output/src2560279378/src/horizon/pages/legal/hosted-software-sla.vue?macro=true";
import { default as partner_45code_45of_45conductMWBMImB3J8Meta } from "/codebuild/output/src2560279378/src/horizon/pages/legal/partner-code-of-conduct.vue?macro=true";
import { default as platform_45terms_45of_45servicedA5kv9WX03Meta } from "/codebuild/output/src2560279378/src/horizon/pages/legal/platform-terms-of-service.vue?macro=true";
import { default as privacyNpqLQIfDopMeta } from "/codebuild/output/src2560279378/src/horizon/pages/legal/privacy.vue?macro=true";
import { default as public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta } from "/codebuild/output/src2560279378/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue?macro=true";
import { default as requestingcustomerdataYAdUX9BZ3TMeta } from "/codebuild/output/src2560279378/src/horizon/pages/legal/requestingcustomerdata.vue?macro=true";
import { default as security8UENcIvcsMMeta } from "/codebuild/output/src2560279378/src/horizon/pages/legal/security.vue?macro=true";
import { default as transparencyreportuIzf9G3ZQmMeta } from "/codebuild/output/src2560279378/src/horizon/pages/legal/transparencyreport.vue?macro=true";
import { default as website_45terms_45of_45serviceC2auspcJ4NMeta } from "/codebuild/output/src2560279378/src/horizon/pages/legal/website-terms-of-service.vue?macro=true";
import { default as your_45privacy_45choicesApPVZ3U6liMeta } from "/codebuild/output/src2560279378/src/horizon/pages/legal/your-privacy-choices.vue?macro=true";
import { default as pricingUeOpnnhg6xMeta } from "/codebuild/output/src2560279378/src/horizon/pages/page/pricing.vue?macro=true";
import { default as _91slug_93kVI4tg3hhGMeta } from "/codebuild/output/src2560279378/src/horizon/pages/pages/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93Xf3maqbHE5Meta } from "/codebuild/output/src2560279378/src/horizon/pages/pages/[slug].vue?macro=true";
import { default as asset_45trackinguyknpr2HzrMeta } from "/codebuild/output/src2560279378/src/horizon/pages/pages/asset-tracking.vue?macro=true";
import { default as best_45dash_45camsScU1R8XGvpMeta } from "/codebuild/output/src2560279378/src/horizon/pages/pages/best-dash-cams.vue?macro=true";
import { default as constructionsxQ2tUHJmcMeta } from "/codebuild/output/src2560279378/src/horizon/pages/pages/construction.vue?macro=true";
import { default as eversource9gLT1Nd4LwMeta } from "/codebuild/output/src2560279378/src/horizon/pages/pages/eversource.vue?macro=true";
import { default as fleet_45managementYx80nSI2sCMeta } from "/codebuild/output/src2560279378/src/horizon/pages/pages/fleet-management.vue?macro=true";
import { default as groundworksB1HlV73E6tMeta } from "/codebuild/output/src2560279378/src/horizon/pages/pages/groundworks.vue?macro=true";
import { default as nationwideIn6xpx9prnMeta } from "/codebuild/output/src2560279378/src/horizon/pages/pages/nationwide.vue?macro=true";
import { default as safety_45coachingesbvejDMJJMeta } from "/codebuild/output/src2560279378/src/horizon/pages/pages/safety-coaching.vue?macro=true";
import { default as unifirst2oD3VQjHISMeta } from "/codebuild/output/src2560279378/src/horizon/pages/pages/unifirst.vue?macro=true";
import { default as pricingCMN0cJRUaHMeta } from "/codebuild/output/src2560279378/src/horizon/pages/pricing.vue?macro=true";
import { default as _91slug_93FjKmhxLgRsMeta } from "/codebuild/output/src2560279378/src/horizon/pages/products/[prefix]/[slug].vue?macro=true";
import { default as _91slug_930CXQx96byqMeta } from "/codebuild/output/src2560279378/src/horizon/pages/products/[slug].vue?macro=true";
import { default as asset_45trackingmoCd5dlKPOMeta } from "/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue?macro=true";
import { default as equipment_45trackingxtpI7zS8iaMeta } from "/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue?macro=true";
import { default as reefer_45monitoringtzekXJQlYoMeta } from "/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue?macro=true";
import { default as smart_45trailerszdgBvqmNYXMeta } from "/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue?macro=true";
import { default as trailer_45trackingvQ7IKBFEicMeta } from "/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue?macro=true";
import { default as indexZpimChz6QeMeta } from "/codebuild/output/src2560279378/src/horizon/pages/products/index.vue?macro=true";
import { default as _91slug_93kN2qORA385Meta } from "/codebuild/output/src2560279378/src/horizon/pages/products/models/[slug].vue?macro=true";
import { default as indexBeFCYus7N5Meta } from "/codebuild/output/src2560279378/src/horizon/pages/products/models/index.vue?macro=true";
import { default as gps_45fleet_45trackingIa0UgZ76FsMeta } from "/codebuild/output/src2560279378/src/horizon/pages/products/telematics/gps-fleet-tracking.vue?macro=true";
import { default as indexcANWpfwdCHMeta } from "/codebuild/output/src2560279378/src/horizon/pages/products/telematics/index.vue?macro=true";
import { default as racingEUjB53F3OCMeta } from "/codebuild/output/src2560279378/src/horizon/pages/racing.vue?macro=true";
import { default as referralskAhRNtxjJMMeta } from "/codebuild/output/src2560279378/src/horizon/pages/referrals.vue?macro=true";
import { default as _91slug_93ARrlhFiKOjMeta } from "/codebuild/output/src2560279378/src/horizon/pages/resources/[slug].vue?macro=true";
import { default as brand_45assetsJovfo9BjosMeta } from "/codebuild/output/src2560279378/src/horizon/pages/resources/brand-assets.vue?macro=true";
import { default as _91slug_9328621VIbpnMeta } from "/codebuild/output/src2560279378/src/horizon/pages/resources/content/[slug].vue?macro=true";
import { default as email_45preferences_45centerFqHXApW00HMeta } from "/codebuild/output/src2560279378/src/horizon/pages/resources/content/email-preferences-center.vue?macro=true";
import { default as _91slug_931UHpTYUayVMeta } from "/codebuild/output/src2560279378/src/horizon/pages/resources/experts/[slug].vue?macro=true";
import { default as _91slug_939pnqQFxEzEMeta } from "/codebuild/output/src2560279378/src/horizon/pages/resources/experts/category/[slug].vue?macro=true";
import { default as indexqhZRk4GVjSMeta } from "/codebuild/output/src2560279378/src/horizon/pages/resources/experts/index.vue?macro=true";
import { default as indexfjxJKxBZ3QMeta } from "/codebuild/output/src2560279378/src/horizon/pages/resources/index.vue?macro=true";
import { default as _91slug_93xgsBNWkv6RMeta } from "/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/[slug].vue?macro=true";
import { default as _91slug_93uVztDn1nFFMeta } from "/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/category/[slug].vue?macro=true";
import { default as indexei6QU6b6MnMeta } from "/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/index.vue?macro=true";
import { default as build_45with_45samsaraD7I2kdpqSuMeta } from "/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/build-with-samsara.vue?macro=true";
import { default as evolve_45insurance_45programs1g8wKIymUnMeta } from "/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue?macro=true";
import { default as indexMlqb27eXFGMeta } from "/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/index.vue?macro=true";
import { default as provide_45servicesYGwMX0BaPuMeta } from "/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/provide-services.vue?macro=true";
import { default as sell_45samsara0MVRrcLfDAMeta } from "/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/sell-samsara.vue?macro=true";
import { default as tourmryfGhhcPIMeta } from "/codebuild/output/src2560279378/src/horizon/pages/resources/tour.vue?macro=true";
import { default as videosGi8FrJmFbkMeta } from "/codebuild/output/src2560279378/src/horizon/pages/resources/videos.vue?macro=true";
import { default as indexTs5wEBHdCzMeta } from "/codebuild/output/src2560279378/src/horizon/pages/roi-calculator/index.vue?macro=true";
import { default as samsara_45ventures9plSWaLnUPMeta } from "/codebuild/output/src2560279378/src/horizon/pages/samsara-ventures.vue?macro=true";
import { default as security_45summitVC0OttwmGUMeta } from "/codebuild/output/src2560279378/src/horizon/pages/security-summit.vue?macro=true";
import { default as _91slug_93QHxO0HjFGoMeta } from "/codebuild/output/src2560279378/src/horizon/pages/solutions/[prefix]/[slug].vue?macro=true";
import { default as _91slug_937EEqtx3UjXMeta } from "/codebuild/output/src2560279378/src/horizon/pages/solutions/[slug].vue?macro=true";
import { default as indexV97nXepeF7Meta } from "/codebuild/output/src2560279378/src/horizon/pages/solutions/index.vue?macro=true";
import { default as securitylaORJlCGROMeta } from "/codebuild/output/src2560279378/src/horizon/pages/solutions/security.vue?macro=true";
import { default as spaceVtDdKepVcEMeta } from "/codebuild/output/src2560279378/src/horizon/pages/space.vue?macro=true";
import { default as spark2kQlUUBMNnMeta } from "/codebuild/output/src2560279378/src/horizon/pages/spark.vue?macro=true";
import { default as _91slug_93R3oXhyQPlZMeta } from "/codebuild/output/src2560279378/src/horizon/pages/styles/[slug].vue?macro=true";
import { default as _91slug_93jmeC6ZuH7CMeta } from "/codebuild/output/src2560279378/src/horizon/pages/support/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93fLvU98zWRhMeta } from "/codebuild/output/src2560279378/src/horizon/pages/support/[slug].vue?macro=true";
import { default as apps_45privacy_45policywIVn9QhX5rMeta } from "/codebuild/output/src2560279378/src/horizon/pages/support/apps-privacy-policy.vue?macro=true";
import { default as customer_45referrals_45faqgo4IN36HC7Meta } from "/codebuild/output/src2560279378/src/horizon/pages/support/customer-referrals-faq.vue?macro=true";
import { default as driver_45training_45spanishu7oOu2TlMaMeta } from "/codebuild/output/src2560279378/src/horizon/pages/support/driver-training-spanish.vue?macro=true";
import { default as driver_45trainingC0pZMUwy1sMeta } from "/codebuild/output/src2560279378/src/horizon/pages/support/driver-training.vue?macro=true";
import { default as indexziXagsi3wqMeta } from "/codebuild/output/src2560279378/src/horizon/pages/support/index.vue?macro=true";
import { default as industrialUhM70xIwxbMeta } from "/codebuild/output/src2560279378/src/horizon/pages/support/industrial.vue?macro=true";
import { default as modern_45slavery_45statement5Fg9KyEb2fMeta } from "/codebuild/output/src2560279378/src/horizon/pages/support/modern-slavery-statement.vue?macro=true";
import { default as apps_45privacy_45policyNRPMP3MeeAMeta } from "/codebuild/output/src2560279378/src/horizon/pages/support/privacy/apps-privacy-policy.vue?macro=true";
import { default as indexFXfw8wAngHMeta } from "/codebuild/output/src2560279378/src/horizon/pages/support/privacy/index.vue?macro=true";
import { default as special_45featuresy63xuBsXzoMeta } from "/codebuild/output/src2560279378/src/horizon/pages/support/privacy/special-features.vue?macro=true";
import { default as subprocessor_45listRKHpeLjTfuMeta } from "/codebuild/output/src2560279378/src/horizon/pages/support/privacy/subprocessor-list.vue?macro=true";
import { default as support_45kb_45articlesh6atXs9FVhMeta } from "/codebuild/output/src2560279378/src/horizon/pages/support/support-kb-articles.vue?macro=true";
import { default as thank_45you_45pageKa8mzY3j7nMeta } from "/codebuild/output/src2560279378/src/horizon/pages/thank-you-page.vue?macro=true";
import { default as thankyou_45recruitingbKiYsogZviMeta } from "/codebuild/output/src2560279378/src/horizon/pages/thankyou-recruiting.vue?macro=true";
import { default as thankyou_45referralLcJ5Ao10z1Meta } from "/codebuild/output/src2560279378/src/horizon/pages/thankyou-referral.vue?macro=true";
import { default as thankyouHQkxHZKgKlMeta } from "/codebuild/output/src2560279378/src/horizon/pages/thankyou.vue?macro=true";
import { default as eliteE2HtOhZ235Meta } from "/codebuild/output/src2560279378/src/horizon/pages/tiers/elite.vue?macro=true";
import { default as plusavIWDQLuvMMeta } from "/codebuild/output/src2560279378/src/horizon/pages/tiers/plus.vue?macro=true";
import { default as premierIz1ydV3RnWMeta } from "/codebuild/output/src2560279378/src/horizon/pages/tiers/premier.vue?macro=true";
import { default as starterrNqrp9oCYZMeta } from "/codebuild/output/src2560279378/src/horizon/pages/tiers/starter.vue?macro=true";
import { default as process3XQYl9WadEMeta } from "/codebuild/output/src2560279378/src/horizon/pages/trial/process.vue?macro=true";
import { default as supportfGi45KLfv3Meta } from "/codebuild/output/src2560279378/src/horizon/pages/warranty/support.vue?macro=true";
import { default as _91slug_93jIPtLgs49lMeta } from "/codebuild/output/src2560279378/src/horizon/pages/webinars/[slug].vue?macro=true";
import { default as indexJAaPmQQsuwMeta } from "/codebuild/output/src2560279378/src/horizon/pages/webinars/index.vue?macro=true";
import { default as _91slug_93SkmiIySTmkMeta } from "/codebuild/output/src2560279378/src/horizon/pages/webinars/thank-you/[slug].vue?macro=true";
export default [
  {
    name: api_45legacyJlo614hhskMeta?.name ?? "api-legacy___en-US",
    path: api_45legacyJlo614hhskMeta?.path ?? "/api-legacy",
    meta: api_45legacyJlo614hhskMeta || {},
    alias: api_45legacyJlo614hhskMeta?.alias || [],
    redirect: api_45legacyJlo614hhskMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyJlo614hhskMeta?.name ?? "api-legacy___ca",
    path: api_45legacyJlo614hhskMeta?.path ?? "/ca/api-legacy",
    meta: api_45legacyJlo614hhskMeta || {},
    alias: api_45legacyJlo614hhskMeta?.alias || [],
    redirect: api_45legacyJlo614hhskMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyJlo614hhskMeta?.name ?? "api-legacy___nl",
    path: api_45legacyJlo614hhskMeta?.path ?? "/nl/api-legacy",
    meta: api_45legacyJlo614hhskMeta || {},
    alias: api_45legacyJlo614hhskMeta?.alias || [],
    redirect: api_45legacyJlo614hhskMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyJlo614hhskMeta?.name ?? "api-legacy___uk",
    path: api_45legacyJlo614hhskMeta?.path ?? "/uk/api-legacy",
    meta: api_45legacyJlo614hhskMeta || {},
    alias: api_45legacyJlo614hhskMeta?.alias || [],
    redirect: api_45legacyJlo614hhskMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyJlo614hhskMeta?.name ?? "api-legacy___de",
    path: api_45legacyJlo614hhskMeta?.path ?? "/de/api-legacy",
    meta: api_45legacyJlo614hhskMeta || {},
    alias: api_45legacyJlo614hhskMeta?.alias || [],
    redirect: api_45legacyJlo614hhskMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyJlo614hhskMeta?.name ?? "api-legacy___mx",
    path: api_45legacyJlo614hhskMeta?.path ?? "/mx/api-legacy",
    meta: api_45legacyJlo614hhskMeta || {},
    alias: api_45legacyJlo614hhskMeta?.alias || [],
    redirect: api_45legacyJlo614hhskMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyJlo614hhskMeta?.name ?? "api-legacy___fr",
    path: api_45legacyJlo614hhskMeta?.path ?? "/fr/api-legacy",
    meta: api_45legacyJlo614hhskMeta || {},
    alias: api_45legacyJlo614hhskMeta?.alias || [],
    redirect: api_45legacyJlo614hhskMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyJlo614hhskMeta?.name ?? "api-legacy___fr-ca",
    path: api_45legacyJlo614hhskMeta?.path ?? "/fr-ca/api-legacy",
    meta: api_45legacyJlo614hhskMeta || {},
    alias: api_45legacyJlo614hhskMeta?.alias || [],
    redirect: api_45legacyJlo614hhskMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: awardsuNM60k7HprMeta?.name ?? "awards___en-US",
    path: awardsuNM60k7HprMeta?.path ?? "/awards",
    meta: awardsuNM60k7HprMeta || {},
    alias: awardsuNM60k7HprMeta?.alias || [],
    redirect: awardsuNM60k7HprMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsuNM60k7HprMeta?.name ?? "awards___ca",
    path: awardsuNM60k7HprMeta?.path ?? "/ca/awards",
    meta: awardsuNM60k7HprMeta || {},
    alias: awardsuNM60k7HprMeta?.alias || [],
    redirect: awardsuNM60k7HprMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsuNM60k7HprMeta?.name ?? "awards___nl",
    path: awardsuNM60k7HprMeta?.path ?? "/nl/awards",
    meta: awardsuNM60k7HprMeta || {},
    alias: awardsuNM60k7HprMeta?.alias || [],
    redirect: awardsuNM60k7HprMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsuNM60k7HprMeta?.name ?? "awards___uk",
    path: awardsuNM60k7HprMeta?.path ?? "/uk/awards",
    meta: awardsuNM60k7HprMeta || {},
    alias: awardsuNM60k7HprMeta?.alias || [],
    redirect: awardsuNM60k7HprMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsuNM60k7HprMeta?.name ?? "awards___de",
    path: awardsuNM60k7HprMeta?.path ?? "/de/awards",
    meta: awardsuNM60k7HprMeta || {},
    alias: awardsuNM60k7HprMeta?.alias || [],
    redirect: awardsuNM60k7HprMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsuNM60k7HprMeta?.name ?? "awards___mx",
    path: awardsuNM60k7HprMeta?.path ?? "/mx/awards",
    meta: awardsuNM60k7HprMeta || {},
    alias: awardsuNM60k7HprMeta?.alias || [],
    redirect: awardsuNM60k7HprMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsuNM60k7HprMeta?.name ?? "awards___fr",
    path: awardsuNM60k7HprMeta?.path ?? "/fr/awards",
    meta: awardsuNM60k7HprMeta || {},
    alias: awardsuNM60k7HprMeta?.alias || [],
    redirect: awardsuNM60k7HprMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsuNM60k7HprMeta?.name ?? "awards___fr-ca",
    path: awardsuNM60k7HprMeta?.path ?? "/fr-ca/awards",
    meta: awardsuNM60k7HprMeta || {},
    alias: awardsuNM60k7HprMeta?.alias || [],
    redirect: awardsuNM60k7HprMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: programsobAOUlGKglMeta?.name ?? "benefits-programs___en-US",
    path: programsobAOUlGKglMeta?.path ?? "/benefits/programs",
    meta: programsobAOUlGKglMeta || {},
    alias: programsobAOUlGKglMeta?.alias || [],
    redirect: programsobAOUlGKglMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsobAOUlGKglMeta?.name ?? "benefits-programs___ca",
    path: programsobAOUlGKglMeta?.path ?? "/ca/benefits/programs",
    meta: programsobAOUlGKglMeta || {},
    alias: programsobAOUlGKglMeta?.alias || [],
    redirect: programsobAOUlGKglMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsobAOUlGKglMeta?.name ?? "benefits-programs___nl",
    path: programsobAOUlGKglMeta?.path ?? "/nl/benefits/programs",
    meta: programsobAOUlGKglMeta || {},
    alias: programsobAOUlGKglMeta?.alias || [],
    redirect: programsobAOUlGKglMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsobAOUlGKglMeta?.name ?? "benefits-programs___uk",
    path: programsobAOUlGKglMeta?.path ?? "/uk/benefits/programs",
    meta: programsobAOUlGKglMeta || {},
    alias: programsobAOUlGKglMeta?.alias || [],
    redirect: programsobAOUlGKglMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsobAOUlGKglMeta?.name ?? "benefits-programs___de",
    path: programsobAOUlGKglMeta?.path ?? "/de/benefits/programs",
    meta: programsobAOUlGKglMeta || {},
    alias: programsobAOUlGKglMeta?.alias || [],
    redirect: programsobAOUlGKglMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsobAOUlGKglMeta?.name ?? "benefits-programs___mx",
    path: programsobAOUlGKglMeta?.path ?? "/mx/benefits/programs",
    meta: programsobAOUlGKglMeta || {},
    alias: programsobAOUlGKglMeta?.alias || [],
    redirect: programsobAOUlGKglMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsobAOUlGKglMeta?.name ?? "benefits-programs___fr",
    path: programsobAOUlGKglMeta?.path ?? "/fr/benefits/programs",
    meta: programsobAOUlGKglMeta || {},
    alias: programsobAOUlGKglMeta?.alias || [],
    redirect: programsobAOUlGKglMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsobAOUlGKglMeta?.name ?? "benefits-programs___fr-ca",
    path: programsobAOUlGKglMeta?.path ?? "/fr-ca/benefits/programs",
    meta: programsobAOUlGKglMeta || {},
    alias: programsobAOUlGKglMeta?.alias || [],
    redirect: programsobAOUlGKglMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: _91slug_933yCvzXhoPlMeta?.name ?? "blog-slug___en-US",
    path: _91slug_933yCvzXhoPlMeta?.path ?? "/blog/:slug()",
    meta: _91slug_933yCvzXhoPlMeta || {},
    alias: _91slug_933yCvzXhoPlMeta?.alias || [],
    redirect: _91slug_933yCvzXhoPlMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933yCvzXhoPlMeta?.name ?? "blog-slug___ca",
    path: _91slug_933yCvzXhoPlMeta?.path ?? "/ca/blog/:slug()",
    meta: _91slug_933yCvzXhoPlMeta || {},
    alias: _91slug_933yCvzXhoPlMeta?.alias || [],
    redirect: _91slug_933yCvzXhoPlMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933yCvzXhoPlMeta?.name ?? "blog-slug___mx",
    path: _91slug_933yCvzXhoPlMeta?.path ?? "/mx/blog/:slug()",
    meta: _91slug_933yCvzXhoPlMeta || {},
    alias: _91slug_933yCvzXhoPlMeta?.alias || [],
    redirect: _91slug_933yCvzXhoPlMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933yCvzXhoPlMeta?.name ?? "blog-slug___uk",
    path: _91slug_933yCvzXhoPlMeta?.path ?? "/uk/blog/:slug()",
    meta: _91slug_933yCvzXhoPlMeta || {},
    alias: _91slug_933yCvzXhoPlMeta?.alias || [],
    redirect: _91slug_933yCvzXhoPlMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933yCvzXhoPlMeta?.name ?? "blog-slug___fr",
    path: _91slug_933yCvzXhoPlMeta?.path ?? "/fr/blog/:slug()",
    meta: _91slug_933yCvzXhoPlMeta || {},
    alias: _91slug_933yCvzXhoPlMeta?.alias || [],
    redirect: _91slug_933yCvzXhoPlMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933yCvzXhoPlMeta?.name ?? "blog-slug___de",
    path: _91slug_933yCvzXhoPlMeta?.path ?? "/de/blog/:slug()",
    meta: _91slug_933yCvzXhoPlMeta || {},
    alias: _91slug_933yCvzXhoPlMeta?.alias || [],
    redirect: _91slug_933yCvzXhoPlMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q4BEM3z45cMeta?.name ?? "blog-category-slug___en-US",
    path: _91slug_93q4BEM3z45cMeta?.path ?? "/blog/category/:slug()",
    meta: _91slug_93q4BEM3z45cMeta || {},
    alias: _91slug_93q4BEM3z45cMeta?.alias || [],
    redirect: _91slug_93q4BEM3z45cMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q4BEM3z45cMeta?.name ?? "blog-category-slug___ca",
    path: _91slug_93q4BEM3z45cMeta?.path ?? "/ca/blog/category/:slug()",
    meta: _91slug_93q4BEM3z45cMeta || {},
    alias: _91slug_93q4BEM3z45cMeta?.alias || [],
    redirect: _91slug_93q4BEM3z45cMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q4BEM3z45cMeta?.name ?? "blog-category-slug___mx",
    path: _91slug_93q4BEM3z45cMeta?.path ?? "/mx/blog/category/:slug()",
    meta: _91slug_93q4BEM3z45cMeta || {},
    alias: _91slug_93q4BEM3z45cMeta?.alias || [],
    redirect: _91slug_93q4BEM3z45cMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q4BEM3z45cMeta?.name ?? "blog-category-slug___uk",
    path: _91slug_93q4BEM3z45cMeta?.path ?? "/uk/blog/category/:slug()",
    meta: _91slug_93q4BEM3z45cMeta || {},
    alias: _91slug_93q4BEM3z45cMeta?.alias || [],
    redirect: _91slug_93q4BEM3z45cMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q4BEM3z45cMeta?.name ?? "blog-category-slug___fr",
    path: _91slug_93q4BEM3z45cMeta?.path ?? "/fr/blog/category/:slug()",
    meta: _91slug_93q4BEM3z45cMeta || {},
    alias: _91slug_93q4BEM3z45cMeta?.alias || [],
    redirect: _91slug_93q4BEM3z45cMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q4BEM3z45cMeta?.name ?? "blog-category-slug___de",
    path: _91slug_93q4BEM3z45cMeta?.path ?? "/de/blog/category/:slug()",
    meta: _91slug_93q4BEM3z45cMeta || {},
    alias: _91slug_93q4BEM3z45cMeta?.alias || [],
    redirect: _91slug_93q4BEM3z45cMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexihyczTLGg8Meta?.name ?? "blog___en-US",
    path: indexihyczTLGg8Meta?.path ?? "/blog",
    meta: indexihyczTLGg8Meta || {},
    alias: indexihyczTLGg8Meta?.alias || [],
    redirect: indexihyczTLGg8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexihyczTLGg8Meta?.name ?? "blog___ca",
    path: indexihyczTLGg8Meta?.path ?? "/ca/blog",
    meta: indexihyczTLGg8Meta || {},
    alias: indexihyczTLGg8Meta?.alias || [],
    redirect: indexihyczTLGg8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexihyczTLGg8Meta?.name ?? "blog___mx",
    path: indexihyczTLGg8Meta?.path ?? "/mx/blog",
    meta: indexihyczTLGg8Meta || {},
    alias: indexihyczTLGg8Meta?.alias || [],
    redirect: indexihyczTLGg8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexihyczTLGg8Meta?.name ?? "blog___uk",
    path: indexihyczTLGg8Meta?.path ?? "/uk/blog",
    meta: indexihyczTLGg8Meta || {},
    alias: indexihyczTLGg8Meta?.alias || [],
    redirect: indexihyczTLGg8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexihyczTLGg8Meta?.name ?? "blog___fr",
    path: indexihyczTLGg8Meta?.path ?? "/fr/blog",
    meta: indexihyczTLGg8Meta || {},
    alias: indexihyczTLGg8Meta?.alias || [],
    redirect: indexihyczTLGg8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexihyczTLGg8Meta?.name ?? "blog___de",
    path: indexihyczTLGg8Meta?.path ?? "/de/blog",
    meta: indexihyczTLGg8Meta || {},
    alias: indexihyczTLGg8Meta?.alias || [],
    redirect: indexihyczTLGg8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.name ?? "call-for-speakers-at-samsara-beyond___en-US",
    path: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.path ?? "/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.name ?? "call-for-speakers-at-samsara-beyond___ca",
    path: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.path ?? "/ca/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.name ?? "call-for-speakers-at-samsara-beyond___nl",
    path: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.path ?? "/nl/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.name ?? "call-for-speakers-at-samsara-beyond___uk",
    path: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.path ?? "/uk/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.name ?? "call-for-speakers-at-samsara-beyond___de",
    path: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.path ?? "/de/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.name ?? "call-for-speakers-at-samsara-beyond___mx",
    path: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.path ?? "/mx/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.name ?? "call-for-speakers-at-samsara-beyond___fr",
    path: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.path ?? "/fr/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.name ?? "call-for-speakers-at-samsara-beyond___fr-ca",
    path: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.path ?? "/fr-ca/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondiglZ3rB8lCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93s7KvkdVov8Meta?.name ?? "company-prefix-slug___en-US",
    path: _91slug_93s7KvkdVov8Meta?.path ?? "/company/:prefix()/:slug()",
    meta: _91slug_93s7KvkdVov8Meta || {},
    alias: _91slug_93s7KvkdVov8Meta?.alias || [],
    redirect: _91slug_93s7KvkdVov8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93s7KvkdVov8Meta?.name ?? "company-prefix-slug___ca",
    path: _91slug_93s7KvkdVov8Meta?.path ?? "/ca/company/:prefix()/:slug()",
    meta: _91slug_93s7KvkdVov8Meta || {},
    alias: _91slug_93s7KvkdVov8Meta?.alias || [],
    redirect: _91slug_93s7KvkdVov8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93s7KvkdVov8Meta?.name ?? "company-prefix-slug___nl",
    path: _91slug_93s7KvkdVov8Meta?.path ?? "/nl/company/:prefix()/:slug()",
    meta: _91slug_93s7KvkdVov8Meta || {},
    alias: _91slug_93s7KvkdVov8Meta?.alias || [],
    redirect: _91slug_93s7KvkdVov8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93s7KvkdVov8Meta?.name ?? "company-prefix-slug___uk",
    path: _91slug_93s7KvkdVov8Meta?.path ?? "/uk/company/:prefix()/:slug()",
    meta: _91slug_93s7KvkdVov8Meta || {},
    alias: _91slug_93s7KvkdVov8Meta?.alias || [],
    redirect: _91slug_93s7KvkdVov8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93s7KvkdVov8Meta?.name ?? "company-prefix-slug___de",
    path: _91slug_93s7KvkdVov8Meta?.path ?? "/de/company/:prefix()/:slug()",
    meta: _91slug_93s7KvkdVov8Meta || {},
    alias: _91slug_93s7KvkdVov8Meta?.alias || [],
    redirect: _91slug_93s7KvkdVov8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93s7KvkdVov8Meta?.name ?? "company-prefix-slug___mx",
    path: _91slug_93s7KvkdVov8Meta?.path ?? "/mx/company/:prefix()/:slug()",
    meta: _91slug_93s7KvkdVov8Meta || {},
    alias: _91slug_93s7KvkdVov8Meta?.alias || [],
    redirect: _91slug_93s7KvkdVov8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93s7KvkdVov8Meta?.name ?? "company-prefix-slug___fr",
    path: _91slug_93s7KvkdVov8Meta?.path ?? "/fr/company/:prefix()/:slug()",
    meta: _91slug_93s7KvkdVov8Meta || {},
    alias: _91slug_93s7KvkdVov8Meta?.alias || [],
    redirect: _91slug_93s7KvkdVov8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93s7KvkdVov8Meta?.name ?? "company-prefix-slug___fr-ca",
    path: _91slug_93s7KvkdVov8Meta?.path ?? "/fr-ca/company/:prefix()/:slug()",
    meta: _91slug_93s7KvkdVov8Meta || {},
    alias: _91slug_93s7KvkdVov8Meta?.alias || [],
    redirect: _91slug_93s7KvkdVov8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QB4NXGlJtvMeta?.name ?? "company-slug___en-US",
    path: _91slug_93QB4NXGlJtvMeta?.path ?? "/company/:slug()",
    meta: _91slug_93QB4NXGlJtvMeta || {},
    alias: _91slug_93QB4NXGlJtvMeta?.alias || [],
    redirect: _91slug_93QB4NXGlJtvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QB4NXGlJtvMeta?.name ?? "company-slug___ca",
    path: _91slug_93QB4NXGlJtvMeta?.path ?? "/ca/company/:slug()",
    meta: _91slug_93QB4NXGlJtvMeta || {},
    alias: _91slug_93QB4NXGlJtvMeta?.alias || [],
    redirect: _91slug_93QB4NXGlJtvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QB4NXGlJtvMeta?.name ?? "company-slug___nl",
    path: _91slug_93QB4NXGlJtvMeta?.path ?? "/nl/company/:slug()",
    meta: _91slug_93QB4NXGlJtvMeta || {},
    alias: _91slug_93QB4NXGlJtvMeta?.alias || [],
    redirect: _91slug_93QB4NXGlJtvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QB4NXGlJtvMeta?.name ?? "company-slug___uk",
    path: _91slug_93QB4NXGlJtvMeta?.path ?? "/uk/company/:slug()",
    meta: _91slug_93QB4NXGlJtvMeta || {},
    alias: _91slug_93QB4NXGlJtvMeta?.alias || [],
    redirect: _91slug_93QB4NXGlJtvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QB4NXGlJtvMeta?.name ?? "company-slug___de",
    path: _91slug_93QB4NXGlJtvMeta?.path ?? "/de/company/:slug()",
    meta: _91slug_93QB4NXGlJtvMeta || {},
    alias: _91slug_93QB4NXGlJtvMeta?.alias || [],
    redirect: _91slug_93QB4NXGlJtvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QB4NXGlJtvMeta?.name ?? "company-slug___mx",
    path: _91slug_93QB4NXGlJtvMeta?.path ?? "/mx/company/:slug()",
    meta: _91slug_93QB4NXGlJtvMeta || {},
    alias: _91slug_93QB4NXGlJtvMeta?.alias || [],
    redirect: _91slug_93QB4NXGlJtvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QB4NXGlJtvMeta?.name ?? "company-slug___fr",
    path: _91slug_93QB4NXGlJtvMeta?.path ?? "/fr/company/:slug()",
    meta: _91slug_93QB4NXGlJtvMeta || {},
    alias: _91slug_93QB4NXGlJtvMeta?.alias || [],
    redirect: _91slug_93QB4NXGlJtvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QB4NXGlJtvMeta?.name ?? "company-slug___fr-ca",
    path: _91slug_93QB4NXGlJtvMeta?.path ?? "/fr-ca/company/:slug()",
    meta: _91slug_93QB4NXGlJtvMeta || {},
    alias: _91slug_93QB4NXGlJtvMeta?.alias || [],
    redirect: _91slug_93QB4NXGlJtvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: aboutAgnPuTwlpMMeta?.name ?? "company-about___en-US",
    path: aboutAgnPuTwlpMMeta?.path ?? "/company/about",
    meta: aboutAgnPuTwlpMMeta || {},
    alias: aboutAgnPuTwlpMMeta?.alias || [],
    redirect: aboutAgnPuTwlpMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutAgnPuTwlpMMeta?.name ?? "company-about___ca",
    path: aboutAgnPuTwlpMMeta?.path ?? "/ca/company/about",
    meta: aboutAgnPuTwlpMMeta || {},
    alias: aboutAgnPuTwlpMMeta?.alias || [],
    redirect: aboutAgnPuTwlpMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutAgnPuTwlpMMeta?.name ?? "company-about___nl",
    path: aboutAgnPuTwlpMMeta?.path ?? "/nl/company/about",
    meta: aboutAgnPuTwlpMMeta || {},
    alias: aboutAgnPuTwlpMMeta?.alias || [],
    redirect: aboutAgnPuTwlpMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutAgnPuTwlpMMeta?.name ?? "company-about___uk",
    path: aboutAgnPuTwlpMMeta?.path ?? "/uk/company/about",
    meta: aboutAgnPuTwlpMMeta || {},
    alias: aboutAgnPuTwlpMMeta?.alias || [],
    redirect: aboutAgnPuTwlpMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutAgnPuTwlpMMeta?.name ?? "company-about___de",
    path: aboutAgnPuTwlpMMeta?.path ?? "/de/company/about",
    meta: aboutAgnPuTwlpMMeta || {},
    alias: aboutAgnPuTwlpMMeta?.alias || [],
    redirect: aboutAgnPuTwlpMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutAgnPuTwlpMMeta?.name ?? "company-about___mx",
    path: aboutAgnPuTwlpMMeta?.path ?? "/mx/company/about",
    meta: aboutAgnPuTwlpMMeta || {},
    alias: aboutAgnPuTwlpMMeta?.alias || [],
    redirect: aboutAgnPuTwlpMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutAgnPuTwlpMMeta?.name ?? "company-about___fr",
    path: aboutAgnPuTwlpMMeta?.path ?? "/fr/company/about",
    meta: aboutAgnPuTwlpMMeta || {},
    alias: aboutAgnPuTwlpMMeta?.alias || [],
    redirect: aboutAgnPuTwlpMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutAgnPuTwlpMMeta?.name ?? "company-about___fr-ca",
    path: aboutAgnPuTwlpMMeta?.path ?? "/fr-ca/company/about",
    meta: aboutAgnPuTwlpMMeta || {},
    alias: aboutAgnPuTwlpMMeta?.alias || [],
    redirect: aboutAgnPuTwlpMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: indexPWCtfbiSdrMeta?.name ?? "company-careers-benefits___en-US",
    path: indexPWCtfbiSdrMeta?.path ?? "/company/careers/benefits",
    meta: indexPWCtfbiSdrMeta || {},
    alias: indexPWCtfbiSdrMeta?.alias || [],
    redirect: indexPWCtfbiSdrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexPWCtfbiSdrMeta?.name ?? "company-careers-benefits___ca",
    path: indexPWCtfbiSdrMeta?.path ?? "/ca/company/careers/benefits",
    meta: indexPWCtfbiSdrMeta || {},
    alias: indexPWCtfbiSdrMeta?.alias || [],
    redirect: indexPWCtfbiSdrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexPWCtfbiSdrMeta?.name ?? "company-careers-benefits___nl",
    path: indexPWCtfbiSdrMeta?.path ?? "/nl/company/careers/benefits",
    meta: indexPWCtfbiSdrMeta || {},
    alias: indexPWCtfbiSdrMeta?.alias || [],
    redirect: indexPWCtfbiSdrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexPWCtfbiSdrMeta?.name ?? "company-careers-benefits___uk",
    path: indexPWCtfbiSdrMeta?.path ?? "/uk/company/careers/benefits",
    meta: indexPWCtfbiSdrMeta || {},
    alias: indexPWCtfbiSdrMeta?.alias || [],
    redirect: indexPWCtfbiSdrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexPWCtfbiSdrMeta?.name ?? "company-careers-benefits___de",
    path: indexPWCtfbiSdrMeta?.path ?? "/de/company/careers/benefits",
    meta: indexPWCtfbiSdrMeta || {},
    alias: indexPWCtfbiSdrMeta?.alias || [],
    redirect: indexPWCtfbiSdrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexPWCtfbiSdrMeta?.name ?? "company-careers-benefits___mx",
    path: indexPWCtfbiSdrMeta?.path ?? "/mx/company/careers/benefits",
    meta: indexPWCtfbiSdrMeta || {},
    alias: indexPWCtfbiSdrMeta?.alias || [],
    redirect: indexPWCtfbiSdrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexPWCtfbiSdrMeta?.name ?? "company-careers-benefits___fr",
    path: indexPWCtfbiSdrMeta?.path ?? "/fr/company/careers/benefits",
    meta: indexPWCtfbiSdrMeta || {},
    alias: indexPWCtfbiSdrMeta?.alias || [],
    redirect: indexPWCtfbiSdrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexPWCtfbiSdrMeta?.name ?? "company-careers-benefits___fr-ca",
    path: indexPWCtfbiSdrMeta?.path ?? "/fr-ca/company/careers/benefits",
    meta: indexPWCtfbiSdrMeta || {},
    alias: indexPWCtfbiSdrMeta?.alias || [],
    redirect: indexPWCtfbiSdrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45producteYIHOeQolwMeta?.name ?? "company-careers-departments-engineering-and-product___en-US",
    path: engineering_45and_45producteYIHOeQolwMeta?.path ?? "/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45producteYIHOeQolwMeta || {},
    alias: engineering_45and_45producteYIHOeQolwMeta?.alias || [],
    redirect: engineering_45and_45producteYIHOeQolwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45producteYIHOeQolwMeta?.name ?? "company-careers-departments-engineering-and-product___ca",
    path: engineering_45and_45producteYIHOeQolwMeta?.path ?? "/ca/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45producteYIHOeQolwMeta || {},
    alias: engineering_45and_45producteYIHOeQolwMeta?.alias || [],
    redirect: engineering_45and_45producteYIHOeQolwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45producteYIHOeQolwMeta?.name ?? "company-careers-departments-engineering-and-product___nl",
    path: engineering_45and_45producteYIHOeQolwMeta?.path ?? "/nl/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45producteYIHOeQolwMeta || {},
    alias: engineering_45and_45producteYIHOeQolwMeta?.alias || [],
    redirect: engineering_45and_45producteYIHOeQolwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45producteYIHOeQolwMeta?.name ?? "company-careers-departments-engineering-and-product___uk",
    path: engineering_45and_45producteYIHOeQolwMeta?.path ?? "/uk/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45producteYIHOeQolwMeta || {},
    alias: engineering_45and_45producteYIHOeQolwMeta?.alias || [],
    redirect: engineering_45and_45producteYIHOeQolwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45producteYIHOeQolwMeta?.name ?? "company-careers-departments-engineering-and-product___de",
    path: engineering_45and_45producteYIHOeQolwMeta?.path ?? "/de/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45producteYIHOeQolwMeta || {},
    alias: engineering_45and_45producteYIHOeQolwMeta?.alias || [],
    redirect: engineering_45and_45producteYIHOeQolwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45producteYIHOeQolwMeta?.name ?? "company-careers-departments-engineering-and-product___mx",
    path: engineering_45and_45producteYIHOeQolwMeta?.path ?? "/mx/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45producteYIHOeQolwMeta || {},
    alias: engineering_45and_45producteYIHOeQolwMeta?.alias || [],
    redirect: engineering_45and_45producteYIHOeQolwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45producteYIHOeQolwMeta?.name ?? "company-careers-departments-engineering-and-product___fr",
    path: engineering_45and_45producteYIHOeQolwMeta?.path ?? "/fr/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45producteYIHOeQolwMeta || {},
    alias: engineering_45and_45producteYIHOeQolwMeta?.alias || [],
    redirect: engineering_45and_45producteYIHOeQolwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45producteYIHOeQolwMeta?.name ?? "company-careers-departments-engineering-and-product___fr-ca",
    path: engineering_45and_45producteYIHOeQolwMeta?.path ?? "/fr-ca/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45producteYIHOeQolwMeta || {},
    alias: engineering_45and_45producteYIHOeQolwMeta?.alias || [],
    redirect: engineering_45and_45producteYIHOeQolwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: indexTe4y015656Meta?.name ?? "company-careers-departments___en-US",
    path: indexTe4y015656Meta?.path ?? "/company/careers/departments",
    meta: indexTe4y015656Meta || {},
    alias: indexTe4y015656Meta?.alias || [],
    redirect: indexTe4y015656Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexTe4y015656Meta?.name ?? "company-careers-departments___ca",
    path: indexTe4y015656Meta?.path ?? "/ca/company/careers/departments",
    meta: indexTe4y015656Meta || {},
    alias: indexTe4y015656Meta?.alias || [],
    redirect: indexTe4y015656Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexTe4y015656Meta?.name ?? "company-careers-departments___nl",
    path: indexTe4y015656Meta?.path ?? "/nl/company/careers/departments",
    meta: indexTe4y015656Meta || {},
    alias: indexTe4y015656Meta?.alias || [],
    redirect: indexTe4y015656Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexTe4y015656Meta?.name ?? "company-careers-departments___uk",
    path: indexTe4y015656Meta?.path ?? "/uk/company/careers/departments",
    meta: indexTe4y015656Meta || {},
    alias: indexTe4y015656Meta?.alias || [],
    redirect: indexTe4y015656Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexTe4y015656Meta?.name ?? "company-careers-departments___de",
    path: indexTe4y015656Meta?.path ?? "/de/company/careers/departments",
    meta: indexTe4y015656Meta || {},
    alias: indexTe4y015656Meta?.alias || [],
    redirect: indexTe4y015656Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexTe4y015656Meta?.name ?? "company-careers-departments___mx",
    path: indexTe4y015656Meta?.path ?? "/mx/company/careers/departments",
    meta: indexTe4y015656Meta || {},
    alias: indexTe4y015656Meta?.alias || [],
    redirect: indexTe4y015656Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexTe4y015656Meta?.name ?? "company-careers-departments___fr",
    path: indexTe4y015656Meta?.path ?? "/fr/company/careers/departments",
    meta: indexTe4y015656Meta || {},
    alias: indexTe4y015656Meta?.alias || [],
    redirect: indexTe4y015656Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexTe4y015656Meta?.name ?? "company-careers-departments___fr-ca",
    path: indexTe4y015656Meta?.path ?? "/fr-ca/company/careers/departments",
    meta: indexTe4y015656Meta || {},
    alias: indexTe4y015656Meta?.alias || [],
    redirect: indexTe4y015656Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: salesMavn4avyV5Meta?.name ?? "company-careers-departments-sales___en-US",
    path: salesMavn4avyV5Meta?.path ?? "/company/careers/departments/sales",
    meta: salesMavn4avyV5Meta || {},
    alias: salesMavn4avyV5Meta?.alias || [],
    redirect: salesMavn4avyV5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesMavn4avyV5Meta?.name ?? "company-careers-departments-sales___ca",
    path: salesMavn4avyV5Meta?.path ?? "/ca/company/careers/departments/sales",
    meta: salesMavn4avyV5Meta || {},
    alias: salesMavn4avyV5Meta?.alias || [],
    redirect: salesMavn4avyV5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesMavn4avyV5Meta?.name ?? "company-careers-departments-sales___nl",
    path: salesMavn4avyV5Meta?.path ?? "/nl/company/careers/departments/sales",
    meta: salesMavn4avyV5Meta || {},
    alias: salesMavn4avyV5Meta?.alias || [],
    redirect: salesMavn4avyV5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesMavn4avyV5Meta?.name ?? "company-careers-departments-sales___uk",
    path: salesMavn4avyV5Meta?.path ?? "/uk/company/careers/departments/sales",
    meta: salesMavn4avyV5Meta || {},
    alias: salesMavn4avyV5Meta?.alias || [],
    redirect: salesMavn4avyV5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesMavn4avyV5Meta?.name ?? "company-careers-departments-sales___de",
    path: salesMavn4avyV5Meta?.path ?? "/de/company/careers/departments/sales",
    meta: salesMavn4avyV5Meta || {},
    alias: salesMavn4avyV5Meta?.alias || [],
    redirect: salesMavn4avyV5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesMavn4avyV5Meta?.name ?? "company-careers-departments-sales___mx",
    path: salesMavn4avyV5Meta?.path ?? "/mx/company/careers/departments/sales",
    meta: salesMavn4avyV5Meta || {},
    alias: salesMavn4avyV5Meta?.alias || [],
    redirect: salesMavn4avyV5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesMavn4avyV5Meta?.name ?? "company-careers-departments-sales___fr",
    path: salesMavn4avyV5Meta?.path ?? "/fr/company/careers/departments/sales",
    meta: salesMavn4avyV5Meta || {},
    alias: salesMavn4avyV5Meta?.alias || [],
    redirect: salesMavn4avyV5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesMavn4avyV5Meta?.name ?? "company-careers-departments-sales___fr-ca",
    path: salesMavn4avyV5Meta?.path ?? "/fr-ca/company/careers/departments/sales",
    meta: salesMavn4avyV5Meta || {},
    alias: salesMavn4avyV5Meta?.alias || [],
    redirect: salesMavn4avyV5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivee4W2jz3yYQMeta?.name ?? "company-careers-emerging-talent-archive___en-US",
    path: emerging_45talent_45archivee4W2jz3yYQMeta?.path ?? "/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivee4W2jz3yYQMeta || {},
    alias: emerging_45talent_45archivee4W2jz3yYQMeta?.alias || [],
    redirect: emerging_45talent_45archivee4W2jz3yYQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivee4W2jz3yYQMeta?.name ?? "company-careers-emerging-talent-archive___ca",
    path: emerging_45talent_45archivee4W2jz3yYQMeta?.path ?? "/ca/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivee4W2jz3yYQMeta || {},
    alias: emerging_45talent_45archivee4W2jz3yYQMeta?.alias || [],
    redirect: emerging_45talent_45archivee4W2jz3yYQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivee4W2jz3yYQMeta?.name ?? "company-careers-emerging-talent-archive___nl",
    path: emerging_45talent_45archivee4W2jz3yYQMeta?.path ?? "/nl/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivee4W2jz3yYQMeta || {},
    alias: emerging_45talent_45archivee4W2jz3yYQMeta?.alias || [],
    redirect: emerging_45talent_45archivee4W2jz3yYQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivee4W2jz3yYQMeta?.name ?? "company-careers-emerging-talent-archive___uk",
    path: emerging_45talent_45archivee4W2jz3yYQMeta?.path ?? "/uk/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivee4W2jz3yYQMeta || {},
    alias: emerging_45talent_45archivee4W2jz3yYQMeta?.alias || [],
    redirect: emerging_45talent_45archivee4W2jz3yYQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivee4W2jz3yYQMeta?.name ?? "company-careers-emerging-talent-archive___de",
    path: emerging_45talent_45archivee4W2jz3yYQMeta?.path ?? "/de/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivee4W2jz3yYQMeta || {},
    alias: emerging_45talent_45archivee4W2jz3yYQMeta?.alias || [],
    redirect: emerging_45talent_45archivee4W2jz3yYQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivee4W2jz3yYQMeta?.name ?? "company-careers-emerging-talent-archive___mx",
    path: emerging_45talent_45archivee4W2jz3yYQMeta?.path ?? "/mx/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivee4W2jz3yYQMeta || {},
    alias: emerging_45talent_45archivee4W2jz3yYQMeta?.alias || [],
    redirect: emerging_45talent_45archivee4W2jz3yYQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivee4W2jz3yYQMeta?.name ?? "company-careers-emerging-talent-archive___fr",
    path: emerging_45talent_45archivee4W2jz3yYQMeta?.path ?? "/fr/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivee4W2jz3yYQMeta || {},
    alias: emerging_45talent_45archivee4W2jz3yYQMeta?.alias || [],
    redirect: emerging_45talent_45archivee4W2jz3yYQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivee4W2jz3yYQMeta?.name ?? "company-careers-emerging-talent-archive___fr-ca",
    path: emerging_45talent_45archivee4W2jz3yYQMeta?.path ?? "/fr-ca/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivee4W2jz3yYQMeta || {},
    alias: emerging_45talent_45archivee4W2jz3yYQMeta?.alias || [],
    redirect: emerging_45talent_45archivee4W2jz3yYQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: indexbPpNHEC5vqMeta?.name ?? "company-careers___en-US",
    path: indexbPpNHEC5vqMeta?.path ?? "/company/careers",
    meta: indexbPpNHEC5vqMeta || {},
    alias: indexbPpNHEC5vqMeta?.alias || [],
    redirect: indexbPpNHEC5vqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexbPpNHEC5vqMeta?.name ?? "company-careers___ca",
    path: indexbPpNHEC5vqMeta?.path ?? "/ca/company/careers",
    meta: indexbPpNHEC5vqMeta || {},
    alias: indexbPpNHEC5vqMeta?.alias || [],
    redirect: indexbPpNHEC5vqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexbPpNHEC5vqMeta?.name ?? "company-careers___nl",
    path: indexbPpNHEC5vqMeta?.path ?? "/nl/company/careers",
    meta: indexbPpNHEC5vqMeta || {},
    alias: indexbPpNHEC5vqMeta?.alias || [],
    redirect: indexbPpNHEC5vqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexbPpNHEC5vqMeta?.name ?? "company-careers___uk",
    path: indexbPpNHEC5vqMeta?.path ?? "/uk/company/careers",
    meta: indexbPpNHEC5vqMeta || {},
    alias: indexbPpNHEC5vqMeta?.alias || [],
    redirect: indexbPpNHEC5vqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexbPpNHEC5vqMeta?.name ?? "company-careers___de",
    path: indexbPpNHEC5vqMeta?.path ?? "/de/company/careers",
    meta: indexbPpNHEC5vqMeta || {},
    alias: indexbPpNHEC5vqMeta?.alias || [],
    redirect: indexbPpNHEC5vqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexbPpNHEC5vqMeta?.name ?? "company-careers___mx",
    path: indexbPpNHEC5vqMeta?.path ?? "/mx/company/careers",
    meta: indexbPpNHEC5vqMeta || {},
    alias: indexbPpNHEC5vqMeta?.alias || [],
    redirect: indexbPpNHEC5vqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexbPpNHEC5vqMeta?.name ?? "company-careers___fr",
    path: indexbPpNHEC5vqMeta?.path ?? "/fr/company/careers",
    meta: indexbPpNHEC5vqMeta || {},
    alias: indexbPpNHEC5vqMeta?.alias || [],
    redirect: indexbPpNHEC5vqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexbPpNHEC5vqMeta?.name ?? "company-careers___fr-ca",
    path: indexbPpNHEC5vqMeta?.path ?? "/fr-ca/company/careers",
    meta: indexbPpNHEC5vqMeta || {},
    alias: indexbPpNHEC5vqMeta?.alias || [],
    redirect: indexbPpNHEC5vqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: index5ST0Pi6jVQMeta?.name ?? "company-careers-locations___en-US",
    path: index5ST0Pi6jVQMeta?.path ?? "/company/careers/locations",
    meta: index5ST0Pi6jVQMeta || {},
    alias: index5ST0Pi6jVQMeta?.alias || [],
    redirect: index5ST0Pi6jVQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: index5ST0Pi6jVQMeta?.name ?? "company-careers-locations___ca",
    path: index5ST0Pi6jVQMeta?.path ?? "/ca/company/careers/locations",
    meta: index5ST0Pi6jVQMeta || {},
    alias: index5ST0Pi6jVQMeta?.alias || [],
    redirect: index5ST0Pi6jVQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: index5ST0Pi6jVQMeta?.name ?? "company-careers-locations___nl",
    path: index5ST0Pi6jVQMeta?.path ?? "/nl/company/careers/locations",
    meta: index5ST0Pi6jVQMeta || {},
    alias: index5ST0Pi6jVQMeta?.alias || [],
    redirect: index5ST0Pi6jVQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: index5ST0Pi6jVQMeta?.name ?? "company-careers-locations___uk",
    path: index5ST0Pi6jVQMeta?.path ?? "/uk/company/careers/locations",
    meta: index5ST0Pi6jVQMeta || {},
    alias: index5ST0Pi6jVQMeta?.alias || [],
    redirect: index5ST0Pi6jVQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: index5ST0Pi6jVQMeta?.name ?? "company-careers-locations___de",
    path: index5ST0Pi6jVQMeta?.path ?? "/de/company/careers/locations",
    meta: index5ST0Pi6jVQMeta || {},
    alias: index5ST0Pi6jVQMeta?.alias || [],
    redirect: index5ST0Pi6jVQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: index5ST0Pi6jVQMeta?.name ?? "company-careers-locations___mx",
    path: index5ST0Pi6jVQMeta?.path ?? "/mx/company/careers/locations",
    meta: index5ST0Pi6jVQMeta || {},
    alias: index5ST0Pi6jVQMeta?.alias || [],
    redirect: index5ST0Pi6jVQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: index5ST0Pi6jVQMeta?.name ?? "company-careers-locations___fr",
    path: index5ST0Pi6jVQMeta?.path ?? "/fr/company/careers/locations",
    meta: index5ST0Pi6jVQMeta || {},
    alias: index5ST0Pi6jVQMeta?.alias || [],
    redirect: index5ST0Pi6jVQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: index5ST0Pi6jVQMeta?.name ?? "company-careers-locations___fr-ca",
    path: index5ST0Pi6jVQMeta?.path ?? "/fr-ca/company/careers/locations",
    meta: index5ST0Pi6jVQMeta || {},
    alias: index5ST0Pi6jVQMeta?.alias || [],
    redirect: index5ST0Pi6jVQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_9314EP0ff5GZMeta?.name ?? "company-careers-roles-slug___en-US",
    path: _91slug_9314EP0ff5GZMeta?.path ?? "/company/careers/roles/:slug()",
    meta: _91slug_9314EP0ff5GZMeta || {},
    alias: _91slug_9314EP0ff5GZMeta?.alias || [],
    redirect: _91slug_9314EP0ff5GZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9314EP0ff5GZMeta?.name ?? "company-careers-roles-slug___ca",
    path: _91slug_9314EP0ff5GZMeta?.path ?? "/ca/company/careers/roles/:slug()",
    meta: _91slug_9314EP0ff5GZMeta || {},
    alias: _91slug_9314EP0ff5GZMeta?.alias || [],
    redirect: _91slug_9314EP0ff5GZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9314EP0ff5GZMeta?.name ?? "company-careers-roles-slug___nl",
    path: _91slug_9314EP0ff5GZMeta?.path ?? "/nl/company/careers/roles/:slug()",
    meta: _91slug_9314EP0ff5GZMeta || {},
    alias: _91slug_9314EP0ff5GZMeta?.alias || [],
    redirect: _91slug_9314EP0ff5GZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9314EP0ff5GZMeta?.name ?? "company-careers-roles-slug___uk",
    path: _91slug_9314EP0ff5GZMeta?.path ?? "/uk/company/careers/roles/:slug()",
    meta: _91slug_9314EP0ff5GZMeta || {},
    alias: _91slug_9314EP0ff5GZMeta?.alias || [],
    redirect: _91slug_9314EP0ff5GZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9314EP0ff5GZMeta?.name ?? "company-careers-roles-slug___de",
    path: _91slug_9314EP0ff5GZMeta?.path ?? "/de/company/careers/roles/:slug()",
    meta: _91slug_9314EP0ff5GZMeta || {},
    alias: _91slug_9314EP0ff5GZMeta?.alias || [],
    redirect: _91slug_9314EP0ff5GZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9314EP0ff5GZMeta?.name ?? "company-careers-roles-slug___mx",
    path: _91slug_9314EP0ff5GZMeta?.path ?? "/mx/company/careers/roles/:slug()",
    meta: _91slug_9314EP0ff5GZMeta || {},
    alias: _91slug_9314EP0ff5GZMeta?.alias || [],
    redirect: _91slug_9314EP0ff5GZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9314EP0ff5GZMeta?.name ?? "company-careers-roles-slug___fr",
    path: _91slug_9314EP0ff5GZMeta?.path ?? "/fr/company/careers/roles/:slug()",
    meta: _91slug_9314EP0ff5GZMeta || {},
    alias: _91slug_9314EP0ff5GZMeta?.alias || [],
    redirect: _91slug_9314EP0ff5GZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9314EP0ff5GZMeta?.name ?? "company-careers-roles-slug___fr-ca",
    path: _91slug_9314EP0ff5GZMeta?.path ?? "/fr-ca/company/careers/roles/:slug()",
    meta: _91slug_9314EP0ff5GZMeta || {},
    alias: _91slug_9314EP0ff5GZMeta?.alias || [],
    redirect: _91slug_9314EP0ff5GZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexzlLnaz7afTMeta?.name ?? "company-careers-roles___en-US",
    path: indexzlLnaz7afTMeta?.path ?? "/company/careers/roles",
    meta: indexzlLnaz7afTMeta || {},
    alias: indexzlLnaz7afTMeta?.alias || [],
    redirect: indexzlLnaz7afTMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexzlLnaz7afTMeta?.name ?? "company-careers-roles___ca",
    path: indexzlLnaz7afTMeta?.path ?? "/ca/company/careers/roles",
    meta: indexzlLnaz7afTMeta || {},
    alias: indexzlLnaz7afTMeta?.alias || [],
    redirect: indexzlLnaz7afTMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexzlLnaz7afTMeta?.name ?? "company-careers-roles___nl",
    path: indexzlLnaz7afTMeta?.path ?? "/nl/company/careers/roles",
    meta: indexzlLnaz7afTMeta || {},
    alias: indexzlLnaz7afTMeta?.alias || [],
    redirect: indexzlLnaz7afTMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexzlLnaz7afTMeta?.name ?? "company-careers-roles___uk",
    path: indexzlLnaz7afTMeta?.path ?? "/uk/company/careers/roles",
    meta: indexzlLnaz7afTMeta || {},
    alias: indexzlLnaz7afTMeta?.alias || [],
    redirect: indexzlLnaz7afTMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexzlLnaz7afTMeta?.name ?? "company-careers-roles___de",
    path: indexzlLnaz7afTMeta?.path ?? "/de/company/careers/roles",
    meta: indexzlLnaz7afTMeta || {},
    alias: indexzlLnaz7afTMeta?.alias || [],
    redirect: indexzlLnaz7afTMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexzlLnaz7afTMeta?.name ?? "company-careers-roles___mx",
    path: indexzlLnaz7afTMeta?.path ?? "/mx/company/careers/roles",
    meta: indexzlLnaz7afTMeta || {},
    alias: indexzlLnaz7afTMeta?.alias || [],
    redirect: indexzlLnaz7afTMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexzlLnaz7afTMeta?.name ?? "company-careers-roles___fr",
    path: indexzlLnaz7afTMeta?.path ?? "/fr/company/careers/roles",
    meta: indexzlLnaz7afTMeta || {},
    alias: indexzlLnaz7afTMeta?.alias || [],
    redirect: indexzlLnaz7afTMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexzlLnaz7afTMeta?.name ?? "company-careers-roles___fr-ca",
    path: indexzlLnaz7afTMeta?.path ?? "/fr-ca/company/careers/roles",
    meta: indexzlLnaz7afTMeta || {},
    alias: indexzlLnaz7afTMeta?.alias || [],
    redirect: indexzlLnaz7afTMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: diversitypwCXgJ5gr3Meta?.name ?? "company-diversity___en-US",
    path: diversitypwCXgJ5gr3Meta?.path ?? "/company/diversity",
    meta: diversitypwCXgJ5gr3Meta || {},
    alias: diversitypwCXgJ5gr3Meta?.alias || [],
    redirect: diversitypwCXgJ5gr3Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversitypwCXgJ5gr3Meta?.name ?? "company-diversity___ca",
    path: diversitypwCXgJ5gr3Meta?.path ?? "/ca/company/diversity",
    meta: diversitypwCXgJ5gr3Meta || {},
    alias: diversitypwCXgJ5gr3Meta?.alias || [],
    redirect: diversitypwCXgJ5gr3Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversitypwCXgJ5gr3Meta?.name ?? "company-diversity___nl",
    path: diversitypwCXgJ5gr3Meta?.path ?? "/nl/company/diversity",
    meta: diversitypwCXgJ5gr3Meta || {},
    alias: diversitypwCXgJ5gr3Meta?.alias || [],
    redirect: diversitypwCXgJ5gr3Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversitypwCXgJ5gr3Meta?.name ?? "company-diversity___uk",
    path: diversitypwCXgJ5gr3Meta?.path ?? "/uk/company/diversity",
    meta: diversitypwCXgJ5gr3Meta || {},
    alias: diversitypwCXgJ5gr3Meta?.alias || [],
    redirect: diversitypwCXgJ5gr3Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversitypwCXgJ5gr3Meta?.name ?? "company-diversity___de",
    path: diversitypwCXgJ5gr3Meta?.path ?? "/de/company/diversity",
    meta: diversitypwCXgJ5gr3Meta || {},
    alias: diversitypwCXgJ5gr3Meta?.alias || [],
    redirect: diversitypwCXgJ5gr3Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversitypwCXgJ5gr3Meta?.name ?? "company-diversity___mx",
    path: diversitypwCXgJ5gr3Meta?.path ?? "/mx/company/diversity",
    meta: diversitypwCXgJ5gr3Meta || {},
    alias: diversitypwCXgJ5gr3Meta?.alias || [],
    redirect: diversitypwCXgJ5gr3Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversitypwCXgJ5gr3Meta?.name ?? "company-diversity___fr",
    path: diversitypwCXgJ5gr3Meta?.path ?? "/fr/company/diversity",
    meta: diversitypwCXgJ5gr3Meta || {},
    alias: diversitypwCXgJ5gr3Meta?.alias || [],
    redirect: diversitypwCXgJ5gr3Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversitypwCXgJ5gr3Meta?.name ?? "company-diversity___fr-ca",
    path: diversitypwCXgJ5gr3Meta?.path ?? "/fr-ca/company/diversity",
    meta: diversitypwCXgJ5gr3Meta || {},
    alias: diversitypwCXgJ5gr3Meta?.alias || [],
    redirect: diversitypwCXgJ5gr3Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: index5hZtGO5qiYMeta?.name ?? "company___en-US",
    path: index5hZtGO5qiYMeta?.path ?? "/company",
    meta: index5hZtGO5qiYMeta || {},
    alias: index5hZtGO5qiYMeta?.alias || [],
    redirect: index5hZtGO5qiYMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index5hZtGO5qiYMeta?.name ?? "company___ca",
    path: index5hZtGO5qiYMeta?.path ?? "/ca/company",
    meta: index5hZtGO5qiYMeta || {},
    alias: index5hZtGO5qiYMeta?.alias || [],
    redirect: index5hZtGO5qiYMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index5hZtGO5qiYMeta?.name ?? "company___nl",
    path: index5hZtGO5qiYMeta?.path ?? "/nl/company",
    meta: index5hZtGO5qiYMeta || {},
    alias: index5hZtGO5qiYMeta?.alias || [],
    redirect: index5hZtGO5qiYMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index5hZtGO5qiYMeta?.name ?? "company___uk",
    path: index5hZtGO5qiYMeta?.path ?? "/uk/company",
    meta: index5hZtGO5qiYMeta || {},
    alias: index5hZtGO5qiYMeta?.alias || [],
    redirect: index5hZtGO5qiYMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index5hZtGO5qiYMeta?.name ?? "company___de",
    path: index5hZtGO5qiYMeta?.path ?? "/de/company",
    meta: index5hZtGO5qiYMeta || {},
    alias: index5hZtGO5qiYMeta?.alias || [],
    redirect: index5hZtGO5qiYMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index5hZtGO5qiYMeta?.name ?? "company___mx",
    path: index5hZtGO5qiYMeta?.path ?? "/mx/company",
    meta: index5hZtGO5qiYMeta || {},
    alias: index5hZtGO5qiYMeta?.alias || [],
    redirect: index5hZtGO5qiYMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index5hZtGO5qiYMeta?.name ?? "company___fr",
    path: index5hZtGO5qiYMeta?.path ?? "/fr/company",
    meta: index5hZtGO5qiYMeta || {},
    alias: index5hZtGO5qiYMeta?.alias || [],
    redirect: index5hZtGO5qiYMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index5hZtGO5qiYMeta?.name ?? "company___fr-ca",
    path: index5hZtGO5qiYMeta?.path ?? "/fr-ca/company",
    meta: index5hZtGO5qiYMeta || {},
    alias: index5hZtGO5qiYMeta?.alias || [],
    redirect: index5hZtGO5qiYMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: efficiencyapMGm4NgycMeta?.name ?? "company-mission-efficiency___en-US",
    path: efficiencyapMGm4NgycMeta?.path ?? "/company/mission/efficiency",
    meta: efficiencyapMGm4NgycMeta || {},
    alias: efficiencyapMGm4NgycMeta?.alias || [],
    redirect: efficiencyapMGm4NgycMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyapMGm4NgycMeta?.name ?? "company-mission-efficiency___ca",
    path: efficiencyapMGm4NgycMeta?.path ?? "/ca/company/mission/efficiency",
    meta: efficiencyapMGm4NgycMeta || {},
    alias: efficiencyapMGm4NgycMeta?.alias || [],
    redirect: efficiencyapMGm4NgycMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyapMGm4NgycMeta?.name ?? "company-mission-efficiency___nl",
    path: efficiencyapMGm4NgycMeta?.path ?? "/nl/company/mission/efficiency",
    meta: efficiencyapMGm4NgycMeta || {},
    alias: efficiencyapMGm4NgycMeta?.alias || [],
    redirect: efficiencyapMGm4NgycMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyapMGm4NgycMeta?.name ?? "company-mission-efficiency___uk",
    path: efficiencyapMGm4NgycMeta?.path ?? "/uk/company/mission/efficiency",
    meta: efficiencyapMGm4NgycMeta || {},
    alias: efficiencyapMGm4NgycMeta?.alias || [],
    redirect: efficiencyapMGm4NgycMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyapMGm4NgycMeta?.name ?? "company-mission-efficiency___de",
    path: efficiencyapMGm4NgycMeta?.path ?? "/de/company/mission/efficiency",
    meta: efficiencyapMGm4NgycMeta || {},
    alias: efficiencyapMGm4NgycMeta?.alias || [],
    redirect: efficiencyapMGm4NgycMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyapMGm4NgycMeta?.name ?? "company-mission-efficiency___mx",
    path: efficiencyapMGm4NgycMeta?.path ?? "/mx/company/mission/efficiency",
    meta: efficiencyapMGm4NgycMeta || {},
    alias: efficiencyapMGm4NgycMeta?.alias || [],
    redirect: efficiencyapMGm4NgycMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyapMGm4NgycMeta?.name ?? "company-mission-efficiency___fr",
    path: efficiencyapMGm4NgycMeta?.path ?? "/fr/company/mission/efficiency",
    meta: efficiencyapMGm4NgycMeta || {},
    alias: efficiencyapMGm4NgycMeta?.alias || [],
    redirect: efficiencyapMGm4NgycMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyapMGm4NgycMeta?.name ?? "company-mission-efficiency___fr-ca",
    path: efficiencyapMGm4NgycMeta?.path ?? "/fr-ca/company/mission/efficiency",
    meta: efficiencyapMGm4NgycMeta || {},
    alias: efficiencyapMGm4NgycMeta?.alias || [],
    redirect: efficiencyapMGm4NgycMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: safetyFdFE9dRcCsMeta?.name ?? "company-mission-safety___en-US",
    path: safetyFdFE9dRcCsMeta?.path ?? "/company/mission/safety",
    meta: safetyFdFE9dRcCsMeta || {},
    alias: safetyFdFE9dRcCsMeta?.alias || [],
    redirect: safetyFdFE9dRcCsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyFdFE9dRcCsMeta?.name ?? "company-mission-safety___ca",
    path: safetyFdFE9dRcCsMeta?.path ?? "/ca/company/mission/safety",
    meta: safetyFdFE9dRcCsMeta || {},
    alias: safetyFdFE9dRcCsMeta?.alias || [],
    redirect: safetyFdFE9dRcCsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyFdFE9dRcCsMeta?.name ?? "company-mission-safety___nl",
    path: safetyFdFE9dRcCsMeta?.path ?? "/nl/company/mission/safety",
    meta: safetyFdFE9dRcCsMeta || {},
    alias: safetyFdFE9dRcCsMeta?.alias || [],
    redirect: safetyFdFE9dRcCsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyFdFE9dRcCsMeta?.name ?? "company-mission-safety___uk",
    path: safetyFdFE9dRcCsMeta?.path ?? "/uk/company/mission/safety",
    meta: safetyFdFE9dRcCsMeta || {},
    alias: safetyFdFE9dRcCsMeta?.alias || [],
    redirect: safetyFdFE9dRcCsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyFdFE9dRcCsMeta?.name ?? "company-mission-safety___de",
    path: safetyFdFE9dRcCsMeta?.path ?? "/de/company/mission/safety",
    meta: safetyFdFE9dRcCsMeta || {},
    alias: safetyFdFE9dRcCsMeta?.alias || [],
    redirect: safetyFdFE9dRcCsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyFdFE9dRcCsMeta?.name ?? "company-mission-safety___mx",
    path: safetyFdFE9dRcCsMeta?.path ?? "/mx/company/mission/safety",
    meta: safetyFdFE9dRcCsMeta || {},
    alias: safetyFdFE9dRcCsMeta?.alias || [],
    redirect: safetyFdFE9dRcCsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyFdFE9dRcCsMeta?.name ?? "company-mission-safety___fr",
    path: safetyFdFE9dRcCsMeta?.path ?? "/fr/company/mission/safety",
    meta: safetyFdFE9dRcCsMeta || {},
    alias: safetyFdFE9dRcCsMeta?.alias || [],
    redirect: safetyFdFE9dRcCsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyFdFE9dRcCsMeta?.name ?? "company-mission-safety___fr-ca",
    path: safetyFdFE9dRcCsMeta?.path ?? "/fr-ca/company/mission/safety",
    meta: safetyFdFE9dRcCsMeta || {},
    alias: safetyFdFE9dRcCsMeta?.alias || [],
    redirect: safetyFdFE9dRcCsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityipBUVymnbyMeta?.name ?? "company-mission-sustainability___en-US",
    path: sustainabilityipBUVymnbyMeta?.path ?? "/company/mission/sustainability",
    meta: sustainabilityipBUVymnbyMeta || {},
    alias: sustainabilityipBUVymnbyMeta?.alias || [],
    redirect: sustainabilityipBUVymnbyMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityipBUVymnbyMeta?.name ?? "company-mission-sustainability___ca",
    path: sustainabilityipBUVymnbyMeta?.path ?? "/ca/company/mission/sustainability",
    meta: sustainabilityipBUVymnbyMeta || {},
    alias: sustainabilityipBUVymnbyMeta?.alias || [],
    redirect: sustainabilityipBUVymnbyMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityipBUVymnbyMeta?.name ?? "company-mission-sustainability___nl",
    path: sustainabilityipBUVymnbyMeta?.path ?? "/nl/company/mission/sustainability",
    meta: sustainabilityipBUVymnbyMeta || {},
    alias: sustainabilityipBUVymnbyMeta?.alias || [],
    redirect: sustainabilityipBUVymnbyMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityipBUVymnbyMeta?.name ?? "company-mission-sustainability___uk",
    path: sustainabilityipBUVymnbyMeta?.path ?? "/uk/company/mission/sustainability",
    meta: sustainabilityipBUVymnbyMeta || {},
    alias: sustainabilityipBUVymnbyMeta?.alias || [],
    redirect: sustainabilityipBUVymnbyMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityipBUVymnbyMeta?.name ?? "company-mission-sustainability___de",
    path: sustainabilityipBUVymnbyMeta?.path ?? "/de/company/mission/sustainability",
    meta: sustainabilityipBUVymnbyMeta || {},
    alias: sustainabilityipBUVymnbyMeta?.alias || [],
    redirect: sustainabilityipBUVymnbyMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityipBUVymnbyMeta?.name ?? "company-mission-sustainability___mx",
    path: sustainabilityipBUVymnbyMeta?.path ?? "/mx/company/mission/sustainability",
    meta: sustainabilityipBUVymnbyMeta || {},
    alias: sustainabilityipBUVymnbyMeta?.alias || [],
    redirect: sustainabilityipBUVymnbyMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityipBUVymnbyMeta?.name ?? "company-mission-sustainability___fr",
    path: sustainabilityipBUVymnbyMeta?.path ?? "/fr/company/mission/sustainability",
    meta: sustainabilityipBUVymnbyMeta || {},
    alias: sustainabilityipBUVymnbyMeta?.alias || [],
    redirect: sustainabilityipBUVymnbyMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityipBUVymnbyMeta?.name ?? "company-mission-sustainability___fr-ca",
    path: sustainabilityipBUVymnbyMeta?.path ?? "/fr-ca/company/mission/sustainability",
    meta: sustainabilityipBUVymnbyMeta || {},
    alias: sustainabilityipBUVymnbyMeta?.alias || [],
    redirect: sustainabilityipBUVymnbyMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newscfRQeRf4pkMeta?.name ?? "company-news-in-the-news___en-US",
    path: in_45the_45newscfRQeRf4pkMeta?.path ?? "/company/news/in-the-news",
    meta: in_45the_45newscfRQeRf4pkMeta || {},
    alias: in_45the_45newscfRQeRf4pkMeta?.alias || [],
    redirect: in_45the_45newscfRQeRf4pkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newscfRQeRf4pkMeta?.name ?? "company-news-in-the-news___ca",
    path: in_45the_45newscfRQeRf4pkMeta?.path ?? "/ca/company/news/in-the-news",
    meta: in_45the_45newscfRQeRf4pkMeta || {},
    alias: in_45the_45newscfRQeRf4pkMeta?.alias || [],
    redirect: in_45the_45newscfRQeRf4pkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newscfRQeRf4pkMeta?.name ?? "company-news-in-the-news___nl",
    path: in_45the_45newscfRQeRf4pkMeta?.path ?? "/nl/company/news/in-the-news",
    meta: in_45the_45newscfRQeRf4pkMeta || {},
    alias: in_45the_45newscfRQeRf4pkMeta?.alias || [],
    redirect: in_45the_45newscfRQeRf4pkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newscfRQeRf4pkMeta?.name ?? "company-news-in-the-news___uk",
    path: in_45the_45newscfRQeRf4pkMeta?.path ?? "/uk/company/news/in-the-news",
    meta: in_45the_45newscfRQeRf4pkMeta || {},
    alias: in_45the_45newscfRQeRf4pkMeta?.alias || [],
    redirect: in_45the_45newscfRQeRf4pkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newscfRQeRf4pkMeta?.name ?? "company-news-in-the-news___de",
    path: in_45the_45newscfRQeRf4pkMeta?.path ?? "/de/company/news/in-the-news",
    meta: in_45the_45newscfRQeRf4pkMeta || {},
    alias: in_45the_45newscfRQeRf4pkMeta?.alias || [],
    redirect: in_45the_45newscfRQeRf4pkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newscfRQeRf4pkMeta?.name ?? "company-news-in-the-news___mx",
    path: in_45the_45newscfRQeRf4pkMeta?.path ?? "/mx/company/news/in-the-news",
    meta: in_45the_45newscfRQeRf4pkMeta || {},
    alias: in_45the_45newscfRQeRf4pkMeta?.alias || [],
    redirect: in_45the_45newscfRQeRf4pkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newscfRQeRf4pkMeta?.name ?? "company-news-in-the-news___fr",
    path: in_45the_45newscfRQeRf4pkMeta?.path ?? "/fr/company/news/in-the-news",
    meta: in_45the_45newscfRQeRf4pkMeta || {},
    alias: in_45the_45newscfRQeRf4pkMeta?.alias || [],
    redirect: in_45the_45newscfRQeRf4pkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newscfRQeRf4pkMeta?.name ?? "company-news-in-the-news___fr-ca",
    path: in_45the_45newscfRQeRf4pkMeta?.path ?? "/fr-ca/company/news/in-the-news",
    meta: in_45the_45newscfRQeRf4pkMeta || {},
    alias: in_45the_45newscfRQeRf4pkMeta?.alias || [],
    redirect: in_45the_45newscfRQeRf4pkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: indexnBZgXo7BGzMeta?.name ?? "company-news___en-US",
    path: indexnBZgXo7BGzMeta?.path ?? "/company/news",
    meta: indexnBZgXo7BGzMeta || {},
    alias: indexnBZgXo7BGzMeta?.alias || [],
    redirect: indexnBZgXo7BGzMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexnBZgXo7BGzMeta?.name ?? "company-news___ca",
    path: indexnBZgXo7BGzMeta?.path ?? "/ca/company/news",
    meta: indexnBZgXo7BGzMeta || {},
    alias: indexnBZgXo7BGzMeta?.alias || [],
    redirect: indexnBZgXo7BGzMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexnBZgXo7BGzMeta?.name ?? "company-news___mx",
    path: indexnBZgXo7BGzMeta?.path ?? "/mx/company/news",
    meta: indexnBZgXo7BGzMeta || {},
    alias: indexnBZgXo7BGzMeta?.alias || [],
    redirect: indexnBZgXo7BGzMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexnBZgXo7BGzMeta?.name ?? "company-news___uk",
    path: indexnBZgXo7BGzMeta?.path ?? "/uk/company/news",
    meta: indexnBZgXo7BGzMeta || {},
    alias: indexnBZgXo7BGzMeta?.alias || [],
    redirect: indexnBZgXo7BGzMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesM9TPJ4WCdrMeta?.name ?? "company-news-press-releases___en-US",
    path: press_45releasesM9TPJ4WCdrMeta?.path ?? "/company/news/press-releases",
    meta: press_45releasesM9TPJ4WCdrMeta || {},
    alias: press_45releasesM9TPJ4WCdrMeta?.alias || [],
    redirect: press_45releasesM9TPJ4WCdrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesM9TPJ4WCdrMeta?.name ?? "company-news-press-releases___ca",
    path: press_45releasesM9TPJ4WCdrMeta?.path ?? "/ca/company/news/press-releases",
    meta: press_45releasesM9TPJ4WCdrMeta || {},
    alias: press_45releasesM9TPJ4WCdrMeta?.alias || [],
    redirect: press_45releasesM9TPJ4WCdrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesM9TPJ4WCdrMeta?.name ?? "company-news-press-releases___nl",
    path: press_45releasesM9TPJ4WCdrMeta?.path ?? "/nl/company/news/press-releases",
    meta: press_45releasesM9TPJ4WCdrMeta || {},
    alias: press_45releasesM9TPJ4WCdrMeta?.alias || [],
    redirect: press_45releasesM9TPJ4WCdrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesM9TPJ4WCdrMeta?.name ?? "company-news-press-releases___uk",
    path: press_45releasesM9TPJ4WCdrMeta?.path ?? "/uk/company/news/press-releases",
    meta: press_45releasesM9TPJ4WCdrMeta || {},
    alias: press_45releasesM9TPJ4WCdrMeta?.alias || [],
    redirect: press_45releasesM9TPJ4WCdrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesM9TPJ4WCdrMeta?.name ?? "company-news-press-releases___de",
    path: press_45releasesM9TPJ4WCdrMeta?.path ?? "/de/company/news/press-releases",
    meta: press_45releasesM9TPJ4WCdrMeta || {},
    alias: press_45releasesM9TPJ4WCdrMeta?.alias || [],
    redirect: press_45releasesM9TPJ4WCdrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesM9TPJ4WCdrMeta?.name ?? "company-news-press-releases___mx",
    path: press_45releasesM9TPJ4WCdrMeta?.path ?? "/mx/company/news/press-releases",
    meta: press_45releasesM9TPJ4WCdrMeta || {},
    alias: press_45releasesM9TPJ4WCdrMeta?.alias || [],
    redirect: press_45releasesM9TPJ4WCdrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesM9TPJ4WCdrMeta?.name ?? "company-news-press-releases___fr",
    path: press_45releasesM9TPJ4WCdrMeta?.path ?? "/fr/company/news/press-releases",
    meta: press_45releasesM9TPJ4WCdrMeta || {},
    alias: press_45releasesM9TPJ4WCdrMeta?.alias || [],
    redirect: press_45releasesM9TPJ4WCdrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesM9TPJ4WCdrMeta?.name ?? "company-news-press-releases___fr-ca",
    path: press_45releasesM9TPJ4WCdrMeta?.path ?? "/fr-ca/company/news/press-releases",
    meta: press_45releasesM9TPJ4WCdrMeta || {},
    alias: press_45releasesM9TPJ4WCdrMeta?.alias || [],
    redirect: press_45releasesM9TPJ4WCdrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: privacyrx0RWeyyUbMeta?.name ?? "company-privacy___en-US",
    path: privacyrx0RWeyyUbMeta?.path ?? "/company/privacy",
    meta: privacyrx0RWeyyUbMeta || {},
    alias: privacyrx0RWeyyUbMeta?.alias || [],
    redirect: privacyrx0RWeyyUbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyrx0RWeyyUbMeta?.name ?? "company-privacy___ca",
    path: privacyrx0RWeyyUbMeta?.path ?? "/ca/company/privacy",
    meta: privacyrx0RWeyyUbMeta || {},
    alias: privacyrx0RWeyyUbMeta?.alias || [],
    redirect: privacyrx0RWeyyUbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyrx0RWeyyUbMeta?.name ?? "company-privacy___nl",
    path: privacyrx0RWeyyUbMeta?.path ?? "/nl/company/privacy",
    meta: privacyrx0RWeyyUbMeta || {},
    alias: privacyrx0RWeyyUbMeta?.alias || [],
    redirect: privacyrx0RWeyyUbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyrx0RWeyyUbMeta?.name ?? "company-privacy___uk",
    path: privacyrx0RWeyyUbMeta?.path ?? "/uk/company/privacy",
    meta: privacyrx0RWeyyUbMeta || {},
    alias: privacyrx0RWeyyUbMeta?.alias || [],
    redirect: privacyrx0RWeyyUbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyrx0RWeyyUbMeta?.name ?? "company-privacy___de",
    path: privacyrx0RWeyyUbMeta?.path ?? "/de/company/privacy",
    meta: privacyrx0RWeyyUbMeta || {},
    alias: privacyrx0RWeyyUbMeta?.alias || [],
    redirect: privacyrx0RWeyyUbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyrx0RWeyyUbMeta?.name ?? "company-privacy___mx",
    path: privacyrx0RWeyyUbMeta?.path ?? "/mx/company/privacy",
    meta: privacyrx0RWeyyUbMeta || {},
    alias: privacyrx0RWeyyUbMeta?.alias || [],
    redirect: privacyrx0RWeyyUbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyrx0RWeyyUbMeta?.name ?? "company-privacy___fr",
    path: privacyrx0RWeyyUbMeta?.path ?? "/fr/company/privacy",
    meta: privacyrx0RWeyyUbMeta || {},
    alias: privacyrx0RWeyyUbMeta?.alias || [],
    redirect: privacyrx0RWeyyUbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyrx0RWeyyUbMeta?.name ?? "company-privacy___fr-ca",
    path: privacyrx0RWeyyUbMeta?.path ?? "/fr-ca/company/privacy",
    meta: privacyrx0RWeyyUbMeta || {},
    alias: privacyrx0RWeyyUbMeta?.alias || [],
    redirect: privacyrx0RWeyyUbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolzcKgiadzkIMeta?.name ?? "cost-assessment-tool___en-US",
    path: cost_45assessment_45toolzcKgiadzkIMeta?.path ?? "/cost-assessment-tool",
    meta: cost_45assessment_45toolzcKgiadzkIMeta || {},
    alias: cost_45assessment_45toolzcKgiadzkIMeta?.alias || [],
    redirect: cost_45assessment_45toolzcKgiadzkIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolzcKgiadzkIMeta?.name ?? "cost-assessment-tool___ca",
    path: cost_45assessment_45toolzcKgiadzkIMeta?.path ?? "/ca/cost-assessment-tool",
    meta: cost_45assessment_45toolzcKgiadzkIMeta || {},
    alias: cost_45assessment_45toolzcKgiadzkIMeta?.alias || [],
    redirect: cost_45assessment_45toolzcKgiadzkIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolzcKgiadzkIMeta?.name ?? "cost-assessment-tool___nl",
    path: cost_45assessment_45toolzcKgiadzkIMeta?.path ?? "/nl/cost-assessment-tool",
    meta: cost_45assessment_45toolzcKgiadzkIMeta || {},
    alias: cost_45assessment_45toolzcKgiadzkIMeta?.alias || [],
    redirect: cost_45assessment_45toolzcKgiadzkIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolzcKgiadzkIMeta?.name ?? "cost-assessment-tool___uk",
    path: cost_45assessment_45toolzcKgiadzkIMeta?.path ?? "/uk/cost-assessment-tool",
    meta: cost_45assessment_45toolzcKgiadzkIMeta || {},
    alias: cost_45assessment_45toolzcKgiadzkIMeta?.alias || [],
    redirect: cost_45assessment_45toolzcKgiadzkIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolzcKgiadzkIMeta?.name ?? "cost-assessment-tool___de",
    path: cost_45assessment_45toolzcKgiadzkIMeta?.path ?? "/de/cost-assessment-tool",
    meta: cost_45assessment_45toolzcKgiadzkIMeta || {},
    alias: cost_45assessment_45toolzcKgiadzkIMeta?.alias || [],
    redirect: cost_45assessment_45toolzcKgiadzkIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolzcKgiadzkIMeta?.name ?? "cost-assessment-tool___mx",
    path: cost_45assessment_45toolzcKgiadzkIMeta?.path ?? "/mx/cost-assessment-tool",
    meta: cost_45assessment_45toolzcKgiadzkIMeta || {},
    alias: cost_45assessment_45toolzcKgiadzkIMeta?.alias || [],
    redirect: cost_45assessment_45toolzcKgiadzkIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolzcKgiadzkIMeta?.name ?? "cost-assessment-tool___fr",
    path: cost_45assessment_45toolzcKgiadzkIMeta?.path ?? "/fr/cost-assessment-tool",
    meta: cost_45assessment_45toolzcKgiadzkIMeta || {},
    alias: cost_45assessment_45toolzcKgiadzkIMeta?.alias || [],
    redirect: cost_45assessment_45toolzcKgiadzkIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolzcKgiadzkIMeta?.name ?? "cost-assessment-tool___fr-ca",
    path: cost_45assessment_45toolzcKgiadzkIMeta?.path ?? "/fr-ca/cost-assessment-tool",
    meta: cost_45assessment_45toolzcKgiadzkIMeta || {},
    alias: cost_45assessment_45toolzcKgiadzkIMeta?.alias || [],
    redirect: cost_45assessment_45toolzcKgiadzkIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tFtml4AdP8Meta?.name ?? "customers-slug___en-US",
    path: _91slug_93tFtml4AdP8Meta?.path ?? "/customers/:slug()",
    meta: _91slug_93tFtml4AdP8Meta || {},
    alias: _91slug_93tFtml4AdP8Meta?.alias || [],
    redirect: _91slug_93tFtml4AdP8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tFtml4AdP8Meta?.name ?? "customers-slug___ca",
    path: _91slug_93tFtml4AdP8Meta?.path ?? "/ca/customers/:slug()",
    meta: _91slug_93tFtml4AdP8Meta || {},
    alias: _91slug_93tFtml4AdP8Meta?.alias || [],
    redirect: _91slug_93tFtml4AdP8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tFtml4AdP8Meta?.name ?? "customers-slug___nl",
    path: _91slug_93tFtml4AdP8Meta?.path ?? "/nl/customers/:slug()",
    meta: _91slug_93tFtml4AdP8Meta || {},
    alias: _91slug_93tFtml4AdP8Meta?.alias || [],
    redirect: _91slug_93tFtml4AdP8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tFtml4AdP8Meta?.name ?? "customers-slug___uk",
    path: _91slug_93tFtml4AdP8Meta?.path ?? "/uk/customers/:slug()",
    meta: _91slug_93tFtml4AdP8Meta || {},
    alias: _91slug_93tFtml4AdP8Meta?.alias || [],
    redirect: _91slug_93tFtml4AdP8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tFtml4AdP8Meta?.name ?? "customers-slug___de",
    path: _91slug_93tFtml4AdP8Meta?.path ?? "/de/customers/:slug()",
    meta: _91slug_93tFtml4AdP8Meta || {},
    alias: _91slug_93tFtml4AdP8Meta?.alias || [],
    redirect: _91slug_93tFtml4AdP8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tFtml4AdP8Meta?.name ?? "customers-slug___mx",
    path: _91slug_93tFtml4AdP8Meta?.path ?? "/mx/customers/:slug()",
    meta: _91slug_93tFtml4AdP8Meta || {},
    alias: _91slug_93tFtml4AdP8Meta?.alias || [],
    redirect: _91slug_93tFtml4AdP8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tFtml4AdP8Meta?.name ?? "customers-slug___fr",
    path: _91slug_93tFtml4AdP8Meta?.path ?? "/fr/customers/:slug()",
    meta: _91slug_93tFtml4AdP8Meta || {},
    alias: _91slug_93tFtml4AdP8Meta?.alias || [],
    redirect: _91slug_93tFtml4AdP8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tFtml4AdP8Meta?.name ?? "customers-slug___fr-ca",
    path: _91slug_93tFtml4AdP8Meta?.path ?? "/fr-ca/customers/:slug()",
    meta: _91slug_93tFtml4AdP8Meta || {},
    alias: _91slug_93tFtml4AdP8Meta?.alias || [],
    redirect: _91slug_93tFtml4AdP8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tW9doUYruiMeta?.name ?? "customers-category-slug___en-US",
    path: _91slug_93tW9doUYruiMeta?.path ?? "/customers/category/:slug()",
    meta: _91slug_93tW9doUYruiMeta || {},
    alias: _91slug_93tW9doUYruiMeta?.alias || [],
    redirect: _91slug_93tW9doUYruiMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tW9doUYruiMeta?.name ?? "customers-category-slug___ca",
    path: _91slug_93tW9doUYruiMeta?.path ?? "/ca/customers/category/:slug()",
    meta: _91slug_93tW9doUYruiMeta || {},
    alias: _91slug_93tW9doUYruiMeta?.alias || [],
    redirect: _91slug_93tW9doUYruiMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tW9doUYruiMeta?.name ?? "customers-category-slug___nl",
    path: _91slug_93tW9doUYruiMeta?.path ?? "/nl/customers/category/:slug()",
    meta: _91slug_93tW9doUYruiMeta || {},
    alias: _91slug_93tW9doUYruiMeta?.alias || [],
    redirect: _91slug_93tW9doUYruiMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tW9doUYruiMeta?.name ?? "customers-category-slug___uk",
    path: _91slug_93tW9doUYruiMeta?.path ?? "/uk/customers/category/:slug()",
    meta: _91slug_93tW9doUYruiMeta || {},
    alias: _91slug_93tW9doUYruiMeta?.alias || [],
    redirect: _91slug_93tW9doUYruiMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tW9doUYruiMeta?.name ?? "customers-category-slug___de",
    path: _91slug_93tW9doUYruiMeta?.path ?? "/de/customers/category/:slug()",
    meta: _91slug_93tW9doUYruiMeta || {},
    alias: _91slug_93tW9doUYruiMeta?.alias || [],
    redirect: _91slug_93tW9doUYruiMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tW9doUYruiMeta?.name ?? "customers-category-slug___mx",
    path: _91slug_93tW9doUYruiMeta?.path ?? "/mx/customers/category/:slug()",
    meta: _91slug_93tW9doUYruiMeta || {},
    alias: _91slug_93tW9doUYruiMeta?.alias || [],
    redirect: _91slug_93tW9doUYruiMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tW9doUYruiMeta?.name ?? "customers-category-slug___fr",
    path: _91slug_93tW9doUYruiMeta?.path ?? "/fr/customers/category/:slug()",
    meta: _91slug_93tW9doUYruiMeta || {},
    alias: _91slug_93tW9doUYruiMeta?.alias || [],
    redirect: _91slug_93tW9doUYruiMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tW9doUYruiMeta?.name ?? "customers-category-slug___fr-ca",
    path: _91slug_93tW9doUYruiMeta?.path ?? "/fr-ca/customers/category/:slug()",
    meta: _91slug_93tW9doUYruiMeta || {},
    alias: _91slug_93tW9doUYruiMeta?.alias || [],
    redirect: _91slug_93tW9doUYruiMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexQ6Du04TbJYMeta?.name ?? "customers___en-US",
    path: indexQ6Du04TbJYMeta?.path ?? "/customers",
    meta: indexQ6Du04TbJYMeta || {},
    alias: indexQ6Du04TbJYMeta?.alias || [],
    redirect: indexQ6Du04TbJYMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ6Du04TbJYMeta?.name ?? "customers___ca",
    path: indexQ6Du04TbJYMeta?.path ?? "/ca/customers",
    meta: indexQ6Du04TbJYMeta || {},
    alias: indexQ6Du04TbJYMeta?.alias || [],
    redirect: indexQ6Du04TbJYMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ6Du04TbJYMeta?.name ?? "customers___nl",
    path: indexQ6Du04TbJYMeta?.path ?? "/nl/customers",
    meta: indexQ6Du04TbJYMeta || {},
    alias: indexQ6Du04TbJYMeta?.alias || [],
    redirect: indexQ6Du04TbJYMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ6Du04TbJYMeta?.name ?? "customers___uk",
    path: indexQ6Du04TbJYMeta?.path ?? "/uk/customers",
    meta: indexQ6Du04TbJYMeta || {},
    alias: indexQ6Du04TbJYMeta?.alias || [],
    redirect: indexQ6Du04TbJYMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ6Du04TbJYMeta?.name ?? "customers___de",
    path: indexQ6Du04TbJYMeta?.path ?? "/de/customers",
    meta: indexQ6Du04TbJYMeta || {},
    alias: indexQ6Du04TbJYMeta?.alias || [],
    redirect: indexQ6Du04TbJYMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ6Du04TbJYMeta?.name ?? "customers___mx",
    path: indexQ6Du04TbJYMeta?.path ?? "/mx/customers",
    meta: indexQ6Du04TbJYMeta || {},
    alias: indexQ6Du04TbJYMeta?.alias || [],
    redirect: indexQ6Du04TbJYMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ6Du04TbJYMeta?.name ?? "customers___fr",
    path: indexQ6Du04TbJYMeta?.path ?? "/fr/customers",
    meta: indexQ6Du04TbJYMeta || {},
    alias: indexQ6Du04TbJYMeta?.alias || [],
    redirect: indexQ6Du04TbJYMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ6Du04TbJYMeta?.name ?? "customers___fr-ca",
    path: indexQ6Du04TbJYMeta?.path ?? "/fr-ca/customers",
    meta: indexQ6Du04TbJYMeta || {},
    alias: indexQ6Du04TbJYMeta?.alias || [],
    redirect: indexQ6Du04TbJYMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tcsRwvEArzMeta?.name ?? "customers-tips-slug___en-US",
    path: _91slug_93tcsRwvEArzMeta?.path ?? "/customers/tips/:slug()",
    meta: _91slug_93tcsRwvEArzMeta || {},
    alias: _91slug_93tcsRwvEArzMeta?.alias || [],
    redirect: _91slug_93tcsRwvEArzMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tcsRwvEArzMeta?.name ?? "customers-tips-slug___ca",
    path: _91slug_93tcsRwvEArzMeta?.path ?? "/ca/customers/tips/:slug()",
    meta: _91slug_93tcsRwvEArzMeta || {},
    alias: _91slug_93tcsRwvEArzMeta?.alias || [],
    redirect: _91slug_93tcsRwvEArzMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tcsRwvEArzMeta?.name ?? "customers-tips-slug___nl",
    path: _91slug_93tcsRwvEArzMeta?.path ?? "/nl/customers/tips/:slug()",
    meta: _91slug_93tcsRwvEArzMeta || {},
    alias: _91slug_93tcsRwvEArzMeta?.alias || [],
    redirect: _91slug_93tcsRwvEArzMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tcsRwvEArzMeta?.name ?? "customers-tips-slug___uk",
    path: _91slug_93tcsRwvEArzMeta?.path ?? "/uk/customers/tips/:slug()",
    meta: _91slug_93tcsRwvEArzMeta || {},
    alias: _91slug_93tcsRwvEArzMeta?.alias || [],
    redirect: _91slug_93tcsRwvEArzMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tcsRwvEArzMeta?.name ?? "customers-tips-slug___de",
    path: _91slug_93tcsRwvEArzMeta?.path ?? "/de/customers/tips/:slug()",
    meta: _91slug_93tcsRwvEArzMeta || {},
    alias: _91slug_93tcsRwvEArzMeta?.alias || [],
    redirect: _91slug_93tcsRwvEArzMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tcsRwvEArzMeta?.name ?? "customers-tips-slug___mx",
    path: _91slug_93tcsRwvEArzMeta?.path ?? "/mx/customers/tips/:slug()",
    meta: _91slug_93tcsRwvEArzMeta || {},
    alias: _91slug_93tcsRwvEArzMeta?.alias || [],
    redirect: _91slug_93tcsRwvEArzMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tcsRwvEArzMeta?.name ?? "customers-tips-slug___fr",
    path: _91slug_93tcsRwvEArzMeta?.path ?? "/fr/customers/tips/:slug()",
    meta: _91slug_93tcsRwvEArzMeta || {},
    alias: _91slug_93tcsRwvEArzMeta?.alias || [],
    redirect: _91slug_93tcsRwvEArzMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tcsRwvEArzMeta?.name ?? "customers-tips-slug___fr-ca",
    path: _91slug_93tcsRwvEArzMeta?.path ?? "/fr-ca/customers/tips/:slug()",
    meta: _91slug_93tcsRwvEArzMeta || {},
    alias: _91slug_93tcsRwvEArzMeta?.alias || [],
    redirect: _91slug_93tcsRwvEArzMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wj4X0sYRytMeta?.name ?? "customers-tips-category-slug___en-US",
    path: _91slug_93wj4X0sYRytMeta?.path ?? "/customers/tips/category/:slug()",
    meta: _91slug_93wj4X0sYRytMeta || {},
    alias: _91slug_93wj4X0sYRytMeta?.alias || [],
    redirect: _91slug_93wj4X0sYRytMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wj4X0sYRytMeta?.name ?? "customers-tips-category-slug___ca",
    path: _91slug_93wj4X0sYRytMeta?.path ?? "/ca/customers/tips/category/:slug()",
    meta: _91slug_93wj4X0sYRytMeta || {},
    alias: _91slug_93wj4X0sYRytMeta?.alias || [],
    redirect: _91slug_93wj4X0sYRytMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wj4X0sYRytMeta?.name ?? "customers-tips-category-slug___nl",
    path: _91slug_93wj4X0sYRytMeta?.path ?? "/nl/customers/tips/category/:slug()",
    meta: _91slug_93wj4X0sYRytMeta || {},
    alias: _91slug_93wj4X0sYRytMeta?.alias || [],
    redirect: _91slug_93wj4X0sYRytMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wj4X0sYRytMeta?.name ?? "customers-tips-category-slug___uk",
    path: _91slug_93wj4X0sYRytMeta?.path ?? "/uk/customers/tips/category/:slug()",
    meta: _91slug_93wj4X0sYRytMeta || {},
    alias: _91slug_93wj4X0sYRytMeta?.alias || [],
    redirect: _91slug_93wj4X0sYRytMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wj4X0sYRytMeta?.name ?? "customers-tips-category-slug___de",
    path: _91slug_93wj4X0sYRytMeta?.path ?? "/de/customers/tips/category/:slug()",
    meta: _91slug_93wj4X0sYRytMeta || {},
    alias: _91slug_93wj4X0sYRytMeta?.alias || [],
    redirect: _91slug_93wj4X0sYRytMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wj4X0sYRytMeta?.name ?? "customers-tips-category-slug___mx",
    path: _91slug_93wj4X0sYRytMeta?.path ?? "/mx/customers/tips/category/:slug()",
    meta: _91slug_93wj4X0sYRytMeta || {},
    alias: _91slug_93wj4X0sYRytMeta?.alias || [],
    redirect: _91slug_93wj4X0sYRytMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wj4X0sYRytMeta?.name ?? "customers-tips-category-slug___fr",
    path: _91slug_93wj4X0sYRytMeta?.path ?? "/fr/customers/tips/category/:slug()",
    meta: _91slug_93wj4X0sYRytMeta || {},
    alias: _91slug_93wj4X0sYRytMeta?.alias || [],
    redirect: _91slug_93wj4X0sYRytMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wj4X0sYRytMeta?.name ?? "customers-tips-category-slug___fr-ca",
    path: _91slug_93wj4X0sYRytMeta?.path ?? "/fr-ca/customers/tips/category/:slug()",
    meta: _91slug_93wj4X0sYRytMeta || {},
    alias: _91slug_93wj4X0sYRytMeta?.alias || [],
    redirect: _91slug_93wj4X0sYRytMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexzfNUyQO8HmMeta?.name ?? "customers-tips___en-US",
    path: indexzfNUyQO8HmMeta?.path ?? "/customers/tips",
    meta: indexzfNUyQO8HmMeta || {},
    alias: indexzfNUyQO8HmMeta?.alias || [],
    redirect: indexzfNUyQO8HmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexzfNUyQO8HmMeta?.name ?? "customers-tips___ca",
    path: indexzfNUyQO8HmMeta?.path ?? "/ca/customers/tips",
    meta: indexzfNUyQO8HmMeta || {},
    alias: indexzfNUyQO8HmMeta?.alias || [],
    redirect: indexzfNUyQO8HmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexzfNUyQO8HmMeta?.name ?? "customers-tips___nl",
    path: indexzfNUyQO8HmMeta?.path ?? "/nl/customers/tips",
    meta: indexzfNUyQO8HmMeta || {},
    alias: indexzfNUyQO8HmMeta?.alias || [],
    redirect: indexzfNUyQO8HmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexzfNUyQO8HmMeta?.name ?? "customers-tips___uk",
    path: indexzfNUyQO8HmMeta?.path ?? "/uk/customers/tips",
    meta: indexzfNUyQO8HmMeta || {},
    alias: indexzfNUyQO8HmMeta?.alias || [],
    redirect: indexzfNUyQO8HmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexzfNUyQO8HmMeta?.name ?? "customers-tips___de",
    path: indexzfNUyQO8HmMeta?.path ?? "/de/customers/tips",
    meta: indexzfNUyQO8HmMeta || {},
    alias: indexzfNUyQO8HmMeta?.alias || [],
    redirect: indexzfNUyQO8HmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexzfNUyQO8HmMeta?.name ?? "customers-tips___mx",
    path: indexzfNUyQO8HmMeta?.path ?? "/mx/customers/tips",
    meta: indexzfNUyQO8HmMeta || {},
    alias: indexzfNUyQO8HmMeta?.alias || [],
    redirect: indexzfNUyQO8HmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexzfNUyQO8HmMeta?.name ?? "customers-tips___fr",
    path: indexzfNUyQO8HmMeta?.path ?? "/fr/customers/tips",
    meta: indexzfNUyQO8HmMeta || {},
    alias: indexzfNUyQO8HmMeta?.alias || [],
    redirect: indexzfNUyQO8HmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexzfNUyQO8HmMeta?.name ?? "customers-tips___fr-ca",
    path: indexzfNUyQO8HmMeta?.path ?? "/fr-ca/customers/tips",
    meta: indexzfNUyQO8HmMeta || {},
    alias: indexzfNUyQO8HmMeta?.alias || [],
    redirect: indexzfNUyQO8HmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93BO8lHtSLh5Meta?.name ?? "customers-topfleets-prefix-slug___en-US",
    path: _91_46_46_46slug_93BO8lHtSLh5Meta?.path ?? "/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93BO8lHtSLh5Meta || {},
    alias: _91_46_46_46slug_93BO8lHtSLh5Meta?.alias || [],
    redirect: _91_46_46_46slug_93BO8lHtSLh5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93BO8lHtSLh5Meta?.name ?? "customers-topfleets-prefix-slug___ca",
    path: _91_46_46_46slug_93BO8lHtSLh5Meta?.path ?? "/ca/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93BO8lHtSLh5Meta || {},
    alias: _91_46_46_46slug_93BO8lHtSLh5Meta?.alias || [],
    redirect: _91_46_46_46slug_93BO8lHtSLh5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93BO8lHtSLh5Meta?.name ?? "customers-topfleets-prefix-slug___nl",
    path: _91_46_46_46slug_93BO8lHtSLh5Meta?.path ?? "/nl/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93BO8lHtSLh5Meta || {},
    alias: _91_46_46_46slug_93BO8lHtSLh5Meta?.alias || [],
    redirect: _91_46_46_46slug_93BO8lHtSLh5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93BO8lHtSLh5Meta?.name ?? "customers-topfleets-prefix-slug___uk",
    path: _91_46_46_46slug_93BO8lHtSLh5Meta?.path ?? "/uk/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93BO8lHtSLh5Meta || {},
    alias: _91_46_46_46slug_93BO8lHtSLh5Meta?.alias || [],
    redirect: _91_46_46_46slug_93BO8lHtSLh5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93BO8lHtSLh5Meta?.name ?? "customers-topfleets-prefix-slug___de",
    path: _91_46_46_46slug_93BO8lHtSLh5Meta?.path ?? "/de/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93BO8lHtSLh5Meta || {},
    alias: _91_46_46_46slug_93BO8lHtSLh5Meta?.alias || [],
    redirect: _91_46_46_46slug_93BO8lHtSLh5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93BO8lHtSLh5Meta?.name ?? "customers-topfleets-prefix-slug___mx",
    path: _91_46_46_46slug_93BO8lHtSLh5Meta?.path ?? "/mx/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93BO8lHtSLh5Meta || {},
    alias: _91_46_46_46slug_93BO8lHtSLh5Meta?.alias || [],
    redirect: _91_46_46_46slug_93BO8lHtSLh5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93BO8lHtSLh5Meta?.name ?? "customers-topfleets-prefix-slug___fr",
    path: _91_46_46_46slug_93BO8lHtSLh5Meta?.path ?? "/fr/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93BO8lHtSLh5Meta || {},
    alias: _91_46_46_46slug_93BO8lHtSLh5Meta?.alias || [],
    redirect: _91_46_46_46slug_93BO8lHtSLh5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93BO8lHtSLh5Meta?.name ?? "customers-topfleets-prefix-slug___fr-ca",
    path: _91_46_46_46slug_93BO8lHtSLh5Meta?.path ?? "/fr-ca/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93BO8lHtSLh5Meta || {},
    alias: _91_46_46_46slug_93BO8lHtSLh5Meta?.alias || [],
    redirect: _91_46_46_46slug_93BO8lHtSLh5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: above_45beyond0kPfraC3AMMeta?.name ?? "customers-topfleets-2019-above-beyond___en-US",
    path: above_45beyond0kPfraC3AMMeta?.path ?? "/customers/topfleets/2019/above-beyond",
    meta: above_45beyond0kPfraC3AMMeta || {},
    alias: above_45beyond0kPfraC3AMMeta?.alias || [],
    redirect: above_45beyond0kPfraC3AMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyond0kPfraC3AMMeta?.name ?? "customers-topfleets-2019-above-beyond___ca",
    path: above_45beyond0kPfraC3AMMeta?.path ?? "/ca/customers/topfleets/2019/above-beyond",
    meta: above_45beyond0kPfraC3AMMeta || {},
    alias: above_45beyond0kPfraC3AMMeta?.alias || [],
    redirect: above_45beyond0kPfraC3AMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyond0kPfraC3AMMeta?.name ?? "customers-topfleets-2019-above-beyond___nl",
    path: above_45beyond0kPfraC3AMMeta?.path ?? "/nl/customers/topfleets/2019/above-beyond",
    meta: above_45beyond0kPfraC3AMMeta || {},
    alias: above_45beyond0kPfraC3AMMeta?.alias || [],
    redirect: above_45beyond0kPfraC3AMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyond0kPfraC3AMMeta?.name ?? "customers-topfleets-2019-above-beyond___uk",
    path: above_45beyond0kPfraC3AMMeta?.path ?? "/uk/customers/topfleets/2019/above-beyond",
    meta: above_45beyond0kPfraC3AMMeta || {},
    alias: above_45beyond0kPfraC3AMMeta?.alias || [],
    redirect: above_45beyond0kPfraC3AMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyond0kPfraC3AMMeta?.name ?? "customers-topfleets-2019-above-beyond___de",
    path: above_45beyond0kPfraC3AMMeta?.path ?? "/de/customers/topfleets/2019/above-beyond",
    meta: above_45beyond0kPfraC3AMMeta || {},
    alias: above_45beyond0kPfraC3AMMeta?.alias || [],
    redirect: above_45beyond0kPfraC3AMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyond0kPfraC3AMMeta?.name ?? "customers-topfleets-2019-above-beyond___mx",
    path: above_45beyond0kPfraC3AMMeta?.path ?? "/mx/customers/topfleets/2019/above-beyond",
    meta: above_45beyond0kPfraC3AMMeta || {},
    alias: above_45beyond0kPfraC3AMMeta?.alias || [],
    redirect: above_45beyond0kPfraC3AMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyond0kPfraC3AMMeta?.name ?? "customers-topfleets-2019-above-beyond___fr",
    path: above_45beyond0kPfraC3AMMeta?.path ?? "/fr/customers/topfleets/2019/above-beyond",
    meta: above_45beyond0kPfraC3AMMeta || {},
    alias: above_45beyond0kPfraC3AMMeta?.alias || [],
    redirect: above_45beyond0kPfraC3AMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyond0kPfraC3AMMeta?.name ?? "customers-topfleets-2019-above-beyond___fr-ca",
    path: above_45beyond0kPfraC3AMMeta?.path ?? "/fr-ca/customers/topfleets/2019/above-beyond",
    meta: above_45beyond0kPfraC3AMMeta || {},
    alias: above_45beyond0kPfraC3AMMeta?.alias || [],
    redirect: above_45beyond0kPfraC3AMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance0RWLCPvekCMeta?.name ?? "customers-topfleets-2019-excellence-performance___en-US",
    path: excellence_45performance0RWLCPvekCMeta?.path ?? "/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance0RWLCPvekCMeta || {},
    alias: excellence_45performance0RWLCPvekCMeta?.alias || [],
    redirect: excellence_45performance0RWLCPvekCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance0RWLCPvekCMeta?.name ?? "customers-topfleets-2019-excellence-performance___ca",
    path: excellence_45performance0RWLCPvekCMeta?.path ?? "/ca/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance0RWLCPvekCMeta || {},
    alias: excellence_45performance0RWLCPvekCMeta?.alias || [],
    redirect: excellence_45performance0RWLCPvekCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance0RWLCPvekCMeta?.name ?? "customers-topfleets-2019-excellence-performance___nl",
    path: excellence_45performance0RWLCPvekCMeta?.path ?? "/nl/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance0RWLCPvekCMeta || {},
    alias: excellence_45performance0RWLCPvekCMeta?.alias || [],
    redirect: excellence_45performance0RWLCPvekCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance0RWLCPvekCMeta?.name ?? "customers-topfleets-2019-excellence-performance___uk",
    path: excellence_45performance0RWLCPvekCMeta?.path ?? "/uk/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance0RWLCPvekCMeta || {},
    alias: excellence_45performance0RWLCPvekCMeta?.alias || [],
    redirect: excellence_45performance0RWLCPvekCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance0RWLCPvekCMeta?.name ?? "customers-topfleets-2019-excellence-performance___de",
    path: excellence_45performance0RWLCPvekCMeta?.path ?? "/de/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance0RWLCPvekCMeta || {},
    alias: excellence_45performance0RWLCPvekCMeta?.alias || [],
    redirect: excellence_45performance0RWLCPvekCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance0RWLCPvekCMeta?.name ?? "customers-topfleets-2019-excellence-performance___mx",
    path: excellence_45performance0RWLCPvekCMeta?.path ?? "/mx/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance0RWLCPvekCMeta || {},
    alias: excellence_45performance0RWLCPvekCMeta?.alias || [],
    redirect: excellence_45performance0RWLCPvekCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance0RWLCPvekCMeta?.name ?? "customers-topfleets-2019-excellence-performance___fr",
    path: excellence_45performance0RWLCPvekCMeta?.path ?? "/fr/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance0RWLCPvekCMeta || {},
    alias: excellence_45performance0RWLCPvekCMeta?.alias || [],
    redirect: excellence_45performance0RWLCPvekCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance0RWLCPvekCMeta?.name ?? "customers-topfleets-2019-excellence-performance___fr-ca",
    path: excellence_45performance0RWLCPvekCMeta?.path ?? "/fr-ca/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance0RWLCPvekCMeta || {},
    alias: excellence_45performance0RWLCPvekCMeta?.alias || [],
    redirect: excellence_45performance0RWLCPvekCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service1zjUAemFzrMeta?.name ?? "customers-topfleets-2019-excellence-service___en-US",
    path: excellence_45service1zjUAemFzrMeta?.path ?? "/customers/topfleets/2019/excellence-service",
    meta: excellence_45service1zjUAemFzrMeta || {},
    alias: excellence_45service1zjUAemFzrMeta?.alias || [],
    redirect: excellence_45service1zjUAemFzrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service1zjUAemFzrMeta?.name ?? "customers-topfleets-2019-excellence-service___ca",
    path: excellence_45service1zjUAemFzrMeta?.path ?? "/ca/customers/topfleets/2019/excellence-service",
    meta: excellence_45service1zjUAemFzrMeta || {},
    alias: excellence_45service1zjUAemFzrMeta?.alias || [],
    redirect: excellence_45service1zjUAemFzrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service1zjUAemFzrMeta?.name ?? "customers-topfleets-2019-excellence-service___nl",
    path: excellence_45service1zjUAemFzrMeta?.path ?? "/nl/customers/topfleets/2019/excellence-service",
    meta: excellence_45service1zjUAemFzrMeta || {},
    alias: excellence_45service1zjUAemFzrMeta?.alias || [],
    redirect: excellence_45service1zjUAemFzrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service1zjUAemFzrMeta?.name ?? "customers-topfleets-2019-excellence-service___uk",
    path: excellence_45service1zjUAemFzrMeta?.path ?? "/uk/customers/topfleets/2019/excellence-service",
    meta: excellence_45service1zjUAemFzrMeta || {},
    alias: excellence_45service1zjUAemFzrMeta?.alias || [],
    redirect: excellence_45service1zjUAemFzrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service1zjUAemFzrMeta?.name ?? "customers-topfleets-2019-excellence-service___de",
    path: excellence_45service1zjUAemFzrMeta?.path ?? "/de/customers/topfleets/2019/excellence-service",
    meta: excellence_45service1zjUAemFzrMeta || {},
    alias: excellence_45service1zjUAemFzrMeta?.alias || [],
    redirect: excellence_45service1zjUAemFzrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service1zjUAemFzrMeta?.name ?? "customers-topfleets-2019-excellence-service___mx",
    path: excellence_45service1zjUAemFzrMeta?.path ?? "/mx/customers/topfleets/2019/excellence-service",
    meta: excellence_45service1zjUAemFzrMeta || {},
    alias: excellence_45service1zjUAemFzrMeta?.alias || [],
    redirect: excellence_45service1zjUAemFzrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service1zjUAemFzrMeta?.name ?? "customers-topfleets-2019-excellence-service___fr",
    path: excellence_45service1zjUAemFzrMeta?.path ?? "/fr/customers/topfleets/2019/excellence-service",
    meta: excellence_45service1zjUAemFzrMeta || {},
    alias: excellence_45service1zjUAemFzrMeta?.alias || [],
    redirect: excellence_45service1zjUAemFzrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service1zjUAemFzrMeta?.name ?? "customers-topfleets-2019-excellence-service___fr-ca",
    path: excellence_45service1zjUAemFzrMeta?.path ?? "/fr-ca/customers/topfleets/2019/excellence-service",
    meta: excellence_45service1zjUAemFzrMeta || {},
    alias: excellence_45service1zjUAemFzrMeta?.alias || [],
    redirect: excellence_45service1zjUAemFzrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: indexc75nC8hOjKMeta?.name ?? "customers-topfleets-2019___en-US",
    path: indexc75nC8hOjKMeta?.path ?? "/customers/topfleets/2019",
    meta: indexc75nC8hOjKMeta || {},
    alias: indexc75nC8hOjKMeta?.alias || [],
    redirect: indexc75nC8hOjKMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexc75nC8hOjKMeta?.name ?? "customers-topfleets-2019___ca",
    path: indexc75nC8hOjKMeta?.path ?? "/ca/customers/topfleets/2019",
    meta: indexc75nC8hOjKMeta || {},
    alias: indexc75nC8hOjKMeta?.alias || [],
    redirect: indexc75nC8hOjKMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexc75nC8hOjKMeta?.name ?? "customers-topfleets-2019___nl",
    path: indexc75nC8hOjKMeta?.path ?? "/nl/customers/topfleets/2019",
    meta: indexc75nC8hOjKMeta || {},
    alias: indexc75nC8hOjKMeta?.alias || [],
    redirect: indexc75nC8hOjKMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexc75nC8hOjKMeta?.name ?? "customers-topfleets-2019___uk",
    path: indexc75nC8hOjKMeta?.path ?? "/uk/customers/topfleets/2019",
    meta: indexc75nC8hOjKMeta || {},
    alias: indexc75nC8hOjKMeta?.alias || [],
    redirect: indexc75nC8hOjKMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexc75nC8hOjKMeta?.name ?? "customers-topfleets-2019___de",
    path: indexc75nC8hOjKMeta?.path ?? "/de/customers/topfleets/2019",
    meta: indexc75nC8hOjKMeta || {},
    alias: indexc75nC8hOjKMeta?.alias || [],
    redirect: indexc75nC8hOjKMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexc75nC8hOjKMeta?.name ?? "customers-topfleets-2019___mx",
    path: indexc75nC8hOjKMeta?.path ?? "/mx/customers/topfleets/2019",
    meta: indexc75nC8hOjKMeta || {},
    alias: indexc75nC8hOjKMeta?.alias || [],
    redirect: indexc75nC8hOjKMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexc75nC8hOjKMeta?.name ?? "customers-topfleets-2019___fr",
    path: indexc75nC8hOjKMeta?.path ?? "/fr/customers/topfleets/2019",
    meta: indexc75nC8hOjKMeta || {},
    alias: indexc75nC8hOjKMeta?.alias || [],
    redirect: indexc75nC8hOjKMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexc75nC8hOjKMeta?.name ?? "customers-topfleets-2019___fr-ca",
    path: indexc75nC8hOjKMeta?.path ?? "/fr-ca/customers/topfleets/2019",
    meta: indexc75nC8hOjKMeta || {},
    alias: indexc75nC8hOjKMeta?.alias || [],
    redirect: indexc75nC8hOjKMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetRXtA4w14MmMeta?.name ?? "customers-topfleets-2019-safest-fleet___en-US",
    path: safest_45fleetRXtA4w14MmMeta?.path ?? "/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetRXtA4w14MmMeta || {},
    alias: safest_45fleetRXtA4w14MmMeta?.alias || [],
    redirect: safest_45fleetRXtA4w14MmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetRXtA4w14MmMeta?.name ?? "customers-topfleets-2019-safest-fleet___ca",
    path: safest_45fleetRXtA4w14MmMeta?.path ?? "/ca/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetRXtA4w14MmMeta || {},
    alias: safest_45fleetRXtA4w14MmMeta?.alias || [],
    redirect: safest_45fleetRXtA4w14MmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetRXtA4w14MmMeta?.name ?? "customers-topfleets-2019-safest-fleet___nl",
    path: safest_45fleetRXtA4w14MmMeta?.path ?? "/nl/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetRXtA4w14MmMeta || {},
    alias: safest_45fleetRXtA4w14MmMeta?.alias || [],
    redirect: safest_45fleetRXtA4w14MmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetRXtA4w14MmMeta?.name ?? "customers-topfleets-2019-safest-fleet___uk",
    path: safest_45fleetRXtA4w14MmMeta?.path ?? "/uk/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetRXtA4w14MmMeta || {},
    alias: safest_45fleetRXtA4w14MmMeta?.alias || [],
    redirect: safest_45fleetRXtA4w14MmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetRXtA4w14MmMeta?.name ?? "customers-topfleets-2019-safest-fleet___de",
    path: safest_45fleetRXtA4w14MmMeta?.path ?? "/de/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetRXtA4w14MmMeta || {},
    alias: safest_45fleetRXtA4w14MmMeta?.alias || [],
    redirect: safest_45fleetRXtA4w14MmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetRXtA4w14MmMeta?.name ?? "customers-topfleets-2019-safest-fleet___mx",
    path: safest_45fleetRXtA4w14MmMeta?.path ?? "/mx/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetRXtA4w14MmMeta || {},
    alias: safest_45fleetRXtA4w14MmMeta?.alias || [],
    redirect: safest_45fleetRXtA4w14MmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetRXtA4w14MmMeta?.name ?? "customers-topfleets-2019-safest-fleet___fr",
    path: safest_45fleetRXtA4w14MmMeta?.path ?? "/fr/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetRXtA4w14MmMeta || {},
    alias: safest_45fleetRXtA4w14MmMeta?.alias || [],
    redirect: safest_45fleetRXtA4w14MmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetRXtA4w14MmMeta?.name ?? "customers-topfleets-2019-safest-fleet___fr-ca",
    path: safest_45fleetRXtA4w14MmMeta?.path ?? "/fr-ca/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetRXtA4w14MmMeta || {},
    alias: safest_45fleetRXtA4w14MmMeta?.alias || [],
    redirect: safest_45fleetRXtA4w14MmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: driver_45performancew721vUIdalMeta?.name ?? "customers-topfleets-2020-driver-performance___en-US",
    path: driver_45performancew721vUIdalMeta?.path ?? "/customers/topfleets/2020/driver-performance",
    meta: driver_45performancew721vUIdalMeta || {},
    alias: driver_45performancew721vUIdalMeta?.alias || [],
    redirect: driver_45performancew721vUIdalMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performancew721vUIdalMeta?.name ?? "customers-topfleets-2020-driver-performance___ca",
    path: driver_45performancew721vUIdalMeta?.path ?? "/ca/customers/topfleets/2020/driver-performance",
    meta: driver_45performancew721vUIdalMeta || {},
    alias: driver_45performancew721vUIdalMeta?.alias || [],
    redirect: driver_45performancew721vUIdalMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performancew721vUIdalMeta?.name ?? "customers-topfleets-2020-driver-performance___nl",
    path: driver_45performancew721vUIdalMeta?.path ?? "/nl/customers/topfleets/2020/driver-performance",
    meta: driver_45performancew721vUIdalMeta || {},
    alias: driver_45performancew721vUIdalMeta?.alias || [],
    redirect: driver_45performancew721vUIdalMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performancew721vUIdalMeta?.name ?? "customers-topfleets-2020-driver-performance___uk",
    path: driver_45performancew721vUIdalMeta?.path ?? "/uk/customers/topfleets/2020/driver-performance",
    meta: driver_45performancew721vUIdalMeta || {},
    alias: driver_45performancew721vUIdalMeta?.alias || [],
    redirect: driver_45performancew721vUIdalMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performancew721vUIdalMeta?.name ?? "customers-topfleets-2020-driver-performance___de",
    path: driver_45performancew721vUIdalMeta?.path ?? "/de/customers/topfleets/2020/driver-performance",
    meta: driver_45performancew721vUIdalMeta || {},
    alias: driver_45performancew721vUIdalMeta?.alias || [],
    redirect: driver_45performancew721vUIdalMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performancew721vUIdalMeta?.name ?? "customers-topfleets-2020-driver-performance___mx",
    path: driver_45performancew721vUIdalMeta?.path ?? "/mx/customers/topfleets/2020/driver-performance",
    meta: driver_45performancew721vUIdalMeta || {},
    alias: driver_45performancew721vUIdalMeta?.alias || [],
    redirect: driver_45performancew721vUIdalMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performancew721vUIdalMeta?.name ?? "customers-topfleets-2020-driver-performance___fr",
    path: driver_45performancew721vUIdalMeta?.path ?? "/fr/customers/topfleets/2020/driver-performance",
    meta: driver_45performancew721vUIdalMeta || {},
    alias: driver_45performancew721vUIdalMeta?.alias || [],
    redirect: driver_45performancew721vUIdalMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performancew721vUIdalMeta?.name ?? "customers-topfleets-2020-driver-performance___fr-ca",
    path: driver_45performancew721vUIdalMeta?.path ?? "/fr-ca/customers/topfleets/2020/driver-performance",
    meta: driver_45performancew721vUIdalMeta || {},
    alias: driver_45performancew721vUIdalMeta?.alias || [],
    redirect: driver_45performancew721vUIdalMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceRtIDOKE6YhMeta?.name ?? "customers-topfleets-2020-excellence-performance___en-US",
    path: excellence_45performanceRtIDOKE6YhMeta?.path ?? "/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceRtIDOKE6YhMeta || {},
    alias: excellence_45performanceRtIDOKE6YhMeta?.alias || [],
    redirect: excellence_45performanceRtIDOKE6YhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceRtIDOKE6YhMeta?.name ?? "customers-topfleets-2020-excellence-performance___ca",
    path: excellence_45performanceRtIDOKE6YhMeta?.path ?? "/ca/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceRtIDOKE6YhMeta || {},
    alias: excellence_45performanceRtIDOKE6YhMeta?.alias || [],
    redirect: excellence_45performanceRtIDOKE6YhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceRtIDOKE6YhMeta?.name ?? "customers-topfleets-2020-excellence-performance___nl",
    path: excellence_45performanceRtIDOKE6YhMeta?.path ?? "/nl/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceRtIDOKE6YhMeta || {},
    alias: excellence_45performanceRtIDOKE6YhMeta?.alias || [],
    redirect: excellence_45performanceRtIDOKE6YhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceRtIDOKE6YhMeta?.name ?? "customers-topfleets-2020-excellence-performance___uk",
    path: excellence_45performanceRtIDOKE6YhMeta?.path ?? "/uk/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceRtIDOKE6YhMeta || {},
    alias: excellence_45performanceRtIDOKE6YhMeta?.alias || [],
    redirect: excellence_45performanceRtIDOKE6YhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceRtIDOKE6YhMeta?.name ?? "customers-topfleets-2020-excellence-performance___de",
    path: excellence_45performanceRtIDOKE6YhMeta?.path ?? "/de/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceRtIDOKE6YhMeta || {},
    alias: excellence_45performanceRtIDOKE6YhMeta?.alias || [],
    redirect: excellence_45performanceRtIDOKE6YhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceRtIDOKE6YhMeta?.name ?? "customers-topfleets-2020-excellence-performance___mx",
    path: excellence_45performanceRtIDOKE6YhMeta?.path ?? "/mx/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceRtIDOKE6YhMeta || {},
    alias: excellence_45performanceRtIDOKE6YhMeta?.alias || [],
    redirect: excellence_45performanceRtIDOKE6YhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceRtIDOKE6YhMeta?.name ?? "customers-topfleets-2020-excellence-performance___fr",
    path: excellence_45performanceRtIDOKE6YhMeta?.path ?? "/fr/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceRtIDOKE6YhMeta || {},
    alias: excellence_45performanceRtIDOKE6YhMeta?.alias || [],
    redirect: excellence_45performanceRtIDOKE6YhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceRtIDOKE6YhMeta?.name ?? "customers-topfleets-2020-excellence-performance___fr-ca",
    path: excellence_45performanceRtIDOKE6YhMeta?.path ?? "/fr-ca/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceRtIDOKE6YhMeta || {},
    alias: excellence_45performanceRtIDOKE6YhMeta?.alias || [],
    redirect: excellence_45performanceRtIDOKE6YhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorMvVh2EPMHGMeta?.name ?? "customers-topfleets-2020-fleet-innovator___en-US",
    path: fleet_45innovatorMvVh2EPMHGMeta?.path ?? "/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorMvVh2EPMHGMeta || {},
    alias: fleet_45innovatorMvVh2EPMHGMeta?.alias || [],
    redirect: fleet_45innovatorMvVh2EPMHGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorMvVh2EPMHGMeta?.name ?? "customers-topfleets-2020-fleet-innovator___ca",
    path: fleet_45innovatorMvVh2EPMHGMeta?.path ?? "/ca/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorMvVh2EPMHGMeta || {},
    alias: fleet_45innovatorMvVh2EPMHGMeta?.alias || [],
    redirect: fleet_45innovatorMvVh2EPMHGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorMvVh2EPMHGMeta?.name ?? "customers-topfleets-2020-fleet-innovator___nl",
    path: fleet_45innovatorMvVh2EPMHGMeta?.path ?? "/nl/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorMvVh2EPMHGMeta || {},
    alias: fleet_45innovatorMvVh2EPMHGMeta?.alias || [],
    redirect: fleet_45innovatorMvVh2EPMHGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorMvVh2EPMHGMeta?.name ?? "customers-topfleets-2020-fleet-innovator___uk",
    path: fleet_45innovatorMvVh2EPMHGMeta?.path ?? "/uk/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorMvVh2EPMHGMeta || {},
    alias: fleet_45innovatorMvVh2EPMHGMeta?.alias || [],
    redirect: fleet_45innovatorMvVh2EPMHGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorMvVh2EPMHGMeta?.name ?? "customers-topfleets-2020-fleet-innovator___de",
    path: fleet_45innovatorMvVh2EPMHGMeta?.path ?? "/de/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorMvVh2EPMHGMeta || {},
    alias: fleet_45innovatorMvVh2EPMHGMeta?.alias || [],
    redirect: fleet_45innovatorMvVh2EPMHGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorMvVh2EPMHGMeta?.name ?? "customers-topfleets-2020-fleet-innovator___mx",
    path: fleet_45innovatorMvVh2EPMHGMeta?.path ?? "/mx/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorMvVh2EPMHGMeta || {},
    alias: fleet_45innovatorMvVh2EPMHGMeta?.alias || [],
    redirect: fleet_45innovatorMvVh2EPMHGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorMvVh2EPMHGMeta?.name ?? "customers-topfleets-2020-fleet-innovator___fr",
    path: fleet_45innovatorMvVh2EPMHGMeta?.path ?? "/fr/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorMvVh2EPMHGMeta || {},
    alias: fleet_45innovatorMvVh2EPMHGMeta?.alias || [],
    redirect: fleet_45innovatorMvVh2EPMHGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorMvVh2EPMHGMeta?.name ?? "customers-topfleets-2020-fleet-innovator___fr-ca",
    path: fleet_45innovatorMvVh2EPMHGMeta?.path ?? "/fr-ca/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorMvVh2EPMHGMeta || {},
    alias: fleet_45innovatorMvVh2EPMHGMeta?.alias || [],
    redirect: fleet_45innovatorMvVh2EPMHGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: index1hOj7KzEibMeta?.name ?? "customers-topfleets-2020___en-US",
    path: index1hOj7KzEibMeta?.path ?? "/customers/topfleets/2020",
    meta: index1hOj7KzEibMeta || {},
    alias: index1hOj7KzEibMeta?.alias || [],
    redirect: index1hOj7KzEibMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: index1hOj7KzEibMeta?.name ?? "customers-topfleets-2020___ca",
    path: index1hOj7KzEibMeta?.path ?? "/ca/customers/topfleets/2020",
    meta: index1hOj7KzEibMeta || {},
    alias: index1hOj7KzEibMeta?.alias || [],
    redirect: index1hOj7KzEibMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: index1hOj7KzEibMeta?.name ?? "customers-topfleets-2020___nl",
    path: index1hOj7KzEibMeta?.path ?? "/nl/customers/topfleets/2020",
    meta: index1hOj7KzEibMeta || {},
    alias: index1hOj7KzEibMeta?.alias || [],
    redirect: index1hOj7KzEibMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: index1hOj7KzEibMeta?.name ?? "customers-topfleets-2020___uk",
    path: index1hOj7KzEibMeta?.path ?? "/uk/customers/topfleets/2020",
    meta: index1hOj7KzEibMeta || {},
    alias: index1hOj7KzEibMeta?.alias || [],
    redirect: index1hOj7KzEibMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: index1hOj7KzEibMeta?.name ?? "customers-topfleets-2020___de",
    path: index1hOj7KzEibMeta?.path ?? "/de/customers/topfleets/2020",
    meta: index1hOj7KzEibMeta || {},
    alias: index1hOj7KzEibMeta?.alias || [],
    redirect: index1hOj7KzEibMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: index1hOj7KzEibMeta?.name ?? "customers-topfleets-2020___mx",
    path: index1hOj7KzEibMeta?.path ?? "/mx/customers/topfleets/2020",
    meta: index1hOj7KzEibMeta || {},
    alias: index1hOj7KzEibMeta?.alias || [],
    redirect: index1hOj7KzEibMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: index1hOj7KzEibMeta?.name ?? "customers-topfleets-2020___fr",
    path: index1hOj7KzEibMeta?.path ?? "/fr/customers/topfleets/2020",
    meta: index1hOj7KzEibMeta || {},
    alias: index1hOj7KzEibMeta?.alias || [],
    redirect: index1hOj7KzEibMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: index1hOj7KzEibMeta?.name ?? "customers-topfleets-2020___fr-ca",
    path: index1hOj7KzEibMeta?.path ?? "/fr-ca/customers/topfleets/2020",
    meta: index1hOj7KzEibMeta || {},
    alias: index1hOj7KzEibMeta?.alias || [],
    redirect: index1hOj7KzEibMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetUgGnUMjbsEMeta?.name ?? "customers-topfleets-2020-public-fleet___en-US",
    path: public_45fleetUgGnUMjbsEMeta?.path ?? "/customers/topfleets/2020/public-fleet",
    meta: public_45fleetUgGnUMjbsEMeta || {},
    alias: public_45fleetUgGnUMjbsEMeta?.alias || [],
    redirect: public_45fleetUgGnUMjbsEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetUgGnUMjbsEMeta?.name ?? "customers-topfleets-2020-public-fleet___ca",
    path: public_45fleetUgGnUMjbsEMeta?.path ?? "/ca/customers/topfleets/2020/public-fleet",
    meta: public_45fleetUgGnUMjbsEMeta || {},
    alias: public_45fleetUgGnUMjbsEMeta?.alias || [],
    redirect: public_45fleetUgGnUMjbsEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetUgGnUMjbsEMeta?.name ?? "customers-topfleets-2020-public-fleet___nl",
    path: public_45fleetUgGnUMjbsEMeta?.path ?? "/nl/customers/topfleets/2020/public-fleet",
    meta: public_45fleetUgGnUMjbsEMeta || {},
    alias: public_45fleetUgGnUMjbsEMeta?.alias || [],
    redirect: public_45fleetUgGnUMjbsEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetUgGnUMjbsEMeta?.name ?? "customers-topfleets-2020-public-fleet___uk",
    path: public_45fleetUgGnUMjbsEMeta?.path ?? "/uk/customers/topfleets/2020/public-fleet",
    meta: public_45fleetUgGnUMjbsEMeta || {},
    alias: public_45fleetUgGnUMjbsEMeta?.alias || [],
    redirect: public_45fleetUgGnUMjbsEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetUgGnUMjbsEMeta?.name ?? "customers-topfleets-2020-public-fleet___de",
    path: public_45fleetUgGnUMjbsEMeta?.path ?? "/de/customers/topfleets/2020/public-fleet",
    meta: public_45fleetUgGnUMjbsEMeta || {},
    alias: public_45fleetUgGnUMjbsEMeta?.alias || [],
    redirect: public_45fleetUgGnUMjbsEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetUgGnUMjbsEMeta?.name ?? "customers-topfleets-2020-public-fleet___mx",
    path: public_45fleetUgGnUMjbsEMeta?.path ?? "/mx/customers/topfleets/2020/public-fleet",
    meta: public_45fleetUgGnUMjbsEMeta || {},
    alias: public_45fleetUgGnUMjbsEMeta?.alias || [],
    redirect: public_45fleetUgGnUMjbsEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetUgGnUMjbsEMeta?.name ?? "customers-topfleets-2020-public-fleet___fr",
    path: public_45fleetUgGnUMjbsEMeta?.path ?? "/fr/customers/topfleets/2020/public-fleet",
    meta: public_45fleetUgGnUMjbsEMeta || {},
    alias: public_45fleetUgGnUMjbsEMeta?.alias || [],
    redirect: public_45fleetUgGnUMjbsEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetUgGnUMjbsEMeta?.name ?? "customers-topfleets-2020-public-fleet___fr-ca",
    path: public_45fleetUgGnUMjbsEMeta?.path ?? "/fr-ca/customers/topfleets/2020/public-fleet",
    meta: public_45fleetUgGnUMjbsEMeta || {},
    alias: public_45fleetUgGnUMjbsEMeta?.alias || [],
    redirect: public_45fleetUgGnUMjbsEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetrMfqk027LkMeta?.name ?? "customers-topfleets-2020-safest-fleet___en-US",
    path: safest_45fleetrMfqk027LkMeta?.path ?? "/customers/topfleets/2020/safest-fleet",
    meta: safest_45fleetrMfqk027LkMeta || {},
    alias: safest_45fleetrMfqk027LkMeta?.alias || [],
    redirect: safest_45fleetrMfqk027LkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetrMfqk027LkMeta?.name ?? "customers-topfleets-2020-safest-fleet___ca",
    path: safest_45fleetrMfqk027LkMeta?.path ?? "/ca/customers/topfleets/2020/safest-fleet",
    meta: safest_45fleetrMfqk027LkMeta || {},
    alias: safest_45fleetrMfqk027LkMeta?.alias || [],
    redirect: safest_45fleetrMfqk027LkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: indexmV65axE5QmMeta?.name ?? "customers-topfleets___en-US",
    path: indexmV65axE5QmMeta?.path ?? "/customers/topfleets",
    meta: indexmV65axE5QmMeta || {},
    alias: indexmV65axE5QmMeta?.alias || [],
    redirect: indexmV65axE5QmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexmV65axE5QmMeta?.name ?? "customers-topfleets___ca",
    path: indexmV65axE5QmMeta?.path ?? "/ca/customers/topfleets",
    meta: indexmV65axE5QmMeta || {},
    alias: indexmV65axE5QmMeta?.alias || [],
    redirect: indexmV65axE5QmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexmV65axE5QmMeta?.name ?? "customers-topfleets___nl",
    path: indexmV65axE5QmMeta?.path ?? "/nl/customers/topfleets",
    meta: indexmV65axE5QmMeta || {},
    alias: indexmV65axE5QmMeta?.alias || [],
    redirect: indexmV65axE5QmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexmV65axE5QmMeta?.name ?? "customers-topfleets___uk",
    path: indexmV65axE5QmMeta?.path ?? "/uk/customers/topfleets",
    meta: indexmV65axE5QmMeta || {},
    alias: indexmV65axE5QmMeta?.alias || [],
    redirect: indexmV65axE5QmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexmV65axE5QmMeta?.name ?? "customers-topfleets___de",
    path: indexmV65axE5QmMeta?.path ?? "/de/customers/topfleets",
    meta: indexmV65axE5QmMeta || {},
    alias: indexmV65axE5QmMeta?.alias || [],
    redirect: indexmV65axE5QmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexmV65axE5QmMeta?.name ?? "customers-topfleets___mx",
    path: indexmV65axE5QmMeta?.path ?? "/mx/customers/topfleets",
    meta: indexmV65axE5QmMeta || {},
    alias: indexmV65axE5QmMeta?.alias || [],
    redirect: indexmV65axE5QmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexmV65axE5QmMeta?.name ?? "customers-topfleets___fr",
    path: indexmV65axE5QmMeta?.path ?? "/fr/customers/topfleets",
    meta: indexmV65axE5QmMeta || {},
    alias: indexmV65axE5QmMeta?.alias || [],
    redirect: indexmV65axE5QmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexmV65axE5QmMeta?.name ?? "customers-topfleets___fr-ca",
    path: indexmV65axE5QmMeta?.path ?? "/fr-ca/customers/topfleets",
    meta: indexmV65axE5QmMeta || {},
    alias: indexmV65axE5QmMeta?.alias || [],
    redirect: indexmV65axE5QmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sA9CJYHof2Meta?.name ?? "events-slug___en-US",
    path: _91slug_93sA9CJYHof2Meta?.path ?? "/events/:slug()",
    meta: _91slug_93sA9CJYHof2Meta || {},
    alias: _91slug_93sA9CJYHof2Meta?.alias || [],
    redirect: _91slug_93sA9CJYHof2Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sA9CJYHof2Meta?.name ?? "events-slug___ca",
    path: _91slug_93sA9CJYHof2Meta?.path ?? "/ca/events/:slug()",
    meta: _91slug_93sA9CJYHof2Meta || {},
    alias: _91slug_93sA9CJYHof2Meta?.alias || [],
    redirect: _91slug_93sA9CJYHof2Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sA9CJYHof2Meta?.name ?? "events-slug___nl",
    path: _91slug_93sA9CJYHof2Meta?.path ?? "/nl/events/:slug()",
    meta: _91slug_93sA9CJYHof2Meta || {},
    alias: _91slug_93sA9CJYHof2Meta?.alias || [],
    redirect: _91slug_93sA9CJYHof2Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sA9CJYHof2Meta?.name ?? "events-slug___uk",
    path: _91slug_93sA9CJYHof2Meta?.path ?? "/uk/events/:slug()",
    meta: _91slug_93sA9CJYHof2Meta || {},
    alias: _91slug_93sA9CJYHof2Meta?.alias || [],
    redirect: _91slug_93sA9CJYHof2Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sA9CJYHof2Meta?.name ?? "events-slug___de",
    path: _91slug_93sA9CJYHof2Meta?.path ?? "/de/events/:slug()",
    meta: _91slug_93sA9CJYHof2Meta || {},
    alias: _91slug_93sA9CJYHof2Meta?.alias || [],
    redirect: _91slug_93sA9CJYHof2Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sA9CJYHof2Meta?.name ?? "events-slug___mx",
    path: _91slug_93sA9CJYHof2Meta?.path ?? "/mx/events/:slug()",
    meta: _91slug_93sA9CJYHof2Meta || {},
    alias: _91slug_93sA9CJYHof2Meta?.alias || [],
    redirect: _91slug_93sA9CJYHof2Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sA9CJYHof2Meta?.name ?? "events-slug___fr",
    path: _91slug_93sA9CJYHof2Meta?.path ?? "/fr/events/:slug()",
    meta: _91slug_93sA9CJYHof2Meta || {},
    alias: _91slug_93sA9CJYHof2Meta?.alias || [],
    redirect: _91slug_93sA9CJYHof2Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sA9CJYHof2Meta?.name ?? "events-slug___fr-ca",
    path: _91slug_93sA9CJYHof2Meta?.path ?? "/fr-ca/events/:slug()",
    meta: _91slug_93sA9CJYHof2Meta || {},
    alias: _91slug_93sA9CJYHof2Meta?.alias || [],
    redirect: _91slug_93sA9CJYHof2Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexMUU0lirlPvMeta?.name ?? "events___en-US",
    path: indexMUU0lirlPvMeta?.path ?? "/events",
    meta: indexMUU0lirlPvMeta || {},
    alias: indexMUU0lirlPvMeta?.alias || [],
    redirect: indexMUU0lirlPvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexMUU0lirlPvMeta?.name ?? "events___ca",
    path: indexMUU0lirlPvMeta?.path ?? "/ca/events",
    meta: indexMUU0lirlPvMeta || {},
    alias: indexMUU0lirlPvMeta?.alias || [],
    redirect: indexMUU0lirlPvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexMUU0lirlPvMeta?.name ?? "events___nl",
    path: indexMUU0lirlPvMeta?.path ?? "/nl/events",
    meta: indexMUU0lirlPvMeta || {},
    alias: indexMUU0lirlPvMeta?.alias || [],
    redirect: indexMUU0lirlPvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexMUU0lirlPvMeta?.name ?? "events___uk",
    path: indexMUU0lirlPvMeta?.path ?? "/uk/events",
    meta: indexMUU0lirlPvMeta || {},
    alias: indexMUU0lirlPvMeta?.alias || [],
    redirect: indexMUU0lirlPvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexMUU0lirlPvMeta?.name ?? "events___de",
    path: indexMUU0lirlPvMeta?.path ?? "/de/events",
    meta: indexMUU0lirlPvMeta || {},
    alias: indexMUU0lirlPvMeta?.alias || [],
    redirect: indexMUU0lirlPvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexMUU0lirlPvMeta?.name ?? "events___mx",
    path: indexMUU0lirlPvMeta?.path ?? "/mx/events",
    meta: indexMUU0lirlPvMeta || {},
    alias: indexMUU0lirlPvMeta?.alias || [],
    redirect: indexMUU0lirlPvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexMUU0lirlPvMeta?.name ?? "events___fr",
    path: indexMUU0lirlPvMeta?.path ?? "/fr/events",
    meta: indexMUU0lirlPvMeta || {},
    alias: indexMUU0lirlPvMeta?.alias || [],
    redirect: indexMUU0lirlPvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexMUU0lirlPvMeta?.name ?? "events___fr-ca",
    path: indexMUU0lirlPvMeta?.path ?? "/fr-ca/events",
    meta: indexMUU0lirlPvMeta || {},
    alias: indexMUU0lirlPvMeta?.alias || [],
    redirect: indexMUU0lirlPvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app7Hxoyu6CMIMeta?.name ?? "fleet-app___en-US",
    path: fleet_45app7Hxoyu6CMIMeta?.path ?? "/fleet-app",
    meta: fleet_45app7Hxoyu6CMIMeta || {},
    alias: fleet_45app7Hxoyu6CMIMeta?.alias || [],
    redirect: fleet_45app7Hxoyu6CMIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app7Hxoyu6CMIMeta?.name ?? "fleet-app___ca",
    path: fleet_45app7Hxoyu6CMIMeta?.path ?? "/ca/fleet-app",
    meta: fleet_45app7Hxoyu6CMIMeta || {},
    alias: fleet_45app7Hxoyu6CMIMeta?.alias || [],
    redirect: fleet_45app7Hxoyu6CMIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app7Hxoyu6CMIMeta?.name ?? "fleet-app___nl",
    path: fleet_45app7Hxoyu6CMIMeta?.path ?? "/nl/fleet-app",
    meta: fleet_45app7Hxoyu6CMIMeta || {},
    alias: fleet_45app7Hxoyu6CMIMeta?.alias || [],
    redirect: fleet_45app7Hxoyu6CMIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app7Hxoyu6CMIMeta?.name ?? "fleet-app___uk",
    path: fleet_45app7Hxoyu6CMIMeta?.path ?? "/uk/fleet-app",
    meta: fleet_45app7Hxoyu6CMIMeta || {},
    alias: fleet_45app7Hxoyu6CMIMeta?.alias || [],
    redirect: fleet_45app7Hxoyu6CMIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app7Hxoyu6CMIMeta?.name ?? "fleet-app___de",
    path: fleet_45app7Hxoyu6CMIMeta?.path ?? "/de/fleet-app",
    meta: fleet_45app7Hxoyu6CMIMeta || {},
    alias: fleet_45app7Hxoyu6CMIMeta?.alias || [],
    redirect: fleet_45app7Hxoyu6CMIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app7Hxoyu6CMIMeta?.name ?? "fleet-app___mx",
    path: fleet_45app7Hxoyu6CMIMeta?.path ?? "/mx/fleet-app",
    meta: fleet_45app7Hxoyu6CMIMeta || {},
    alias: fleet_45app7Hxoyu6CMIMeta?.alias || [],
    redirect: fleet_45app7Hxoyu6CMIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app7Hxoyu6CMIMeta?.name ?? "fleet-app___fr",
    path: fleet_45app7Hxoyu6CMIMeta?.path ?? "/fr/fleet-app",
    meta: fleet_45app7Hxoyu6CMIMeta || {},
    alias: fleet_45app7Hxoyu6CMIMeta?.alias || [],
    redirect: fleet_45app7Hxoyu6CMIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app7Hxoyu6CMIMeta?.name ?? "fleet-app___fr-ca",
    path: fleet_45app7Hxoyu6CMIMeta?.path ?? "/fr-ca/fleet-app",
    meta: fleet_45app7Hxoyu6CMIMeta || {},
    alias: fleet_45app7Hxoyu6CMIMeta?.alias || [],
    redirect: fleet_45app7Hxoyu6CMIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ifW7K2BEtcMeta?.name ?? "fleet-prefix-slug___en-US",
    path: _91slug_93ifW7K2BEtcMeta?.path ?? "/fleet/:prefix()/:slug()",
    meta: _91slug_93ifW7K2BEtcMeta || {},
    alias: _91slug_93ifW7K2BEtcMeta?.alias || [],
    redirect: _91slug_93ifW7K2BEtcMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ifW7K2BEtcMeta?.name ?? "fleet-prefix-slug___ca",
    path: _91slug_93ifW7K2BEtcMeta?.path ?? "/ca/fleet/:prefix()/:slug()",
    meta: _91slug_93ifW7K2BEtcMeta || {},
    alias: _91slug_93ifW7K2BEtcMeta?.alias || [],
    redirect: _91slug_93ifW7K2BEtcMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ifW7K2BEtcMeta?.name ?? "fleet-prefix-slug___nl",
    path: _91slug_93ifW7K2BEtcMeta?.path ?? "/nl/fleet/:prefix()/:slug()",
    meta: _91slug_93ifW7K2BEtcMeta || {},
    alias: _91slug_93ifW7K2BEtcMeta?.alias || [],
    redirect: _91slug_93ifW7K2BEtcMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ifW7K2BEtcMeta?.name ?? "fleet-prefix-slug___uk",
    path: _91slug_93ifW7K2BEtcMeta?.path ?? "/uk/fleet/:prefix()/:slug()",
    meta: _91slug_93ifW7K2BEtcMeta || {},
    alias: _91slug_93ifW7K2BEtcMeta?.alias || [],
    redirect: _91slug_93ifW7K2BEtcMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ifW7K2BEtcMeta?.name ?? "fleet-prefix-slug___de",
    path: _91slug_93ifW7K2BEtcMeta?.path ?? "/de/fleet/:prefix()/:slug()",
    meta: _91slug_93ifW7K2BEtcMeta || {},
    alias: _91slug_93ifW7K2BEtcMeta?.alias || [],
    redirect: _91slug_93ifW7K2BEtcMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ifW7K2BEtcMeta?.name ?? "fleet-prefix-slug___mx",
    path: _91slug_93ifW7K2BEtcMeta?.path ?? "/mx/fleet/:prefix()/:slug()",
    meta: _91slug_93ifW7K2BEtcMeta || {},
    alias: _91slug_93ifW7K2BEtcMeta?.alias || [],
    redirect: _91slug_93ifW7K2BEtcMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ifW7K2BEtcMeta?.name ?? "fleet-prefix-slug___fr",
    path: _91slug_93ifW7K2BEtcMeta?.path ?? "/fr/fleet/:prefix()/:slug()",
    meta: _91slug_93ifW7K2BEtcMeta || {},
    alias: _91slug_93ifW7K2BEtcMeta?.alias || [],
    redirect: _91slug_93ifW7K2BEtcMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ifW7K2BEtcMeta?.name ?? "fleet-prefix-slug___fr-ca",
    path: _91slug_93ifW7K2BEtcMeta?.path ?? "/fr-ca/fleet/:prefix()/:slug()",
    meta: _91slug_93ifW7K2BEtcMeta || {},
    alias: _91slug_93ifW7K2BEtcMeta?.alias || [],
    redirect: _91slug_93ifW7K2BEtcMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WYMAGwh7ZdMeta?.name ?? "fleet-slug___en-US",
    path: _91slug_93WYMAGwh7ZdMeta?.path ?? "/fleet/:slug()",
    meta: _91slug_93WYMAGwh7ZdMeta || {},
    alias: _91slug_93WYMAGwh7ZdMeta?.alias || [],
    redirect: _91slug_93WYMAGwh7ZdMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WYMAGwh7ZdMeta?.name ?? "fleet-slug___ca",
    path: _91slug_93WYMAGwh7ZdMeta?.path ?? "/ca/fleet/:slug()",
    meta: _91slug_93WYMAGwh7ZdMeta || {},
    alias: _91slug_93WYMAGwh7ZdMeta?.alias || [],
    redirect: _91slug_93WYMAGwh7ZdMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WYMAGwh7ZdMeta?.name ?? "fleet-slug___nl",
    path: _91slug_93WYMAGwh7ZdMeta?.path ?? "/nl/fleet/:slug()",
    meta: _91slug_93WYMAGwh7ZdMeta || {},
    alias: _91slug_93WYMAGwh7ZdMeta?.alias || [],
    redirect: _91slug_93WYMAGwh7ZdMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WYMAGwh7ZdMeta?.name ?? "fleet-slug___uk",
    path: _91slug_93WYMAGwh7ZdMeta?.path ?? "/uk/fleet/:slug()",
    meta: _91slug_93WYMAGwh7ZdMeta || {},
    alias: _91slug_93WYMAGwh7ZdMeta?.alias || [],
    redirect: _91slug_93WYMAGwh7ZdMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WYMAGwh7ZdMeta?.name ?? "fleet-slug___de",
    path: _91slug_93WYMAGwh7ZdMeta?.path ?? "/de/fleet/:slug()",
    meta: _91slug_93WYMAGwh7ZdMeta || {},
    alias: _91slug_93WYMAGwh7ZdMeta?.alias || [],
    redirect: _91slug_93WYMAGwh7ZdMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WYMAGwh7ZdMeta?.name ?? "fleet-slug___mx",
    path: _91slug_93WYMAGwh7ZdMeta?.path ?? "/mx/fleet/:slug()",
    meta: _91slug_93WYMAGwh7ZdMeta || {},
    alias: _91slug_93WYMAGwh7ZdMeta?.alias || [],
    redirect: _91slug_93WYMAGwh7ZdMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WYMAGwh7ZdMeta?.name ?? "fleet-slug___fr",
    path: _91slug_93WYMAGwh7ZdMeta?.path ?? "/fr/fleet/:slug()",
    meta: _91slug_93WYMAGwh7ZdMeta || {},
    alias: _91slug_93WYMAGwh7ZdMeta?.alias || [],
    redirect: _91slug_93WYMAGwh7ZdMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WYMAGwh7ZdMeta?.name ?? "fleet-slug___fr-ca",
    path: _91slug_93WYMAGwh7ZdMeta?.path ?? "/fr-ca/fleet/:slug()",
    meta: _91slug_93WYMAGwh7ZdMeta || {},
    alias: _91slug_93WYMAGwh7ZdMeta?.alias || [],
    redirect: _91slug_93WYMAGwh7ZdMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937AnMKdFBJAMeta?.name ?? "fleets-prefix-slug___en-US",
    path: _91slug_937AnMKdFBJAMeta?.path ?? "/fleets/:prefix()/:slug()",
    meta: _91slug_937AnMKdFBJAMeta || {},
    alias: _91slug_937AnMKdFBJAMeta?.alias || [],
    redirect: _91slug_937AnMKdFBJAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937AnMKdFBJAMeta?.name ?? "fleets-prefix-slug___ca",
    path: _91slug_937AnMKdFBJAMeta?.path ?? "/ca/fleets/:prefix()/:slug()",
    meta: _91slug_937AnMKdFBJAMeta || {},
    alias: _91slug_937AnMKdFBJAMeta?.alias || [],
    redirect: _91slug_937AnMKdFBJAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937AnMKdFBJAMeta?.name ?? "fleets-prefix-slug___nl",
    path: _91slug_937AnMKdFBJAMeta?.path ?? "/nl/fleets/:prefix()/:slug()",
    meta: _91slug_937AnMKdFBJAMeta || {},
    alias: _91slug_937AnMKdFBJAMeta?.alias || [],
    redirect: _91slug_937AnMKdFBJAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937AnMKdFBJAMeta?.name ?? "fleets-prefix-slug___uk",
    path: _91slug_937AnMKdFBJAMeta?.path ?? "/uk/fleets/:prefix()/:slug()",
    meta: _91slug_937AnMKdFBJAMeta || {},
    alias: _91slug_937AnMKdFBJAMeta?.alias || [],
    redirect: _91slug_937AnMKdFBJAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937AnMKdFBJAMeta?.name ?? "fleets-prefix-slug___de",
    path: _91slug_937AnMKdFBJAMeta?.path ?? "/de/fleets/:prefix()/:slug()",
    meta: _91slug_937AnMKdFBJAMeta || {},
    alias: _91slug_937AnMKdFBJAMeta?.alias || [],
    redirect: _91slug_937AnMKdFBJAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937AnMKdFBJAMeta?.name ?? "fleets-prefix-slug___mx",
    path: _91slug_937AnMKdFBJAMeta?.path ?? "/mx/fleets/:prefix()/:slug()",
    meta: _91slug_937AnMKdFBJAMeta || {},
    alias: _91slug_937AnMKdFBJAMeta?.alias || [],
    redirect: _91slug_937AnMKdFBJAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937AnMKdFBJAMeta?.name ?? "fleets-prefix-slug___fr",
    path: _91slug_937AnMKdFBJAMeta?.path ?? "/fr/fleets/:prefix()/:slug()",
    meta: _91slug_937AnMKdFBJAMeta || {},
    alias: _91slug_937AnMKdFBJAMeta?.alias || [],
    redirect: _91slug_937AnMKdFBJAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937AnMKdFBJAMeta?.name ?? "fleets-prefix-slug___fr-ca",
    path: _91slug_937AnMKdFBJAMeta?.path ?? "/fr-ca/fleets/:prefix()/:slug()",
    meta: _91slug_937AnMKdFBJAMeta || {},
    alias: _91slug_937AnMKdFBJAMeta?.alias || [],
    redirect: _91slug_937AnMKdFBJAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zFdCMNmMhZMeta?.name ?? "fleets-slug___en-US",
    path: _91slug_93zFdCMNmMhZMeta?.path ?? "/fleets/:slug()",
    meta: _91slug_93zFdCMNmMhZMeta || {},
    alias: _91slug_93zFdCMNmMhZMeta?.alias || [],
    redirect: _91slug_93zFdCMNmMhZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zFdCMNmMhZMeta?.name ?? "fleets-slug___ca",
    path: _91slug_93zFdCMNmMhZMeta?.path ?? "/ca/fleets/:slug()",
    meta: _91slug_93zFdCMNmMhZMeta || {},
    alias: _91slug_93zFdCMNmMhZMeta?.alias || [],
    redirect: _91slug_93zFdCMNmMhZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zFdCMNmMhZMeta?.name ?? "fleets-slug___nl",
    path: _91slug_93zFdCMNmMhZMeta?.path ?? "/nl/fleets/:slug()",
    meta: _91slug_93zFdCMNmMhZMeta || {},
    alias: _91slug_93zFdCMNmMhZMeta?.alias || [],
    redirect: _91slug_93zFdCMNmMhZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zFdCMNmMhZMeta?.name ?? "fleets-slug___uk",
    path: _91slug_93zFdCMNmMhZMeta?.path ?? "/uk/fleets/:slug()",
    meta: _91slug_93zFdCMNmMhZMeta || {},
    alias: _91slug_93zFdCMNmMhZMeta?.alias || [],
    redirect: _91slug_93zFdCMNmMhZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zFdCMNmMhZMeta?.name ?? "fleets-slug___de",
    path: _91slug_93zFdCMNmMhZMeta?.path ?? "/de/fleets/:slug()",
    meta: _91slug_93zFdCMNmMhZMeta || {},
    alias: _91slug_93zFdCMNmMhZMeta?.alias || [],
    redirect: _91slug_93zFdCMNmMhZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zFdCMNmMhZMeta?.name ?? "fleets-slug___mx",
    path: _91slug_93zFdCMNmMhZMeta?.path ?? "/mx/fleets/:slug()",
    meta: _91slug_93zFdCMNmMhZMeta || {},
    alias: _91slug_93zFdCMNmMhZMeta?.alias || [],
    redirect: _91slug_93zFdCMNmMhZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zFdCMNmMhZMeta?.name ?? "fleets-slug___fr",
    path: _91slug_93zFdCMNmMhZMeta?.path ?? "/fr/fleets/:slug()",
    meta: _91slug_93zFdCMNmMhZMeta || {},
    alias: _91slug_93zFdCMNmMhZMeta?.alias || [],
    redirect: _91slug_93zFdCMNmMhZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zFdCMNmMhZMeta?.name ?? "fleets-slug___fr-ca",
    path: _91slug_93zFdCMNmMhZMeta?.path ?? "/fr-ca/fleets/:slug()",
    meta: _91slug_93zFdCMNmMhZMeta || {},
    alias: _91slug_93zFdCMNmMhZMeta?.alias || [],
    redirect: _91slug_93zFdCMNmMhZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f7kQDhX61AMeta?.name ?? "guides-slug___en-US",
    path: _91slug_93f7kQDhX61AMeta?.path ?? "/guides/:slug()",
    meta: _91slug_93f7kQDhX61AMeta || {},
    alias: _91slug_93f7kQDhX61AMeta?.alias || [],
    redirect: _91slug_93f7kQDhX61AMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f7kQDhX61AMeta?.name ?? "guides-slug___ca",
    path: _91slug_93f7kQDhX61AMeta?.path ?? "/ca/guides/:slug()",
    meta: _91slug_93f7kQDhX61AMeta || {},
    alias: _91slug_93f7kQDhX61AMeta?.alias || [],
    redirect: _91slug_93f7kQDhX61AMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f7kQDhX61AMeta?.name ?? "guides-slug___nl",
    path: _91slug_93f7kQDhX61AMeta?.path ?? "/nl/guides/:slug()",
    meta: _91slug_93f7kQDhX61AMeta || {},
    alias: _91slug_93f7kQDhX61AMeta?.alias || [],
    redirect: _91slug_93f7kQDhX61AMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f7kQDhX61AMeta?.name ?? "guides-slug___uk",
    path: _91slug_93f7kQDhX61AMeta?.path ?? "/uk/guides/:slug()",
    meta: _91slug_93f7kQDhX61AMeta || {},
    alias: _91slug_93f7kQDhX61AMeta?.alias || [],
    redirect: _91slug_93f7kQDhX61AMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f7kQDhX61AMeta?.name ?? "guides-slug___de",
    path: _91slug_93f7kQDhX61AMeta?.path ?? "/de/guides/:slug()",
    meta: _91slug_93f7kQDhX61AMeta || {},
    alias: _91slug_93f7kQDhX61AMeta?.alias || [],
    redirect: _91slug_93f7kQDhX61AMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f7kQDhX61AMeta?.name ?? "guides-slug___mx",
    path: _91slug_93f7kQDhX61AMeta?.path ?? "/mx/guides/:slug()",
    meta: _91slug_93f7kQDhX61AMeta || {},
    alias: _91slug_93f7kQDhX61AMeta?.alias || [],
    redirect: _91slug_93f7kQDhX61AMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f7kQDhX61AMeta?.name ?? "guides-slug___fr",
    path: _91slug_93f7kQDhX61AMeta?.path ?? "/fr/guides/:slug()",
    meta: _91slug_93f7kQDhX61AMeta || {},
    alias: _91slug_93f7kQDhX61AMeta?.alias || [],
    redirect: _91slug_93f7kQDhX61AMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f7kQDhX61AMeta?.name ?? "guides-slug___fr-ca",
    path: _91slug_93f7kQDhX61AMeta?.path ?? "/fr-ca/guides/:slug()",
    meta: _91slug_93f7kQDhX61AMeta || {},
    alias: _91slug_93f7kQDhX61AMeta?.alias || [],
    redirect: _91slug_93f7kQDhX61AMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent8xPLAG7DGlMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___en-US",
    path: attract_45retain_45talent8xPLAG7DGlMeta?.path ?? "/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent8xPLAG7DGlMeta || {},
    alias: attract_45retain_45talent8xPLAG7DGlMeta?.alias || [],
    redirect: attract_45retain_45talent8xPLAG7DGlMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent8xPLAG7DGlMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___ca",
    path: attract_45retain_45talent8xPLAG7DGlMeta?.path ?? "/ca/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent8xPLAG7DGlMeta || {},
    alias: attract_45retain_45talent8xPLAG7DGlMeta?.alias || [],
    redirect: attract_45retain_45talent8xPLAG7DGlMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent8xPLAG7DGlMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___nl",
    path: attract_45retain_45talent8xPLAG7DGlMeta?.path ?? "/nl/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent8xPLAG7DGlMeta || {},
    alias: attract_45retain_45talent8xPLAG7DGlMeta?.alias || [],
    redirect: attract_45retain_45talent8xPLAG7DGlMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent8xPLAG7DGlMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___uk",
    path: attract_45retain_45talent8xPLAG7DGlMeta?.path ?? "/uk/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent8xPLAG7DGlMeta || {},
    alias: attract_45retain_45talent8xPLAG7DGlMeta?.alias || [],
    redirect: attract_45retain_45talent8xPLAG7DGlMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent8xPLAG7DGlMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___de",
    path: attract_45retain_45talent8xPLAG7DGlMeta?.path ?? "/de/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent8xPLAG7DGlMeta || {},
    alias: attract_45retain_45talent8xPLAG7DGlMeta?.alias || [],
    redirect: attract_45retain_45talent8xPLAG7DGlMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent8xPLAG7DGlMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___mx",
    path: attract_45retain_45talent8xPLAG7DGlMeta?.path ?? "/mx/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent8xPLAG7DGlMeta || {},
    alias: attract_45retain_45talent8xPLAG7DGlMeta?.alias || [],
    redirect: attract_45retain_45talent8xPLAG7DGlMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent8xPLAG7DGlMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___fr",
    path: attract_45retain_45talent8xPLAG7DGlMeta?.path ?? "/fr/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent8xPLAG7DGlMeta || {},
    alias: attract_45retain_45talent8xPLAG7DGlMeta?.alias || [],
    redirect: attract_45retain_45talent8xPLAG7DGlMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent8xPLAG7DGlMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___fr-ca",
    path: attract_45retain_45talent8xPLAG7DGlMeta?.path ?? "/fr-ca/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent8xPLAG7DGlMeta || {},
    alias: attract_45retain_45talent8xPLAG7DGlMeta?.alias || [],
    redirect: attract_45retain_45talent8xPLAG7DGlMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___en-US",
    path: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.path ?? "/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta || {},
    alias: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___ca",
    path: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.path ?? "/ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta || {},
    alias: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___nl",
    path: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.path ?? "/nl/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta || {},
    alias: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___uk",
    path: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.path ?? "/uk/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta || {},
    alias: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___de",
    path: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.path ?? "/de/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta || {},
    alias: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___mx",
    path: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.path ?? "/mx/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta || {},
    alias: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___fr",
    path: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.path ?? "/fr/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta || {},
    alias: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___fr-ca",
    path: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.path ?? "/fr-ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta || {},
    alias: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsEiV7eWvhxQMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: indexzZvRyHSU0VMeta?.name ?? "guides-build-business-resilience___en-US",
    path: indexzZvRyHSU0VMeta?.path ?? "/guides/build-business-resilience",
    meta: indexzZvRyHSU0VMeta || {},
    alias: indexzZvRyHSU0VMeta?.alias || [],
    redirect: indexzZvRyHSU0VMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexzZvRyHSU0VMeta?.name ?? "guides-build-business-resilience___ca",
    path: indexzZvRyHSU0VMeta?.path ?? "/ca/guides/build-business-resilience",
    meta: indexzZvRyHSU0VMeta || {},
    alias: indexzZvRyHSU0VMeta?.alias || [],
    redirect: indexzZvRyHSU0VMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexzZvRyHSU0VMeta?.name ?? "guides-build-business-resilience___nl",
    path: indexzZvRyHSU0VMeta?.path ?? "/nl/guides/build-business-resilience",
    meta: indexzZvRyHSU0VMeta || {},
    alias: indexzZvRyHSU0VMeta?.alias || [],
    redirect: indexzZvRyHSU0VMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexzZvRyHSU0VMeta?.name ?? "guides-build-business-resilience___uk",
    path: indexzZvRyHSU0VMeta?.path ?? "/uk/guides/build-business-resilience",
    meta: indexzZvRyHSU0VMeta || {},
    alias: indexzZvRyHSU0VMeta?.alias || [],
    redirect: indexzZvRyHSU0VMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexzZvRyHSU0VMeta?.name ?? "guides-build-business-resilience___de",
    path: indexzZvRyHSU0VMeta?.path ?? "/de/guides/build-business-resilience",
    meta: indexzZvRyHSU0VMeta || {},
    alias: indexzZvRyHSU0VMeta?.alias || [],
    redirect: indexzZvRyHSU0VMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexzZvRyHSU0VMeta?.name ?? "guides-build-business-resilience___mx",
    path: indexzZvRyHSU0VMeta?.path ?? "/mx/guides/build-business-resilience",
    meta: indexzZvRyHSU0VMeta || {},
    alias: indexzZvRyHSU0VMeta?.alias || [],
    redirect: indexzZvRyHSU0VMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexzZvRyHSU0VMeta?.name ?? "guides-build-business-resilience___fr",
    path: indexzZvRyHSU0VMeta?.path ?? "/fr/guides/build-business-resilience",
    meta: indexzZvRyHSU0VMeta || {},
    alias: indexzZvRyHSU0VMeta?.alias || [],
    redirect: indexzZvRyHSU0VMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexzZvRyHSU0VMeta?.name ?? "guides-build-business-resilience___fr-ca",
    path: indexzZvRyHSU0VMeta?.path ?? "/fr-ca/guides/build-business-resilience",
    meta: indexzZvRyHSU0VMeta || {},
    alias: indexzZvRyHSU0VMeta?.alias || [],
    redirect: indexzZvRyHSU0VMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsnbHrmhg336Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___en-US",
    path: protect_45optimize_45assetsnbHrmhg336Meta?.path ?? "/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsnbHrmhg336Meta || {},
    alias: protect_45optimize_45assetsnbHrmhg336Meta?.alias || [],
    redirect: protect_45optimize_45assetsnbHrmhg336Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsnbHrmhg336Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___ca",
    path: protect_45optimize_45assetsnbHrmhg336Meta?.path ?? "/ca/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsnbHrmhg336Meta || {},
    alias: protect_45optimize_45assetsnbHrmhg336Meta?.alias || [],
    redirect: protect_45optimize_45assetsnbHrmhg336Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsnbHrmhg336Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___nl",
    path: protect_45optimize_45assetsnbHrmhg336Meta?.path ?? "/nl/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsnbHrmhg336Meta || {},
    alias: protect_45optimize_45assetsnbHrmhg336Meta?.alias || [],
    redirect: protect_45optimize_45assetsnbHrmhg336Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsnbHrmhg336Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___uk",
    path: protect_45optimize_45assetsnbHrmhg336Meta?.path ?? "/uk/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsnbHrmhg336Meta || {},
    alias: protect_45optimize_45assetsnbHrmhg336Meta?.alias || [],
    redirect: protect_45optimize_45assetsnbHrmhg336Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsnbHrmhg336Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___de",
    path: protect_45optimize_45assetsnbHrmhg336Meta?.path ?? "/de/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsnbHrmhg336Meta || {},
    alias: protect_45optimize_45assetsnbHrmhg336Meta?.alias || [],
    redirect: protect_45optimize_45assetsnbHrmhg336Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsnbHrmhg336Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___mx",
    path: protect_45optimize_45assetsnbHrmhg336Meta?.path ?? "/mx/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsnbHrmhg336Meta || {},
    alias: protect_45optimize_45assetsnbHrmhg336Meta?.alias || [],
    redirect: protect_45optimize_45assetsnbHrmhg336Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsnbHrmhg336Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___fr",
    path: protect_45optimize_45assetsnbHrmhg336Meta?.path ?? "/fr/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsnbHrmhg336Meta || {},
    alias: protect_45optimize_45assetsnbHrmhg336Meta?.alias || [],
    redirect: protect_45optimize_45assetsnbHrmhg336Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsnbHrmhg336Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___fr-ca",
    path: protect_45optimize_45assetsnbHrmhg336Meta?.path ?? "/fr-ca/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsnbHrmhg336Meta || {},
    alias: protect_45optimize_45assetsnbHrmhg336Meta?.alias || [],
    redirect: protect_45optimize_45assetsnbHrmhg336Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsaSFEIuMTM4Meta?.name ?? "guides-fleet-safety-best-dash-cams___en-US",
    path: best_45dash_45camsaSFEIuMTM4Meta?.path ?? "/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsaSFEIuMTM4Meta || {},
    alias: best_45dash_45camsaSFEIuMTM4Meta?.alias || [],
    redirect: best_45dash_45camsaSFEIuMTM4Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsaSFEIuMTM4Meta?.name ?? "guides-fleet-safety-best-dash-cams___ca",
    path: best_45dash_45camsaSFEIuMTM4Meta?.path ?? "/ca/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsaSFEIuMTM4Meta || {},
    alias: best_45dash_45camsaSFEIuMTM4Meta?.alias || [],
    redirect: best_45dash_45camsaSFEIuMTM4Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsaSFEIuMTM4Meta?.name ?? "guides-fleet-safety-best-dash-cams___nl",
    path: best_45dash_45camsaSFEIuMTM4Meta?.path ?? "/nl/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsaSFEIuMTM4Meta || {},
    alias: best_45dash_45camsaSFEIuMTM4Meta?.alias || [],
    redirect: best_45dash_45camsaSFEIuMTM4Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsaSFEIuMTM4Meta?.name ?? "guides-fleet-safety-best-dash-cams___uk",
    path: best_45dash_45camsaSFEIuMTM4Meta?.path ?? "/uk/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsaSFEIuMTM4Meta || {},
    alias: best_45dash_45camsaSFEIuMTM4Meta?.alias || [],
    redirect: best_45dash_45camsaSFEIuMTM4Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsaSFEIuMTM4Meta?.name ?? "guides-fleet-safety-best-dash-cams___de",
    path: best_45dash_45camsaSFEIuMTM4Meta?.path ?? "/de/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsaSFEIuMTM4Meta || {},
    alias: best_45dash_45camsaSFEIuMTM4Meta?.alias || [],
    redirect: best_45dash_45camsaSFEIuMTM4Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsaSFEIuMTM4Meta?.name ?? "guides-fleet-safety-best-dash-cams___mx",
    path: best_45dash_45camsaSFEIuMTM4Meta?.path ?? "/mx/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsaSFEIuMTM4Meta || {},
    alias: best_45dash_45camsaSFEIuMTM4Meta?.alias || [],
    redirect: best_45dash_45camsaSFEIuMTM4Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsaSFEIuMTM4Meta?.name ?? "guides-fleet-safety-best-dash-cams___fr",
    path: best_45dash_45camsaSFEIuMTM4Meta?.path ?? "/fr/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsaSFEIuMTM4Meta || {},
    alias: best_45dash_45camsaSFEIuMTM4Meta?.alias || [],
    redirect: best_45dash_45camsaSFEIuMTM4Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsaSFEIuMTM4Meta?.name ?? "guides-fleet-safety-best-dash-cams___fr-ca",
    path: best_45dash_45camsaSFEIuMTM4Meta?.path ?? "/fr-ca/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsaSFEIuMTM4Meta || {},
    alias: best_45dash_45camsaSFEIuMTM4Meta?.alias || [],
    redirect: best_45dash_45camsaSFEIuMTM4Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: index4yXXtHaoa3Meta?.name ?? "guides-fleet-safety___en-US",
    path: index4yXXtHaoa3Meta?.path ?? "/guides/fleet-safety",
    meta: index4yXXtHaoa3Meta || {},
    alias: index4yXXtHaoa3Meta?.alias || [],
    redirect: index4yXXtHaoa3Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: index4yXXtHaoa3Meta?.name ?? "guides-fleet-safety___ca",
    path: index4yXXtHaoa3Meta?.path ?? "/ca/guides/fleet-safety",
    meta: index4yXXtHaoa3Meta || {},
    alias: index4yXXtHaoa3Meta?.alias || [],
    redirect: index4yXXtHaoa3Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: index4yXXtHaoa3Meta?.name ?? "guides-fleet-safety___nl",
    path: index4yXXtHaoa3Meta?.path ?? "/nl/guides/fleet-safety",
    meta: index4yXXtHaoa3Meta || {},
    alias: index4yXXtHaoa3Meta?.alias || [],
    redirect: index4yXXtHaoa3Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: index4yXXtHaoa3Meta?.name ?? "guides-fleet-safety___uk",
    path: index4yXXtHaoa3Meta?.path ?? "/uk/guides/fleet-safety",
    meta: index4yXXtHaoa3Meta || {},
    alias: index4yXXtHaoa3Meta?.alias || [],
    redirect: index4yXXtHaoa3Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: index4yXXtHaoa3Meta?.name ?? "guides-fleet-safety___de",
    path: index4yXXtHaoa3Meta?.path ?? "/de/guides/fleet-safety",
    meta: index4yXXtHaoa3Meta || {},
    alias: index4yXXtHaoa3Meta?.alias || [],
    redirect: index4yXXtHaoa3Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: index4yXXtHaoa3Meta?.name ?? "guides-fleet-safety___mx",
    path: index4yXXtHaoa3Meta?.path ?? "/mx/guias/seguridad-flotas",
    meta: index4yXXtHaoa3Meta || {},
    alias: index4yXXtHaoa3Meta?.alias || [],
    redirect: index4yXXtHaoa3Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: index4yXXtHaoa3Meta?.name ?? "guides-fleet-safety___fr",
    path: index4yXXtHaoa3Meta?.path ?? "/fr/guides/fleet-safety",
    meta: index4yXXtHaoa3Meta || {},
    alias: index4yXXtHaoa3Meta?.alias || [],
    redirect: index4yXXtHaoa3Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: index4yXXtHaoa3Meta?.name ?? "guides-fleet-safety___fr-ca",
    path: index4yXXtHaoa3Meta?.path ?? "/fr-ca/guides/fleet-safety",
    meta: index4yXXtHaoa3Meta || {},
    alias: index4yXXtHaoa3Meta?.alias || [],
    redirect: index4yXXtHaoa3Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: safety_45programfqjjnzXHKGMeta?.name ?? "guides-fleet-safety-safety-program___en-US",
    path: safety_45programfqjjnzXHKGMeta?.path ?? "/guides/fleet-safety/safety-program",
    meta: safety_45programfqjjnzXHKGMeta || {},
    alias: safety_45programfqjjnzXHKGMeta?.alias || [],
    redirect: safety_45programfqjjnzXHKGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programfqjjnzXHKGMeta?.name ?? "guides-fleet-safety-safety-program___ca",
    path: safety_45programfqjjnzXHKGMeta?.path ?? "/ca/guides/fleet-safety/safety-program",
    meta: safety_45programfqjjnzXHKGMeta || {},
    alias: safety_45programfqjjnzXHKGMeta?.alias || [],
    redirect: safety_45programfqjjnzXHKGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programfqjjnzXHKGMeta?.name ?? "guides-fleet-safety-safety-program___nl",
    path: safety_45programfqjjnzXHKGMeta?.path ?? "/nl/guides/fleet-safety/safety-program",
    meta: safety_45programfqjjnzXHKGMeta || {},
    alias: safety_45programfqjjnzXHKGMeta?.alias || [],
    redirect: safety_45programfqjjnzXHKGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programfqjjnzXHKGMeta?.name ?? "guides-fleet-safety-safety-program___uk",
    path: safety_45programfqjjnzXHKGMeta?.path ?? "/uk/guides/fleet-safety/safety-program",
    meta: safety_45programfqjjnzXHKGMeta || {},
    alias: safety_45programfqjjnzXHKGMeta?.alias || [],
    redirect: safety_45programfqjjnzXHKGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programfqjjnzXHKGMeta?.name ?? "guides-fleet-safety-safety-program___de",
    path: safety_45programfqjjnzXHKGMeta?.path ?? "/de/guides/fleet-safety/safety-program",
    meta: safety_45programfqjjnzXHKGMeta || {},
    alias: safety_45programfqjjnzXHKGMeta?.alias || [],
    redirect: safety_45programfqjjnzXHKGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programfqjjnzXHKGMeta?.name ?? "guides-fleet-safety-safety-program___mx",
    path: safety_45programfqjjnzXHKGMeta?.path ?? "/mx/guides/fleet-safety/safety-program",
    meta: safety_45programfqjjnzXHKGMeta || {},
    alias: safety_45programfqjjnzXHKGMeta?.alias || [],
    redirect: safety_45programfqjjnzXHKGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programfqjjnzXHKGMeta?.name ?? "guides-fleet-safety-safety-program___fr",
    path: safety_45programfqjjnzXHKGMeta?.path ?? "/fr/guides/fleet-safety/safety-program",
    meta: safety_45programfqjjnzXHKGMeta || {},
    alias: safety_45programfqjjnzXHKGMeta?.alias || [],
    redirect: safety_45programfqjjnzXHKGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programfqjjnzXHKGMeta?.name ?? "guides-fleet-safety-safety-program___fr-ca",
    path: safety_45programfqjjnzXHKGMeta?.path ?? "/fr-ca/guides/fleet-safety/safety-program",
    meta: safety_45programfqjjnzXHKGMeta || {},
    alias: safety_45programfqjjnzXHKGMeta?.alias || [],
    redirect: safety_45programfqjjnzXHKGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiML5PuD0kWvMeta?.name ?? "guides-fleet-safety-safety-roi___en-US",
    path: safety_45roiML5PuD0kWvMeta?.path ?? "/guides/fleet-safety/safety-roi",
    meta: safety_45roiML5PuD0kWvMeta || {},
    alias: safety_45roiML5PuD0kWvMeta?.alias || [],
    redirect: safety_45roiML5PuD0kWvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiML5PuD0kWvMeta?.name ?? "guides-fleet-safety-safety-roi___ca",
    path: safety_45roiML5PuD0kWvMeta?.path ?? "/ca/guides/fleet-safety/safety-roi",
    meta: safety_45roiML5PuD0kWvMeta || {},
    alias: safety_45roiML5PuD0kWvMeta?.alias || [],
    redirect: safety_45roiML5PuD0kWvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiML5PuD0kWvMeta?.name ?? "guides-fleet-safety-safety-roi___nl",
    path: safety_45roiML5PuD0kWvMeta?.path ?? "/nl/guides/fleet-safety/safety-roi",
    meta: safety_45roiML5PuD0kWvMeta || {},
    alias: safety_45roiML5PuD0kWvMeta?.alias || [],
    redirect: safety_45roiML5PuD0kWvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiML5PuD0kWvMeta?.name ?? "guides-fleet-safety-safety-roi___uk",
    path: safety_45roiML5PuD0kWvMeta?.path ?? "/uk/guides/fleet-safety/safety-roi",
    meta: safety_45roiML5PuD0kWvMeta || {},
    alias: safety_45roiML5PuD0kWvMeta?.alias || [],
    redirect: safety_45roiML5PuD0kWvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiML5PuD0kWvMeta?.name ?? "guides-fleet-safety-safety-roi___de",
    path: safety_45roiML5PuD0kWvMeta?.path ?? "/de/guides/fleet-safety/safety-roi",
    meta: safety_45roiML5PuD0kWvMeta || {},
    alias: safety_45roiML5PuD0kWvMeta?.alias || [],
    redirect: safety_45roiML5PuD0kWvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiML5PuD0kWvMeta?.name ?? "guides-fleet-safety-safety-roi___mx",
    path: safety_45roiML5PuD0kWvMeta?.path ?? "/mx/guides/fleet-safety/safety-roi",
    meta: safety_45roiML5PuD0kWvMeta || {},
    alias: safety_45roiML5PuD0kWvMeta?.alias || [],
    redirect: safety_45roiML5PuD0kWvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiML5PuD0kWvMeta?.name ?? "guides-fleet-safety-safety-roi___fr",
    path: safety_45roiML5PuD0kWvMeta?.path ?? "/fr/guides/fleet-safety/safety-roi",
    meta: safety_45roiML5PuD0kWvMeta || {},
    alias: safety_45roiML5PuD0kWvMeta?.alias || [],
    redirect: safety_45roiML5PuD0kWvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiML5PuD0kWvMeta?.name ?? "guides-fleet-safety-safety-roi___fr-ca",
    path: safety_45roiML5PuD0kWvMeta?.path ?? "/fr-ca/guides/fleet-safety/safety-roi",
    meta: safety_45roiML5PuD0kWvMeta || {},
    alias: safety_45roiML5PuD0kWvMeta?.alias || [],
    redirect: safety_45roiML5PuD0kWvMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: indexr5eiT9bKzqMeta?.name ?? "guides___en-US",
    path: indexr5eiT9bKzqMeta?.path ?? "/guides",
    meta: indexr5eiT9bKzqMeta || {},
    alias: indexr5eiT9bKzqMeta?.alias || [],
    redirect: indexr5eiT9bKzqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexr5eiT9bKzqMeta?.name ?? "guides___ca",
    path: indexr5eiT9bKzqMeta?.path ?? "/ca/guides",
    meta: indexr5eiT9bKzqMeta || {},
    alias: indexr5eiT9bKzqMeta?.alias || [],
    redirect: indexr5eiT9bKzqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexr5eiT9bKzqMeta?.name ?? "guides___nl",
    path: indexr5eiT9bKzqMeta?.path ?? "/nl/guides",
    meta: indexr5eiT9bKzqMeta || {},
    alias: indexr5eiT9bKzqMeta?.alias || [],
    redirect: indexr5eiT9bKzqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexr5eiT9bKzqMeta?.name ?? "guides___uk",
    path: indexr5eiT9bKzqMeta?.path ?? "/uk/guides",
    meta: indexr5eiT9bKzqMeta || {},
    alias: indexr5eiT9bKzqMeta?.alias || [],
    redirect: indexr5eiT9bKzqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexr5eiT9bKzqMeta?.name ?? "guides___de",
    path: indexr5eiT9bKzqMeta?.path ?? "/de/guides",
    meta: indexr5eiT9bKzqMeta || {},
    alias: indexr5eiT9bKzqMeta?.alias || [],
    redirect: indexr5eiT9bKzqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexr5eiT9bKzqMeta?.name ?? "guides___mx",
    path: indexr5eiT9bKzqMeta?.path ?? "/mx/guides",
    meta: indexr5eiT9bKzqMeta || {},
    alias: indexr5eiT9bKzqMeta?.alias || [],
    redirect: indexr5eiT9bKzqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexr5eiT9bKzqMeta?.name ?? "guides___fr",
    path: indexr5eiT9bKzqMeta?.path ?? "/fr/guides",
    meta: indexr5eiT9bKzqMeta || {},
    alias: indexr5eiT9bKzqMeta?.alias || [],
    redirect: indexr5eiT9bKzqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexr5eiT9bKzqMeta?.name ?? "guides___fr-ca",
    path: indexr5eiT9bKzqMeta?.path ?? "/fr-ca/guides",
    meta: indexr5eiT9bKzqMeta || {},
    alias: indexr5eiT9bKzqMeta?.alias || [],
    redirect: indexr5eiT9bKzqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureFGWajw6k1AMeta?.name ?? "guides-make-more-possible-build-for-the-future___en-US",
    path: build_45for_45the_45futureFGWajw6k1AMeta?.path ?? "/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureFGWajw6k1AMeta || {},
    alias: build_45for_45the_45futureFGWajw6k1AMeta?.alias || [],
    redirect: build_45for_45the_45futureFGWajw6k1AMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureFGWajw6k1AMeta?.name ?? "guides-make-more-possible-build-for-the-future___ca",
    path: build_45for_45the_45futureFGWajw6k1AMeta?.path ?? "/ca/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureFGWajw6k1AMeta || {},
    alias: build_45for_45the_45futureFGWajw6k1AMeta?.alias || [],
    redirect: build_45for_45the_45futureFGWajw6k1AMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureFGWajw6k1AMeta?.name ?? "guides-make-more-possible-build-for-the-future___nl",
    path: build_45for_45the_45futureFGWajw6k1AMeta?.path ?? "/nl/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureFGWajw6k1AMeta || {},
    alias: build_45for_45the_45futureFGWajw6k1AMeta?.alias || [],
    redirect: build_45for_45the_45futureFGWajw6k1AMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureFGWajw6k1AMeta?.name ?? "guides-make-more-possible-build-for-the-future___uk",
    path: build_45for_45the_45futureFGWajw6k1AMeta?.path ?? "/uk/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureFGWajw6k1AMeta || {},
    alias: build_45for_45the_45futureFGWajw6k1AMeta?.alias || [],
    redirect: build_45for_45the_45futureFGWajw6k1AMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureFGWajw6k1AMeta?.name ?? "guides-make-more-possible-build-for-the-future___de",
    path: build_45for_45the_45futureFGWajw6k1AMeta?.path ?? "/de/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureFGWajw6k1AMeta || {},
    alias: build_45for_45the_45futureFGWajw6k1AMeta?.alias || [],
    redirect: build_45for_45the_45futureFGWajw6k1AMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureFGWajw6k1AMeta?.name ?? "guides-make-more-possible-build-for-the-future___mx",
    path: build_45for_45the_45futureFGWajw6k1AMeta?.path ?? "/mx/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureFGWajw6k1AMeta || {},
    alias: build_45for_45the_45futureFGWajw6k1AMeta?.alias || [],
    redirect: build_45for_45the_45futureFGWajw6k1AMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureFGWajw6k1AMeta?.name ?? "guides-make-more-possible-build-for-the-future___fr",
    path: build_45for_45the_45futureFGWajw6k1AMeta?.path ?? "/fr/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureFGWajw6k1AMeta || {},
    alias: build_45for_45the_45futureFGWajw6k1AMeta?.alias || [],
    redirect: build_45for_45the_45futureFGWajw6k1AMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureFGWajw6k1AMeta?.name ?? "guides-make-more-possible-build-for-the-future___fr-ca",
    path: build_45for_45the_45futureFGWajw6k1AMeta?.path ?? "/fr-ca/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureFGWajw6k1AMeta || {},
    alias: build_45for_45the_45futureFGWajw6k1AMeta?.alias || [],
    redirect: build_45for_45the_45futureFGWajw6k1AMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsPCm8cM1OV9Meta?.name ?? "guides-make-more-possible-empower-your-teams___en-US",
    path: empower_45your_45teamsPCm8cM1OV9Meta?.path ?? "/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsPCm8cM1OV9Meta || {},
    alias: empower_45your_45teamsPCm8cM1OV9Meta?.alias || [],
    redirect: empower_45your_45teamsPCm8cM1OV9Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsPCm8cM1OV9Meta?.name ?? "guides-make-more-possible-empower-your-teams___ca",
    path: empower_45your_45teamsPCm8cM1OV9Meta?.path ?? "/ca/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsPCm8cM1OV9Meta || {},
    alias: empower_45your_45teamsPCm8cM1OV9Meta?.alias || [],
    redirect: empower_45your_45teamsPCm8cM1OV9Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsPCm8cM1OV9Meta?.name ?? "guides-make-more-possible-empower-your-teams___nl",
    path: empower_45your_45teamsPCm8cM1OV9Meta?.path ?? "/nl/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsPCm8cM1OV9Meta || {},
    alias: empower_45your_45teamsPCm8cM1OV9Meta?.alias || [],
    redirect: empower_45your_45teamsPCm8cM1OV9Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsPCm8cM1OV9Meta?.name ?? "guides-make-more-possible-empower-your-teams___uk",
    path: empower_45your_45teamsPCm8cM1OV9Meta?.path ?? "/uk/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsPCm8cM1OV9Meta || {},
    alias: empower_45your_45teamsPCm8cM1OV9Meta?.alias || [],
    redirect: empower_45your_45teamsPCm8cM1OV9Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsPCm8cM1OV9Meta?.name ?? "guides-make-more-possible-empower-your-teams___de",
    path: empower_45your_45teamsPCm8cM1OV9Meta?.path ?? "/de/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsPCm8cM1OV9Meta || {},
    alias: empower_45your_45teamsPCm8cM1OV9Meta?.alias || [],
    redirect: empower_45your_45teamsPCm8cM1OV9Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsPCm8cM1OV9Meta?.name ?? "guides-make-more-possible-empower-your-teams___mx",
    path: empower_45your_45teamsPCm8cM1OV9Meta?.path ?? "/mx/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsPCm8cM1OV9Meta || {},
    alias: empower_45your_45teamsPCm8cM1OV9Meta?.alias || [],
    redirect: empower_45your_45teamsPCm8cM1OV9Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsPCm8cM1OV9Meta?.name ?? "guides-make-more-possible-empower-your-teams___fr",
    path: empower_45your_45teamsPCm8cM1OV9Meta?.path ?? "/fr/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsPCm8cM1OV9Meta || {},
    alias: empower_45your_45teamsPCm8cM1OV9Meta?.alias || [],
    redirect: empower_45your_45teamsPCm8cM1OV9Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsPCm8cM1OV9Meta?.name ?? "guides-make-more-possible-empower-your-teams___fr-ca",
    path: empower_45your_45teamsPCm8cM1OV9Meta?.path ?? "/fr-ca/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsPCm8cM1OV9Meta || {},
    alias: empower_45your_45teamsPCm8cM1OV9Meta?.alias || [],
    redirect: empower_45your_45teamsPCm8cM1OV9Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: indexpGSrkE5V3tMeta?.name ?? "guides-make-more-possible___en-US",
    path: indexpGSrkE5V3tMeta?.path ?? "/guides/make-more-possible",
    meta: indexpGSrkE5V3tMeta || {},
    alias: indexpGSrkE5V3tMeta?.alias || [],
    redirect: indexpGSrkE5V3tMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexpGSrkE5V3tMeta?.name ?? "guides-make-more-possible___ca",
    path: indexpGSrkE5V3tMeta?.path ?? "/ca/guides/make-more-possible",
    meta: indexpGSrkE5V3tMeta || {},
    alias: indexpGSrkE5V3tMeta?.alias || [],
    redirect: indexpGSrkE5V3tMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexpGSrkE5V3tMeta?.name ?? "guides-make-more-possible___nl",
    path: indexpGSrkE5V3tMeta?.path ?? "/nl/guides/make-more-possible",
    meta: indexpGSrkE5V3tMeta || {},
    alias: indexpGSrkE5V3tMeta?.alias || [],
    redirect: indexpGSrkE5V3tMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexpGSrkE5V3tMeta?.name ?? "guides-make-more-possible___uk",
    path: indexpGSrkE5V3tMeta?.path ?? "/uk/guides/make-more-possible",
    meta: indexpGSrkE5V3tMeta || {},
    alias: indexpGSrkE5V3tMeta?.alias || [],
    redirect: indexpGSrkE5V3tMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexpGSrkE5V3tMeta?.name ?? "guides-make-more-possible___de",
    path: indexpGSrkE5V3tMeta?.path ?? "/de/guides/make-more-possible",
    meta: indexpGSrkE5V3tMeta || {},
    alias: indexpGSrkE5V3tMeta?.alias || [],
    redirect: indexpGSrkE5V3tMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexpGSrkE5V3tMeta?.name ?? "guides-make-more-possible___mx",
    path: indexpGSrkE5V3tMeta?.path ?? "/mx/guides/make-more-possible",
    meta: indexpGSrkE5V3tMeta || {},
    alias: indexpGSrkE5V3tMeta?.alias || [],
    redirect: indexpGSrkE5V3tMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexpGSrkE5V3tMeta?.name ?? "guides-make-more-possible___fr",
    path: indexpGSrkE5V3tMeta?.path ?? "/fr/guides/make-more-possible",
    meta: indexpGSrkE5V3tMeta || {},
    alias: indexpGSrkE5V3tMeta?.alias || [],
    redirect: indexpGSrkE5V3tMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexpGSrkE5V3tMeta?.name ?? "guides-make-more-possible___fr-ca",
    path: indexpGSrkE5V3tMeta?.path ?? "/fr-ca/guides/make-more-possible",
    meta: indexpGSrkE5V3tMeta || {},
    alias: indexpGSrkE5V3tMeta?.alias || [],
    redirect: indexpGSrkE5V3tMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataUAk1dWHp2QMeta?.name ?? "guides-make-more-possible-take-action-with-data___en-US",
    path: take_45action_45with_45dataUAk1dWHp2QMeta?.path ?? "/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataUAk1dWHp2QMeta || {},
    alias: take_45action_45with_45dataUAk1dWHp2QMeta?.alias || [],
    redirect: take_45action_45with_45dataUAk1dWHp2QMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataUAk1dWHp2QMeta?.name ?? "guides-make-more-possible-take-action-with-data___ca",
    path: take_45action_45with_45dataUAk1dWHp2QMeta?.path ?? "/ca/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataUAk1dWHp2QMeta || {},
    alias: take_45action_45with_45dataUAk1dWHp2QMeta?.alias || [],
    redirect: take_45action_45with_45dataUAk1dWHp2QMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataUAk1dWHp2QMeta?.name ?? "guides-make-more-possible-take-action-with-data___nl",
    path: take_45action_45with_45dataUAk1dWHp2QMeta?.path ?? "/nl/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataUAk1dWHp2QMeta || {},
    alias: take_45action_45with_45dataUAk1dWHp2QMeta?.alias || [],
    redirect: take_45action_45with_45dataUAk1dWHp2QMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataUAk1dWHp2QMeta?.name ?? "guides-make-more-possible-take-action-with-data___uk",
    path: take_45action_45with_45dataUAk1dWHp2QMeta?.path ?? "/uk/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataUAk1dWHp2QMeta || {},
    alias: take_45action_45with_45dataUAk1dWHp2QMeta?.alias || [],
    redirect: take_45action_45with_45dataUAk1dWHp2QMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataUAk1dWHp2QMeta?.name ?? "guides-make-more-possible-take-action-with-data___de",
    path: take_45action_45with_45dataUAk1dWHp2QMeta?.path ?? "/de/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataUAk1dWHp2QMeta || {},
    alias: take_45action_45with_45dataUAk1dWHp2QMeta?.alias || [],
    redirect: take_45action_45with_45dataUAk1dWHp2QMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataUAk1dWHp2QMeta?.name ?? "guides-make-more-possible-take-action-with-data___mx",
    path: take_45action_45with_45dataUAk1dWHp2QMeta?.path ?? "/mx/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataUAk1dWHp2QMeta || {},
    alias: take_45action_45with_45dataUAk1dWHp2QMeta?.alias || [],
    redirect: take_45action_45with_45dataUAk1dWHp2QMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataUAk1dWHp2QMeta?.name ?? "guides-make-more-possible-take-action-with-data___fr",
    path: take_45action_45with_45dataUAk1dWHp2QMeta?.path ?? "/fr/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataUAk1dWHp2QMeta || {},
    alias: take_45action_45with_45dataUAk1dWHp2QMeta?.alias || [],
    redirect: take_45action_45with_45dataUAk1dWHp2QMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataUAk1dWHp2QMeta?.name ?? "guides-make-more-possible-take-action-with-data___fr-ca",
    path: take_45action_45with_45dataUAk1dWHp2QMeta?.path ?? "/fr-ca/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataUAk1dWHp2QMeta || {},
    alias: take_45action_45with_45dataUAk1dWHp2QMeta?.alias || [],
    redirect: take_45action_45with_45dataUAk1dWHp2QMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CjB0RSetklMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___en-US",
    path: _91slug_93CjB0RSetklMeta?.path ?? "/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93CjB0RSetklMeta || {},
    alias: _91slug_93CjB0RSetklMeta?.alias || [],
    redirect: _91slug_93CjB0RSetklMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CjB0RSetklMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___ca",
    path: _91slug_93CjB0RSetklMeta?.path ?? "/ca/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93CjB0RSetklMeta || {},
    alias: _91slug_93CjB0RSetklMeta?.alias || [],
    redirect: _91slug_93CjB0RSetklMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CjB0RSetklMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___nl",
    path: _91slug_93CjB0RSetklMeta?.path ?? "/nl/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93CjB0RSetklMeta || {},
    alias: _91slug_93CjB0RSetklMeta?.alias || [],
    redirect: _91slug_93CjB0RSetklMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CjB0RSetklMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___uk",
    path: _91slug_93CjB0RSetklMeta?.path ?? "/uk/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93CjB0RSetklMeta || {},
    alias: _91slug_93CjB0RSetklMeta?.alias || [],
    redirect: _91slug_93CjB0RSetklMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CjB0RSetklMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___de",
    path: _91slug_93CjB0RSetklMeta?.path ?? "/de/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93CjB0RSetklMeta || {},
    alias: _91slug_93CjB0RSetklMeta?.alias || [],
    redirect: _91slug_93CjB0RSetklMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CjB0RSetklMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___mx",
    path: _91slug_93CjB0RSetklMeta?.path ?? "/mx/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93CjB0RSetklMeta || {},
    alias: _91slug_93CjB0RSetklMeta?.alias || [],
    redirect: _91slug_93CjB0RSetklMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CjB0RSetklMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___fr",
    path: _91slug_93CjB0RSetklMeta?.path ?? "/fr/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93CjB0RSetklMeta || {},
    alias: _91slug_93CjB0RSetklMeta?.alias || [],
    redirect: _91slug_93CjB0RSetklMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CjB0RSetklMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___fr-ca",
    path: _91slug_93CjB0RSetklMeta?.path ?? "/fr-ca/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93CjB0RSetklMeta || {},
    alias: _91slug_93CjB0RSetklMeta?.alias || [],
    redirect: _91slug_93CjB0RSetklMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: index2loFhsgk6eMeta?.name ?? "guides-your-partner-for-the-road-ahead___en-US",
    path: index2loFhsgk6eMeta?.path ?? "/guides/your-partner-for-the-road-ahead",
    meta: index2loFhsgk6eMeta || {},
    alias: index2loFhsgk6eMeta?.alias || [],
    redirect: index2loFhsgk6eMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: index2loFhsgk6eMeta?.name ?? "guides-your-partner-for-the-road-ahead___ca",
    path: index2loFhsgk6eMeta?.path ?? "/ca/guides/your-partner-for-the-road-ahead",
    meta: index2loFhsgk6eMeta || {},
    alias: index2loFhsgk6eMeta?.alias || [],
    redirect: index2loFhsgk6eMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: index2loFhsgk6eMeta?.name ?? "guides-your-partner-for-the-road-ahead___nl",
    path: index2loFhsgk6eMeta?.path ?? "/nl/guides/your-partner-for-the-road-ahead",
    meta: index2loFhsgk6eMeta || {},
    alias: index2loFhsgk6eMeta?.alias || [],
    redirect: index2loFhsgk6eMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: index2loFhsgk6eMeta?.name ?? "guides-your-partner-for-the-road-ahead___uk",
    path: index2loFhsgk6eMeta?.path ?? "/uk/guides/your-partner-for-the-road-ahead",
    meta: index2loFhsgk6eMeta || {},
    alias: index2loFhsgk6eMeta?.alias || [],
    redirect: index2loFhsgk6eMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: index2loFhsgk6eMeta?.name ?? "guides-your-partner-for-the-road-ahead___de",
    path: index2loFhsgk6eMeta?.path ?? "/de/guides/your-partner-for-the-road-ahead",
    meta: index2loFhsgk6eMeta || {},
    alias: index2loFhsgk6eMeta?.alias || [],
    redirect: index2loFhsgk6eMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: index2loFhsgk6eMeta?.name ?? "guides-your-partner-for-the-road-ahead___mx",
    path: index2loFhsgk6eMeta?.path ?? "/mx/guides/your-partner-for-the-road-ahead",
    meta: index2loFhsgk6eMeta || {},
    alias: index2loFhsgk6eMeta?.alias || [],
    redirect: index2loFhsgk6eMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: index2loFhsgk6eMeta?.name ?? "guides-your-partner-for-the-road-ahead___fr",
    path: index2loFhsgk6eMeta?.path ?? "/fr/guides/your-partner-for-the-road-ahead",
    meta: index2loFhsgk6eMeta || {},
    alias: index2loFhsgk6eMeta?.alias || [],
    redirect: index2loFhsgk6eMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: index2loFhsgk6eMeta?.name ?? "guides-your-partner-for-the-road-ahead___fr-ca",
    path: index2loFhsgk6eMeta?.path ?? "/fr-ca/guides/your-partner-for-the-road-ahead",
    meta: index2loFhsgk6eMeta || {},
    alias: index2loFhsgk6eMeta?.alias || [],
    redirect: index2loFhsgk6eMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexRVID7FWdHoMeta?.name ?? "index___en-US",
    path: indexRVID7FWdHoMeta?.path ?? "/",
    meta: indexRVID7FWdHoMeta || {},
    alias: indexRVID7FWdHoMeta?.alias || [],
    redirect: indexRVID7FWdHoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexRVID7FWdHoMeta?.name ?? "index___ca",
    path: indexRVID7FWdHoMeta?.path ?? "/ca",
    meta: indexRVID7FWdHoMeta || {},
    alias: indexRVID7FWdHoMeta?.alias || [],
    redirect: indexRVID7FWdHoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexRVID7FWdHoMeta?.name ?? "index___nl",
    path: indexRVID7FWdHoMeta?.path ?? "/nl",
    meta: indexRVID7FWdHoMeta || {},
    alias: indexRVID7FWdHoMeta?.alias || [],
    redirect: indexRVID7FWdHoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexRVID7FWdHoMeta?.name ?? "index___uk",
    path: indexRVID7FWdHoMeta?.path ?? "/uk",
    meta: indexRVID7FWdHoMeta || {},
    alias: indexRVID7FWdHoMeta?.alias || [],
    redirect: indexRVID7FWdHoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexRVID7FWdHoMeta?.name ?? "index___de",
    path: indexRVID7FWdHoMeta?.path ?? "/de",
    meta: indexRVID7FWdHoMeta || {},
    alias: indexRVID7FWdHoMeta?.alias || [],
    redirect: indexRVID7FWdHoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexRVID7FWdHoMeta?.name ?? "index___mx",
    path: indexRVID7FWdHoMeta?.path ?? "/mx",
    meta: indexRVID7FWdHoMeta || {},
    alias: indexRVID7FWdHoMeta?.alias || [],
    redirect: indexRVID7FWdHoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexRVID7FWdHoMeta?.name ?? "index___fr",
    path: indexRVID7FWdHoMeta?.path ?? "/fr",
    meta: indexRVID7FWdHoMeta || {},
    alias: indexRVID7FWdHoMeta?.alias || [],
    redirect: indexRVID7FWdHoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexRVID7FWdHoMeta?.name ?? "index___fr-ca",
    path: indexRVID7FWdHoMeta?.path ?? "/fr-ca",
    meta: indexRVID7FWdHoMeta || {},
    alias: indexRVID7FWdHoMeta?.alias || [],
    redirect: indexRVID7FWdHoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_931dOh0T6OR5Meta?.name ?? "industrial-slug___en-US",
    path: _91slug_931dOh0T6OR5Meta?.path ?? "/industrial/:slug()",
    meta: _91slug_931dOh0T6OR5Meta || {},
    alias: _91slug_931dOh0T6OR5Meta?.alias || [],
    redirect: _91slug_931dOh0T6OR5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931dOh0T6OR5Meta?.name ?? "industrial-slug___ca",
    path: _91slug_931dOh0T6OR5Meta?.path ?? "/ca/industrial/:slug()",
    meta: _91slug_931dOh0T6OR5Meta || {},
    alias: _91slug_931dOh0T6OR5Meta?.alias || [],
    redirect: _91slug_931dOh0T6OR5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931dOh0T6OR5Meta?.name ?? "industrial-slug___nl",
    path: _91slug_931dOh0T6OR5Meta?.path ?? "/nl/industrial/:slug()",
    meta: _91slug_931dOh0T6OR5Meta || {},
    alias: _91slug_931dOh0T6OR5Meta?.alias || [],
    redirect: _91slug_931dOh0T6OR5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931dOh0T6OR5Meta?.name ?? "industrial-slug___uk",
    path: _91slug_931dOh0T6OR5Meta?.path ?? "/uk/industrial/:slug()",
    meta: _91slug_931dOh0T6OR5Meta || {},
    alias: _91slug_931dOh0T6OR5Meta?.alias || [],
    redirect: _91slug_931dOh0T6OR5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931dOh0T6OR5Meta?.name ?? "industrial-slug___de",
    path: _91slug_931dOh0T6OR5Meta?.path ?? "/de/industrial/:slug()",
    meta: _91slug_931dOh0T6OR5Meta || {},
    alias: _91slug_931dOh0T6OR5Meta?.alias || [],
    redirect: _91slug_931dOh0T6OR5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931dOh0T6OR5Meta?.name ?? "industrial-slug___mx",
    path: _91slug_931dOh0T6OR5Meta?.path ?? "/mx/industrial/:slug()",
    meta: _91slug_931dOh0T6OR5Meta || {},
    alias: _91slug_931dOh0T6OR5Meta?.alias || [],
    redirect: _91slug_931dOh0T6OR5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931dOh0T6OR5Meta?.name ?? "industrial-slug___fr",
    path: _91slug_931dOh0T6OR5Meta?.path ?? "/fr/industrial/:slug()",
    meta: _91slug_931dOh0T6OR5Meta || {},
    alias: _91slug_931dOh0T6OR5Meta?.alias || [],
    redirect: _91slug_931dOh0T6OR5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931dOh0T6OR5Meta?.name ?? "industrial-slug___fr-ca",
    path: _91slug_931dOh0T6OR5Meta?.path ?? "/fr-ca/industrial/:slug()",
    meta: _91slug_931dOh0T6OR5Meta || {},
    alias: _91slug_931dOh0T6OR5Meta?.alias || [],
    redirect: _91slug_931dOh0T6OR5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QGIuat36BaMeta?.name ?? "industries-slug___en-US",
    path: _91slug_93QGIuat36BaMeta?.path ?? "/industries/:slug()",
    meta: _91slug_93QGIuat36BaMeta || {},
    alias: _91slug_93QGIuat36BaMeta?.alias || [],
    redirect: _91slug_93QGIuat36BaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QGIuat36BaMeta?.name ?? "industries-slug___ca",
    path: _91slug_93QGIuat36BaMeta?.path ?? "/ca/industries/:slug()",
    meta: _91slug_93QGIuat36BaMeta || {},
    alias: _91slug_93QGIuat36BaMeta?.alias || [],
    redirect: _91slug_93QGIuat36BaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QGIuat36BaMeta?.name ?? "industries-slug___nl",
    path: _91slug_93QGIuat36BaMeta?.path ?? "/nl/industries/:slug()",
    meta: _91slug_93QGIuat36BaMeta || {},
    alias: _91slug_93QGIuat36BaMeta?.alias || [],
    redirect: _91slug_93QGIuat36BaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QGIuat36BaMeta?.name ?? "industries-slug___uk",
    path: _91slug_93QGIuat36BaMeta?.path ?? "/uk/industries/:slug()",
    meta: _91slug_93QGIuat36BaMeta || {},
    alias: _91slug_93QGIuat36BaMeta?.alias || [],
    redirect: _91slug_93QGIuat36BaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QGIuat36BaMeta?.name ?? "industries-slug___de",
    path: _91slug_93QGIuat36BaMeta?.path ?? "/de/industries/:slug()",
    meta: _91slug_93QGIuat36BaMeta || {},
    alias: _91slug_93QGIuat36BaMeta?.alias || [],
    redirect: _91slug_93QGIuat36BaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QGIuat36BaMeta?.name ?? "industries-slug___mx",
    path: _91slug_93QGIuat36BaMeta?.path ?? "/mx/industries/:slug()",
    meta: _91slug_93QGIuat36BaMeta || {},
    alias: _91slug_93QGIuat36BaMeta?.alias || [],
    redirect: _91slug_93QGIuat36BaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QGIuat36BaMeta?.name ?? "industries-slug___fr",
    path: _91slug_93QGIuat36BaMeta?.path ?? "/fr/industries/:slug()",
    meta: _91slug_93QGIuat36BaMeta || {},
    alias: _91slug_93QGIuat36BaMeta?.alias || [],
    redirect: _91slug_93QGIuat36BaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QGIuat36BaMeta?.name ?? "industries-slug___fr-ca",
    path: _91slug_93QGIuat36BaMeta?.path ?? "/fr-ca/industries/:slug()",
    meta: _91slug_93QGIuat36BaMeta || {},
    alias: _91slug_93QGIuat36BaMeta?.alias || [],
    redirect: _91slug_93QGIuat36BaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexNXRmQROsctMeta?.name ?? "industries___en-US",
    path: indexNXRmQROsctMeta?.path ?? "/industries",
    meta: indexNXRmQROsctMeta || {},
    alias: indexNXRmQROsctMeta?.alias || [],
    redirect: indexNXRmQROsctMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexNXRmQROsctMeta?.name ?? "industries___ca",
    path: indexNXRmQROsctMeta?.path ?? "/ca/industries",
    meta: indexNXRmQROsctMeta || {},
    alias: indexNXRmQROsctMeta?.alias || [],
    redirect: indexNXRmQROsctMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexNXRmQROsctMeta?.name ?? "industries___nl",
    path: indexNXRmQROsctMeta?.path ?? "/nl/industries",
    meta: indexNXRmQROsctMeta || {},
    alias: indexNXRmQROsctMeta?.alias || [],
    redirect: indexNXRmQROsctMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexNXRmQROsctMeta?.name ?? "industries___uk",
    path: indexNXRmQROsctMeta?.path ?? "/uk/industries",
    meta: indexNXRmQROsctMeta || {},
    alias: indexNXRmQROsctMeta?.alias || [],
    redirect: indexNXRmQROsctMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexNXRmQROsctMeta?.name ?? "industries___de",
    path: indexNXRmQROsctMeta?.path ?? "/de/industries",
    meta: indexNXRmQROsctMeta || {},
    alias: indexNXRmQROsctMeta?.alias || [],
    redirect: indexNXRmQROsctMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexNXRmQROsctMeta?.name ?? "industries___mx",
    path: indexNXRmQROsctMeta?.path ?? "/mx/industries",
    meta: indexNXRmQROsctMeta || {},
    alias: indexNXRmQROsctMeta?.alias || [],
    redirect: indexNXRmQROsctMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexNXRmQROsctMeta?.name ?? "industries___fr",
    path: indexNXRmQROsctMeta?.path ?? "/fr/industries",
    meta: indexNXRmQROsctMeta || {},
    alias: indexNXRmQROsctMeta?.alias || [],
    redirect: indexNXRmQROsctMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexNXRmQROsctMeta?.name ?? "industries___fr-ca",
    path: indexNXRmQROsctMeta?.path ?? "/fr-ca/industries",
    meta: indexNXRmQROsctMeta || {},
    alias: indexNXRmQROsctMeta?.alias || [],
    redirect: indexNXRmQROsctMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: citiesSBBThDHfUdMeta?.name ?? "insights-cities___en-US",
    path: citiesSBBThDHfUdMeta?.path ?? "/insights/cities",
    meta: citiesSBBThDHfUdMeta || {},
    alias: citiesSBBThDHfUdMeta?.alias || [],
    redirect: citiesSBBThDHfUdMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesSBBThDHfUdMeta?.name ?? "insights-cities___ca",
    path: citiesSBBThDHfUdMeta?.path ?? "/ca/insights/cities",
    meta: citiesSBBThDHfUdMeta || {},
    alias: citiesSBBThDHfUdMeta?.alias || [],
    redirect: citiesSBBThDHfUdMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesSBBThDHfUdMeta?.name ?? "insights-cities___nl",
    path: citiesSBBThDHfUdMeta?.path ?? "/nl/insights/cities",
    meta: citiesSBBThDHfUdMeta || {},
    alias: citiesSBBThDHfUdMeta?.alias || [],
    redirect: citiesSBBThDHfUdMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesSBBThDHfUdMeta?.name ?? "insights-cities___uk",
    path: citiesSBBThDHfUdMeta?.path ?? "/uk/insights/cities",
    meta: citiesSBBThDHfUdMeta || {},
    alias: citiesSBBThDHfUdMeta?.alias || [],
    redirect: citiesSBBThDHfUdMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesSBBThDHfUdMeta?.name ?? "insights-cities___de",
    path: citiesSBBThDHfUdMeta?.path ?? "/de/insights/cities",
    meta: citiesSBBThDHfUdMeta || {},
    alias: citiesSBBThDHfUdMeta?.alias || [],
    redirect: citiesSBBThDHfUdMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesSBBThDHfUdMeta?.name ?? "insights-cities___mx",
    path: citiesSBBThDHfUdMeta?.path ?? "/mx/insights/cities",
    meta: citiesSBBThDHfUdMeta || {},
    alias: citiesSBBThDHfUdMeta?.alias || [],
    redirect: citiesSBBThDHfUdMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesSBBThDHfUdMeta?.name ?? "insights-cities___fr",
    path: citiesSBBThDHfUdMeta?.path ?? "/fr/insights/cities",
    meta: citiesSBBThDHfUdMeta || {},
    alias: citiesSBBThDHfUdMeta?.alias || [],
    redirect: citiesSBBThDHfUdMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesSBBThDHfUdMeta?.name ?? "insights-cities___fr-ca",
    path: citiesSBBThDHfUdMeta?.path ?? "/fr-ca/insights/cities",
    meta: citiesSBBThDHfUdMeta || {},
    alias: citiesSBBThDHfUdMeta?.alias || [],
    redirect: citiesSBBThDHfUdMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: indexSJXoeETKc0Meta?.name ?? "integrations___en-US",
    path: indexSJXoeETKc0Meta?.path ?? "/integrations",
    meta: indexSJXoeETKc0Meta || {},
    alias: indexSJXoeETKc0Meta?.alias || [],
    redirect: indexSJXoeETKc0Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexSJXoeETKc0Meta?.name ?? "integrations___ca",
    path: indexSJXoeETKc0Meta?.path ?? "/ca/integrations",
    meta: indexSJXoeETKc0Meta || {},
    alias: indexSJXoeETKc0Meta?.alias || [],
    redirect: indexSJXoeETKc0Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexSJXoeETKc0Meta?.name ?? "integrations___nl",
    path: indexSJXoeETKc0Meta?.path ?? "/nl/integrations",
    meta: indexSJXoeETKc0Meta || {},
    alias: indexSJXoeETKc0Meta?.alias || [],
    redirect: indexSJXoeETKc0Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexSJXoeETKc0Meta?.name ?? "integrations___uk",
    path: indexSJXoeETKc0Meta?.path ?? "/uk/integrations",
    meta: indexSJXoeETKc0Meta || {},
    alias: indexSJXoeETKc0Meta?.alias || [],
    redirect: indexSJXoeETKc0Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexSJXoeETKc0Meta?.name ?? "integrations___de",
    path: indexSJXoeETKc0Meta?.path ?? "/de/integrations",
    meta: indexSJXoeETKc0Meta || {},
    alias: indexSJXoeETKc0Meta?.alias || [],
    redirect: indexSJXoeETKc0Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexSJXoeETKc0Meta?.name ?? "integrations___mx",
    path: indexSJXoeETKc0Meta?.path ?? "/mx/integrations",
    meta: indexSJXoeETKc0Meta || {},
    alias: indexSJXoeETKc0Meta?.alias || [],
    redirect: indexSJXoeETKc0Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexSJXoeETKc0Meta?.name ?? "integrations___fr",
    path: indexSJXoeETKc0Meta?.path ?? "/fr/integrations",
    meta: indexSJXoeETKc0Meta || {},
    alias: indexSJXoeETKc0Meta?.alias || [],
    redirect: indexSJXoeETKc0Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexSJXoeETKc0Meta?.name ?? "integrations___fr-ca",
    path: indexSJXoeETKc0Meta?.path ?? "/fr-ca/integrations",
    meta: indexSJXoeETKc0Meta || {},
    alias: indexSJXoeETKc0Meta?.alias || [],
    redirect: indexSJXoeETKc0Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index217cyXTIlCMeta?.name ?? "invite___en-US",
    path: index217cyXTIlCMeta?.path ?? "/invite",
    meta: index217cyXTIlCMeta || {},
    alias: index217cyXTIlCMeta?.alias || [],
    redirect: index217cyXTIlCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: index217cyXTIlCMeta?.name ?? "invite___ca",
    path: index217cyXTIlCMeta?.path ?? "/ca/invite",
    meta: index217cyXTIlCMeta || {},
    alias: index217cyXTIlCMeta?.alias || [],
    redirect: index217cyXTIlCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: index217cyXTIlCMeta?.name ?? "invite___nl",
    path: index217cyXTIlCMeta?.path ?? "/nl/invite",
    meta: index217cyXTIlCMeta || {},
    alias: index217cyXTIlCMeta?.alias || [],
    redirect: index217cyXTIlCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: index217cyXTIlCMeta?.name ?? "invite___uk",
    path: index217cyXTIlCMeta?.path ?? "/uk/invite",
    meta: index217cyXTIlCMeta || {},
    alias: index217cyXTIlCMeta?.alias || [],
    redirect: index217cyXTIlCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: index217cyXTIlCMeta?.name ?? "invite___de",
    path: index217cyXTIlCMeta?.path ?? "/de/invite",
    meta: index217cyXTIlCMeta || {},
    alias: index217cyXTIlCMeta?.alias || [],
    redirect: index217cyXTIlCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: index217cyXTIlCMeta?.name ?? "invite___mx",
    path: index217cyXTIlCMeta?.path ?? "/mx/invite",
    meta: index217cyXTIlCMeta || {},
    alias: index217cyXTIlCMeta?.alias || [],
    redirect: index217cyXTIlCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: index217cyXTIlCMeta?.name ?? "invite___fr",
    path: index217cyXTIlCMeta?.path ?? "/fr/invite",
    meta: index217cyXTIlCMeta || {},
    alias: index217cyXTIlCMeta?.alias || [],
    redirect: index217cyXTIlCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: index217cyXTIlCMeta?.name ?? "invite___fr-ca",
    path: index217cyXTIlCMeta?.path ?? "/fr-ca/invite",
    meta: index217cyXTIlCMeta || {},
    alias: index217cyXTIlCMeta?.alias || [],
    redirect: index217cyXTIlCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45notice3h1eE1GSHbMeta?.name ?? "legal-candidate-privacy-notice___en-US",
    path: candidate_45privacy_45notice3h1eE1GSHbMeta?.path ?? "/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45notice3h1eE1GSHbMeta || {},
    alias: candidate_45privacy_45notice3h1eE1GSHbMeta?.alias || [],
    redirect: candidate_45privacy_45notice3h1eE1GSHbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45notice3h1eE1GSHbMeta?.name ?? "legal-candidate-privacy-notice___ca",
    path: candidate_45privacy_45notice3h1eE1GSHbMeta?.path ?? "/ca/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45notice3h1eE1GSHbMeta || {},
    alias: candidate_45privacy_45notice3h1eE1GSHbMeta?.alias || [],
    redirect: candidate_45privacy_45notice3h1eE1GSHbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45notice3h1eE1GSHbMeta?.name ?? "legal-candidate-privacy-notice___nl",
    path: candidate_45privacy_45notice3h1eE1GSHbMeta?.path ?? "/nl/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45notice3h1eE1GSHbMeta || {},
    alias: candidate_45privacy_45notice3h1eE1GSHbMeta?.alias || [],
    redirect: candidate_45privacy_45notice3h1eE1GSHbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45notice3h1eE1GSHbMeta?.name ?? "legal-candidate-privacy-notice___uk",
    path: candidate_45privacy_45notice3h1eE1GSHbMeta?.path ?? "/uk/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45notice3h1eE1GSHbMeta || {},
    alias: candidate_45privacy_45notice3h1eE1GSHbMeta?.alias || [],
    redirect: candidate_45privacy_45notice3h1eE1GSHbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45notice3h1eE1GSHbMeta?.name ?? "legal-candidate-privacy-notice___de",
    path: candidate_45privacy_45notice3h1eE1GSHbMeta?.path ?? "/de/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45notice3h1eE1GSHbMeta || {},
    alias: candidate_45privacy_45notice3h1eE1GSHbMeta?.alias || [],
    redirect: candidate_45privacy_45notice3h1eE1GSHbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45notice3h1eE1GSHbMeta?.name ?? "legal-candidate-privacy-notice___mx",
    path: candidate_45privacy_45notice3h1eE1GSHbMeta?.path ?? "/mx/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45notice3h1eE1GSHbMeta || {},
    alias: candidate_45privacy_45notice3h1eE1GSHbMeta?.alias || [],
    redirect: candidate_45privacy_45notice3h1eE1GSHbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45notice3h1eE1GSHbMeta?.name ?? "legal-candidate-privacy-notice___fr",
    path: candidate_45privacy_45notice3h1eE1GSHbMeta?.path ?? "/fr/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45notice3h1eE1GSHbMeta || {},
    alias: candidate_45privacy_45notice3h1eE1GSHbMeta?.alias || [],
    redirect: candidate_45privacy_45notice3h1eE1GSHbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45notice3h1eE1GSHbMeta?.name ?? "legal-candidate-privacy-notice___fr-ca",
    path: candidate_45privacy_45notice3h1eE1GSHbMeta?.path ?? "/fr-ca/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45notice3h1eE1GSHbMeta || {},
    alias: candidate_45privacy_45notice3h1eE1GSHbMeta?.alias || [],
    redirect: candidate_45privacy_45notice3h1eE1GSHbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentZQoDyYq4cSMeta?.name ?? "legal-cookie-consent___en-US",
    path: cookie_45consentZQoDyYq4cSMeta?.path ?? "/legal/cookie-consent",
    meta: cookie_45consentZQoDyYq4cSMeta || {},
    alias: cookie_45consentZQoDyYq4cSMeta?.alias || [],
    redirect: cookie_45consentZQoDyYq4cSMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentZQoDyYq4cSMeta?.name ?? "legal-cookie-consent___ca",
    path: cookie_45consentZQoDyYq4cSMeta?.path ?? "/ca/legal/cookie-consent",
    meta: cookie_45consentZQoDyYq4cSMeta || {},
    alias: cookie_45consentZQoDyYq4cSMeta?.alias || [],
    redirect: cookie_45consentZQoDyYq4cSMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentZQoDyYq4cSMeta?.name ?? "legal-cookie-consent___nl",
    path: cookie_45consentZQoDyYq4cSMeta?.path ?? "/nl/legal/cookie-consent",
    meta: cookie_45consentZQoDyYq4cSMeta || {},
    alias: cookie_45consentZQoDyYq4cSMeta?.alias || [],
    redirect: cookie_45consentZQoDyYq4cSMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentZQoDyYq4cSMeta?.name ?? "legal-cookie-consent___uk",
    path: cookie_45consentZQoDyYq4cSMeta?.path ?? "/uk/legal/cookie-consent",
    meta: cookie_45consentZQoDyYq4cSMeta || {},
    alias: cookie_45consentZQoDyYq4cSMeta?.alias || [],
    redirect: cookie_45consentZQoDyYq4cSMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentZQoDyYq4cSMeta?.name ?? "legal-cookie-consent___de",
    path: cookie_45consentZQoDyYq4cSMeta?.path ?? "/de/legal/cookie-consent",
    meta: cookie_45consentZQoDyYq4cSMeta || {},
    alias: cookie_45consentZQoDyYq4cSMeta?.alias || [],
    redirect: cookie_45consentZQoDyYq4cSMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentZQoDyYq4cSMeta?.name ?? "legal-cookie-consent___mx",
    path: cookie_45consentZQoDyYq4cSMeta?.path ?? "/mx/legal/cookie-consent",
    meta: cookie_45consentZQoDyYq4cSMeta || {},
    alias: cookie_45consentZQoDyYq4cSMeta?.alias || [],
    redirect: cookie_45consentZQoDyYq4cSMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentZQoDyYq4cSMeta?.name ?? "legal-cookie-consent___fr",
    path: cookie_45consentZQoDyYq4cSMeta?.path ?? "/fr/legal/cookie-consent",
    meta: cookie_45consentZQoDyYq4cSMeta || {},
    alias: cookie_45consentZQoDyYq4cSMeta?.alias || [],
    redirect: cookie_45consentZQoDyYq4cSMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentZQoDyYq4cSMeta?.name ?? "legal-cookie-consent___fr-ca",
    path: cookie_45consentZQoDyYq4cSMeta?.path ?? "/fr-ca/legal/cookie-consent",
    meta: cookie_45consentZQoDyYq4cSMeta || {},
    alias: cookie_45consentZQoDyYq4cSMeta?.alias || [],
    redirect: cookie_45consentZQoDyYq4cSMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyFXALTEHlGUMeta?.name ?? "legal-cookie-policy___en-US",
    path: cookie_45policyFXALTEHlGUMeta?.path ?? "/legal/cookie-policy",
    meta: cookie_45policyFXALTEHlGUMeta || {},
    alias: cookie_45policyFXALTEHlGUMeta?.alias || [],
    redirect: cookie_45policyFXALTEHlGUMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyFXALTEHlGUMeta?.name ?? "legal-cookie-policy___ca",
    path: cookie_45policyFXALTEHlGUMeta?.path ?? "/ca/legal/cookie-policy",
    meta: cookie_45policyFXALTEHlGUMeta || {},
    alias: cookie_45policyFXALTEHlGUMeta?.alias || [],
    redirect: cookie_45policyFXALTEHlGUMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyFXALTEHlGUMeta?.name ?? "legal-cookie-policy___nl",
    path: cookie_45policyFXALTEHlGUMeta?.path ?? "/nl/legal/cookie-policy",
    meta: cookie_45policyFXALTEHlGUMeta || {},
    alias: cookie_45policyFXALTEHlGUMeta?.alias || [],
    redirect: cookie_45policyFXALTEHlGUMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyFXALTEHlGUMeta?.name ?? "legal-cookie-policy___uk",
    path: cookie_45policyFXALTEHlGUMeta?.path ?? "/uk/legal/cookie-policy",
    meta: cookie_45policyFXALTEHlGUMeta || {},
    alias: cookie_45policyFXALTEHlGUMeta?.alias || [],
    redirect: cookie_45policyFXALTEHlGUMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyFXALTEHlGUMeta?.name ?? "legal-cookie-policy___de",
    path: cookie_45policyFXALTEHlGUMeta?.path ?? "/de/legal/cookie-policy",
    meta: cookie_45policyFXALTEHlGUMeta || {},
    alias: cookie_45policyFXALTEHlGUMeta?.alias || [],
    redirect: cookie_45policyFXALTEHlGUMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyFXALTEHlGUMeta?.name ?? "legal-cookie-policy___mx",
    path: cookie_45policyFXALTEHlGUMeta?.path ?? "/mx/legal/cookie-policy",
    meta: cookie_45policyFXALTEHlGUMeta || {},
    alias: cookie_45policyFXALTEHlGUMeta?.alias || [],
    redirect: cookie_45policyFXALTEHlGUMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyFXALTEHlGUMeta?.name ?? "legal-cookie-policy___fr",
    path: cookie_45policyFXALTEHlGUMeta?.path ?? "/fr/legal/cookie-policy",
    meta: cookie_45policyFXALTEHlGUMeta || {},
    alias: cookie_45policyFXALTEHlGUMeta?.alias || [],
    redirect: cookie_45policyFXALTEHlGUMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyFXALTEHlGUMeta?.name ?? "legal-cookie-policy___fr-ca",
    path: cookie_45policyFXALTEHlGUMeta?.path ?? "/fr-ca/legal/cookie-policy",
    meta: cookie_45policyFXALTEHlGUMeta || {},
    alias: cookie_45policyFXALTEHlGUMeta?.alias || [],
    redirect: cookie_45policyFXALTEHlGUMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumCLcBvlJyDeMeta?.name ?? "legal-data-protection-addendum___en-US",
    path: data_45protection_45addendumCLcBvlJyDeMeta?.path ?? "/legal/data-protection-addendum",
    meta: data_45protection_45addendumCLcBvlJyDeMeta || {},
    alias: data_45protection_45addendumCLcBvlJyDeMeta?.alias || [],
    redirect: data_45protection_45addendumCLcBvlJyDeMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumCLcBvlJyDeMeta?.name ?? "legal-data-protection-addendum___ca",
    path: data_45protection_45addendumCLcBvlJyDeMeta?.path ?? "/ca/legal/data-protection-addendum",
    meta: data_45protection_45addendumCLcBvlJyDeMeta || {},
    alias: data_45protection_45addendumCLcBvlJyDeMeta?.alias || [],
    redirect: data_45protection_45addendumCLcBvlJyDeMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumCLcBvlJyDeMeta?.name ?? "legal-data-protection-addendum___nl",
    path: data_45protection_45addendumCLcBvlJyDeMeta?.path ?? "/nl/legal/data-protection-addendum",
    meta: data_45protection_45addendumCLcBvlJyDeMeta || {},
    alias: data_45protection_45addendumCLcBvlJyDeMeta?.alias || [],
    redirect: data_45protection_45addendumCLcBvlJyDeMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumCLcBvlJyDeMeta?.name ?? "legal-data-protection-addendum___uk",
    path: data_45protection_45addendumCLcBvlJyDeMeta?.path ?? "/uk/legal/data-protection-addendum",
    meta: data_45protection_45addendumCLcBvlJyDeMeta || {},
    alias: data_45protection_45addendumCLcBvlJyDeMeta?.alias || [],
    redirect: data_45protection_45addendumCLcBvlJyDeMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumCLcBvlJyDeMeta?.name ?? "legal-data-protection-addendum___de",
    path: data_45protection_45addendumCLcBvlJyDeMeta?.path ?? "/de/legal/data-protection-addendum",
    meta: data_45protection_45addendumCLcBvlJyDeMeta || {},
    alias: data_45protection_45addendumCLcBvlJyDeMeta?.alias || [],
    redirect: data_45protection_45addendumCLcBvlJyDeMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumCLcBvlJyDeMeta?.name ?? "legal-data-protection-addendum___mx",
    path: data_45protection_45addendumCLcBvlJyDeMeta?.path ?? "/mx/legal/data-protection-addendum",
    meta: data_45protection_45addendumCLcBvlJyDeMeta || {},
    alias: data_45protection_45addendumCLcBvlJyDeMeta?.alias || [],
    redirect: data_45protection_45addendumCLcBvlJyDeMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumCLcBvlJyDeMeta?.name ?? "legal-data-protection-addendum___fr",
    path: data_45protection_45addendumCLcBvlJyDeMeta?.path ?? "/fr/legal/data-protection-addendum",
    meta: data_45protection_45addendumCLcBvlJyDeMeta || {},
    alias: data_45protection_45addendumCLcBvlJyDeMeta?.alias || [],
    redirect: data_45protection_45addendumCLcBvlJyDeMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumCLcBvlJyDeMeta?.name ?? "legal-data-protection-addendum___fr-ca",
    path: data_45protection_45addendumCLcBvlJyDeMeta?.path ?? "/fr-ca/legal/data-protection-addendum",
    meta: data_45protection_45addendumCLcBvlJyDeMeta || {},
    alias: data_45protection_45addendumCLcBvlJyDeMeta?.alias || [],
    redirect: data_45protection_45addendumCLcBvlJyDeMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termssc7QkQIEKtMeta?.name ?? "legal-express-order-terms___en-US",
    path: express_45order_45termssc7QkQIEKtMeta?.path ?? "/legal/express-order-terms",
    meta: express_45order_45termssc7QkQIEKtMeta || {},
    alias: express_45order_45termssc7QkQIEKtMeta?.alias || [],
    redirect: express_45order_45termssc7QkQIEKtMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termssc7QkQIEKtMeta?.name ?? "legal-express-order-terms___ca",
    path: express_45order_45termssc7QkQIEKtMeta?.path ?? "/ca/legal/express-order-terms",
    meta: express_45order_45termssc7QkQIEKtMeta || {},
    alias: express_45order_45termssc7QkQIEKtMeta?.alias || [],
    redirect: express_45order_45termssc7QkQIEKtMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termssc7QkQIEKtMeta?.name ?? "legal-express-order-terms___nl",
    path: express_45order_45termssc7QkQIEKtMeta?.path ?? "/nl/legal/express-order-terms",
    meta: express_45order_45termssc7QkQIEKtMeta || {},
    alias: express_45order_45termssc7QkQIEKtMeta?.alias || [],
    redirect: express_45order_45termssc7QkQIEKtMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termssc7QkQIEKtMeta?.name ?? "legal-express-order-terms___uk",
    path: express_45order_45termssc7QkQIEKtMeta?.path ?? "/uk/legal/express-order-terms",
    meta: express_45order_45termssc7QkQIEKtMeta || {},
    alias: express_45order_45termssc7QkQIEKtMeta?.alias || [],
    redirect: express_45order_45termssc7QkQIEKtMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termssc7QkQIEKtMeta?.name ?? "legal-express-order-terms___de",
    path: express_45order_45termssc7QkQIEKtMeta?.path ?? "/de/legal/express-order-terms",
    meta: express_45order_45termssc7QkQIEKtMeta || {},
    alias: express_45order_45termssc7QkQIEKtMeta?.alias || [],
    redirect: express_45order_45termssc7QkQIEKtMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termssc7QkQIEKtMeta?.name ?? "legal-express-order-terms___mx",
    path: express_45order_45termssc7QkQIEKtMeta?.path ?? "/mx/legal/express-order-terms",
    meta: express_45order_45termssc7QkQIEKtMeta || {},
    alias: express_45order_45termssc7QkQIEKtMeta?.alias || [],
    redirect: express_45order_45termssc7QkQIEKtMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termssc7QkQIEKtMeta?.name ?? "legal-express-order-terms___fr",
    path: express_45order_45termssc7QkQIEKtMeta?.path ?? "/fr/legal/express-order-terms",
    meta: express_45order_45termssc7QkQIEKtMeta || {},
    alias: express_45order_45termssc7QkQIEKtMeta?.alias || [],
    redirect: express_45order_45termssc7QkQIEKtMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termssc7QkQIEKtMeta?.name ?? "legal-express-order-terms___fr-ca",
    path: express_45order_45termssc7QkQIEKtMeta?.path ?? "/fr-ca/legal/express-order-terms",
    meta: express_45order_45termssc7QkQIEKtMeta || {},
    alias: express_45order_45termssc7QkQIEKtMeta?.alias || [],
    redirect: express_45order_45termssc7QkQIEKtMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyWo3gHs7KBOMeta?.name ?? "legal-hardware-warranty___en-US",
    path: hardware_45warrantyWo3gHs7KBOMeta?.path ?? "/legal/hardware-warranty",
    meta: hardware_45warrantyWo3gHs7KBOMeta || {},
    alias: hardware_45warrantyWo3gHs7KBOMeta?.alias || [],
    redirect: hardware_45warrantyWo3gHs7KBOMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyWo3gHs7KBOMeta?.name ?? "legal-hardware-warranty___ca",
    path: hardware_45warrantyWo3gHs7KBOMeta?.path ?? "/ca/legal/hardware-warranty",
    meta: hardware_45warrantyWo3gHs7KBOMeta || {},
    alias: hardware_45warrantyWo3gHs7KBOMeta?.alias || [],
    redirect: hardware_45warrantyWo3gHs7KBOMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyWo3gHs7KBOMeta?.name ?? "legal-hardware-warranty___nl",
    path: hardware_45warrantyWo3gHs7KBOMeta?.path ?? "/nl/legal/hardware-warranty",
    meta: hardware_45warrantyWo3gHs7KBOMeta || {},
    alias: hardware_45warrantyWo3gHs7KBOMeta?.alias || [],
    redirect: hardware_45warrantyWo3gHs7KBOMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyWo3gHs7KBOMeta?.name ?? "legal-hardware-warranty___uk",
    path: hardware_45warrantyWo3gHs7KBOMeta?.path ?? "/uk/legal/hardware-warranty",
    meta: hardware_45warrantyWo3gHs7KBOMeta || {},
    alias: hardware_45warrantyWo3gHs7KBOMeta?.alias || [],
    redirect: hardware_45warrantyWo3gHs7KBOMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyWo3gHs7KBOMeta?.name ?? "legal-hardware-warranty___de",
    path: hardware_45warrantyWo3gHs7KBOMeta?.path ?? "/de/legal/hardware-warranty",
    meta: hardware_45warrantyWo3gHs7KBOMeta || {},
    alias: hardware_45warrantyWo3gHs7KBOMeta?.alias || [],
    redirect: hardware_45warrantyWo3gHs7KBOMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyWo3gHs7KBOMeta?.name ?? "legal-hardware-warranty___mx",
    path: hardware_45warrantyWo3gHs7KBOMeta?.path ?? "/mx/legal/hardware-warranty",
    meta: hardware_45warrantyWo3gHs7KBOMeta || {},
    alias: hardware_45warrantyWo3gHs7KBOMeta?.alias || [],
    redirect: hardware_45warrantyWo3gHs7KBOMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyWo3gHs7KBOMeta?.name ?? "legal-hardware-warranty___fr",
    path: hardware_45warrantyWo3gHs7KBOMeta?.path ?? "/fr/legal/hardware-warranty",
    meta: hardware_45warrantyWo3gHs7KBOMeta || {},
    alias: hardware_45warrantyWo3gHs7KBOMeta?.alias || [],
    redirect: hardware_45warrantyWo3gHs7KBOMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyWo3gHs7KBOMeta?.name ?? "legal-hardware-warranty___fr-ca",
    path: hardware_45warrantyWo3gHs7KBOMeta?.path ?? "/fr-ca/legal/hardware-warranty",
    meta: hardware_45warrantyWo3gHs7KBOMeta || {},
    alias: hardware_45warrantyWo3gHs7KBOMeta?.alias || [],
    redirect: hardware_45warrantyWo3gHs7KBOMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaTX0B6si46ZMeta?.name ?? "legal-hosted-software-sla___en-US",
    path: hosted_45software_45slaTX0B6si46ZMeta?.path ?? "/legal/hosted-software-sla",
    meta: hosted_45software_45slaTX0B6si46ZMeta || {},
    alias: hosted_45software_45slaTX0B6si46ZMeta?.alias || [],
    redirect: hosted_45software_45slaTX0B6si46ZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaTX0B6si46ZMeta?.name ?? "legal-hosted-software-sla___ca",
    path: hosted_45software_45slaTX0B6si46ZMeta?.path ?? "/ca/legal/hosted-software-sla",
    meta: hosted_45software_45slaTX0B6si46ZMeta || {},
    alias: hosted_45software_45slaTX0B6si46ZMeta?.alias || [],
    redirect: hosted_45software_45slaTX0B6si46ZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaTX0B6si46ZMeta?.name ?? "legal-hosted-software-sla___nl",
    path: hosted_45software_45slaTX0B6si46ZMeta?.path ?? "/nl/legal/hosted-software-sla",
    meta: hosted_45software_45slaTX0B6si46ZMeta || {},
    alias: hosted_45software_45slaTX0B6si46ZMeta?.alias || [],
    redirect: hosted_45software_45slaTX0B6si46ZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaTX0B6si46ZMeta?.name ?? "legal-hosted-software-sla___uk",
    path: hosted_45software_45slaTX0B6si46ZMeta?.path ?? "/uk/legal/hosted-software-sla",
    meta: hosted_45software_45slaTX0B6si46ZMeta || {},
    alias: hosted_45software_45slaTX0B6si46ZMeta?.alias || [],
    redirect: hosted_45software_45slaTX0B6si46ZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaTX0B6si46ZMeta?.name ?? "legal-hosted-software-sla___de",
    path: hosted_45software_45slaTX0B6si46ZMeta?.path ?? "/de/legal/hosted-software-sla",
    meta: hosted_45software_45slaTX0B6si46ZMeta || {},
    alias: hosted_45software_45slaTX0B6si46ZMeta?.alias || [],
    redirect: hosted_45software_45slaTX0B6si46ZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaTX0B6si46ZMeta?.name ?? "legal-hosted-software-sla___mx",
    path: hosted_45software_45slaTX0B6si46ZMeta?.path ?? "/mx/legal/hosted-software-sla",
    meta: hosted_45software_45slaTX0B6si46ZMeta || {},
    alias: hosted_45software_45slaTX0B6si46ZMeta?.alias || [],
    redirect: hosted_45software_45slaTX0B6si46ZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaTX0B6si46ZMeta?.name ?? "legal-hosted-software-sla___fr",
    path: hosted_45software_45slaTX0B6si46ZMeta?.path ?? "/fr/legal/hosted-software-sla",
    meta: hosted_45software_45slaTX0B6si46ZMeta || {},
    alias: hosted_45software_45slaTX0B6si46ZMeta?.alias || [],
    redirect: hosted_45software_45slaTX0B6si46ZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaTX0B6si46ZMeta?.name ?? "legal-hosted-software-sla___fr-ca",
    path: hosted_45software_45slaTX0B6si46ZMeta?.path ?? "/fr-ca/legal/hosted-software-sla",
    meta: hosted_45software_45slaTX0B6si46ZMeta || {},
    alias: hosted_45software_45slaTX0B6si46ZMeta?.alias || [],
    redirect: hosted_45software_45slaTX0B6si46ZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductMWBMImB3J8Meta?.name ?? "legal-partner-code-of-conduct___en-US",
    path: partner_45code_45of_45conductMWBMImB3J8Meta?.path ?? "/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductMWBMImB3J8Meta || {},
    alias: partner_45code_45of_45conductMWBMImB3J8Meta?.alias || [],
    redirect: partner_45code_45of_45conductMWBMImB3J8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductMWBMImB3J8Meta?.name ?? "legal-partner-code-of-conduct___ca",
    path: partner_45code_45of_45conductMWBMImB3J8Meta?.path ?? "/ca/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductMWBMImB3J8Meta || {},
    alias: partner_45code_45of_45conductMWBMImB3J8Meta?.alias || [],
    redirect: partner_45code_45of_45conductMWBMImB3J8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductMWBMImB3J8Meta?.name ?? "legal-partner-code-of-conduct___nl",
    path: partner_45code_45of_45conductMWBMImB3J8Meta?.path ?? "/nl/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductMWBMImB3J8Meta || {},
    alias: partner_45code_45of_45conductMWBMImB3J8Meta?.alias || [],
    redirect: partner_45code_45of_45conductMWBMImB3J8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductMWBMImB3J8Meta?.name ?? "legal-partner-code-of-conduct___uk",
    path: partner_45code_45of_45conductMWBMImB3J8Meta?.path ?? "/uk/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductMWBMImB3J8Meta || {},
    alias: partner_45code_45of_45conductMWBMImB3J8Meta?.alias || [],
    redirect: partner_45code_45of_45conductMWBMImB3J8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductMWBMImB3J8Meta?.name ?? "legal-partner-code-of-conduct___de",
    path: partner_45code_45of_45conductMWBMImB3J8Meta?.path ?? "/de/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductMWBMImB3J8Meta || {},
    alias: partner_45code_45of_45conductMWBMImB3J8Meta?.alias || [],
    redirect: partner_45code_45of_45conductMWBMImB3J8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductMWBMImB3J8Meta?.name ?? "legal-partner-code-of-conduct___mx",
    path: partner_45code_45of_45conductMWBMImB3J8Meta?.path ?? "/mx/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductMWBMImB3J8Meta || {},
    alias: partner_45code_45of_45conductMWBMImB3J8Meta?.alias || [],
    redirect: partner_45code_45of_45conductMWBMImB3J8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductMWBMImB3J8Meta?.name ?? "legal-partner-code-of-conduct___fr",
    path: partner_45code_45of_45conductMWBMImB3J8Meta?.path ?? "/fr/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductMWBMImB3J8Meta || {},
    alias: partner_45code_45of_45conductMWBMImB3J8Meta?.alias || [],
    redirect: partner_45code_45of_45conductMWBMImB3J8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductMWBMImB3J8Meta?.name ?? "legal-partner-code-of-conduct___fr-ca",
    path: partner_45code_45of_45conductMWBMImB3J8Meta?.path ?? "/fr-ca/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductMWBMImB3J8Meta || {},
    alias: partner_45code_45of_45conductMWBMImB3J8Meta?.alias || [],
    redirect: partner_45code_45of_45conductMWBMImB3J8Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicedA5kv9WX03Meta?.name ?? "legal-platform-terms-of-service___en-US",
    path: platform_45terms_45of_45servicedA5kv9WX03Meta?.path ?? "/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicedA5kv9WX03Meta || {},
    alias: platform_45terms_45of_45servicedA5kv9WX03Meta?.alias || [],
    redirect: platform_45terms_45of_45servicedA5kv9WX03Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicedA5kv9WX03Meta?.name ?? "legal-platform-terms-of-service___ca",
    path: platform_45terms_45of_45servicedA5kv9WX03Meta?.path ?? "/ca/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicedA5kv9WX03Meta || {},
    alias: platform_45terms_45of_45servicedA5kv9WX03Meta?.alias || [],
    redirect: platform_45terms_45of_45servicedA5kv9WX03Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicedA5kv9WX03Meta?.name ?? "legal-platform-terms-of-service___nl",
    path: platform_45terms_45of_45servicedA5kv9WX03Meta?.path ?? "/nl/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicedA5kv9WX03Meta || {},
    alias: platform_45terms_45of_45servicedA5kv9WX03Meta?.alias || [],
    redirect: platform_45terms_45of_45servicedA5kv9WX03Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicedA5kv9WX03Meta?.name ?? "legal-platform-terms-of-service___uk",
    path: platform_45terms_45of_45servicedA5kv9WX03Meta?.path ?? "/uk/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicedA5kv9WX03Meta || {},
    alias: platform_45terms_45of_45servicedA5kv9WX03Meta?.alias || [],
    redirect: platform_45terms_45of_45servicedA5kv9WX03Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicedA5kv9WX03Meta?.name ?? "legal-platform-terms-of-service___de",
    path: platform_45terms_45of_45servicedA5kv9WX03Meta?.path ?? "/de/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicedA5kv9WX03Meta || {},
    alias: platform_45terms_45of_45servicedA5kv9WX03Meta?.alias || [],
    redirect: platform_45terms_45of_45servicedA5kv9WX03Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicedA5kv9WX03Meta?.name ?? "legal-platform-terms-of-service___mx",
    path: platform_45terms_45of_45servicedA5kv9WX03Meta?.path ?? "/mx/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicedA5kv9WX03Meta || {},
    alias: platform_45terms_45of_45servicedA5kv9WX03Meta?.alias || [],
    redirect: platform_45terms_45of_45servicedA5kv9WX03Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicedA5kv9WX03Meta?.name ?? "legal-platform-terms-of-service___fr",
    path: platform_45terms_45of_45servicedA5kv9WX03Meta?.path ?? "/fr/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicedA5kv9WX03Meta || {},
    alias: platform_45terms_45of_45servicedA5kv9WX03Meta?.alias || [],
    redirect: platform_45terms_45of_45servicedA5kv9WX03Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicedA5kv9WX03Meta?.name ?? "legal-platform-terms-of-service___fr-ca",
    path: platform_45terms_45of_45servicedA5kv9WX03Meta?.path ?? "/fr-ca/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicedA5kv9WX03Meta || {},
    alias: platform_45terms_45of_45servicedA5kv9WX03Meta?.alias || [],
    redirect: platform_45terms_45of_45servicedA5kv9WX03Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: privacyNpqLQIfDopMeta?.name ?? "legal-privacy___en-US",
    path: privacyNpqLQIfDopMeta?.path ?? "/legal/privacy",
    meta: privacyNpqLQIfDopMeta || {},
    alias: privacyNpqLQIfDopMeta?.alias || [],
    redirect: privacyNpqLQIfDopMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyNpqLQIfDopMeta?.name ?? "legal-privacy___ca",
    path: privacyNpqLQIfDopMeta?.path ?? "/ca/legal/privacy",
    meta: privacyNpqLQIfDopMeta || {},
    alias: privacyNpqLQIfDopMeta?.alias || [],
    redirect: privacyNpqLQIfDopMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyNpqLQIfDopMeta?.name ?? "legal-privacy___nl",
    path: privacyNpqLQIfDopMeta?.path ?? "/nl/legal/privacy",
    meta: privacyNpqLQIfDopMeta || {},
    alias: privacyNpqLQIfDopMeta?.alias || [],
    redirect: privacyNpqLQIfDopMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyNpqLQIfDopMeta?.name ?? "legal-privacy___uk",
    path: privacyNpqLQIfDopMeta?.path ?? "/uk/legal/privacy",
    meta: privacyNpqLQIfDopMeta || {},
    alias: privacyNpqLQIfDopMeta?.alias || [],
    redirect: privacyNpqLQIfDopMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyNpqLQIfDopMeta?.name ?? "legal-privacy___de",
    path: privacyNpqLQIfDopMeta?.path ?? "/de/legal/privacy",
    meta: privacyNpqLQIfDopMeta || {},
    alias: privacyNpqLQIfDopMeta?.alias || [],
    redirect: privacyNpqLQIfDopMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyNpqLQIfDopMeta?.name ?? "legal-privacy___mx",
    path: privacyNpqLQIfDopMeta?.path ?? "/mx/legal/privacy",
    meta: privacyNpqLQIfDopMeta || {},
    alias: privacyNpqLQIfDopMeta?.alias || [],
    redirect: privacyNpqLQIfDopMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyNpqLQIfDopMeta?.name ?? "legal-privacy___fr",
    path: privacyNpqLQIfDopMeta?.path ?? "/fr/legal/privacy",
    meta: privacyNpqLQIfDopMeta || {},
    alias: privacyNpqLQIfDopMeta?.alias || [],
    redirect: privacyNpqLQIfDopMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyNpqLQIfDopMeta?.name ?? "legal-privacy___fr-ca",
    path: privacyNpqLQIfDopMeta?.path ?? "/fr-ca/legal/privacy",
    meta: privacyNpqLQIfDopMeta || {},
    alias: privacyNpqLQIfDopMeta?.alias || [],
    redirect: privacyNpqLQIfDopMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___en-US",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.path ?? "/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___ca",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.path ?? "/ca/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___nl",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.path ?? "/nl/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___uk",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.path ?? "/uk/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___de",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.path ?? "/de/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___mx",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.path ?? "/mx/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___fr",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.path ?? "/fr/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___fr-ca",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.path ?? "/fr-ca/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceEHHBFEgJNhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataYAdUX9BZ3TMeta?.name ?? "legal-requestingcustomerdata___en-US",
    path: requestingcustomerdataYAdUX9BZ3TMeta?.path ?? "/legal/requestingcustomerdata",
    meta: requestingcustomerdataYAdUX9BZ3TMeta || {},
    alias: requestingcustomerdataYAdUX9BZ3TMeta?.alias || [],
    redirect: requestingcustomerdataYAdUX9BZ3TMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataYAdUX9BZ3TMeta?.name ?? "legal-requestingcustomerdata___ca",
    path: requestingcustomerdataYAdUX9BZ3TMeta?.path ?? "/ca/legal/requestingcustomerdata",
    meta: requestingcustomerdataYAdUX9BZ3TMeta || {},
    alias: requestingcustomerdataYAdUX9BZ3TMeta?.alias || [],
    redirect: requestingcustomerdataYAdUX9BZ3TMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataYAdUX9BZ3TMeta?.name ?? "legal-requestingcustomerdata___nl",
    path: requestingcustomerdataYAdUX9BZ3TMeta?.path ?? "/nl/legal/requestingcustomerdata",
    meta: requestingcustomerdataYAdUX9BZ3TMeta || {},
    alias: requestingcustomerdataYAdUX9BZ3TMeta?.alias || [],
    redirect: requestingcustomerdataYAdUX9BZ3TMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataYAdUX9BZ3TMeta?.name ?? "legal-requestingcustomerdata___uk",
    path: requestingcustomerdataYAdUX9BZ3TMeta?.path ?? "/uk/legal/requestingcustomerdata",
    meta: requestingcustomerdataYAdUX9BZ3TMeta || {},
    alias: requestingcustomerdataYAdUX9BZ3TMeta?.alias || [],
    redirect: requestingcustomerdataYAdUX9BZ3TMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataYAdUX9BZ3TMeta?.name ?? "legal-requestingcustomerdata___de",
    path: requestingcustomerdataYAdUX9BZ3TMeta?.path ?? "/de/legal/requestingcustomerdata",
    meta: requestingcustomerdataYAdUX9BZ3TMeta || {},
    alias: requestingcustomerdataYAdUX9BZ3TMeta?.alias || [],
    redirect: requestingcustomerdataYAdUX9BZ3TMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataYAdUX9BZ3TMeta?.name ?? "legal-requestingcustomerdata___mx",
    path: requestingcustomerdataYAdUX9BZ3TMeta?.path ?? "/mx/legal/requestingcustomerdata",
    meta: requestingcustomerdataYAdUX9BZ3TMeta || {},
    alias: requestingcustomerdataYAdUX9BZ3TMeta?.alias || [],
    redirect: requestingcustomerdataYAdUX9BZ3TMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataYAdUX9BZ3TMeta?.name ?? "legal-requestingcustomerdata___fr",
    path: requestingcustomerdataYAdUX9BZ3TMeta?.path ?? "/fr/legal/requestingcustomerdata",
    meta: requestingcustomerdataYAdUX9BZ3TMeta || {},
    alias: requestingcustomerdataYAdUX9BZ3TMeta?.alias || [],
    redirect: requestingcustomerdataYAdUX9BZ3TMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataYAdUX9BZ3TMeta?.name ?? "legal-requestingcustomerdata___fr-ca",
    path: requestingcustomerdataYAdUX9BZ3TMeta?.path ?? "/fr-ca/legal/requestingcustomerdata",
    meta: requestingcustomerdataYAdUX9BZ3TMeta || {},
    alias: requestingcustomerdataYAdUX9BZ3TMeta?.alias || [],
    redirect: requestingcustomerdataYAdUX9BZ3TMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: security8UENcIvcsMMeta?.name ?? "legal-security___en-US",
    path: security8UENcIvcsMMeta?.path ?? "/legal/security",
    meta: security8UENcIvcsMMeta || {},
    alias: security8UENcIvcsMMeta?.alias || [],
    redirect: security8UENcIvcsMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security8UENcIvcsMMeta?.name ?? "legal-security___ca",
    path: security8UENcIvcsMMeta?.path ?? "/ca/legal/security",
    meta: security8UENcIvcsMMeta || {},
    alias: security8UENcIvcsMMeta?.alias || [],
    redirect: security8UENcIvcsMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security8UENcIvcsMMeta?.name ?? "legal-security___nl",
    path: security8UENcIvcsMMeta?.path ?? "/nl/legal/security",
    meta: security8UENcIvcsMMeta || {},
    alias: security8UENcIvcsMMeta?.alias || [],
    redirect: security8UENcIvcsMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security8UENcIvcsMMeta?.name ?? "legal-security___uk",
    path: security8UENcIvcsMMeta?.path ?? "/uk/legal/security",
    meta: security8UENcIvcsMMeta || {},
    alias: security8UENcIvcsMMeta?.alias || [],
    redirect: security8UENcIvcsMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security8UENcIvcsMMeta?.name ?? "legal-security___de",
    path: security8UENcIvcsMMeta?.path ?? "/de/legal/security",
    meta: security8UENcIvcsMMeta || {},
    alias: security8UENcIvcsMMeta?.alias || [],
    redirect: security8UENcIvcsMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security8UENcIvcsMMeta?.name ?? "legal-security___mx",
    path: security8UENcIvcsMMeta?.path ?? "/mx/legal/security",
    meta: security8UENcIvcsMMeta || {},
    alias: security8UENcIvcsMMeta?.alias || [],
    redirect: security8UENcIvcsMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security8UENcIvcsMMeta?.name ?? "legal-security___fr",
    path: security8UENcIvcsMMeta?.path ?? "/fr/legal/security",
    meta: security8UENcIvcsMMeta || {},
    alias: security8UENcIvcsMMeta?.alias || [],
    redirect: security8UENcIvcsMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security8UENcIvcsMMeta?.name ?? "legal-security___fr-ca",
    path: security8UENcIvcsMMeta?.path ?? "/fr-ca/legal/security",
    meta: security8UENcIvcsMMeta || {},
    alias: security8UENcIvcsMMeta?.alias || [],
    redirect: security8UENcIvcsMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportuIzf9G3ZQmMeta?.name ?? "legal-transparencyreport___en-US",
    path: transparencyreportuIzf9G3ZQmMeta?.path ?? "/legal/transparencyreport",
    meta: transparencyreportuIzf9G3ZQmMeta || {},
    alias: transparencyreportuIzf9G3ZQmMeta?.alias || [],
    redirect: transparencyreportuIzf9G3ZQmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportuIzf9G3ZQmMeta?.name ?? "legal-transparencyreport___ca",
    path: transparencyreportuIzf9G3ZQmMeta?.path ?? "/ca/legal/transparencyreport",
    meta: transparencyreportuIzf9G3ZQmMeta || {},
    alias: transparencyreportuIzf9G3ZQmMeta?.alias || [],
    redirect: transparencyreportuIzf9G3ZQmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportuIzf9G3ZQmMeta?.name ?? "legal-transparencyreport___nl",
    path: transparencyreportuIzf9G3ZQmMeta?.path ?? "/nl/legal/transparencyreport",
    meta: transparencyreportuIzf9G3ZQmMeta || {},
    alias: transparencyreportuIzf9G3ZQmMeta?.alias || [],
    redirect: transparencyreportuIzf9G3ZQmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportuIzf9G3ZQmMeta?.name ?? "legal-transparencyreport___uk",
    path: transparencyreportuIzf9G3ZQmMeta?.path ?? "/uk/legal/transparencyreport",
    meta: transparencyreportuIzf9G3ZQmMeta || {},
    alias: transparencyreportuIzf9G3ZQmMeta?.alias || [],
    redirect: transparencyreportuIzf9G3ZQmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportuIzf9G3ZQmMeta?.name ?? "legal-transparencyreport___de",
    path: transparencyreportuIzf9G3ZQmMeta?.path ?? "/de/legal/transparencyreport",
    meta: transparencyreportuIzf9G3ZQmMeta || {},
    alias: transparencyreportuIzf9G3ZQmMeta?.alias || [],
    redirect: transparencyreportuIzf9G3ZQmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportuIzf9G3ZQmMeta?.name ?? "legal-transparencyreport___mx",
    path: transparencyreportuIzf9G3ZQmMeta?.path ?? "/mx/legal/transparencyreport",
    meta: transparencyreportuIzf9G3ZQmMeta || {},
    alias: transparencyreportuIzf9G3ZQmMeta?.alias || [],
    redirect: transparencyreportuIzf9G3ZQmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportuIzf9G3ZQmMeta?.name ?? "legal-transparencyreport___fr",
    path: transparencyreportuIzf9G3ZQmMeta?.path ?? "/fr/legal/transparencyreport",
    meta: transparencyreportuIzf9G3ZQmMeta || {},
    alias: transparencyreportuIzf9G3ZQmMeta?.alias || [],
    redirect: transparencyreportuIzf9G3ZQmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportuIzf9G3ZQmMeta?.name ?? "legal-transparencyreport___fr-ca",
    path: transparencyreportuIzf9G3ZQmMeta?.path ?? "/fr-ca/legal/transparencyreport",
    meta: transparencyreportuIzf9G3ZQmMeta || {},
    alias: transparencyreportuIzf9G3ZQmMeta?.alias || [],
    redirect: transparencyreportuIzf9G3ZQmMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceC2auspcJ4NMeta?.name ?? "legal-website-terms-of-service___en-US",
    path: website_45terms_45of_45serviceC2auspcJ4NMeta?.path ?? "/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceC2auspcJ4NMeta || {},
    alias: website_45terms_45of_45serviceC2auspcJ4NMeta?.alias || [],
    redirect: website_45terms_45of_45serviceC2auspcJ4NMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceC2auspcJ4NMeta?.name ?? "legal-website-terms-of-service___ca",
    path: website_45terms_45of_45serviceC2auspcJ4NMeta?.path ?? "/ca/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceC2auspcJ4NMeta || {},
    alias: website_45terms_45of_45serviceC2auspcJ4NMeta?.alias || [],
    redirect: website_45terms_45of_45serviceC2auspcJ4NMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceC2auspcJ4NMeta?.name ?? "legal-website-terms-of-service___nl",
    path: website_45terms_45of_45serviceC2auspcJ4NMeta?.path ?? "/nl/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceC2auspcJ4NMeta || {},
    alias: website_45terms_45of_45serviceC2auspcJ4NMeta?.alias || [],
    redirect: website_45terms_45of_45serviceC2auspcJ4NMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceC2auspcJ4NMeta?.name ?? "legal-website-terms-of-service___uk",
    path: website_45terms_45of_45serviceC2auspcJ4NMeta?.path ?? "/uk/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceC2auspcJ4NMeta || {},
    alias: website_45terms_45of_45serviceC2auspcJ4NMeta?.alias || [],
    redirect: website_45terms_45of_45serviceC2auspcJ4NMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceC2auspcJ4NMeta?.name ?? "legal-website-terms-of-service___de",
    path: website_45terms_45of_45serviceC2auspcJ4NMeta?.path ?? "/de/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceC2auspcJ4NMeta || {},
    alias: website_45terms_45of_45serviceC2auspcJ4NMeta?.alias || [],
    redirect: website_45terms_45of_45serviceC2auspcJ4NMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceC2auspcJ4NMeta?.name ?? "legal-website-terms-of-service___mx",
    path: website_45terms_45of_45serviceC2auspcJ4NMeta?.path ?? "/mx/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceC2auspcJ4NMeta || {},
    alias: website_45terms_45of_45serviceC2auspcJ4NMeta?.alias || [],
    redirect: website_45terms_45of_45serviceC2auspcJ4NMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceC2auspcJ4NMeta?.name ?? "legal-website-terms-of-service___fr",
    path: website_45terms_45of_45serviceC2auspcJ4NMeta?.path ?? "/fr/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceC2auspcJ4NMeta || {},
    alias: website_45terms_45of_45serviceC2auspcJ4NMeta?.alias || [],
    redirect: website_45terms_45of_45serviceC2auspcJ4NMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceC2auspcJ4NMeta?.name ?? "legal-website-terms-of-service___fr-ca",
    path: website_45terms_45of_45serviceC2auspcJ4NMeta?.path ?? "/fr-ca/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceC2auspcJ4NMeta || {},
    alias: website_45terms_45of_45serviceC2auspcJ4NMeta?.alias || [],
    redirect: website_45terms_45of_45serviceC2auspcJ4NMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesApPVZ3U6liMeta?.name ?? "legal-your-privacy-choices___en-US",
    path: your_45privacy_45choicesApPVZ3U6liMeta?.path ?? "/legal/your-privacy-choices",
    meta: your_45privacy_45choicesApPVZ3U6liMeta || {},
    alias: your_45privacy_45choicesApPVZ3U6liMeta?.alias || [],
    redirect: your_45privacy_45choicesApPVZ3U6liMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesApPVZ3U6liMeta?.name ?? "legal-your-privacy-choices___ca",
    path: your_45privacy_45choicesApPVZ3U6liMeta?.path ?? "/ca/legal/your-privacy-choices",
    meta: your_45privacy_45choicesApPVZ3U6liMeta || {},
    alias: your_45privacy_45choicesApPVZ3U6liMeta?.alias || [],
    redirect: your_45privacy_45choicesApPVZ3U6liMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesApPVZ3U6liMeta?.name ?? "legal-your-privacy-choices___nl",
    path: your_45privacy_45choicesApPVZ3U6liMeta?.path ?? "/nl/legal/your-privacy-choices",
    meta: your_45privacy_45choicesApPVZ3U6liMeta || {},
    alias: your_45privacy_45choicesApPVZ3U6liMeta?.alias || [],
    redirect: your_45privacy_45choicesApPVZ3U6liMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesApPVZ3U6liMeta?.name ?? "legal-your-privacy-choices___uk",
    path: your_45privacy_45choicesApPVZ3U6liMeta?.path ?? "/uk/legal/your-privacy-choices",
    meta: your_45privacy_45choicesApPVZ3U6liMeta || {},
    alias: your_45privacy_45choicesApPVZ3U6liMeta?.alias || [],
    redirect: your_45privacy_45choicesApPVZ3U6liMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesApPVZ3U6liMeta?.name ?? "legal-your-privacy-choices___de",
    path: your_45privacy_45choicesApPVZ3U6liMeta?.path ?? "/de/legal/your-privacy-choices",
    meta: your_45privacy_45choicesApPVZ3U6liMeta || {},
    alias: your_45privacy_45choicesApPVZ3U6liMeta?.alias || [],
    redirect: your_45privacy_45choicesApPVZ3U6liMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesApPVZ3U6liMeta?.name ?? "legal-your-privacy-choices___mx",
    path: your_45privacy_45choicesApPVZ3U6liMeta?.path ?? "/mx/legal/your-privacy-choices",
    meta: your_45privacy_45choicesApPVZ3U6liMeta || {},
    alias: your_45privacy_45choicesApPVZ3U6liMeta?.alias || [],
    redirect: your_45privacy_45choicesApPVZ3U6liMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesApPVZ3U6liMeta?.name ?? "legal-your-privacy-choices___fr",
    path: your_45privacy_45choicesApPVZ3U6liMeta?.path ?? "/fr/legal/your-privacy-choices",
    meta: your_45privacy_45choicesApPVZ3U6liMeta || {},
    alias: your_45privacy_45choicesApPVZ3U6liMeta?.alias || [],
    redirect: your_45privacy_45choicesApPVZ3U6liMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesApPVZ3U6liMeta?.name ?? "legal-your-privacy-choices___fr-ca",
    path: your_45privacy_45choicesApPVZ3U6liMeta?.path ?? "/fr-ca/legal/your-privacy-choices",
    meta: your_45privacy_45choicesApPVZ3U6liMeta || {},
    alias: your_45privacy_45choicesApPVZ3U6liMeta?.alias || [],
    redirect: your_45privacy_45choicesApPVZ3U6liMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: pricingUeOpnnhg6xMeta?.name ?? "page-pricing___en-US",
    path: pricingUeOpnnhg6xMeta?.path ?? "/page/pricing",
    meta: pricingUeOpnnhg6xMeta || {},
    alias: pricingUeOpnnhg6xMeta?.alias || [],
    redirect: pricingUeOpnnhg6xMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingUeOpnnhg6xMeta?.name ?? "page-pricing___ca",
    path: pricingUeOpnnhg6xMeta?.path ?? "/ca/page/pricing",
    meta: pricingUeOpnnhg6xMeta || {},
    alias: pricingUeOpnnhg6xMeta?.alias || [],
    redirect: pricingUeOpnnhg6xMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingUeOpnnhg6xMeta?.name ?? "page-pricing___nl",
    path: pricingUeOpnnhg6xMeta?.path ?? "/nl/page/pricing",
    meta: pricingUeOpnnhg6xMeta || {},
    alias: pricingUeOpnnhg6xMeta?.alias || [],
    redirect: pricingUeOpnnhg6xMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingUeOpnnhg6xMeta?.name ?? "page-pricing___uk",
    path: pricingUeOpnnhg6xMeta?.path ?? "/uk/page/pricing",
    meta: pricingUeOpnnhg6xMeta || {},
    alias: pricingUeOpnnhg6xMeta?.alias || [],
    redirect: pricingUeOpnnhg6xMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingUeOpnnhg6xMeta?.name ?? "page-pricing___de",
    path: pricingUeOpnnhg6xMeta?.path ?? "/de/page/pricing",
    meta: pricingUeOpnnhg6xMeta || {},
    alias: pricingUeOpnnhg6xMeta?.alias || [],
    redirect: pricingUeOpnnhg6xMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingUeOpnnhg6xMeta?.name ?? "page-pricing___mx",
    path: pricingUeOpnnhg6xMeta?.path ?? "/mx/page/pricing",
    meta: pricingUeOpnnhg6xMeta || {},
    alias: pricingUeOpnnhg6xMeta?.alias || [],
    redirect: pricingUeOpnnhg6xMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingUeOpnnhg6xMeta?.name ?? "page-pricing___fr",
    path: pricingUeOpnnhg6xMeta?.path ?? "/fr/page/pricing",
    meta: pricingUeOpnnhg6xMeta || {},
    alias: pricingUeOpnnhg6xMeta?.alias || [],
    redirect: pricingUeOpnnhg6xMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingUeOpnnhg6xMeta?.name ?? "page-pricing___fr-ca",
    path: pricingUeOpnnhg6xMeta?.path ?? "/fr-ca/page/pricing",
    meta: pricingUeOpnnhg6xMeta || {},
    alias: pricingUeOpnnhg6xMeta?.alias || [],
    redirect: pricingUeOpnnhg6xMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kVI4tg3hhGMeta?.name ?? "pages-prefix-slug___en-US",
    path: _91slug_93kVI4tg3hhGMeta?.path ?? "/pages/:prefix()/:slug()",
    meta: _91slug_93kVI4tg3hhGMeta || {},
    alias: _91slug_93kVI4tg3hhGMeta?.alias || [],
    redirect: _91slug_93kVI4tg3hhGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kVI4tg3hhGMeta?.name ?? "pages-prefix-slug___ca",
    path: _91slug_93kVI4tg3hhGMeta?.path ?? "/ca/pages/:prefix()/:slug()",
    meta: _91slug_93kVI4tg3hhGMeta || {},
    alias: _91slug_93kVI4tg3hhGMeta?.alias || [],
    redirect: _91slug_93kVI4tg3hhGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kVI4tg3hhGMeta?.name ?? "pages-prefix-slug___nl",
    path: _91slug_93kVI4tg3hhGMeta?.path ?? "/nl/pages/:prefix()/:slug()",
    meta: _91slug_93kVI4tg3hhGMeta || {},
    alias: _91slug_93kVI4tg3hhGMeta?.alias || [],
    redirect: _91slug_93kVI4tg3hhGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kVI4tg3hhGMeta?.name ?? "pages-prefix-slug___uk",
    path: _91slug_93kVI4tg3hhGMeta?.path ?? "/uk/pages/:prefix()/:slug()",
    meta: _91slug_93kVI4tg3hhGMeta || {},
    alias: _91slug_93kVI4tg3hhGMeta?.alias || [],
    redirect: _91slug_93kVI4tg3hhGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kVI4tg3hhGMeta?.name ?? "pages-prefix-slug___de",
    path: _91slug_93kVI4tg3hhGMeta?.path ?? "/de/pages/:prefix()/:slug()",
    meta: _91slug_93kVI4tg3hhGMeta || {},
    alias: _91slug_93kVI4tg3hhGMeta?.alias || [],
    redirect: _91slug_93kVI4tg3hhGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kVI4tg3hhGMeta?.name ?? "pages-prefix-slug___mx",
    path: _91slug_93kVI4tg3hhGMeta?.path ?? "/mx/pages/:prefix()/:slug()",
    meta: _91slug_93kVI4tg3hhGMeta || {},
    alias: _91slug_93kVI4tg3hhGMeta?.alias || [],
    redirect: _91slug_93kVI4tg3hhGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kVI4tg3hhGMeta?.name ?? "pages-prefix-slug___fr",
    path: _91slug_93kVI4tg3hhGMeta?.path ?? "/fr/pages/:prefix()/:slug()",
    meta: _91slug_93kVI4tg3hhGMeta || {},
    alias: _91slug_93kVI4tg3hhGMeta?.alias || [],
    redirect: _91slug_93kVI4tg3hhGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kVI4tg3hhGMeta?.name ?? "pages-prefix-slug___fr-ca",
    path: _91slug_93kVI4tg3hhGMeta?.path ?? "/fr-ca/pages/:prefix()/:slug()",
    meta: _91slug_93kVI4tg3hhGMeta || {},
    alias: _91slug_93kVI4tg3hhGMeta?.alias || [],
    redirect: _91slug_93kVI4tg3hhGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Xf3maqbHE5Meta?.name ?? "pages-slug___en-US",
    path: _91slug_93Xf3maqbHE5Meta?.path ?? "/pages/:slug()",
    meta: _91slug_93Xf3maqbHE5Meta || {},
    alias: _91slug_93Xf3maqbHE5Meta?.alias || [],
    redirect: _91slug_93Xf3maqbHE5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Xf3maqbHE5Meta?.name ?? "pages-slug___ca",
    path: _91slug_93Xf3maqbHE5Meta?.path ?? "/ca/pages/:slug()",
    meta: _91slug_93Xf3maqbHE5Meta || {},
    alias: _91slug_93Xf3maqbHE5Meta?.alias || [],
    redirect: _91slug_93Xf3maqbHE5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Xf3maqbHE5Meta?.name ?? "pages-slug___nl",
    path: _91slug_93Xf3maqbHE5Meta?.path ?? "/nl/pages/:slug()",
    meta: _91slug_93Xf3maqbHE5Meta || {},
    alias: _91slug_93Xf3maqbHE5Meta?.alias || [],
    redirect: _91slug_93Xf3maqbHE5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Xf3maqbHE5Meta?.name ?? "pages-slug___uk",
    path: _91slug_93Xf3maqbHE5Meta?.path ?? "/uk/pages/:slug()",
    meta: _91slug_93Xf3maqbHE5Meta || {},
    alias: _91slug_93Xf3maqbHE5Meta?.alias || [],
    redirect: _91slug_93Xf3maqbHE5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Xf3maqbHE5Meta?.name ?? "pages-slug___de",
    path: _91slug_93Xf3maqbHE5Meta?.path ?? "/de/pages/:slug()",
    meta: _91slug_93Xf3maqbHE5Meta || {},
    alias: _91slug_93Xf3maqbHE5Meta?.alias || [],
    redirect: _91slug_93Xf3maqbHE5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Xf3maqbHE5Meta?.name ?? "pages-slug___mx",
    path: _91slug_93Xf3maqbHE5Meta?.path ?? "/mx/pages/:slug()",
    meta: _91slug_93Xf3maqbHE5Meta || {},
    alias: _91slug_93Xf3maqbHE5Meta?.alias || [],
    redirect: _91slug_93Xf3maqbHE5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Xf3maqbHE5Meta?.name ?? "pages-slug___fr",
    path: _91slug_93Xf3maqbHE5Meta?.path ?? "/fr/pages/:slug()",
    meta: _91slug_93Xf3maqbHE5Meta || {},
    alias: _91slug_93Xf3maqbHE5Meta?.alias || [],
    redirect: _91slug_93Xf3maqbHE5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Xf3maqbHE5Meta?.name ?? "pages-slug___fr-ca",
    path: _91slug_93Xf3maqbHE5Meta?.path ?? "/fr-ca/pages/:slug()",
    meta: _91slug_93Xf3maqbHE5Meta || {},
    alias: _91slug_93Xf3maqbHE5Meta?.alias || [],
    redirect: _91slug_93Xf3maqbHE5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: asset_45trackinguyknpr2HzrMeta?.name ?? "pages-asset-tracking___en-US",
    path: asset_45trackinguyknpr2HzrMeta?.path ?? "/pages/asset-tracking",
    meta: asset_45trackinguyknpr2HzrMeta || {},
    alias: asset_45trackinguyknpr2HzrMeta?.alias || [],
    redirect: asset_45trackinguyknpr2HzrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackinguyknpr2HzrMeta?.name ?? "pages-asset-tracking___ca",
    path: asset_45trackinguyknpr2HzrMeta?.path ?? "/ca/pages/asset-tracking",
    meta: asset_45trackinguyknpr2HzrMeta || {},
    alias: asset_45trackinguyknpr2HzrMeta?.alias || [],
    redirect: asset_45trackinguyknpr2HzrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackinguyknpr2HzrMeta?.name ?? "pages-asset-tracking___nl",
    path: asset_45trackinguyknpr2HzrMeta?.path ?? "/nl/pages/asset-tracking",
    meta: asset_45trackinguyknpr2HzrMeta || {},
    alias: asset_45trackinguyknpr2HzrMeta?.alias || [],
    redirect: asset_45trackinguyknpr2HzrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackinguyknpr2HzrMeta?.name ?? "pages-asset-tracking___uk",
    path: asset_45trackinguyknpr2HzrMeta?.path ?? "/uk/pages/asset-tracking",
    meta: asset_45trackinguyknpr2HzrMeta || {},
    alias: asset_45trackinguyknpr2HzrMeta?.alias || [],
    redirect: asset_45trackinguyknpr2HzrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackinguyknpr2HzrMeta?.name ?? "pages-asset-tracking___de",
    path: asset_45trackinguyknpr2HzrMeta?.path ?? "/de/pages/asset-tracking",
    meta: asset_45trackinguyknpr2HzrMeta || {},
    alias: asset_45trackinguyknpr2HzrMeta?.alias || [],
    redirect: asset_45trackinguyknpr2HzrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackinguyknpr2HzrMeta?.name ?? "pages-asset-tracking___mx",
    path: asset_45trackinguyknpr2HzrMeta?.path ?? "/mx/pages/asset-tracking",
    meta: asset_45trackinguyknpr2HzrMeta || {},
    alias: asset_45trackinguyknpr2HzrMeta?.alias || [],
    redirect: asset_45trackinguyknpr2HzrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackinguyknpr2HzrMeta?.name ?? "pages-asset-tracking___fr",
    path: asset_45trackinguyknpr2HzrMeta?.path ?? "/fr/pages/asset-tracking",
    meta: asset_45trackinguyknpr2HzrMeta || {},
    alias: asset_45trackinguyknpr2HzrMeta?.alias || [],
    redirect: asset_45trackinguyknpr2HzrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackinguyknpr2HzrMeta?.name ?? "pages-asset-tracking___fr-ca",
    path: asset_45trackinguyknpr2HzrMeta?.path ?? "/fr-ca/pages/asset-tracking",
    meta: asset_45trackinguyknpr2HzrMeta || {},
    alias: asset_45trackinguyknpr2HzrMeta?.alias || [],
    redirect: asset_45trackinguyknpr2HzrMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsScU1R8XGvpMeta?.name ?? "pages-best-dash-cams___en-US",
    path: best_45dash_45camsScU1R8XGvpMeta?.path ?? "/pages/best-dash-cams",
    meta: best_45dash_45camsScU1R8XGvpMeta || {},
    alias: best_45dash_45camsScU1R8XGvpMeta?.alias || [],
    redirect: best_45dash_45camsScU1R8XGvpMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsScU1R8XGvpMeta?.name ?? "pages-best-dash-cams___ca",
    path: best_45dash_45camsScU1R8XGvpMeta?.path ?? "/ca/pages/best-dash-cams",
    meta: best_45dash_45camsScU1R8XGvpMeta || {},
    alias: best_45dash_45camsScU1R8XGvpMeta?.alias || [],
    redirect: best_45dash_45camsScU1R8XGvpMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsScU1R8XGvpMeta?.name ?? "pages-best-dash-cams___nl",
    path: best_45dash_45camsScU1R8XGvpMeta?.path ?? "/nl/pages/best-dash-cams",
    meta: best_45dash_45camsScU1R8XGvpMeta || {},
    alias: best_45dash_45camsScU1R8XGvpMeta?.alias || [],
    redirect: best_45dash_45camsScU1R8XGvpMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsScU1R8XGvpMeta?.name ?? "pages-best-dash-cams___uk",
    path: best_45dash_45camsScU1R8XGvpMeta?.path ?? "/uk/pages/best-dash-cams",
    meta: best_45dash_45camsScU1R8XGvpMeta || {},
    alias: best_45dash_45camsScU1R8XGvpMeta?.alias || [],
    redirect: best_45dash_45camsScU1R8XGvpMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsScU1R8XGvpMeta?.name ?? "pages-best-dash-cams___de",
    path: best_45dash_45camsScU1R8XGvpMeta?.path ?? "/de/pages/best-dash-cams",
    meta: best_45dash_45camsScU1R8XGvpMeta || {},
    alias: best_45dash_45camsScU1R8XGvpMeta?.alias || [],
    redirect: best_45dash_45camsScU1R8XGvpMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsScU1R8XGvpMeta?.name ?? "pages-best-dash-cams___mx",
    path: best_45dash_45camsScU1R8XGvpMeta?.path ?? "/mx/pages/best-dash-cams",
    meta: best_45dash_45camsScU1R8XGvpMeta || {},
    alias: best_45dash_45camsScU1R8XGvpMeta?.alias || [],
    redirect: best_45dash_45camsScU1R8XGvpMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsScU1R8XGvpMeta?.name ?? "pages-best-dash-cams___fr",
    path: best_45dash_45camsScU1R8XGvpMeta?.path ?? "/fr/pages/best-dash-cams",
    meta: best_45dash_45camsScU1R8XGvpMeta || {},
    alias: best_45dash_45camsScU1R8XGvpMeta?.alias || [],
    redirect: best_45dash_45camsScU1R8XGvpMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsScU1R8XGvpMeta?.name ?? "pages-best-dash-cams___fr-ca",
    path: best_45dash_45camsScU1R8XGvpMeta?.path ?? "/fr-ca/pages/best-dash-cams",
    meta: best_45dash_45camsScU1R8XGvpMeta || {},
    alias: best_45dash_45camsScU1R8XGvpMeta?.alias || [],
    redirect: best_45dash_45camsScU1R8XGvpMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: constructionsxQ2tUHJmcMeta?.name ?? "pages-construction___en-US",
    path: constructionsxQ2tUHJmcMeta?.path ?? "/pages/construction",
    meta: constructionsxQ2tUHJmcMeta || {},
    alias: constructionsxQ2tUHJmcMeta?.alias || [],
    redirect: constructionsxQ2tUHJmcMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionsxQ2tUHJmcMeta?.name ?? "pages-construction___ca",
    path: constructionsxQ2tUHJmcMeta?.path ?? "/ca/pages/construction",
    meta: constructionsxQ2tUHJmcMeta || {},
    alias: constructionsxQ2tUHJmcMeta?.alias || [],
    redirect: constructionsxQ2tUHJmcMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionsxQ2tUHJmcMeta?.name ?? "pages-construction___nl",
    path: constructionsxQ2tUHJmcMeta?.path ?? "/nl/pages/construction",
    meta: constructionsxQ2tUHJmcMeta || {},
    alias: constructionsxQ2tUHJmcMeta?.alias || [],
    redirect: constructionsxQ2tUHJmcMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionsxQ2tUHJmcMeta?.name ?? "pages-construction___uk",
    path: constructionsxQ2tUHJmcMeta?.path ?? "/uk/pages/construction",
    meta: constructionsxQ2tUHJmcMeta || {},
    alias: constructionsxQ2tUHJmcMeta?.alias || [],
    redirect: constructionsxQ2tUHJmcMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionsxQ2tUHJmcMeta?.name ?? "pages-construction___de",
    path: constructionsxQ2tUHJmcMeta?.path ?? "/de/pages/construction",
    meta: constructionsxQ2tUHJmcMeta || {},
    alias: constructionsxQ2tUHJmcMeta?.alias || [],
    redirect: constructionsxQ2tUHJmcMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionsxQ2tUHJmcMeta?.name ?? "pages-construction___mx",
    path: constructionsxQ2tUHJmcMeta?.path ?? "/mx/pages/construction",
    meta: constructionsxQ2tUHJmcMeta || {},
    alias: constructionsxQ2tUHJmcMeta?.alias || [],
    redirect: constructionsxQ2tUHJmcMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionsxQ2tUHJmcMeta?.name ?? "pages-construction___fr",
    path: constructionsxQ2tUHJmcMeta?.path ?? "/fr/pages/construction",
    meta: constructionsxQ2tUHJmcMeta || {},
    alias: constructionsxQ2tUHJmcMeta?.alias || [],
    redirect: constructionsxQ2tUHJmcMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionsxQ2tUHJmcMeta?.name ?? "pages-construction___fr-ca",
    path: constructionsxQ2tUHJmcMeta?.path ?? "/fr-ca/pages/construction",
    meta: constructionsxQ2tUHJmcMeta || {},
    alias: constructionsxQ2tUHJmcMeta?.alias || [],
    redirect: constructionsxQ2tUHJmcMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: eversource9gLT1Nd4LwMeta?.name ?? "pages-eversource___en-US",
    path: eversource9gLT1Nd4LwMeta?.path ?? "/pages/eversource",
    meta: eversource9gLT1Nd4LwMeta || {},
    alias: eversource9gLT1Nd4LwMeta?.alias || [],
    redirect: eversource9gLT1Nd4LwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversource9gLT1Nd4LwMeta?.name ?? "pages-eversource___ca",
    path: eversource9gLT1Nd4LwMeta?.path ?? "/ca/pages/eversource",
    meta: eversource9gLT1Nd4LwMeta || {},
    alias: eversource9gLT1Nd4LwMeta?.alias || [],
    redirect: eversource9gLT1Nd4LwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversource9gLT1Nd4LwMeta?.name ?? "pages-eversource___nl",
    path: eversource9gLT1Nd4LwMeta?.path ?? "/nl/pages/eversource",
    meta: eversource9gLT1Nd4LwMeta || {},
    alias: eversource9gLT1Nd4LwMeta?.alias || [],
    redirect: eversource9gLT1Nd4LwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversource9gLT1Nd4LwMeta?.name ?? "pages-eversource___uk",
    path: eversource9gLT1Nd4LwMeta?.path ?? "/uk/pages/eversource",
    meta: eversource9gLT1Nd4LwMeta || {},
    alias: eversource9gLT1Nd4LwMeta?.alias || [],
    redirect: eversource9gLT1Nd4LwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversource9gLT1Nd4LwMeta?.name ?? "pages-eversource___de",
    path: eversource9gLT1Nd4LwMeta?.path ?? "/de/pages/eversource",
    meta: eversource9gLT1Nd4LwMeta || {},
    alias: eversource9gLT1Nd4LwMeta?.alias || [],
    redirect: eversource9gLT1Nd4LwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversource9gLT1Nd4LwMeta?.name ?? "pages-eversource___mx",
    path: eversource9gLT1Nd4LwMeta?.path ?? "/mx/pages/eversource",
    meta: eversource9gLT1Nd4LwMeta || {},
    alias: eversource9gLT1Nd4LwMeta?.alias || [],
    redirect: eversource9gLT1Nd4LwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversource9gLT1Nd4LwMeta?.name ?? "pages-eversource___fr",
    path: eversource9gLT1Nd4LwMeta?.path ?? "/fr/pages/eversource",
    meta: eversource9gLT1Nd4LwMeta || {},
    alias: eversource9gLT1Nd4LwMeta?.alias || [],
    redirect: eversource9gLT1Nd4LwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversource9gLT1Nd4LwMeta?.name ?? "pages-eversource___fr-ca",
    path: eversource9gLT1Nd4LwMeta?.path ?? "/fr-ca/pages/eversource",
    meta: eversource9gLT1Nd4LwMeta || {},
    alias: eversource9gLT1Nd4LwMeta?.alias || [],
    redirect: eversource9gLT1Nd4LwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementYx80nSI2sCMeta?.name ?? "pages-fleet-management___en-US",
    path: fleet_45managementYx80nSI2sCMeta?.path ?? "/pages/fleet-management",
    meta: fleet_45managementYx80nSI2sCMeta || {},
    alias: fleet_45managementYx80nSI2sCMeta?.alias || [],
    redirect: fleet_45managementYx80nSI2sCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementYx80nSI2sCMeta?.name ?? "pages-fleet-management___ca",
    path: fleet_45managementYx80nSI2sCMeta?.path ?? "/ca/pages/fleet-management",
    meta: fleet_45managementYx80nSI2sCMeta || {},
    alias: fleet_45managementYx80nSI2sCMeta?.alias || [],
    redirect: fleet_45managementYx80nSI2sCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementYx80nSI2sCMeta?.name ?? "pages-fleet-management___nl",
    path: fleet_45managementYx80nSI2sCMeta?.path ?? "/nl/pages/fleet-management",
    meta: fleet_45managementYx80nSI2sCMeta || {},
    alias: fleet_45managementYx80nSI2sCMeta?.alias || [],
    redirect: fleet_45managementYx80nSI2sCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementYx80nSI2sCMeta?.name ?? "pages-fleet-management___uk",
    path: fleet_45managementYx80nSI2sCMeta?.path ?? "/uk/pages/fleet-management",
    meta: fleet_45managementYx80nSI2sCMeta || {},
    alias: fleet_45managementYx80nSI2sCMeta?.alias || [],
    redirect: fleet_45managementYx80nSI2sCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementYx80nSI2sCMeta?.name ?? "pages-fleet-management___de",
    path: fleet_45managementYx80nSI2sCMeta?.path ?? "/de/pages/fleet-management",
    meta: fleet_45managementYx80nSI2sCMeta || {},
    alias: fleet_45managementYx80nSI2sCMeta?.alias || [],
    redirect: fleet_45managementYx80nSI2sCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementYx80nSI2sCMeta?.name ?? "pages-fleet-management___mx",
    path: fleet_45managementYx80nSI2sCMeta?.path ?? "/mx/pages/fleet-management",
    meta: fleet_45managementYx80nSI2sCMeta || {},
    alias: fleet_45managementYx80nSI2sCMeta?.alias || [],
    redirect: fleet_45managementYx80nSI2sCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementYx80nSI2sCMeta?.name ?? "pages-fleet-management___fr",
    path: fleet_45managementYx80nSI2sCMeta?.path ?? "/fr/pages/fleet-management",
    meta: fleet_45managementYx80nSI2sCMeta || {},
    alias: fleet_45managementYx80nSI2sCMeta?.alias || [],
    redirect: fleet_45managementYx80nSI2sCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementYx80nSI2sCMeta?.name ?? "pages-fleet-management___fr-ca",
    path: fleet_45managementYx80nSI2sCMeta?.path ?? "/fr-ca/pages/fleet-management",
    meta: fleet_45managementYx80nSI2sCMeta || {},
    alias: fleet_45managementYx80nSI2sCMeta?.alias || [],
    redirect: fleet_45managementYx80nSI2sCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: groundworksB1HlV73E6tMeta?.name ?? "pages-groundworks___en-US",
    path: groundworksB1HlV73E6tMeta?.path ?? "/pages/groundworks",
    meta: groundworksB1HlV73E6tMeta || {},
    alias: groundworksB1HlV73E6tMeta?.alias || [],
    redirect: groundworksB1HlV73E6tMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworksB1HlV73E6tMeta?.name ?? "pages-groundworks___ca",
    path: groundworksB1HlV73E6tMeta?.path ?? "/ca/pages/groundworks",
    meta: groundworksB1HlV73E6tMeta || {},
    alias: groundworksB1HlV73E6tMeta?.alias || [],
    redirect: groundworksB1HlV73E6tMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworksB1HlV73E6tMeta?.name ?? "pages-groundworks___nl",
    path: groundworksB1HlV73E6tMeta?.path ?? "/nl/pages/groundworks",
    meta: groundworksB1HlV73E6tMeta || {},
    alias: groundworksB1HlV73E6tMeta?.alias || [],
    redirect: groundworksB1HlV73E6tMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworksB1HlV73E6tMeta?.name ?? "pages-groundworks___uk",
    path: groundworksB1HlV73E6tMeta?.path ?? "/uk/pages/groundworks",
    meta: groundworksB1HlV73E6tMeta || {},
    alias: groundworksB1HlV73E6tMeta?.alias || [],
    redirect: groundworksB1HlV73E6tMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworksB1HlV73E6tMeta?.name ?? "pages-groundworks___de",
    path: groundworksB1HlV73E6tMeta?.path ?? "/de/pages/groundworks",
    meta: groundworksB1HlV73E6tMeta || {},
    alias: groundworksB1HlV73E6tMeta?.alias || [],
    redirect: groundworksB1HlV73E6tMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworksB1HlV73E6tMeta?.name ?? "pages-groundworks___mx",
    path: groundworksB1HlV73E6tMeta?.path ?? "/mx/pages/groundworks",
    meta: groundworksB1HlV73E6tMeta || {},
    alias: groundworksB1HlV73E6tMeta?.alias || [],
    redirect: groundworksB1HlV73E6tMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworksB1HlV73E6tMeta?.name ?? "pages-groundworks___fr",
    path: groundworksB1HlV73E6tMeta?.path ?? "/fr/pages/groundworks",
    meta: groundworksB1HlV73E6tMeta || {},
    alias: groundworksB1HlV73E6tMeta?.alias || [],
    redirect: groundworksB1HlV73E6tMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworksB1HlV73E6tMeta?.name ?? "pages-groundworks___fr-ca",
    path: groundworksB1HlV73E6tMeta?.path ?? "/fr-ca/pages/groundworks",
    meta: groundworksB1HlV73E6tMeta || {},
    alias: groundworksB1HlV73E6tMeta?.alias || [],
    redirect: groundworksB1HlV73E6tMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: nationwideIn6xpx9prnMeta?.name ?? "pages-nationwide___en-US",
    path: nationwideIn6xpx9prnMeta?.path ?? "/pages/nationwide",
    meta: nationwideIn6xpx9prnMeta || {},
    alias: nationwideIn6xpx9prnMeta?.alias || [],
    redirect: nationwideIn6xpx9prnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideIn6xpx9prnMeta?.name ?? "pages-nationwide___ca",
    path: nationwideIn6xpx9prnMeta?.path ?? "/ca/pages/nationwide",
    meta: nationwideIn6xpx9prnMeta || {},
    alias: nationwideIn6xpx9prnMeta?.alias || [],
    redirect: nationwideIn6xpx9prnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideIn6xpx9prnMeta?.name ?? "pages-nationwide___nl",
    path: nationwideIn6xpx9prnMeta?.path ?? "/nl/pages/nationwide",
    meta: nationwideIn6xpx9prnMeta || {},
    alias: nationwideIn6xpx9prnMeta?.alias || [],
    redirect: nationwideIn6xpx9prnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideIn6xpx9prnMeta?.name ?? "pages-nationwide___uk",
    path: nationwideIn6xpx9prnMeta?.path ?? "/uk/pages/nationwide",
    meta: nationwideIn6xpx9prnMeta || {},
    alias: nationwideIn6xpx9prnMeta?.alias || [],
    redirect: nationwideIn6xpx9prnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideIn6xpx9prnMeta?.name ?? "pages-nationwide___de",
    path: nationwideIn6xpx9prnMeta?.path ?? "/de/pages/nationwide",
    meta: nationwideIn6xpx9prnMeta || {},
    alias: nationwideIn6xpx9prnMeta?.alias || [],
    redirect: nationwideIn6xpx9prnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideIn6xpx9prnMeta?.name ?? "pages-nationwide___mx",
    path: nationwideIn6xpx9prnMeta?.path ?? "/mx/pages/nationwide",
    meta: nationwideIn6xpx9prnMeta || {},
    alias: nationwideIn6xpx9prnMeta?.alias || [],
    redirect: nationwideIn6xpx9prnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideIn6xpx9prnMeta?.name ?? "pages-nationwide___fr",
    path: nationwideIn6xpx9prnMeta?.path ?? "/fr/pages/nationwide",
    meta: nationwideIn6xpx9prnMeta || {},
    alias: nationwideIn6xpx9prnMeta?.alias || [],
    redirect: nationwideIn6xpx9prnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideIn6xpx9prnMeta?.name ?? "pages-nationwide___fr-ca",
    path: nationwideIn6xpx9prnMeta?.path ?? "/fr-ca/pages/nationwide",
    meta: nationwideIn6xpx9prnMeta || {},
    alias: nationwideIn6xpx9prnMeta?.alias || [],
    redirect: nationwideIn6xpx9prnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingesbvejDMJJMeta?.name ?? "pages-safety-coaching___en-US",
    path: safety_45coachingesbvejDMJJMeta?.path ?? "/pages/safety-coaching",
    meta: safety_45coachingesbvejDMJJMeta || {},
    alias: safety_45coachingesbvejDMJJMeta?.alias || [],
    redirect: safety_45coachingesbvejDMJJMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingesbvejDMJJMeta?.name ?? "pages-safety-coaching___ca",
    path: safety_45coachingesbvejDMJJMeta?.path ?? "/ca/pages/safety-coaching",
    meta: safety_45coachingesbvejDMJJMeta || {},
    alias: safety_45coachingesbvejDMJJMeta?.alias || [],
    redirect: safety_45coachingesbvejDMJJMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingesbvejDMJJMeta?.name ?? "pages-safety-coaching___nl",
    path: safety_45coachingesbvejDMJJMeta?.path ?? "/nl/pages/safety-coaching",
    meta: safety_45coachingesbvejDMJJMeta || {},
    alias: safety_45coachingesbvejDMJJMeta?.alias || [],
    redirect: safety_45coachingesbvejDMJJMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingesbvejDMJJMeta?.name ?? "pages-safety-coaching___uk",
    path: safety_45coachingesbvejDMJJMeta?.path ?? "/uk/pages/safety-coaching",
    meta: safety_45coachingesbvejDMJJMeta || {},
    alias: safety_45coachingesbvejDMJJMeta?.alias || [],
    redirect: safety_45coachingesbvejDMJJMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingesbvejDMJJMeta?.name ?? "pages-safety-coaching___de",
    path: safety_45coachingesbvejDMJJMeta?.path ?? "/de/pages/safety-coaching",
    meta: safety_45coachingesbvejDMJJMeta || {},
    alias: safety_45coachingesbvejDMJJMeta?.alias || [],
    redirect: safety_45coachingesbvejDMJJMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingesbvejDMJJMeta?.name ?? "pages-safety-coaching___mx",
    path: safety_45coachingesbvejDMJJMeta?.path ?? "/mx/pages/safety-coaching",
    meta: safety_45coachingesbvejDMJJMeta || {},
    alias: safety_45coachingesbvejDMJJMeta?.alias || [],
    redirect: safety_45coachingesbvejDMJJMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingesbvejDMJJMeta?.name ?? "pages-safety-coaching___fr",
    path: safety_45coachingesbvejDMJJMeta?.path ?? "/fr/pages/safety-coaching",
    meta: safety_45coachingesbvejDMJJMeta || {},
    alias: safety_45coachingesbvejDMJJMeta?.alias || [],
    redirect: safety_45coachingesbvejDMJJMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingesbvejDMJJMeta?.name ?? "pages-safety-coaching___fr-ca",
    path: safety_45coachingesbvejDMJJMeta?.path ?? "/fr-ca/pages/safety-coaching",
    meta: safety_45coachingesbvejDMJJMeta || {},
    alias: safety_45coachingesbvejDMJJMeta?.alias || [],
    redirect: safety_45coachingesbvejDMJJMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: unifirst2oD3VQjHISMeta?.name ?? "pages-unifirst___en-US",
    path: unifirst2oD3VQjHISMeta?.path ?? "/pages/unifirst",
    meta: unifirst2oD3VQjHISMeta || {},
    alias: unifirst2oD3VQjHISMeta?.alias || [],
    redirect: unifirst2oD3VQjHISMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirst2oD3VQjHISMeta?.name ?? "pages-unifirst___ca",
    path: unifirst2oD3VQjHISMeta?.path ?? "/ca/pages/unifirst",
    meta: unifirst2oD3VQjHISMeta || {},
    alias: unifirst2oD3VQjHISMeta?.alias || [],
    redirect: unifirst2oD3VQjHISMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirst2oD3VQjHISMeta?.name ?? "pages-unifirst___nl",
    path: unifirst2oD3VQjHISMeta?.path ?? "/nl/pages/unifirst",
    meta: unifirst2oD3VQjHISMeta || {},
    alias: unifirst2oD3VQjHISMeta?.alias || [],
    redirect: unifirst2oD3VQjHISMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirst2oD3VQjHISMeta?.name ?? "pages-unifirst___uk",
    path: unifirst2oD3VQjHISMeta?.path ?? "/uk/pages/unifirst",
    meta: unifirst2oD3VQjHISMeta || {},
    alias: unifirst2oD3VQjHISMeta?.alias || [],
    redirect: unifirst2oD3VQjHISMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirst2oD3VQjHISMeta?.name ?? "pages-unifirst___de",
    path: unifirst2oD3VQjHISMeta?.path ?? "/de/pages/unifirst",
    meta: unifirst2oD3VQjHISMeta || {},
    alias: unifirst2oD3VQjHISMeta?.alias || [],
    redirect: unifirst2oD3VQjHISMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirst2oD3VQjHISMeta?.name ?? "pages-unifirst___mx",
    path: unifirst2oD3VQjHISMeta?.path ?? "/mx/pages/unifirst",
    meta: unifirst2oD3VQjHISMeta || {},
    alias: unifirst2oD3VQjHISMeta?.alias || [],
    redirect: unifirst2oD3VQjHISMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirst2oD3VQjHISMeta?.name ?? "pages-unifirst___fr",
    path: unifirst2oD3VQjHISMeta?.path ?? "/fr/pages/unifirst",
    meta: unifirst2oD3VQjHISMeta || {},
    alias: unifirst2oD3VQjHISMeta?.alias || [],
    redirect: unifirst2oD3VQjHISMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirst2oD3VQjHISMeta?.name ?? "pages-unifirst___fr-ca",
    path: unifirst2oD3VQjHISMeta?.path ?? "/fr-ca/pages/unifirst",
    meta: unifirst2oD3VQjHISMeta || {},
    alias: unifirst2oD3VQjHISMeta?.alias || [],
    redirect: unifirst2oD3VQjHISMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: pricingCMN0cJRUaHMeta?.name ?? "pricing___en-US",
    path: pricingCMN0cJRUaHMeta?.path ?? "/pricing",
    meta: pricingCMN0cJRUaHMeta || {},
    alias: pricingCMN0cJRUaHMeta?.alias || [],
    redirect: pricingCMN0cJRUaHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingCMN0cJRUaHMeta?.name ?? "pricing___ca",
    path: pricingCMN0cJRUaHMeta?.path ?? "/ca/pricing",
    meta: pricingCMN0cJRUaHMeta || {},
    alias: pricingCMN0cJRUaHMeta?.alias || [],
    redirect: pricingCMN0cJRUaHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingCMN0cJRUaHMeta?.name ?? "pricing___nl",
    path: pricingCMN0cJRUaHMeta?.path ?? "/nl/pricing",
    meta: pricingCMN0cJRUaHMeta || {},
    alias: pricingCMN0cJRUaHMeta?.alias || [],
    redirect: pricingCMN0cJRUaHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingCMN0cJRUaHMeta?.name ?? "pricing___uk",
    path: pricingCMN0cJRUaHMeta?.path ?? "/uk/pricing",
    meta: pricingCMN0cJRUaHMeta || {},
    alias: pricingCMN0cJRUaHMeta?.alias || [],
    redirect: pricingCMN0cJRUaHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingCMN0cJRUaHMeta?.name ?? "pricing___de",
    path: pricingCMN0cJRUaHMeta?.path ?? "/de/pricing",
    meta: pricingCMN0cJRUaHMeta || {},
    alias: pricingCMN0cJRUaHMeta?.alias || [],
    redirect: pricingCMN0cJRUaHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingCMN0cJRUaHMeta?.name ?? "pricing___mx",
    path: pricingCMN0cJRUaHMeta?.path ?? "/mx/prueba-gratis",
    meta: pricingCMN0cJRUaHMeta || {},
    alias: pricingCMN0cJRUaHMeta?.alias || [],
    redirect: pricingCMN0cJRUaHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingCMN0cJRUaHMeta?.name ?? "pricing___fr",
    path: pricingCMN0cJRUaHMeta?.path ?? "/fr/pricing",
    meta: pricingCMN0cJRUaHMeta || {},
    alias: pricingCMN0cJRUaHMeta?.alias || [],
    redirect: pricingCMN0cJRUaHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingCMN0cJRUaHMeta?.name ?? "pricing___fr-ca",
    path: pricingCMN0cJRUaHMeta?.path ?? "/fr-ca/pricing",
    meta: pricingCMN0cJRUaHMeta || {},
    alias: pricingCMN0cJRUaHMeta?.alias || [],
    redirect: pricingCMN0cJRUaHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FjKmhxLgRsMeta?.name ?? "products-prefix-slug___en-US",
    path: _91slug_93FjKmhxLgRsMeta?.path ?? "/products/:prefix()/:slug()",
    meta: _91slug_93FjKmhxLgRsMeta || {},
    alias: _91slug_93FjKmhxLgRsMeta?.alias || [],
    redirect: _91slug_93FjKmhxLgRsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FjKmhxLgRsMeta?.name ?? "products-prefix-slug___ca",
    path: _91slug_93FjKmhxLgRsMeta?.path ?? "/ca/products/:prefix()/:slug()",
    meta: _91slug_93FjKmhxLgRsMeta || {},
    alias: _91slug_93FjKmhxLgRsMeta?.alias || [],
    redirect: _91slug_93FjKmhxLgRsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FjKmhxLgRsMeta?.name ?? "products-prefix-slug___nl",
    path: _91slug_93FjKmhxLgRsMeta?.path ?? "/nl/products/:prefix()/:slug()",
    meta: _91slug_93FjKmhxLgRsMeta || {},
    alias: _91slug_93FjKmhxLgRsMeta?.alias || [],
    redirect: _91slug_93FjKmhxLgRsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FjKmhxLgRsMeta?.name ?? "products-prefix-slug___uk",
    path: _91slug_93FjKmhxLgRsMeta?.path ?? "/uk/products/:prefix()/:slug()",
    meta: _91slug_93FjKmhxLgRsMeta || {},
    alias: _91slug_93FjKmhxLgRsMeta?.alias || [],
    redirect: _91slug_93FjKmhxLgRsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FjKmhxLgRsMeta?.name ?? "products-prefix-slug___de",
    path: _91slug_93FjKmhxLgRsMeta?.path ?? "/de/products/:prefix()/:slug()",
    meta: _91slug_93FjKmhxLgRsMeta || {},
    alias: _91slug_93FjKmhxLgRsMeta?.alias || [],
    redirect: _91slug_93FjKmhxLgRsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FjKmhxLgRsMeta?.name ?? "products-prefix-slug___mx",
    path: _91slug_93FjKmhxLgRsMeta?.path ?? "/mx/products/:prefix()/:slug()",
    meta: _91slug_93FjKmhxLgRsMeta || {},
    alias: _91slug_93FjKmhxLgRsMeta?.alias || [],
    redirect: _91slug_93FjKmhxLgRsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FjKmhxLgRsMeta?.name ?? "products-prefix-slug___fr",
    path: _91slug_93FjKmhxLgRsMeta?.path ?? "/fr/products/:prefix()/:slug()",
    meta: _91slug_93FjKmhxLgRsMeta || {},
    alias: _91slug_93FjKmhxLgRsMeta?.alias || [],
    redirect: _91slug_93FjKmhxLgRsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FjKmhxLgRsMeta?.name ?? "products-prefix-slug___fr-ca",
    path: _91slug_93FjKmhxLgRsMeta?.path ?? "/fr-ca/products/:prefix()/:slug()",
    meta: _91slug_93FjKmhxLgRsMeta || {},
    alias: _91slug_93FjKmhxLgRsMeta?.alias || [],
    redirect: _91slug_93FjKmhxLgRsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930CXQx96byqMeta?.name ?? "products-slug___en-US",
    path: _91slug_930CXQx96byqMeta?.path ?? "/products/:slug()",
    meta: _91slug_930CXQx96byqMeta || {},
    alias: _91slug_930CXQx96byqMeta?.alias || [],
    redirect: _91slug_930CXQx96byqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930CXQx96byqMeta?.name ?? "products-slug___ca",
    path: _91slug_930CXQx96byqMeta?.path ?? "/ca/products/:slug()",
    meta: _91slug_930CXQx96byqMeta || {},
    alias: _91slug_930CXQx96byqMeta?.alias || [],
    redirect: _91slug_930CXQx96byqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930CXQx96byqMeta?.name ?? "products-slug___nl",
    path: _91slug_930CXQx96byqMeta?.path ?? "/nl/products/:slug()",
    meta: _91slug_930CXQx96byqMeta || {},
    alias: _91slug_930CXQx96byqMeta?.alias || [],
    redirect: _91slug_930CXQx96byqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930CXQx96byqMeta?.name ?? "products-slug___uk",
    path: _91slug_930CXQx96byqMeta?.path ?? "/uk/products/:slug()",
    meta: _91slug_930CXQx96byqMeta || {},
    alias: _91slug_930CXQx96byqMeta?.alias || [],
    redirect: _91slug_930CXQx96byqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930CXQx96byqMeta?.name ?? "products-slug___de",
    path: _91slug_930CXQx96byqMeta?.path ?? "/de/products/:slug()",
    meta: _91slug_930CXQx96byqMeta || {},
    alias: _91slug_930CXQx96byqMeta?.alias || [],
    redirect: _91slug_930CXQx96byqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930CXQx96byqMeta?.name ?? "products-slug___mx",
    path: _91slug_930CXQx96byqMeta?.path ?? "/mx/products/:slug()",
    meta: _91slug_930CXQx96byqMeta || {},
    alias: _91slug_930CXQx96byqMeta?.alias || [],
    redirect: _91slug_930CXQx96byqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930CXQx96byqMeta?.name ?? "products-slug___fr",
    path: _91slug_930CXQx96byqMeta?.path ?? "/fr/products/:slug()",
    meta: _91slug_930CXQx96byqMeta || {},
    alias: _91slug_930CXQx96byqMeta?.alias || [],
    redirect: _91slug_930CXQx96byqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930CXQx96byqMeta?.name ?? "products-slug___fr-ca",
    path: _91slug_930CXQx96byqMeta?.path ?? "/fr-ca/products/:slug()",
    meta: _91slug_930CXQx96byqMeta || {},
    alias: _91slug_930CXQx96byqMeta?.alias || [],
    redirect: _91slug_930CXQx96byqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingmoCd5dlKPOMeta?.name ?? "products-equipment-monitoring-asset-tracking___en-US",
    path: asset_45trackingmoCd5dlKPOMeta?.path ?? "/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingmoCd5dlKPOMeta || {},
    alias: asset_45trackingmoCd5dlKPOMeta?.alias || [],
    redirect: asset_45trackingmoCd5dlKPOMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingmoCd5dlKPOMeta?.name ?? "products-equipment-monitoring-asset-tracking___ca",
    path: asset_45trackingmoCd5dlKPOMeta?.path ?? "/ca/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingmoCd5dlKPOMeta || {},
    alias: asset_45trackingmoCd5dlKPOMeta?.alias || [],
    redirect: asset_45trackingmoCd5dlKPOMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingmoCd5dlKPOMeta?.name ?? "products-equipment-monitoring-asset-tracking___nl",
    path: asset_45trackingmoCd5dlKPOMeta?.path ?? "/nl/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingmoCd5dlKPOMeta || {},
    alias: asset_45trackingmoCd5dlKPOMeta?.alias || [],
    redirect: asset_45trackingmoCd5dlKPOMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingmoCd5dlKPOMeta?.name ?? "products-equipment-monitoring-asset-tracking___uk",
    path: asset_45trackingmoCd5dlKPOMeta?.path ?? "/uk/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingmoCd5dlKPOMeta || {},
    alias: asset_45trackingmoCd5dlKPOMeta?.alias || [],
    redirect: asset_45trackingmoCd5dlKPOMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingmoCd5dlKPOMeta?.name ?? "products-equipment-monitoring-asset-tracking___de",
    path: asset_45trackingmoCd5dlKPOMeta?.path ?? "/de/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingmoCd5dlKPOMeta || {},
    alias: asset_45trackingmoCd5dlKPOMeta?.alias || [],
    redirect: asset_45trackingmoCd5dlKPOMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingmoCd5dlKPOMeta?.name ?? "products-equipment-monitoring-asset-tracking___mx",
    path: asset_45trackingmoCd5dlKPOMeta?.path ?? "/mx/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingmoCd5dlKPOMeta || {},
    alias: asset_45trackingmoCd5dlKPOMeta?.alias || [],
    redirect: asset_45trackingmoCd5dlKPOMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingmoCd5dlKPOMeta?.name ?? "products-equipment-monitoring-asset-tracking___fr",
    path: asset_45trackingmoCd5dlKPOMeta?.path ?? "/fr/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingmoCd5dlKPOMeta || {},
    alias: asset_45trackingmoCd5dlKPOMeta?.alias || [],
    redirect: asset_45trackingmoCd5dlKPOMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingmoCd5dlKPOMeta?.name ?? "products-equipment-monitoring-asset-tracking___fr-ca",
    path: asset_45trackingmoCd5dlKPOMeta?.path ?? "/fr-ca/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingmoCd5dlKPOMeta || {},
    alias: asset_45trackingmoCd5dlKPOMeta?.alias || [],
    redirect: asset_45trackingmoCd5dlKPOMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingxtpI7zS8iaMeta?.name ?? "products-equipment-monitoring-equipment-tracking___en-US",
    path: equipment_45trackingxtpI7zS8iaMeta?.path ?? "/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingxtpI7zS8iaMeta || {},
    alias: equipment_45trackingxtpI7zS8iaMeta?.alias || [],
    redirect: equipment_45trackingxtpI7zS8iaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingxtpI7zS8iaMeta?.name ?? "products-equipment-monitoring-equipment-tracking___ca",
    path: equipment_45trackingxtpI7zS8iaMeta?.path ?? "/ca/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingxtpI7zS8iaMeta || {},
    alias: equipment_45trackingxtpI7zS8iaMeta?.alias || [],
    redirect: equipment_45trackingxtpI7zS8iaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingxtpI7zS8iaMeta?.name ?? "products-equipment-monitoring-equipment-tracking___nl",
    path: equipment_45trackingxtpI7zS8iaMeta?.path ?? "/nl/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingxtpI7zS8iaMeta || {},
    alias: equipment_45trackingxtpI7zS8iaMeta?.alias || [],
    redirect: equipment_45trackingxtpI7zS8iaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingxtpI7zS8iaMeta?.name ?? "products-equipment-monitoring-equipment-tracking___uk",
    path: equipment_45trackingxtpI7zS8iaMeta?.path ?? "/uk/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingxtpI7zS8iaMeta || {},
    alias: equipment_45trackingxtpI7zS8iaMeta?.alias || [],
    redirect: equipment_45trackingxtpI7zS8iaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingxtpI7zS8iaMeta?.name ?? "products-equipment-monitoring-equipment-tracking___de",
    path: equipment_45trackingxtpI7zS8iaMeta?.path ?? "/de/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingxtpI7zS8iaMeta || {},
    alias: equipment_45trackingxtpI7zS8iaMeta?.alias || [],
    redirect: equipment_45trackingxtpI7zS8iaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingxtpI7zS8iaMeta?.name ?? "products-equipment-monitoring-equipment-tracking___mx",
    path: equipment_45trackingxtpI7zS8iaMeta?.path ?? "/mx/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingxtpI7zS8iaMeta || {},
    alias: equipment_45trackingxtpI7zS8iaMeta?.alias || [],
    redirect: equipment_45trackingxtpI7zS8iaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingxtpI7zS8iaMeta?.name ?? "products-equipment-monitoring-equipment-tracking___fr",
    path: equipment_45trackingxtpI7zS8iaMeta?.path ?? "/fr/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingxtpI7zS8iaMeta || {},
    alias: equipment_45trackingxtpI7zS8iaMeta?.alias || [],
    redirect: equipment_45trackingxtpI7zS8iaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingxtpI7zS8iaMeta?.name ?? "products-equipment-monitoring-equipment-tracking___fr-ca",
    path: equipment_45trackingxtpI7zS8iaMeta?.path ?? "/fr-ca/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingxtpI7zS8iaMeta || {},
    alias: equipment_45trackingxtpI7zS8iaMeta?.alias || [],
    redirect: equipment_45trackingxtpI7zS8iaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringtzekXJQlYoMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___en-US",
    path: reefer_45monitoringtzekXJQlYoMeta?.path ?? "/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringtzekXJQlYoMeta || {},
    alias: reefer_45monitoringtzekXJQlYoMeta?.alias || [],
    redirect: reefer_45monitoringtzekXJQlYoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringtzekXJQlYoMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___ca",
    path: reefer_45monitoringtzekXJQlYoMeta?.path ?? "/ca/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringtzekXJQlYoMeta || {},
    alias: reefer_45monitoringtzekXJQlYoMeta?.alias || [],
    redirect: reefer_45monitoringtzekXJQlYoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringtzekXJQlYoMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___nl",
    path: reefer_45monitoringtzekXJQlYoMeta?.path ?? "/nl/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringtzekXJQlYoMeta || {},
    alias: reefer_45monitoringtzekXJQlYoMeta?.alias || [],
    redirect: reefer_45monitoringtzekXJQlYoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringtzekXJQlYoMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___uk",
    path: reefer_45monitoringtzekXJQlYoMeta?.path ?? "/uk/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringtzekXJQlYoMeta || {},
    alias: reefer_45monitoringtzekXJQlYoMeta?.alias || [],
    redirect: reefer_45monitoringtzekXJQlYoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringtzekXJQlYoMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___de",
    path: reefer_45monitoringtzekXJQlYoMeta?.path ?? "/de/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringtzekXJQlYoMeta || {},
    alias: reefer_45monitoringtzekXJQlYoMeta?.alias || [],
    redirect: reefer_45monitoringtzekXJQlYoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringtzekXJQlYoMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___mx",
    path: reefer_45monitoringtzekXJQlYoMeta?.path ?? "/mx/productos/rastreo-equipos/refrigerados",
    meta: reefer_45monitoringtzekXJQlYoMeta || {},
    alias: reefer_45monitoringtzekXJQlYoMeta?.alias || [],
    redirect: reefer_45monitoringtzekXJQlYoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringtzekXJQlYoMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___fr",
    path: reefer_45monitoringtzekXJQlYoMeta?.path ?? "/fr/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringtzekXJQlYoMeta || {},
    alias: reefer_45monitoringtzekXJQlYoMeta?.alias || [],
    redirect: reefer_45monitoringtzekXJQlYoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringtzekXJQlYoMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___fr-ca",
    path: reefer_45monitoringtzekXJQlYoMeta?.path ?? "/fr-ca/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringtzekXJQlYoMeta || {},
    alias: reefer_45monitoringtzekXJQlYoMeta?.alias || [],
    redirect: reefer_45monitoringtzekXJQlYoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerszdgBvqmNYXMeta?.name ?? "products-equipment-monitoring-smart-trailers___en-US",
    path: smart_45trailerszdgBvqmNYXMeta?.path ?? "/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerszdgBvqmNYXMeta || {},
    alias: smart_45trailerszdgBvqmNYXMeta?.alias || [],
    redirect: smart_45trailerszdgBvqmNYXMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerszdgBvqmNYXMeta?.name ?? "products-equipment-monitoring-smart-trailers___ca",
    path: smart_45trailerszdgBvqmNYXMeta?.path ?? "/ca/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerszdgBvqmNYXMeta || {},
    alias: smart_45trailerszdgBvqmNYXMeta?.alias || [],
    redirect: smart_45trailerszdgBvqmNYXMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerszdgBvqmNYXMeta?.name ?? "products-equipment-monitoring-smart-trailers___nl",
    path: smart_45trailerszdgBvqmNYXMeta?.path ?? "/nl/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerszdgBvqmNYXMeta || {},
    alias: smart_45trailerszdgBvqmNYXMeta?.alias || [],
    redirect: smart_45trailerszdgBvqmNYXMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerszdgBvqmNYXMeta?.name ?? "products-equipment-monitoring-smart-trailers___uk",
    path: smart_45trailerszdgBvqmNYXMeta?.path ?? "/uk/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerszdgBvqmNYXMeta || {},
    alias: smart_45trailerszdgBvqmNYXMeta?.alias || [],
    redirect: smart_45trailerszdgBvqmNYXMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerszdgBvqmNYXMeta?.name ?? "products-equipment-monitoring-smart-trailers___de",
    path: smart_45trailerszdgBvqmNYXMeta?.path ?? "/de/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerszdgBvqmNYXMeta || {},
    alias: smart_45trailerszdgBvqmNYXMeta?.alias || [],
    redirect: smart_45trailerszdgBvqmNYXMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerszdgBvqmNYXMeta?.name ?? "products-equipment-monitoring-smart-trailers___mx",
    path: smart_45trailerszdgBvqmNYXMeta?.path ?? "/mx/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerszdgBvqmNYXMeta || {},
    alias: smart_45trailerszdgBvqmNYXMeta?.alias || [],
    redirect: smart_45trailerszdgBvqmNYXMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerszdgBvqmNYXMeta?.name ?? "products-equipment-monitoring-smart-trailers___fr",
    path: smart_45trailerszdgBvqmNYXMeta?.path ?? "/fr/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerszdgBvqmNYXMeta || {},
    alias: smart_45trailerszdgBvqmNYXMeta?.alias || [],
    redirect: smart_45trailerszdgBvqmNYXMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerszdgBvqmNYXMeta?.name ?? "products-equipment-monitoring-smart-trailers___fr-ca",
    path: smart_45trailerszdgBvqmNYXMeta?.path ?? "/fr-ca/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerszdgBvqmNYXMeta || {},
    alias: smart_45trailerszdgBvqmNYXMeta?.alias || [],
    redirect: smart_45trailerszdgBvqmNYXMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingvQ7IKBFEicMeta?.name ?? "products-equipment-monitoring-trailer-tracking___en-US",
    path: trailer_45trackingvQ7IKBFEicMeta?.path ?? "/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingvQ7IKBFEicMeta || {},
    alias: trailer_45trackingvQ7IKBFEicMeta?.alias || [],
    redirect: trailer_45trackingvQ7IKBFEicMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingvQ7IKBFEicMeta?.name ?? "products-equipment-monitoring-trailer-tracking___ca",
    path: trailer_45trackingvQ7IKBFEicMeta?.path ?? "/ca/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingvQ7IKBFEicMeta || {},
    alias: trailer_45trackingvQ7IKBFEicMeta?.alias || [],
    redirect: trailer_45trackingvQ7IKBFEicMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingvQ7IKBFEicMeta?.name ?? "products-equipment-monitoring-trailer-tracking___nl",
    path: trailer_45trackingvQ7IKBFEicMeta?.path ?? "/nl/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingvQ7IKBFEicMeta || {},
    alias: trailer_45trackingvQ7IKBFEicMeta?.alias || [],
    redirect: trailer_45trackingvQ7IKBFEicMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingvQ7IKBFEicMeta?.name ?? "products-equipment-monitoring-trailer-tracking___uk",
    path: trailer_45trackingvQ7IKBFEicMeta?.path ?? "/uk/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingvQ7IKBFEicMeta || {},
    alias: trailer_45trackingvQ7IKBFEicMeta?.alias || [],
    redirect: trailer_45trackingvQ7IKBFEicMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingvQ7IKBFEicMeta?.name ?? "products-equipment-monitoring-trailer-tracking___de",
    path: trailer_45trackingvQ7IKBFEicMeta?.path ?? "/de/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingvQ7IKBFEicMeta || {},
    alias: trailer_45trackingvQ7IKBFEicMeta?.alias || [],
    redirect: trailer_45trackingvQ7IKBFEicMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingvQ7IKBFEicMeta?.name ?? "products-equipment-monitoring-trailer-tracking___mx",
    path: trailer_45trackingvQ7IKBFEicMeta?.path ?? "/mx/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingvQ7IKBFEicMeta || {},
    alias: trailer_45trackingvQ7IKBFEicMeta?.alias || [],
    redirect: trailer_45trackingvQ7IKBFEicMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingvQ7IKBFEicMeta?.name ?? "products-equipment-monitoring-trailer-tracking___fr",
    path: trailer_45trackingvQ7IKBFEicMeta?.path ?? "/fr/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingvQ7IKBFEicMeta || {},
    alias: trailer_45trackingvQ7IKBFEicMeta?.alias || [],
    redirect: trailer_45trackingvQ7IKBFEicMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingvQ7IKBFEicMeta?.name ?? "products-equipment-monitoring-trailer-tracking___fr-ca",
    path: trailer_45trackingvQ7IKBFEicMeta?.path ?? "/fr-ca/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingvQ7IKBFEicMeta || {},
    alias: trailer_45trackingvQ7IKBFEicMeta?.alias || [],
    redirect: trailer_45trackingvQ7IKBFEicMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: indexZpimChz6QeMeta?.name ?? "products___en-US",
    path: indexZpimChz6QeMeta?.path ?? "/products",
    meta: indexZpimChz6QeMeta || {},
    alias: indexZpimChz6QeMeta?.alias || [],
    redirect: indexZpimChz6QeMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexZpimChz6QeMeta?.name ?? "products___ca",
    path: indexZpimChz6QeMeta?.path ?? "/ca/products",
    meta: indexZpimChz6QeMeta || {},
    alias: indexZpimChz6QeMeta?.alias || [],
    redirect: indexZpimChz6QeMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexZpimChz6QeMeta?.name ?? "products___nl",
    path: indexZpimChz6QeMeta?.path ?? "/nl/products",
    meta: indexZpimChz6QeMeta || {},
    alias: indexZpimChz6QeMeta?.alias || [],
    redirect: indexZpimChz6QeMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexZpimChz6QeMeta?.name ?? "products___uk",
    path: indexZpimChz6QeMeta?.path ?? "/uk/products",
    meta: indexZpimChz6QeMeta || {},
    alias: indexZpimChz6QeMeta?.alias || [],
    redirect: indexZpimChz6QeMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexZpimChz6QeMeta?.name ?? "products___de",
    path: indexZpimChz6QeMeta?.path ?? "/de/products",
    meta: indexZpimChz6QeMeta || {},
    alias: indexZpimChz6QeMeta?.alias || [],
    redirect: indexZpimChz6QeMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexZpimChz6QeMeta?.name ?? "products___mx",
    path: indexZpimChz6QeMeta?.path ?? "/mx/products",
    meta: indexZpimChz6QeMeta || {},
    alias: indexZpimChz6QeMeta?.alias || [],
    redirect: indexZpimChz6QeMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexZpimChz6QeMeta?.name ?? "products___fr",
    path: indexZpimChz6QeMeta?.path ?? "/fr/products",
    meta: indexZpimChz6QeMeta || {},
    alias: indexZpimChz6QeMeta?.alias || [],
    redirect: indexZpimChz6QeMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexZpimChz6QeMeta?.name ?? "products___fr-ca",
    path: indexZpimChz6QeMeta?.path ?? "/fr-ca/products",
    meta: indexZpimChz6QeMeta || {},
    alias: indexZpimChz6QeMeta?.alias || [],
    redirect: indexZpimChz6QeMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kN2qORA385Meta?.name ?? "products-models-slug___en-US",
    path: _91slug_93kN2qORA385Meta?.path ?? "/products/models/:slug()",
    meta: _91slug_93kN2qORA385Meta || {},
    alias: _91slug_93kN2qORA385Meta?.alias || [],
    redirect: _91slug_93kN2qORA385Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kN2qORA385Meta?.name ?? "products-models-slug___ca",
    path: _91slug_93kN2qORA385Meta?.path ?? "/ca/products/models/:slug()",
    meta: _91slug_93kN2qORA385Meta || {},
    alias: _91slug_93kN2qORA385Meta?.alias || [],
    redirect: _91slug_93kN2qORA385Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kN2qORA385Meta?.name ?? "products-models-slug___nl",
    path: _91slug_93kN2qORA385Meta?.path ?? "/nl/products/models/:slug()",
    meta: _91slug_93kN2qORA385Meta || {},
    alias: _91slug_93kN2qORA385Meta?.alias || [],
    redirect: _91slug_93kN2qORA385Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kN2qORA385Meta?.name ?? "products-models-slug___uk",
    path: _91slug_93kN2qORA385Meta?.path ?? "/uk/products/models/:slug()",
    meta: _91slug_93kN2qORA385Meta || {},
    alias: _91slug_93kN2qORA385Meta?.alias || [],
    redirect: _91slug_93kN2qORA385Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kN2qORA385Meta?.name ?? "products-models-slug___de",
    path: _91slug_93kN2qORA385Meta?.path ?? "/de/products/models/:slug()",
    meta: _91slug_93kN2qORA385Meta || {},
    alias: _91slug_93kN2qORA385Meta?.alias || [],
    redirect: _91slug_93kN2qORA385Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kN2qORA385Meta?.name ?? "products-models-slug___mx",
    path: _91slug_93kN2qORA385Meta?.path ?? "/mx/products/models/:slug()",
    meta: _91slug_93kN2qORA385Meta || {},
    alias: _91slug_93kN2qORA385Meta?.alias || [],
    redirect: _91slug_93kN2qORA385Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kN2qORA385Meta?.name ?? "products-models-slug___fr",
    path: _91slug_93kN2qORA385Meta?.path ?? "/fr/products/models/:slug()",
    meta: _91slug_93kN2qORA385Meta || {},
    alias: _91slug_93kN2qORA385Meta?.alias || [],
    redirect: _91slug_93kN2qORA385Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kN2qORA385Meta?.name ?? "products-models-slug___fr-ca",
    path: _91slug_93kN2qORA385Meta?.path ?? "/fr-ca/products/models/:slug()",
    meta: _91slug_93kN2qORA385Meta || {},
    alias: _91slug_93kN2qORA385Meta?.alias || [],
    redirect: _91slug_93kN2qORA385Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexBeFCYus7N5Meta?.name ?? "products-models___en-US",
    path: indexBeFCYus7N5Meta?.path ?? "/products/models",
    meta: indexBeFCYus7N5Meta || {},
    alias: indexBeFCYus7N5Meta?.alias || [],
    redirect: indexBeFCYus7N5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexBeFCYus7N5Meta?.name ?? "products-models___ca",
    path: indexBeFCYus7N5Meta?.path ?? "/ca/products/models",
    meta: indexBeFCYus7N5Meta || {},
    alias: indexBeFCYus7N5Meta?.alias || [],
    redirect: indexBeFCYus7N5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexBeFCYus7N5Meta?.name ?? "products-models___nl",
    path: indexBeFCYus7N5Meta?.path ?? "/nl/products/models",
    meta: indexBeFCYus7N5Meta || {},
    alias: indexBeFCYus7N5Meta?.alias || [],
    redirect: indexBeFCYus7N5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexBeFCYus7N5Meta?.name ?? "products-models___uk",
    path: indexBeFCYus7N5Meta?.path ?? "/uk/products/models",
    meta: indexBeFCYus7N5Meta || {},
    alias: indexBeFCYus7N5Meta?.alias || [],
    redirect: indexBeFCYus7N5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexBeFCYus7N5Meta?.name ?? "products-models___de",
    path: indexBeFCYus7N5Meta?.path ?? "/de/products/models",
    meta: indexBeFCYus7N5Meta || {},
    alias: indexBeFCYus7N5Meta?.alias || [],
    redirect: indexBeFCYus7N5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexBeFCYus7N5Meta?.name ?? "products-models___mx",
    path: indexBeFCYus7N5Meta?.path ?? "/mx/products/models",
    meta: indexBeFCYus7N5Meta || {},
    alias: indexBeFCYus7N5Meta?.alias || [],
    redirect: indexBeFCYus7N5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexBeFCYus7N5Meta?.name ?? "products-models___fr",
    path: indexBeFCYus7N5Meta?.path ?? "/fr/products/models",
    meta: indexBeFCYus7N5Meta || {},
    alias: indexBeFCYus7N5Meta?.alias || [],
    redirect: indexBeFCYus7N5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexBeFCYus7N5Meta?.name ?? "products-models___fr-ca",
    path: indexBeFCYus7N5Meta?.path ?? "/fr-ca/products/models",
    meta: indexBeFCYus7N5Meta || {},
    alias: indexBeFCYus7N5Meta?.alias || [],
    redirect: indexBeFCYus7N5Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingIa0UgZ76FsMeta?.name ?? "products-telematics-gps-fleet-tracking___en-US",
    path: gps_45fleet_45trackingIa0UgZ76FsMeta?.path ?? "/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingIa0UgZ76FsMeta || {},
    alias: gps_45fleet_45trackingIa0UgZ76FsMeta?.alias || [],
    redirect: gps_45fleet_45trackingIa0UgZ76FsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingIa0UgZ76FsMeta?.name ?? "products-telematics-gps-fleet-tracking___ca",
    path: gps_45fleet_45trackingIa0UgZ76FsMeta?.path ?? "/ca/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingIa0UgZ76FsMeta || {},
    alias: gps_45fleet_45trackingIa0UgZ76FsMeta?.alias || [],
    redirect: gps_45fleet_45trackingIa0UgZ76FsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingIa0UgZ76FsMeta?.name ?? "products-telematics-gps-fleet-tracking___nl",
    path: gps_45fleet_45trackingIa0UgZ76FsMeta?.path ?? "/nl/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingIa0UgZ76FsMeta || {},
    alias: gps_45fleet_45trackingIa0UgZ76FsMeta?.alias || [],
    redirect: gps_45fleet_45trackingIa0UgZ76FsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingIa0UgZ76FsMeta?.name ?? "products-telematics-gps-fleet-tracking___uk",
    path: gps_45fleet_45trackingIa0UgZ76FsMeta?.path ?? "/uk/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingIa0UgZ76FsMeta || {},
    alias: gps_45fleet_45trackingIa0UgZ76FsMeta?.alias || [],
    redirect: gps_45fleet_45trackingIa0UgZ76FsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingIa0UgZ76FsMeta?.name ?? "products-telematics-gps-fleet-tracking___de",
    path: gps_45fleet_45trackingIa0UgZ76FsMeta?.path ?? "/de/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingIa0UgZ76FsMeta || {},
    alias: gps_45fleet_45trackingIa0UgZ76FsMeta?.alias || [],
    redirect: gps_45fleet_45trackingIa0UgZ76FsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingIa0UgZ76FsMeta?.name ?? "products-telematics-gps-fleet-tracking___mx",
    path: gps_45fleet_45trackingIa0UgZ76FsMeta?.path ?? "/mx/productos/telematica/rastreo-gps-flota",
    meta: gps_45fleet_45trackingIa0UgZ76FsMeta || {},
    alias: gps_45fleet_45trackingIa0UgZ76FsMeta?.alias || [],
    redirect: gps_45fleet_45trackingIa0UgZ76FsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingIa0UgZ76FsMeta?.name ?? "products-telematics-gps-fleet-tracking___fr",
    path: gps_45fleet_45trackingIa0UgZ76FsMeta?.path ?? "/fr/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingIa0UgZ76FsMeta || {},
    alias: gps_45fleet_45trackingIa0UgZ76FsMeta?.alias || [],
    redirect: gps_45fleet_45trackingIa0UgZ76FsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingIa0UgZ76FsMeta?.name ?? "products-telematics-gps-fleet-tracking___fr-ca",
    path: gps_45fleet_45trackingIa0UgZ76FsMeta?.path ?? "/fr-ca/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingIa0UgZ76FsMeta || {},
    alias: gps_45fleet_45trackingIa0UgZ76FsMeta?.alias || [],
    redirect: gps_45fleet_45trackingIa0UgZ76FsMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: indexcANWpfwdCHMeta?.name ?? "products-telematics___en-US",
    path: indexcANWpfwdCHMeta?.path ?? "/products/telematics",
    meta: indexcANWpfwdCHMeta || {},
    alias: indexcANWpfwdCHMeta?.alias || [],
    redirect: indexcANWpfwdCHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexcANWpfwdCHMeta?.name ?? "products-telematics___ca",
    path: indexcANWpfwdCHMeta?.path ?? "/ca/products/telematics",
    meta: indexcANWpfwdCHMeta || {},
    alias: indexcANWpfwdCHMeta?.alias || [],
    redirect: indexcANWpfwdCHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexcANWpfwdCHMeta?.name ?? "products-telematics___nl",
    path: indexcANWpfwdCHMeta?.path ?? "/nl/products/telematics",
    meta: indexcANWpfwdCHMeta || {},
    alias: indexcANWpfwdCHMeta?.alias || [],
    redirect: indexcANWpfwdCHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexcANWpfwdCHMeta?.name ?? "products-telematics___uk",
    path: indexcANWpfwdCHMeta?.path ?? "/uk/products/telematics",
    meta: indexcANWpfwdCHMeta || {},
    alias: indexcANWpfwdCHMeta?.alias || [],
    redirect: indexcANWpfwdCHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexcANWpfwdCHMeta?.name ?? "products-telematics___de",
    path: indexcANWpfwdCHMeta?.path ?? "/de/products/telematics",
    meta: indexcANWpfwdCHMeta || {},
    alias: indexcANWpfwdCHMeta?.alias || [],
    redirect: indexcANWpfwdCHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexcANWpfwdCHMeta?.name ?? "products-telematics___mx",
    path: indexcANWpfwdCHMeta?.path ?? "/mx/products/flota",
    meta: indexcANWpfwdCHMeta || {},
    alias: indexcANWpfwdCHMeta?.alias || [],
    redirect: indexcANWpfwdCHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexcANWpfwdCHMeta?.name ?? "products-telematics___fr",
    path: indexcANWpfwdCHMeta?.path ?? "/fr/products/telematics",
    meta: indexcANWpfwdCHMeta || {},
    alias: indexcANWpfwdCHMeta?.alias || [],
    redirect: indexcANWpfwdCHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexcANWpfwdCHMeta?.name ?? "products-telematics___fr-ca",
    path: indexcANWpfwdCHMeta?.path ?? "/fr-ca/products/telematics",
    meta: indexcANWpfwdCHMeta || {},
    alias: indexcANWpfwdCHMeta?.alias || [],
    redirect: indexcANWpfwdCHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: racingEUjB53F3OCMeta?.name ?? "racing___en-US",
    path: racingEUjB53F3OCMeta?.path ?? "/racing",
    meta: racingEUjB53F3OCMeta || {},
    alias: racingEUjB53F3OCMeta?.alias || [],
    redirect: racingEUjB53F3OCMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/racing.vue").then(m => m.default || m)
  },
  {
    name: referralskAhRNtxjJMMeta?.name ?? "referrals___en-US",
    path: referralskAhRNtxjJMMeta?.path ?? "/referrals",
    meta: referralskAhRNtxjJMMeta || {},
    alias: referralskAhRNtxjJMMeta?.alias || [],
    redirect: referralskAhRNtxjJMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralskAhRNtxjJMMeta?.name ?? "referrals___ca",
    path: referralskAhRNtxjJMMeta?.path ?? "/ca/referrals",
    meta: referralskAhRNtxjJMMeta || {},
    alias: referralskAhRNtxjJMMeta?.alias || [],
    redirect: referralskAhRNtxjJMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralskAhRNtxjJMMeta?.name ?? "referrals___nl",
    path: referralskAhRNtxjJMMeta?.path ?? "/nl/referrals",
    meta: referralskAhRNtxjJMMeta || {},
    alias: referralskAhRNtxjJMMeta?.alias || [],
    redirect: referralskAhRNtxjJMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralskAhRNtxjJMMeta?.name ?? "referrals___uk",
    path: referralskAhRNtxjJMMeta?.path ?? "/uk/referrals",
    meta: referralskAhRNtxjJMMeta || {},
    alias: referralskAhRNtxjJMMeta?.alias || [],
    redirect: referralskAhRNtxjJMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralskAhRNtxjJMMeta?.name ?? "referrals___de",
    path: referralskAhRNtxjJMMeta?.path ?? "/de/referrals",
    meta: referralskAhRNtxjJMMeta || {},
    alias: referralskAhRNtxjJMMeta?.alias || [],
    redirect: referralskAhRNtxjJMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralskAhRNtxjJMMeta?.name ?? "referrals___mx",
    path: referralskAhRNtxjJMMeta?.path ?? "/mx/referrals",
    meta: referralskAhRNtxjJMMeta || {},
    alias: referralskAhRNtxjJMMeta?.alias || [],
    redirect: referralskAhRNtxjJMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralskAhRNtxjJMMeta?.name ?? "referrals___fr",
    path: referralskAhRNtxjJMMeta?.path ?? "/fr/referrals",
    meta: referralskAhRNtxjJMMeta || {},
    alias: referralskAhRNtxjJMMeta?.alias || [],
    redirect: referralskAhRNtxjJMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralskAhRNtxjJMMeta?.name ?? "referrals___fr-ca",
    path: referralskAhRNtxjJMMeta?.path ?? "/fr-ca/referrals",
    meta: referralskAhRNtxjJMMeta || {},
    alias: referralskAhRNtxjJMMeta?.alias || [],
    redirect: referralskAhRNtxjJMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ARrlhFiKOjMeta?.name ?? "resources-slug___en-US",
    path: _91slug_93ARrlhFiKOjMeta?.path ?? "/resources/:slug()",
    meta: _91slug_93ARrlhFiKOjMeta || {},
    alias: _91slug_93ARrlhFiKOjMeta?.alias || [],
    redirect: _91slug_93ARrlhFiKOjMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ARrlhFiKOjMeta?.name ?? "resources-slug___ca",
    path: _91slug_93ARrlhFiKOjMeta?.path ?? "/ca/resources/:slug()",
    meta: _91slug_93ARrlhFiKOjMeta || {},
    alias: _91slug_93ARrlhFiKOjMeta?.alias || [],
    redirect: _91slug_93ARrlhFiKOjMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ARrlhFiKOjMeta?.name ?? "resources-slug___nl",
    path: _91slug_93ARrlhFiKOjMeta?.path ?? "/nl/resources/:slug()",
    meta: _91slug_93ARrlhFiKOjMeta || {},
    alias: _91slug_93ARrlhFiKOjMeta?.alias || [],
    redirect: _91slug_93ARrlhFiKOjMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ARrlhFiKOjMeta?.name ?? "resources-slug___uk",
    path: _91slug_93ARrlhFiKOjMeta?.path ?? "/uk/resources/:slug()",
    meta: _91slug_93ARrlhFiKOjMeta || {},
    alias: _91slug_93ARrlhFiKOjMeta?.alias || [],
    redirect: _91slug_93ARrlhFiKOjMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ARrlhFiKOjMeta?.name ?? "resources-slug___de",
    path: _91slug_93ARrlhFiKOjMeta?.path ?? "/de/resources/:slug()",
    meta: _91slug_93ARrlhFiKOjMeta || {},
    alias: _91slug_93ARrlhFiKOjMeta?.alias || [],
    redirect: _91slug_93ARrlhFiKOjMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ARrlhFiKOjMeta?.name ?? "resources-slug___mx",
    path: _91slug_93ARrlhFiKOjMeta?.path ?? "/mx/resources/:slug()",
    meta: _91slug_93ARrlhFiKOjMeta || {},
    alias: _91slug_93ARrlhFiKOjMeta?.alias || [],
    redirect: _91slug_93ARrlhFiKOjMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ARrlhFiKOjMeta?.name ?? "resources-slug___fr",
    path: _91slug_93ARrlhFiKOjMeta?.path ?? "/fr/resources/:slug()",
    meta: _91slug_93ARrlhFiKOjMeta || {},
    alias: _91slug_93ARrlhFiKOjMeta?.alias || [],
    redirect: _91slug_93ARrlhFiKOjMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ARrlhFiKOjMeta?.name ?? "resources-slug___fr-ca",
    path: _91slug_93ARrlhFiKOjMeta?.path ?? "/fr-ca/resources/:slug()",
    meta: _91slug_93ARrlhFiKOjMeta || {},
    alias: _91slug_93ARrlhFiKOjMeta?.alias || [],
    redirect: _91slug_93ARrlhFiKOjMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsJovfo9BjosMeta?.name ?? "resources-brand-assets___en-US",
    path: brand_45assetsJovfo9BjosMeta?.path ?? "/resources/brand-assets",
    meta: brand_45assetsJovfo9BjosMeta || {},
    alias: brand_45assetsJovfo9BjosMeta?.alias || [],
    redirect: brand_45assetsJovfo9BjosMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsJovfo9BjosMeta?.name ?? "resources-brand-assets___ca",
    path: brand_45assetsJovfo9BjosMeta?.path ?? "/ca/resources/brand-assets",
    meta: brand_45assetsJovfo9BjosMeta || {},
    alias: brand_45assetsJovfo9BjosMeta?.alias || [],
    redirect: brand_45assetsJovfo9BjosMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsJovfo9BjosMeta?.name ?? "resources-brand-assets___nl",
    path: brand_45assetsJovfo9BjosMeta?.path ?? "/nl/resources/brand-assets",
    meta: brand_45assetsJovfo9BjosMeta || {},
    alias: brand_45assetsJovfo9BjosMeta?.alias || [],
    redirect: brand_45assetsJovfo9BjosMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsJovfo9BjosMeta?.name ?? "resources-brand-assets___uk",
    path: brand_45assetsJovfo9BjosMeta?.path ?? "/uk/resources/brand-assets",
    meta: brand_45assetsJovfo9BjosMeta || {},
    alias: brand_45assetsJovfo9BjosMeta?.alias || [],
    redirect: brand_45assetsJovfo9BjosMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsJovfo9BjosMeta?.name ?? "resources-brand-assets___de",
    path: brand_45assetsJovfo9BjosMeta?.path ?? "/de/resources/brand-assets",
    meta: brand_45assetsJovfo9BjosMeta || {},
    alias: brand_45assetsJovfo9BjosMeta?.alias || [],
    redirect: brand_45assetsJovfo9BjosMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsJovfo9BjosMeta?.name ?? "resources-brand-assets___mx",
    path: brand_45assetsJovfo9BjosMeta?.path ?? "/mx/resources/brand-assets",
    meta: brand_45assetsJovfo9BjosMeta || {},
    alias: brand_45assetsJovfo9BjosMeta?.alias || [],
    redirect: brand_45assetsJovfo9BjosMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsJovfo9BjosMeta?.name ?? "resources-brand-assets___fr",
    path: brand_45assetsJovfo9BjosMeta?.path ?? "/fr/resources/brand-assets",
    meta: brand_45assetsJovfo9BjosMeta || {},
    alias: brand_45assetsJovfo9BjosMeta?.alias || [],
    redirect: brand_45assetsJovfo9BjosMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsJovfo9BjosMeta?.name ?? "resources-brand-assets___fr-ca",
    path: brand_45assetsJovfo9BjosMeta?.path ?? "/fr-ca/resources/brand-assets",
    meta: brand_45assetsJovfo9BjosMeta || {},
    alias: brand_45assetsJovfo9BjosMeta?.alias || [],
    redirect: brand_45assetsJovfo9BjosMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: _91slug_9328621VIbpnMeta?.name ?? "resources-content-slug___en-US",
    path: _91slug_9328621VIbpnMeta?.path ?? "/resources/content/:slug()",
    meta: _91slug_9328621VIbpnMeta || {},
    alias: _91slug_9328621VIbpnMeta?.alias || [],
    redirect: _91slug_9328621VIbpnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9328621VIbpnMeta?.name ?? "resources-content-slug___ca",
    path: _91slug_9328621VIbpnMeta?.path ?? "/ca/resources/content/:slug()",
    meta: _91slug_9328621VIbpnMeta || {},
    alias: _91slug_9328621VIbpnMeta?.alias || [],
    redirect: _91slug_9328621VIbpnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9328621VIbpnMeta?.name ?? "resources-content-slug___nl",
    path: _91slug_9328621VIbpnMeta?.path ?? "/nl/resources/content/:slug()",
    meta: _91slug_9328621VIbpnMeta || {},
    alias: _91slug_9328621VIbpnMeta?.alias || [],
    redirect: _91slug_9328621VIbpnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9328621VIbpnMeta?.name ?? "resources-content-slug___uk",
    path: _91slug_9328621VIbpnMeta?.path ?? "/uk/resources/content/:slug()",
    meta: _91slug_9328621VIbpnMeta || {},
    alias: _91slug_9328621VIbpnMeta?.alias || [],
    redirect: _91slug_9328621VIbpnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9328621VIbpnMeta?.name ?? "resources-content-slug___de",
    path: _91slug_9328621VIbpnMeta?.path ?? "/de/resources/content/:slug()",
    meta: _91slug_9328621VIbpnMeta || {},
    alias: _91slug_9328621VIbpnMeta?.alias || [],
    redirect: _91slug_9328621VIbpnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9328621VIbpnMeta?.name ?? "resources-content-slug___mx",
    path: _91slug_9328621VIbpnMeta?.path ?? "/mx/resources/content/:slug()",
    meta: _91slug_9328621VIbpnMeta || {},
    alias: _91slug_9328621VIbpnMeta?.alias || [],
    redirect: _91slug_9328621VIbpnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9328621VIbpnMeta?.name ?? "resources-content-slug___fr",
    path: _91slug_9328621VIbpnMeta?.path ?? "/fr/resources/content/:slug()",
    meta: _91slug_9328621VIbpnMeta || {},
    alias: _91slug_9328621VIbpnMeta?.alias || [],
    redirect: _91slug_9328621VIbpnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9328621VIbpnMeta?.name ?? "resources-content-slug___fr-ca",
    path: _91slug_9328621VIbpnMeta?.path ?? "/fr-ca/resources/content/:slug()",
    meta: _91slug_9328621VIbpnMeta || {},
    alias: _91slug_9328621VIbpnMeta?.alias || [],
    redirect: _91slug_9328621VIbpnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerFqHXApW00HMeta?.name ?? "resources-content-email-preferences-center___en-US",
    path: email_45preferences_45centerFqHXApW00HMeta?.path ?? "/resources/content/email-preferences-center",
    meta: email_45preferences_45centerFqHXApW00HMeta || {},
    alias: email_45preferences_45centerFqHXApW00HMeta?.alias || [],
    redirect: email_45preferences_45centerFqHXApW00HMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerFqHXApW00HMeta?.name ?? "resources-content-email-preferences-center___ca",
    path: email_45preferences_45centerFqHXApW00HMeta?.path ?? "/ca/resources/content/email-preferences-center",
    meta: email_45preferences_45centerFqHXApW00HMeta || {},
    alias: email_45preferences_45centerFqHXApW00HMeta?.alias || [],
    redirect: email_45preferences_45centerFqHXApW00HMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerFqHXApW00HMeta?.name ?? "resources-content-email-preferences-center___nl",
    path: email_45preferences_45centerFqHXApW00HMeta?.path ?? "/nl/resources/content/email-preferences-center",
    meta: email_45preferences_45centerFqHXApW00HMeta || {},
    alias: email_45preferences_45centerFqHXApW00HMeta?.alias || [],
    redirect: email_45preferences_45centerFqHXApW00HMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerFqHXApW00HMeta?.name ?? "resources-content-email-preferences-center___uk",
    path: email_45preferences_45centerFqHXApW00HMeta?.path ?? "/uk/resources/content/email-preferences-center",
    meta: email_45preferences_45centerFqHXApW00HMeta || {},
    alias: email_45preferences_45centerFqHXApW00HMeta?.alias || [],
    redirect: email_45preferences_45centerFqHXApW00HMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerFqHXApW00HMeta?.name ?? "resources-content-email-preferences-center___de",
    path: email_45preferences_45centerFqHXApW00HMeta?.path ?? "/de/resources/content/email-preferences-center",
    meta: email_45preferences_45centerFqHXApW00HMeta || {},
    alias: email_45preferences_45centerFqHXApW00HMeta?.alias || [],
    redirect: email_45preferences_45centerFqHXApW00HMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerFqHXApW00HMeta?.name ?? "resources-content-email-preferences-center___mx",
    path: email_45preferences_45centerFqHXApW00HMeta?.path ?? "/mx/resources/content/email-preferences-center",
    meta: email_45preferences_45centerFqHXApW00HMeta || {},
    alias: email_45preferences_45centerFqHXApW00HMeta?.alias || [],
    redirect: email_45preferences_45centerFqHXApW00HMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerFqHXApW00HMeta?.name ?? "resources-content-email-preferences-center___fr",
    path: email_45preferences_45centerFqHXApW00HMeta?.path ?? "/fr/resources/content/email-preferences-center",
    meta: email_45preferences_45centerFqHXApW00HMeta || {},
    alias: email_45preferences_45centerFqHXApW00HMeta?.alias || [],
    redirect: email_45preferences_45centerFqHXApW00HMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerFqHXApW00HMeta?.name ?? "resources-content-email-preferences-center___fr-ca",
    path: email_45preferences_45centerFqHXApW00HMeta?.path ?? "/fr-ca/resources/content/email-preferences-center",
    meta: email_45preferences_45centerFqHXApW00HMeta || {},
    alias: email_45preferences_45centerFqHXApW00HMeta?.alias || [],
    redirect: email_45preferences_45centerFqHXApW00HMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: _91slug_931UHpTYUayVMeta?.name ?? "resources-experts-slug___en-US",
    path: _91slug_931UHpTYUayVMeta?.path ?? "/resources/experts/:slug()",
    meta: _91slug_931UHpTYUayVMeta || {},
    alias: _91slug_931UHpTYUayVMeta?.alias || [],
    redirect: _91slug_931UHpTYUayVMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931UHpTYUayVMeta?.name ?? "resources-experts-slug___ca",
    path: _91slug_931UHpTYUayVMeta?.path ?? "/ca/resources/experts/:slug()",
    meta: _91slug_931UHpTYUayVMeta || {},
    alias: _91slug_931UHpTYUayVMeta?.alias || [],
    redirect: _91slug_931UHpTYUayVMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931UHpTYUayVMeta?.name ?? "resources-experts-slug___nl",
    path: _91slug_931UHpTYUayVMeta?.path ?? "/nl/resources/experts/:slug()",
    meta: _91slug_931UHpTYUayVMeta || {},
    alias: _91slug_931UHpTYUayVMeta?.alias || [],
    redirect: _91slug_931UHpTYUayVMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931UHpTYUayVMeta?.name ?? "resources-experts-slug___uk",
    path: _91slug_931UHpTYUayVMeta?.path ?? "/uk/resources/experts/:slug()",
    meta: _91slug_931UHpTYUayVMeta || {},
    alias: _91slug_931UHpTYUayVMeta?.alias || [],
    redirect: _91slug_931UHpTYUayVMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931UHpTYUayVMeta?.name ?? "resources-experts-slug___de",
    path: _91slug_931UHpTYUayVMeta?.path ?? "/de/resources/experts/:slug()",
    meta: _91slug_931UHpTYUayVMeta || {},
    alias: _91slug_931UHpTYUayVMeta?.alias || [],
    redirect: _91slug_931UHpTYUayVMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931UHpTYUayVMeta?.name ?? "resources-experts-slug___mx",
    path: _91slug_931UHpTYUayVMeta?.path ?? "/mx/resources/experts/:slug()",
    meta: _91slug_931UHpTYUayVMeta || {},
    alias: _91slug_931UHpTYUayVMeta?.alias || [],
    redirect: _91slug_931UHpTYUayVMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931UHpTYUayVMeta?.name ?? "resources-experts-slug___fr",
    path: _91slug_931UHpTYUayVMeta?.path ?? "/fr/resources/experts/:slug()",
    meta: _91slug_931UHpTYUayVMeta || {},
    alias: _91slug_931UHpTYUayVMeta?.alias || [],
    redirect: _91slug_931UHpTYUayVMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931UHpTYUayVMeta?.name ?? "resources-experts-slug___fr-ca",
    path: _91slug_931UHpTYUayVMeta?.path ?? "/fr-ca/resources/experts/:slug()",
    meta: _91slug_931UHpTYUayVMeta || {},
    alias: _91slug_931UHpTYUayVMeta?.alias || [],
    redirect: _91slug_931UHpTYUayVMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939pnqQFxEzEMeta?.name ?? "resources-experts-category-slug___en-US",
    path: _91slug_939pnqQFxEzEMeta?.path ?? "/resources/experts/category/:slug()",
    meta: _91slug_939pnqQFxEzEMeta || {},
    alias: _91slug_939pnqQFxEzEMeta?.alias || [],
    redirect: _91slug_939pnqQFxEzEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939pnqQFxEzEMeta?.name ?? "resources-experts-category-slug___ca",
    path: _91slug_939pnqQFxEzEMeta?.path ?? "/ca/resources/experts/category/:slug()",
    meta: _91slug_939pnqQFxEzEMeta || {},
    alias: _91slug_939pnqQFxEzEMeta?.alias || [],
    redirect: _91slug_939pnqQFxEzEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939pnqQFxEzEMeta?.name ?? "resources-experts-category-slug___nl",
    path: _91slug_939pnqQFxEzEMeta?.path ?? "/nl/resources/experts/category/:slug()",
    meta: _91slug_939pnqQFxEzEMeta || {},
    alias: _91slug_939pnqQFxEzEMeta?.alias || [],
    redirect: _91slug_939pnqQFxEzEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939pnqQFxEzEMeta?.name ?? "resources-experts-category-slug___uk",
    path: _91slug_939pnqQFxEzEMeta?.path ?? "/uk/resources/experts/category/:slug()",
    meta: _91slug_939pnqQFxEzEMeta || {},
    alias: _91slug_939pnqQFxEzEMeta?.alias || [],
    redirect: _91slug_939pnqQFxEzEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939pnqQFxEzEMeta?.name ?? "resources-experts-category-slug___de",
    path: _91slug_939pnqQFxEzEMeta?.path ?? "/de/resources/experts/category/:slug()",
    meta: _91slug_939pnqQFxEzEMeta || {},
    alias: _91slug_939pnqQFxEzEMeta?.alias || [],
    redirect: _91slug_939pnqQFxEzEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939pnqQFxEzEMeta?.name ?? "resources-experts-category-slug___mx",
    path: _91slug_939pnqQFxEzEMeta?.path ?? "/mx/resources/experts/category/:slug()",
    meta: _91slug_939pnqQFxEzEMeta || {},
    alias: _91slug_939pnqQFxEzEMeta?.alias || [],
    redirect: _91slug_939pnqQFxEzEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939pnqQFxEzEMeta?.name ?? "resources-experts-category-slug___fr",
    path: _91slug_939pnqQFxEzEMeta?.path ?? "/fr/resources/experts/category/:slug()",
    meta: _91slug_939pnqQFxEzEMeta || {},
    alias: _91slug_939pnqQFxEzEMeta?.alias || [],
    redirect: _91slug_939pnqQFxEzEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939pnqQFxEzEMeta?.name ?? "resources-experts-category-slug___fr-ca",
    path: _91slug_939pnqQFxEzEMeta?.path ?? "/fr-ca/resources/experts/category/:slug()",
    meta: _91slug_939pnqQFxEzEMeta || {},
    alias: _91slug_939pnqQFxEzEMeta?.alias || [],
    redirect: _91slug_939pnqQFxEzEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexqhZRk4GVjSMeta?.name ?? "resources-experts___en-US",
    path: indexqhZRk4GVjSMeta?.path ?? "/resources/experts",
    meta: indexqhZRk4GVjSMeta || {},
    alias: indexqhZRk4GVjSMeta?.alias || [],
    redirect: indexqhZRk4GVjSMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexqhZRk4GVjSMeta?.name ?? "resources-experts___ca",
    path: indexqhZRk4GVjSMeta?.path ?? "/ca/resources/experts",
    meta: indexqhZRk4GVjSMeta || {},
    alias: indexqhZRk4GVjSMeta?.alias || [],
    redirect: indexqhZRk4GVjSMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexqhZRk4GVjSMeta?.name ?? "resources-experts___nl",
    path: indexqhZRk4GVjSMeta?.path ?? "/nl/resources/experts",
    meta: indexqhZRk4GVjSMeta || {},
    alias: indexqhZRk4GVjSMeta?.alias || [],
    redirect: indexqhZRk4GVjSMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexqhZRk4GVjSMeta?.name ?? "resources-experts___uk",
    path: indexqhZRk4GVjSMeta?.path ?? "/uk/resources/experts",
    meta: indexqhZRk4GVjSMeta || {},
    alias: indexqhZRk4GVjSMeta?.alias || [],
    redirect: indexqhZRk4GVjSMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexqhZRk4GVjSMeta?.name ?? "resources-experts___de",
    path: indexqhZRk4GVjSMeta?.path ?? "/de/resources/experts",
    meta: indexqhZRk4GVjSMeta || {},
    alias: indexqhZRk4GVjSMeta?.alias || [],
    redirect: indexqhZRk4GVjSMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexqhZRk4GVjSMeta?.name ?? "resources-experts___mx",
    path: indexqhZRk4GVjSMeta?.path ?? "/mx/resources/experts",
    meta: indexqhZRk4GVjSMeta || {},
    alias: indexqhZRk4GVjSMeta?.alias || [],
    redirect: indexqhZRk4GVjSMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexqhZRk4GVjSMeta?.name ?? "resources-experts___fr",
    path: indexqhZRk4GVjSMeta?.path ?? "/fr/resources/experts",
    meta: indexqhZRk4GVjSMeta || {},
    alias: indexqhZRk4GVjSMeta?.alias || [],
    redirect: indexqhZRk4GVjSMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexqhZRk4GVjSMeta?.name ?? "resources-experts___fr-ca",
    path: indexqhZRk4GVjSMeta?.path ?? "/fr-ca/resources/experts",
    meta: indexqhZRk4GVjSMeta || {},
    alias: indexqhZRk4GVjSMeta?.alias || [],
    redirect: indexqhZRk4GVjSMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexfjxJKxBZ3QMeta?.name ?? "resources___en-US",
    path: indexfjxJKxBZ3QMeta?.path ?? "/resources",
    meta: indexfjxJKxBZ3QMeta || {},
    alias: indexfjxJKxBZ3QMeta?.alias || [],
    redirect: indexfjxJKxBZ3QMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexfjxJKxBZ3QMeta?.name ?? "resources___ca",
    path: indexfjxJKxBZ3QMeta?.path ?? "/ca/resources",
    meta: indexfjxJKxBZ3QMeta || {},
    alias: indexfjxJKxBZ3QMeta?.alias || [],
    redirect: indexfjxJKxBZ3QMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexfjxJKxBZ3QMeta?.name ?? "resources___nl",
    path: indexfjxJKxBZ3QMeta?.path ?? "/nl/resources",
    meta: indexfjxJKxBZ3QMeta || {},
    alias: indexfjxJKxBZ3QMeta?.alias || [],
    redirect: indexfjxJKxBZ3QMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexfjxJKxBZ3QMeta?.name ?? "resources___uk",
    path: indexfjxJKxBZ3QMeta?.path ?? "/uk/resources",
    meta: indexfjxJKxBZ3QMeta || {},
    alias: indexfjxJKxBZ3QMeta?.alias || [],
    redirect: indexfjxJKxBZ3QMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexfjxJKxBZ3QMeta?.name ?? "resources___de",
    path: indexfjxJKxBZ3QMeta?.path ?? "/de/resources",
    meta: indexfjxJKxBZ3QMeta || {},
    alias: indexfjxJKxBZ3QMeta?.alias || [],
    redirect: indexfjxJKxBZ3QMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexfjxJKxBZ3QMeta?.name ?? "resources___mx",
    path: indexfjxJKxBZ3QMeta?.path ?? "/mx/resources",
    meta: indexfjxJKxBZ3QMeta || {},
    alias: indexfjxJKxBZ3QMeta?.alias || [],
    redirect: indexfjxJKxBZ3QMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexfjxJKxBZ3QMeta?.name ?? "resources___fr",
    path: indexfjxJKxBZ3QMeta?.path ?? "/fr/resources",
    meta: indexfjxJKxBZ3QMeta || {},
    alias: indexfjxJKxBZ3QMeta?.alias || [],
    redirect: indexfjxJKxBZ3QMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexfjxJKxBZ3QMeta?.name ?? "resources___fr-ca",
    path: indexfjxJKxBZ3QMeta?.path ?? "/fr-ca/resources",
    meta: indexfjxJKxBZ3QMeta || {},
    alias: indexfjxJKxBZ3QMeta?.alias || [],
    redirect: indexfjxJKxBZ3QMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xgsBNWkv6RMeta?.name ?? "resources-marketplace-slug___en-US",
    path: _91slug_93xgsBNWkv6RMeta?.path ?? "/resources/marketplace/:slug()",
    meta: _91slug_93xgsBNWkv6RMeta || {},
    alias: _91slug_93xgsBNWkv6RMeta?.alias || [],
    redirect: _91slug_93xgsBNWkv6RMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xgsBNWkv6RMeta?.name ?? "resources-marketplace-slug___ca",
    path: _91slug_93xgsBNWkv6RMeta?.path ?? "/ca/resources/marketplace/:slug()",
    meta: _91slug_93xgsBNWkv6RMeta || {},
    alias: _91slug_93xgsBNWkv6RMeta?.alias || [],
    redirect: _91slug_93xgsBNWkv6RMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xgsBNWkv6RMeta?.name ?? "resources-marketplace-slug___nl",
    path: _91slug_93xgsBNWkv6RMeta?.path ?? "/nl/resources/marketplace/:slug()",
    meta: _91slug_93xgsBNWkv6RMeta || {},
    alias: _91slug_93xgsBNWkv6RMeta?.alias || [],
    redirect: _91slug_93xgsBNWkv6RMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xgsBNWkv6RMeta?.name ?? "resources-marketplace-slug___uk",
    path: _91slug_93xgsBNWkv6RMeta?.path ?? "/uk/resources/marketplace/:slug()",
    meta: _91slug_93xgsBNWkv6RMeta || {},
    alias: _91slug_93xgsBNWkv6RMeta?.alias || [],
    redirect: _91slug_93xgsBNWkv6RMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xgsBNWkv6RMeta?.name ?? "resources-marketplace-slug___de",
    path: _91slug_93xgsBNWkv6RMeta?.path ?? "/de/resources/marketplace/:slug()",
    meta: _91slug_93xgsBNWkv6RMeta || {},
    alias: _91slug_93xgsBNWkv6RMeta?.alias || [],
    redirect: _91slug_93xgsBNWkv6RMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xgsBNWkv6RMeta?.name ?? "resources-marketplace-slug___mx",
    path: _91slug_93xgsBNWkv6RMeta?.path ?? "/mx/resources/marketplace/:slug()",
    meta: _91slug_93xgsBNWkv6RMeta || {},
    alias: _91slug_93xgsBNWkv6RMeta?.alias || [],
    redirect: _91slug_93xgsBNWkv6RMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xgsBNWkv6RMeta?.name ?? "resources-marketplace-slug___fr",
    path: _91slug_93xgsBNWkv6RMeta?.path ?? "/fr/resources/marketplace/:slug()",
    meta: _91slug_93xgsBNWkv6RMeta || {},
    alias: _91slug_93xgsBNWkv6RMeta?.alias || [],
    redirect: _91slug_93xgsBNWkv6RMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xgsBNWkv6RMeta?.name ?? "resources-marketplace-slug___fr-ca",
    path: _91slug_93xgsBNWkv6RMeta?.path ?? "/fr-ca/resources/marketplace/:slug()",
    meta: _91slug_93xgsBNWkv6RMeta || {},
    alias: _91slug_93xgsBNWkv6RMeta?.alias || [],
    redirect: _91slug_93xgsBNWkv6RMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uVztDn1nFFMeta?.name ?? "resources-marketplace-category-slug___en-US",
    path: _91slug_93uVztDn1nFFMeta?.path ?? "/resources/marketplace/category/:slug()",
    meta: _91slug_93uVztDn1nFFMeta || {},
    alias: _91slug_93uVztDn1nFFMeta?.alias || [],
    redirect: _91slug_93uVztDn1nFFMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uVztDn1nFFMeta?.name ?? "resources-marketplace-category-slug___ca",
    path: _91slug_93uVztDn1nFFMeta?.path ?? "/ca/resources/marketplace/category/:slug()",
    meta: _91slug_93uVztDn1nFFMeta || {},
    alias: _91slug_93uVztDn1nFFMeta?.alias || [],
    redirect: _91slug_93uVztDn1nFFMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uVztDn1nFFMeta?.name ?? "resources-marketplace-category-slug___nl",
    path: _91slug_93uVztDn1nFFMeta?.path ?? "/nl/resources/marketplace/category/:slug()",
    meta: _91slug_93uVztDn1nFFMeta || {},
    alias: _91slug_93uVztDn1nFFMeta?.alias || [],
    redirect: _91slug_93uVztDn1nFFMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uVztDn1nFFMeta?.name ?? "resources-marketplace-category-slug___uk",
    path: _91slug_93uVztDn1nFFMeta?.path ?? "/uk/resources/marketplace/category/:slug()",
    meta: _91slug_93uVztDn1nFFMeta || {},
    alias: _91slug_93uVztDn1nFFMeta?.alias || [],
    redirect: _91slug_93uVztDn1nFFMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uVztDn1nFFMeta?.name ?? "resources-marketplace-category-slug___de",
    path: _91slug_93uVztDn1nFFMeta?.path ?? "/de/resources/marketplace/category/:slug()",
    meta: _91slug_93uVztDn1nFFMeta || {},
    alias: _91slug_93uVztDn1nFFMeta?.alias || [],
    redirect: _91slug_93uVztDn1nFFMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uVztDn1nFFMeta?.name ?? "resources-marketplace-category-slug___mx",
    path: _91slug_93uVztDn1nFFMeta?.path ?? "/mx/resources/marketplace/category/:slug()",
    meta: _91slug_93uVztDn1nFFMeta || {},
    alias: _91slug_93uVztDn1nFFMeta?.alias || [],
    redirect: _91slug_93uVztDn1nFFMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uVztDn1nFFMeta?.name ?? "resources-marketplace-category-slug___fr",
    path: _91slug_93uVztDn1nFFMeta?.path ?? "/fr/resources/marketplace/category/:slug()",
    meta: _91slug_93uVztDn1nFFMeta || {},
    alias: _91slug_93uVztDn1nFFMeta?.alias || [],
    redirect: _91slug_93uVztDn1nFFMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uVztDn1nFFMeta?.name ?? "resources-marketplace-category-slug___fr-ca",
    path: _91slug_93uVztDn1nFFMeta?.path ?? "/fr-ca/resources/marketplace/category/:slug()",
    meta: _91slug_93uVztDn1nFFMeta || {},
    alias: _91slug_93uVztDn1nFFMeta?.alias || [],
    redirect: _91slug_93uVztDn1nFFMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexei6QU6b6MnMeta?.name ?? "resources-marketplace___en-US",
    path: indexei6QU6b6MnMeta?.path ?? "/resources/marketplace",
    meta: indexei6QU6b6MnMeta || {},
    alias: indexei6QU6b6MnMeta?.alias || [],
    redirect: indexei6QU6b6MnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexei6QU6b6MnMeta?.name ?? "resources-marketplace___ca",
    path: indexei6QU6b6MnMeta?.path ?? "/ca/resources/marketplace",
    meta: indexei6QU6b6MnMeta || {},
    alias: indexei6QU6b6MnMeta?.alias || [],
    redirect: indexei6QU6b6MnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexei6QU6b6MnMeta?.name ?? "resources-marketplace___nl",
    path: indexei6QU6b6MnMeta?.path ?? "/nl/resources/marketplace",
    meta: indexei6QU6b6MnMeta || {},
    alias: indexei6QU6b6MnMeta?.alias || [],
    redirect: indexei6QU6b6MnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexei6QU6b6MnMeta?.name ?? "resources-marketplace___uk",
    path: indexei6QU6b6MnMeta?.path ?? "/uk/resources/marketplace",
    meta: indexei6QU6b6MnMeta || {},
    alias: indexei6QU6b6MnMeta?.alias || [],
    redirect: indexei6QU6b6MnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexei6QU6b6MnMeta?.name ?? "resources-marketplace___de",
    path: indexei6QU6b6MnMeta?.path ?? "/de/resources/marketplace",
    meta: indexei6QU6b6MnMeta || {},
    alias: indexei6QU6b6MnMeta?.alias || [],
    redirect: indexei6QU6b6MnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexei6QU6b6MnMeta?.name ?? "resources-marketplace___mx",
    path: indexei6QU6b6MnMeta?.path ?? "/mx/resources/marketplace",
    meta: indexei6QU6b6MnMeta || {},
    alias: indexei6QU6b6MnMeta?.alias || [],
    redirect: indexei6QU6b6MnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexei6QU6b6MnMeta?.name ?? "resources-marketplace___fr",
    path: indexei6QU6b6MnMeta?.path ?? "/fr/resources/marketplace",
    meta: indexei6QU6b6MnMeta || {},
    alias: indexei6QU6b6MnMeta?.alias || [],
    redirect: indexei6QU6b6MnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexei6QU6b6MnMeta?.name ?? "resources-marketplace___fr-ca",
    path: indexei6QU6b6MnMeta?.path ?? "/fr-ca/resources/marketplace",
    meta: indexei6QU6b6MnMeta || {},
    alias: indexei6QU6b6MnMeta?.alias || [],
    redirect: indexei6QU6b6MnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraD7I2kdpqSuMeta?.name ?? "resources-partner-programs-build-with-samsara___en-US",
    path: build_45with_45samsaraD7I2kdpqSuMeta?.path ?? "/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraD7I2kdpqSuMeta || {},
    alias: build_45with_45samsaraD7I2kdpqSuMeta?.alias || [],
    redirect: build_45with_45samsaraD7I2kdpqSuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraD7I2kdpqSuMeta?.name ?? "resources-partner-programs-build-with-samsara___ca",
    path: build_45with_45samsaraD7I2kdpqSuMeta?.path ?? "/ca/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraD7I2kdpqSuMeta || {},
    alias: build_45with_45samsaraD7I2kdpqSuMeta?.alias || [],
    redirect: build_45with_45samsaraD7I2kdpqSuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraD7I2kdpqSuMeta?.name ?? "resources-partner-programs-build-with-samsara___nl",
    path: build_45with_45samsaraD7I2kdpqSuMeta?.path ?? "/nl/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraD7I2kdpqSuMeta || {},
    alias: build_45with_45samsaraD7I2kdpqSuMeta?.alias || [],
    redirect: build_45with_45samsaraD7I2kdpqSuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraD7I2kdpqSuMeta?.name ?? "resources-partner-programs-build-with-samsara___uk",
    path: build_45with_45samsaraD7I2kdpqSuMeta?.path ?? "/uk/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraD7I2kdpqSuMeta || {},
    alias: build_45with_45samsaraD7I2kdpqSuMeta?.alias || [],
    redirect: build_45with_45samsaraD7I2kdpqSuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraD7I2kdpqSuMeta?.name ?? "resources-partner-programs-build-with-samsara___de",
    path: build_45with_45samsaraD7I2kdpqSuMeta?.path ?? "/de/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraD7I2kdpqSuMeta || {},
    alias: build_45with_45samsaraD7I2kdpqSuMeta?.alias || [],
    redirect: build_45with_45samsaraD7I2kdpqSuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraD7I2kdpqSuMeta?.name ?? "resources-partner-programs-build-with-samsara___mx",
    path: build_45with_45samsaraD7I2kdpqSuMeta?.path ?? "/mx/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraD7I2kdpqSuMeta || {},
    alias: build_45with_45samsaraD7I2kdpqSuMeta?.alias || [],
    redirect: build_45with_45samsaraD7I2kdpqSuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraD7I2kdpqSuMeta?.name ?? "resources-partner-programs-build-with-samsara___fr",
    path: build_45with_45samsaraD7I2kdpqSuMeta?.path ?? "/fr/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraD7I2kdpqSuMeta || {},
    alias: build_45with_45samsaraD7I2kdpqSuMeta?.alias || [],
    redirect: build_45with_45samsaraD7I2kdpqSuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraD7I2kdpqSuMeta?.name ?? "resources-partner-programs-build-with-samsara___fr-ca",
    path: build_45with_45samsaraD7I2kdpqSuMeta?.path ?? "/fr-ca/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraD7I2kdpqSuMeta || {},
    alias: build_45with_45samsaraD7I2kdpqSuMeta?.alias || [],
    redirect: build_45with_45samsaraD7I2kdpqSuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programs1g8wKIymUnMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___en-US",
    path: evolve_45insurance_45programs1g8wKIymUnMeta?.path ?? "/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programs1g8wKIymUnMeta || {},
    alias: evolve_45insurance_45programs1g8wKIymUnMeta?.alias || [],
    redirect: evolve_45insurance_45programs1g8wKIymUnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programs1g8wKIymUnMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___ca",
    path: evolve_45insurance_45programs1g8wKIymUnMeta?.path ?? "/ca/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programs1g8wKIymUnMeta || {},
    alias: evolve_45insurance_45programs1g8wKIymUnMeta?.alias || [],
    redirect: evolve_45insurance_45programs1g8wKIymUnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programs1g8wKIymUnMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___nl",
    path: evolve_45insurance_45programs1g8wKIymUnMeta?.path ?? "/nl/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programs1g8wKIymUnMeta || {},
    alias: evolve_45insurance_45programs1g8wKIymUnMeta?.alias || [],
    redirect: evolve_45insurance_45programs1g8wKIymUnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programs1g8wKIymUnMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___uk",
    path: evolve_45insurance_45programs1g8wKIymUnMeta?.path ?? "/uk/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programs1g8wKIymUnMeta || {},
    alias: evolve_45insurance_45programs1g8wKIymUnMeta?.alias || [],
    redirect: evolve_45insurance_45programs1g8wKIymUnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programs1g8wKIymUnMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___de",
    path: evolve_45insurance_45programs1g8wKIymUnMeta?.path ?? "/de/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programs1g8wKIymUnMeta || {},
    alias: evolve_45insurance_45programs1g8wKIymUnMeta?.alias || [],
    redirect: evolve_45insurance_45programs1g8wKIymUnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programs1g8wKIymUnMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___mx",
    path: evolve_45insurance_45programs1g8wKIymUnMeta?.path ?? "/mx/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programs1g8wKIymUnMeta || {},
    alias: evolve_45insurance_45programs1g8wKIymUnMeta?.alias || [],
    redirect: evolve_45insurance_45programs1g8wKIymUnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programs1g8wKIymUnMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___fr",
    path: evolve_45insurance_45programs1g8wKIymUnMeta?.path ?? "/fr/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programs1g8wKIymUnMeta || {},
    alias: evolve_45insurance_45programs1g8wKIymUnMeta?.alias || [],
    redirect: evolve_45insurance_45programs1g8wKIymUnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programs1g8wKIymUnMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___fr-ca",
    path: evolve_45insurance_45programs1g8wKIymUnMeta?.path ?? "/fr-ca/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programs1g8wKIymUnMeta || {},
    alias: evolve_45insurance_45programs1g8wKIymUnMeta?.alias || [],
    redirect: evolve_45insurance_45programs1g8wKIymUnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: indexMlqb27eXFGMeta?.name ?? "resources-partner-programs___en-US",
    path: indexMlqb27eXFGMeta?.path ?? "/resources/partner-programs",
    meta: indexMlqb27eXFGMeta || {},
    alias: indexMlqb27eXFGMeta?.alias || [],
    redirect: indexMlqb27eXFGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexMlqb27eXFGMeta?.name ?? "resources-partner-programs___ca",
    path: indexMlqb27eXFGMeta?.path ?? "/ca/resources/partner-programs",
    meta: indexMlqb27eXFGMeta || {},
    alias: indexMlqb27eXFGMeta?.alias || [],
    redirect: indexMlqb27eXFGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexMlqb27eXFGMeta?.name ?? "resources-partner-programs___nl",
    path: indexMlqb27eXFGMeta?.path ?? "/nl/resources/partner-programs",
    meta: indexMlqb27eXFGMeta || {},
    alias: indexMlqb27eXFGMeta?.alias || [],
    redirect: indexMlqb27eXFGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexMlqb27eXFGMeta?.name ?? "resources-partner-programs___uk",
    path: indexMlqb27eXFGMeta?.path ?? "/uk/resources/partner-programs",
    meta: indexMlqb27eXFGMeta || {},
    alias: indexMlqb27eXFGMeta?.alias || [],
    redirect: indexMlqb27eXFGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexMlqb27eXFGMeta?.name ?? "resources-partner-programs___de",
    path: indexMlqb27eXFGMeta?.path ?? "/de/resources/partner-programs",
    meta: indexMlqb27eXFGMeta || {},
    alias: indexMlqb27eXFGMeta?.alias || [],
    redirect: indexMlqb27eXFGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexMlqb27eXFGMeta?.name ?? "resources-partner-programs___mx",
    path: indexMlqb27eXFGMeta?.path ?? "/mx/resources/partner-programs",
    meta: indexMlqb27eXFGMeta || {},
    alias: indexMlqb27eXFGMeta?.alias || [],
    redirect: indexMlqb27eXFGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexMlqb27eXFGMeta?.name ?? "resources-partner-programs___fr",
    path: indexMlqb27eXFGMeta?.path ?? "/fr/resources/partner-programs",
    meta: indexMlqb27eXFGMeta || {},
    alias: indexMlqb27eXFGMeta?.alias || [],
    redirect: indexMlqb27eXFGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexMlqb27eXFGMeta?.name ?? "resources-partner-programs___fr-ca",
    path: indexMlqb27eXFGMeta?.path ?? "/fr-ca/resources/partner-programs",
    meta: indexMlqb27eXFGMeta || {},
    alias: indexMlqb27eXFGMeta?.alias || [],
    redirect: indexMlqb27eXFGMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesYGwMX0BaPuMeta?.name ?? "resources-partner-programs-provide-services___en-US",
    path: provide_45servicesYGwMX0BaPuMeta?.path ?? "/resources/partner-programs/provide-services",
    meta: provide_45servicesYGwMX0BaPuMeta || {},
    alias: provide_45servicesYGwMX0BaPuMeta?.alias || [],
    redirect: provide_45servicesYGwMX0BaPuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesYGwMX0BaPuMeta?.name ?? "resources-partner-programs-provide-services___ca",
    path: provide_45servicesYGwMX0BaPuMeta?.path ?? "/ca/resources/partner-programs/provide-services",
    meta: provide_45servicesYGwMX0BaPuMeta || {},
    alias: provide_45servicesYGwMX0BaPuMeta?.alias || [],
    redirect: provide_45servicesYGwMX0BaPuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesYGwMX0BaPuMeta?.name ?? "resources-partner-programs-provide-services___nl",
    path: provide_45servicesYGwMX0BaPuMeta?.path ?? "/nl/resources/partner-programs/provide-services",
    meta: provide_45servicesYGwMX0BaPuMeta || {},
    alias: provide_45servicesYGwMX0BaPuMeta?.alias || [],
    redirect: provide_45servicesYGwMX0BaPuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesYGwMX0BaPuMeta?.name ?? "resources-partner-programs-provide-services___uk",
    path: provide_45servicesYGwMX0BaPuMeta?.path ?? "/uk/resources/partner-programs/provide-services",
    meta: provide_45servicesYGwMX0BaPuMeta || {},
    alias: provide_45servicesYGwMX0BaPuMeta?.alias || [],
    redirect: provide_45servicesYGwMX0BaPuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesYGwMX0BaPuMeta?.name ?? "resources-partner-programs-provide-services___de",
    path: provide_45servicesYGwMX0BaPuMeta?.path ?? "/de/resources/partner-programs/provide-services",
    meta: provide_45servicesYGwMX0BaPuMeta || {},
    alias: provide_45servicesYGwMX0BaPuMeta?.alias || [],
    redirect: provide_45servicesYGwMX0BaPuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesYGwMX0BaPuMeta?.name ?? "resources-partner-programs-provide-services___mx",
    path: provide_45servicesYGwMX0BaPuMeta?.path ?? "/mx/resources/partner-programs/provide-services",
    meta: provide_45servicesYGwMX0BaPuMeta || {},
    alias: provide_45servicesYGwMX0BaPuMeta?.alias || [],
    redirect: provide_45servicesYGwMX0BaPuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesYGwMX0BaPuMeta?.name ?? "resources-partner-programs-provide-services___fr",
    path: provide_45servicesYGwMX0BaPuMeta?.path ?? "/fr/resources/partner-programs/provide-services",
    meta: provide_45servicesYGwMX0BaPuMeta || {},
    alias: provide_45servicesYGwMX0BaPuMeta?.alias || [],
    redirect: provide_45servicesYGwMX0BaPuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesYGwMX0BaPuMeta?.name ?? "resources-partner-programs-provide-services___fr-ca",
    path: provide_45servicesYGwMX0BaPuMeta?.path ?? "/fr-ca/resources/partner-programs/provide-services",
    meta: provide_45servicesYGwMX0BaPuMeta || {},
    alias: provide_45servicesYGwMX0BaPuMeta?.alias || [],
    redirect: provide_45servicesYGwMX0BaPuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara0MVRrcLfDAMeta?.name ?? "resources-partner-programs-sell-samsara___en-US",
    path: sell_45samsara0MVRrcLfDAMeta?.path ?? "/resources/partner-programs/sell-samsara",
    meta: sell_45samsara0MVRrcLfDAMeta || {},
    alias: sell_45samsara0MVRrcLfDAMeta?.alias || [],
    redirect: sell_45samsara0MVRrcLfDAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara0MVRrcLfDAMeta?.name ?? "resources-partner-programs-sell-samsara___ca",
    path: sell_45samsara0MVRrcLfDAMeta?.path ?? "/ca/resources/partner-programs/sell-samsara",
    meta: sell_45samsara0MVRrcLfDAMeta || {},
    alias: sell_45samsara0MVRrcLfDAMeta?.alias || [],
    redirect: sell_45samsara0MVRrcLfDAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara0MVRrcLfDAMeta?.name ?? "resources-partner-programs-sell-samsara___nl",
    path: sell_45samsara0MVRrcLfDAMeta?.path ?? "/nl/resources/partner-programs/sell-samsara",
    meta: sell_45samsara0MVRrcLfDAMeta || {},
    alias: sell_45samsara0MVRrcLfDAMeta?.alias || [],
    redirect: sell_45samsara0MVRrcLfDAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara0MVRrcLfDAMeta?.name ?? "resources-partner-programs-sell-samsara___uk",
    path: sell_45samsara0MVRrcLfDAMeta?.path ?? "/uk/resources/partner-programs/sell-samsara",
    meta: sell_45samsara0MVRrcLfDAMeta || {},
    alias: sell_45samsara0MVRrcLfDAMeta?.alias || [],
    redirect: sell_45samsara0MVRrcLfDAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara0MVRrcLfDAMeta?.name ?? "resources-partner-programs-sell-samsara___de",
    path: sell_45samsara0MVRrcLfDAMeta?.path ?? "/de/resources/partner-programs/sell-samsara",
    meta: sell_45samsara0MVRrcLfDAMeta || {},
    alias: sell_45samsara0MVRrcLfDAMeta?.alias || [],
    redirect: sell_45samsara0MVRrcLfDAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara0MVRrcLfDAMeta?.name ?? "resources-partner-programs-sell-samsara___mx",
    path: sell_45samsara0MVRrcLfDAMeta?.path ?? "/mx/resources/partner-programs/sell-samsara",
    meta: sell_45samsara0MVRrcLfDAMeta || {},
    alias: sell_45samsara0MVRrcLfDAMeta?.alias || [],
    redirect: sell_45samsara0MVRrcLfDAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara0MVRrcLfDAMeta?.name ?? "resources-partner-programs-sell-samsara___fr",
    path: sell_45samsara0MVRrcLfDAMeta?.path ?? "/fr/resources/partner-programs/sell-samsara",
    meta: sell_45samsara0MVRrcLfDAMeta || {},
    alias: sell_45samsara0MVRrcLfDAMeta?.alias || [],
    redirect: sell_45samsara0MVRrcLfDAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara0MVRrcLfDAMeta?.name ?? "resources-partner-programs-sell-samsara___fr-ca",
    path: sell_45samsara0MVRrcLfDAMeta?.path ?? "/fr-ca/resources/partner-programs/sell-samsara",
    meta: sell_45samsara0MVRrcLfDAMeta || {},
    alias: sell_45samsara0MVRrcLfDAMeta?.alias || [],
    redirect: sell_45samsara0MVRrcLfDAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: tourmryfGhhcPIMeta?.name ?? "resources-tour___en-US",
    path: tourmryfGhhcPIMeta?.path ?? "/resources/tour",
    meta: tourmryfGhhcPIMeta || {},
    alias: tourmryfGhhcPIMeta?.alias || [],
    redirect: tourmryfGhhcPIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourmryfGhhcPIMeta?.name ?? "resources-tour___ca",
    path: tourmryfGhhcPIMeta?.path ?? "/ca/resources/tour",
    meta: tourmryfGhhcPIMeta || {},
    alias: tourmryfGhhcPIMeta?.alias || [],
    redirect: tourmryfGhhcPIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourmryfGhhcPIMeta?.name ?? "resources-tour___nl",
    path: tourmryfGhhcPIMeta?.path ?? "/nl/resources/tour",
    meta: tourmryfGhhcPIMeta || {},
    alias: tourmryfGhhcPIMeta?.alias || [],
    redirect: tourmryfGhhcPIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourmryfGhhcPIMeta?.name ?? "resources-tour___uk",
    path: tourmryfGhhcPIMeta?.path ?? "/uk/resources/tour",
    meta: tourmryfGhhcPIMeta || {},
    alias: tourmryfGhhcPIMeta?.alias || [],
    redirect: tourmryfGhhcPIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourmryfGhhcPIMeta?.name ?? "resources-tour___de",
    path: tourmryfGhhcPIMeta?.path ?? "/de/resources/tour",
    meta: tourmryfGhhcPIMeta || {},
    alias: tourmryfGhhcPIMeta?.alias || [],
    redirect: tourmryfGhhcPIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourmryfGhhcPIMeta?.name ?? "resources-tour___mx",
    path: tourmryfGhhcPIMeta?.path ?? "/mx/resources/tour",
    meta: tourmryfGhhcPIMeta || {},
    alias: tourmryfGhhcPIMeta?.alias || [],
    redirect: tourmryfGhhcPIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourmryfGhhcPIMeta?.name ?? "resources-tour___fr",
    path: tourmryfGhhcPIMeta?.path ?? "/fr/resources/tour",
    meta: tourmryfGhhcPIMeta || {},
    alias: tourmryfGhhcPIMeta?.alias || [],
    redirect: tourmryfGhhcPIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourmryfGhhcPIMeta?.name ?? "resources-tour___fr-ca",
    path: tourmryfGhhcPIMeta?.path ?? "/fr-ca/resources/tour",
    meta: tourmryfGhhcPIMeta || {},
    alias: tourmryfGhhcPIMeta?.alias || [],
    redirect: tourmryfGhhcPIMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: videosGi8FrJmFbkMeta?.name ?? "resources-videos___en-US",
    path: videosGi8FrJmFbkMeta?.path ?? "/resources/videos",
    meta: videosGi8FrJmFbkMeta || {},
    alias: videosGi8FrJmFbkMeta?.alias || [],
    redirect: videosGi8FrJmFbkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosGi8FrJmFbkMeta?.name ?? "resources-videos___ca",
    path: videosGi8FrJmFbkMeta?.path ?? "/ca/resources/videos",
    meta: videosGi8FrJmFbkMeta || {},
    alias: videosGi8FrJmFbkMeta?.alias || [],
    redirect: videosGi8FrJmFbkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosGi8FrJmFbkMeta?.name ?? "resources-videos___nl",
    path: videosGi8FrJmFbkMeta?.path ?? "/nl/resources/videos",
    meta: videosGi8FrJmFbkMeta || {},
    alias: videosGi8FrJmFbkMeta?.alias || [],
    redirect: videosGi8FrJmFbkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosGi8FrJmFbkMeta?.name ?? "resources-videos___uk",
    path: videosGi8FrJmFbkMeta?.path ?? "/uk/resources/videos",
    meta: videosGi8FrJmFbkMeta || {},
    alias: videosGi8FrJmFbkMeta?.alias || [],
    redirect: videosGi8FrJmFbkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosGi8FrJmFbkMeta?.name ?? "resources-videos___de",
    path: videosGi8FrJmFbkMeta?.path ?? "/de/resources/videos",
    meta: videosGi8FrJmFbkMeta || {},
    alias: videosGi8FrJmFbkMeta?.alias || [],
    redirect: videosGi8FrJmFbkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosGi8FrJmFbkMeta?.name ?? "resources-videos___mx",
    path: videosGi8FrJmFbkMeta?.path ?? "/mx/resources/videos",
    meta: videosGi8FrJmFbkMeta || {},
    alias: videosGi8FrJmFbkMeta?.alias || [],
    redirect: videosGi8FrJmFbkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosGi8FrJmFbkMeta?.name ?? "resources-videos___fr",
    path: videosGi8FrJmFbkMeta?.path ?? "/fr/resources/videos",
    meta: videosGi8FrJmFbkMeta || {},
    alias: videosGi8FrJmFbkMeta?.alias || [],
    redirect: videosGi8FrJmFbkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosGi8FrJmFbkMeta?.name ?? "resources-videos___fr-ca",
    path: videosGi8FrJmFbkMeta?.path ?? "/fr-ca/resources/videos",
    meta: videosGi8FrJmFbkMeta || {},
    alias: videosGi8FrJmFbkMeta?.alias || [],
    redirect: videosGi8FrJmFbkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: indexTs5wEBHdCzMeta?.name ?? "roi-calculator___en-US",
    path: indexTs5wEBHdCzMeta?.path ?? "/roi-calculator",
    meta: indexTs5wEBHdCzMeta || {},
    alias: indexTs5wEBHdCzMeta?.alias || [],
    redirect: indexTs5wEBHdCzMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexTs5wEBHdCzMeta?.name ?? "roi-calculator___ca",
    path: indexTs5wEBHdCzMeta?.path ?? "/ca/roi-calculator",
    meta: indexTs5wEBHdCzMeta || {},
    alias: indexTs5wEBHdCzMeta?.alias || [],
    redirect: indexTs5wEBHdCzMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexTs5wEBHdCzMeta?.name ?? "roi-calculator___nl",
    path: indexTs5wEBHdCzMeta?.path ?? "/nl/roi-calculator",
    meta: indexTs5wEBHdCzMeta || {},
    alias: indexTs5wEBHdCzMeta?.alias || [],
    redirect: indexTs5wEBHdCzMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexTs5wEBHdCzMeta?.name ?? "roi-calculator___uk",
    path: indexTs5wEBHdCzMeta?.path ?? "/uk/roi-calculator",
    meta: indexTs5wEBHdCzMeta || {},
    alias: indexTs5wEBHdCzMeta?.alias || [],
    redirect: indexTs5wEBHdCzMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexTs5wEBHdCzMeta?.name ?? "roi-calculator___de",
    path: indexTs5wEBHdCzMeta?.path ?? "/de/roi-calculator",
    meta: indexTs5wEBHdCzMeta || {},
    alias: indexTs5wEBHdCzMeta?.alias || [],
    redirect: indexTs5wEBHdCzMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexTs5wEBHdCzMeta?.name ?? "roi-calculator___mx",
    path: indexTs5wEBHdCzMeta?.path ?? "/mx/roi-calculator",
    meta: indexTs5wEBHdCzMeta || {},
    alias: indexTs5wEBHdCzMeta?.alias || [],
    redirect: indexTs5wEBHdCzMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexTs5wEBHdCzMeta?.name ?? "roi-calculator___fr",
    path: indexTs5wEBHdCzMeta?.path ?? "/fr/roi-calculator",
    meta: indexTs5wEBHdCzMeta || {},
    alias: indexTs5wEBHdCzMeta?.alias || [],
    redirect: indexTs5wEBHdCzMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexTs5wEBHdCzMeta?.name ?? "roi-calculator___fr-ca",
    path: indexTs5wEBHdCzMeta?.path ?? "/fr-ca/roi-calculator",
    meta: indexTs5wEBHdCzMeta || {},
    alias: indexTs5wEBHdCzMeta?.alias || [],
    redirect: indexTs5wEBHdCzMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: samsara_45ventures9plSWaLnUPMeta?.name ?? "samsara-ventures___en-US",
    path: samsara_45ventures9plSWaLnUPMeta?.path ?? "/samsara-ventures",
    meta: samsara_45ventures9plSWaLnUPMeta || {},
    alias: samsara_45ventures9plSWaLnUPMeta?.alias || [],
    redirect: samsara_45ventures9plSWaLnUPMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45ventures9plSWaLnUPMeta?.name ?? "samsara-ventures___ca",
    path: samsara_45ventures9plSWaLnUPMeta?.path ?? "/ca/samsara-ventures",
    meta: samsara_45ventures9plSWaLnUPMeta || {},
    alias: samsara_45ventures9plSWaLnUPMeta?.alias || [],
    redirect: samsara_45ventures9plSWaLnUPMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45ventures9plSWaLnUPMeta?.name ?? "samsara-ventures___nl",
    path: samsara_45ventures9plSWaLnUPMeta?.path ?? "/nl/samsara-ventures",
    meta: samsara_45ventures9plSWaLnUPMeta || {},
    alias: samsara_45ventures9plSWaLnUPMeta?.alias || [],
    redirect: samsara_45ventures9plSWaLnUPMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45ventures9plSWaLnUPMeta?.name ?? "samsara-ventures___uk",
    path: samsara_45ventures9plSWaLnUPMeta?.path ?? "/uk/samsara-ventures",
    meta: samsara_45ventures9plSWaLnUPMeta || {},
    alias: samsara_45ventures9plSWaLnUPMeta?.alias || [],
    redirect: samsara_45ventures9plSWaLnUPMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45ventures9plSWaLnUPMeta?.name ?? "samsara-ventures___de",
    path: samsara_45ventures9plSWaLnUPMeta?.path ?? "/de/samsara-ventures",
    meta: samsara_45ventures9plSWaLnUPMeta || {},
    alias: samsara_45ventures9plSWaLnUPMeta?.alias || [],
    redirect: samsara_45ventures9plSWaLnUPMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45ventures9plSWaLnUPMeta?.name ?? "samsara-ventures___mx",
    path: samsara_45ventures9plSWaLnUPMeta?.path ?? "/mx/samsara-ventures",
    meta: samsara_45ventures9plSWaLnUPMeta || {},
    alias: samsara_45ventures9plSWaLnUPMeta?.alias || [],
    redirect: samsara_45ventures9plSWaLnUPMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45ventures9plSWaLnUPMeta?.name ?? "samsara-ventures___fr",
    path: samsara_45ventures9plSWaLnUPMeta?.path ?? "/fr/samsara-ventures",
    meta: samsara_45ventures9plSWaLnUPMeta || {},
    alias: samsara_45ventures9plSWaLnUPMeta?.alias || [],
    redirect: samsara_45ventures9plSWaLnUPMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45ventures9plSWaLnUPMeta?.name ?? "samsara-ventures___fr-ca",
    path: samsara_45ventures9plSWaLnUPMeta?.path ?? "/fr-ca/samsara-ventures",
    meta: samsara_45ventures9plSWaLnUPMeta || {},
    alias: samsara_45ventures9plSWaLnUPMeta?.alias || [],
    redirect: samsara_45ventures9plSWaLnUPMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: security_45summitVC0OttwmGUMeta?.name ?? "security-summit___en-US",
    path: security_45summitVC0OttwmGUMeta?.path ?? "/security-summit",
    meta: security_45summitVC0OttwmGUMeta || {},
    alias: security_45summitVC0OttwmGUMeta?.alias || [],
    redirect: security_45summitVC0OttwmGUMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitVC0OttwmGUMeta?.name ?? "security-summit___ca",
    path: security_45summitVC0OttwmGUMeta?.path ?? "/ca/security-summit",
    meta: security_45summitVC0OttwmGUMeta || {},
    alias: security_45summitVC0OttwmGUMeta?.alias || [],
    redirect: security_45summitVC0OttwmGUMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitVC0OttwmGUMeta?.name ?? "security-summit___nl",
    path: security_45summitVC0OttwmGUMeta?.path ?? "/nl/security-summit",
    meta: security_45summitVC0OttwmGUMeta || {},
    alias: security_45summitVC0OttwmGUMeta?.alias || [],
    redirect: security_45summitVC0OttwmGUMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitVC0OttwmGUMeta?.name ?? "security-summit___uk",
    path: security_45summitVC0OttwmGUMeta?.path ?? "/uk/security-summit",
    meta: security_45summitVC0OttwmGUMeta || {},
    alias: security_45summitVC0OttwmGUMeta?.alias || [],
    redirect: security_45summitVC0OttwmGUMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitVC0OttwmGUMeta?.name ?? "security-summit___de",
    path: security_45summitVC0OttwmGUMeta?.path ?? "/de/security-summit",
    meta: security_45summitVC0OttwmGUMeta || {},
    alias: security_45summitVC0OttwmGUMeta?.alias || [],
    redirect: security_45summitVC0OttwmGUMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitVC0OttwmGUMeta?.name ?? "security-summit___mx",
    path: security_45summitVC0OttwmGUMeta?.path ?? "/mx/security-summit",
    meta: security_45summitVC0OttwmGUMeta || {},
    alias: security_45summitVC0OttwmGUMeta?.alias || [],
    redirect: security_45summitVC0OttwmGUMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitVC0OttwmGUMeta?.name ?? "security-summit___fr",
    path: security_45summitVC0OttwmGUMeta?.path ?? "/fr/security-summit",
    meta: security_45summitVC0OttwmGUMeta || {},
    alias: security_45summitVC0OttwmGUMeta?.alias || [],
    redirect: security_45summitVC0OttwmGUMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitVC0OttwmGUMeta?.name ?? "security-summit___fr-ca",
    path: security_45summitVC0OttwmGUMeta?.path ?? "/fr-ca/security-summit",
    meta: security_45summitVC0OttwmGUMeta || {},
    alias: security_45summitVC0OttwmGUMeta?.alias || [],
    redirect: security_45summitVC0OttwmGUMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QHxO0HjFGoMeta?.name ?? "solutions-prefix-slug___en-US",
    path: _91slug_93QHxO0HjFGoMeta?.path ?? "/solutions/:prefix()/:slug()",
    meta: _91slug_93QHxO0HjFGoMeta || {},
    alias: _91slug_93QHxO0HjFGoMeta?.alias || [],
    redirect: _91slug_93QHxO0HjFGoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QHxO0HjFGoMeta?.name ?? "solutions-prefix-slug___ca",
    path: _91slug_93QHxO0HjFGoMeta?.path ?? "/ca/solutions/:prefix()/:slug()",
    meta: _91slug_93QHxO0HjFGoMeta || {},
    alias: _91slug_93QHxO0HjFGoMeta?.alias || [],
    redirect: _91slug_93QHxO0HjFGoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QHxO0HjFGoMeta?.name ?? "solutions-prefix-slug___nl",
    path: _91slug_93QHxO0HjFGoMeta?.path ?? "/nl/solutions/:prefix()/:slug()",
    meta: _91slug_93QHxO0HjFGoMeta || {},
    alias: _91slug_93QHxO0HjFGoMeta?.alias || [],
    redirect: _91slug_93QHxO0HjFGoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QHxO0HjFGoMeta?.name ?? "solutions-prefix-slug___uk",
    path: _91slug_93QHxO0HjFGoMeta?.path ?? "/uk/solutions/:prefix()/:slug()",
    meta: _91slug_93QHxO0HjFGoMeta || {},
    alias: _91slug_93QHxO0HjFGoMeta?.alias || [],
    redirect: _91slug_93QHxO0HjFGoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QHxO0HjFGoMeta?.name ?? "solutions-prefix-slug___de",
    path: _91slug_93QHxO0HjFGoMeta?.path ?? "/de/solutions/:prefix()/:slug()",
    meta: _91slug_93QHxO0HjFGoMeta || {},
    alias: _91slug_93QHxO0HjFGoMeta?.alias || [],
    redirect: _91slug_93QHxO0HjFGoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QHxO0HjFGoMeta?.name ?? "solutions-prefix-slug___mx",
    path: _91slug_93QHxO0HjFGoMeta?.path ?? "/mx/solutions/:prefix()/:slug()",
    meta: _91slug_93QHxO0HjFGoMeta || {},
    alias: _91slug_93QHxO0HjFGoMeta?.alias || [],
    redirect: _91slug_93QHxO0HjFGoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QHxO0HjFGoMeta?.name ?? "solutions-prefix-slug___fr",
    path: _91slug_93QHxO0HjFGoMeta?.path ?? "/fr/solutions/:prefix()/:slug()",
    meta: _91slug_93QHxO0HjFGoMeta || {},
    alias: _91slug_93QHxO0HjFGoMeta?.alias || [],
    redirect: _91slug_93QHxO0HjFGoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QHxO0HjFGoMeta?.name ?? "solutions-prefix-slug___fr-ca",
    path: _91slug_93QHxO0HjFGoMeta?.path ?? "/fr-ca/solutions/:prefix()/:slug()",
    meta: _91slug_93QHxO0HjFGoMeta || {},
    alias: _91slug_93QHxO0HjFGoMeta?.alias || [],
    redirect: _91slug_93QHxO0HjFGoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937EEqtx3UjXMeta?.name ?? "solutions-slug___en-US",
    path: _91slug_937EEqtx3UjXMeta?.path ?? "/solutions/:slug()",
    meta: _91slug_937EEqtx3UjXMeta || {},
    alias: _91slug_937EEqtx3UjXMeta?.alias || [],
    redirect: _91slug_937EEqtx3UjXMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937EEqtx3UjXMeta?.name ?? "solutions-slug___ca",
    path: _91slug_937EEqtx3UjXMeta?.path ?? "/ca/solutions/:slug()",
    meta: _91slug_937EEqtx3UjXMeta || {},
    alias: _91slug_937EEqtx3UjXMeta?.alias || [],
    redirect: _91slug_937EEqtx3UjXMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937EEqtx3UjXMeta?.name ?? "solutions-slug___nl",
    path: _91slug_937EEqtx3UjXMeta?.path ?? "/nl/solutions/:slug()",
    meta: _91slug_937EEqtx3UjXMeta || {},
    alias: _91slug_937EEqtx3UjXMeta?.alias || [],
    redirect: _91slug_937EEqtx3UjXMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937EEqtx3UjXMeta?.name ?? "solutions-slug___uk",
    path: _91slug_937EEqtx3UjXMeta?.path ?? "/uk/solutions/:slug()",
    meta: _91slug_937EEqtx3UjXMeta || {},
    alias: _91slug_937EEqtx3UjXMeta?.alias || [],
    redirect: _91slug_937EEqtx3UjXMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937EEqtx3UjXMeta?.name ?? "solutions-slug___de",
    path: _91slug_937EEqtx3UjXMeta?.path ?? "/de/solutions/:slug()",
    meta: _91slug_937EEqtx3UjXMeta || {},
    alias: _91slug_937EEqtx3UjXMeta?.alias || [],
    redirect: _91slug_937EEqtx3UjXMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937EEqtx3UjXMeta?.name ?? "solutions-slug___mx",
    path: _91slug_937EEqtx3UjXMeta?.path ?? "/mx/solutions/:slug()",
    meta: _91slug_937EEqtx3UjXMeta || {},
    alias: _91slug_937EEqtx3UjXMeta?.alias || [],
    redirect: _91slug_937EEqtx3UjXMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937EEqtx3UjXMeta?.name ?? "solutions-slug___fr",
    path: _91slug_937EEqtx3UjXMeta?.path ?? "/fr/solutions/:slug()",
    meta: _91slug_937EEqtx3UjXMeta || {},
    alias: _91slug_937EEqtx3UjXMeta?.alias || [],
    redirect: _91slug_937EEqtx3UjXMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937EEqtx3UjXMeta?.name ?? "solutions-slug___fr-ca",
    path: _91slug_937EEqtx3UjXMeta?.path ?? "/fr-ca/solutions/:slug()",
    meta: _91slug_937EEqtx3UjXMeta || {},
    alias: _91slug_937EEqtx3UjXMeta?.alias || [],
    redirect: _91slug_937EEqtx3UjXMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexV97nXepeF7Meta?.name ?? "solutions___en-US",
    path: indexV97nXepeF7Meta?.path ?? "/solutions",
    meta: indexV97nXepeF7Meta || {},
    alias: indexV97nXepeF7Meta?.alias || [],
    redirect: indexV97nXepeF7Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexV97nXepeF7Meta?.name ?? "solutions___ca",
    path: indexV97nXepeF7Meta?.path ?? "/ca/solutions",
    meta: indexV97nXepeF7Meta || {},
    alias: indexV97nXepeF7Meta?.alias || [],
    redirect: indexV97nXepeF7Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexV97nXepeF7Meta?.name ?? "solutions___nl",
    path: indexV97nXepeF7Meta?.path ?? "/nl/solutions",
    meta: indexV97nXepeF7Meta || {},
    alias: indexV97nXepeF7Meta?.alias || [],
    redirect: indexV97nXepeF7Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexV97nXepeF7Meta?.name ?? "solutions___uk",
    path: indexV97nXepeF7Meta?.path ?? "/uk/solutions",
    meta: indexV97nXepeF7Meta || {},
    alias: indexV97nXepeF7Meta?.alias || [],
    redirect: indexV97nXepeF7Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexV97nXepeF7Meta?.name ?? "solutions___de",
    path: indexV97nXepeF7Meta?.path ?? "/de/solutions",
    meta: indexV97nXepeF7Meta || {},
    alias: indexV97nXepeF7Meta?.alias || [],
    redirect: indexV97nXepeF7Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexV97nXepeF7Meta?.name ?? "solutions___mx",
    path: indexV97nXepeF7Meta?.path ?? "/mx/solutions",
    meta: indexV97nXepeF7Meta || {},
    alias: indexV97nXepeF7Meta?.alias || [],
    redirect: indexV97nXepeF7Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexV97nXepeF7Meta?.name ?? "solutions___fr",
    path: indexV97nXepeF7Meta?.path ?? "/fr/solutions",
    meta: indexV97nXepeF7Meta || {},
    alias: indexV97nXepeF7Meta?.alias || [],
    redirect: indexV97nXepeF7Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexV97nXepeF7Meta?.name ?? "solutions___fr-ca",
    path: indexV97nXepeF7Meta?.path ?? "/fr-ca/solutions",
    meta: indexV97nXepeF7Meta || {},
    alias: indexV97nXepeF7Meta?.alias || [],
    redirect: indexV97nXepeF7Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: securitylaORJlCGROMeta?.name ?? "solutions-security___en-US",
    path: securitylaORJlCGROMeta?.path ?? "/solutions/security",
    meta: securitylaORJlCGROMeta || {},
    alias: securitylaORJlCGROMeta?.alias || [],
    redirect: securitylaORJlCGROMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securitylaORJlCGROMeta?.name ?? "solutions-security___ca",
    path: securitylaORJlCGROMeta?.path ?? "/ca/solutions/security",
    meta: securitylaORJlCGROMeta || {},
    alias: securitylaORJlCGROMeta?.alias || [],
    redirect: securitylaORJlCGROMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securitylaORJlCGROMeta?.name ?? "solutions-security___nl",
    path: securitylaORJlCGROMeta?.path ?? "/nl/solutions/security",
    meta: securitylaORJlCGROMeta || {},
    alias: securitylaORJlCGROMeta?.alias || [],
    redirect: securitylaORJlCGROMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securitylaORJlCGROMeta?.name ?? "solutions-security___uk",
    path: securitylaORJlCGROMeta?.path ?? "/uk/solutions/security",
    meta: securitylaORJlCGROMeta || {},
    alias: securitylaORJlCGROMeta?.alias || [],
    redirect: securitylaORJlCGROMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securitylaORJlCGROMeta?.name ?? "solutions-security___de",
    path: securitylaORJlCGROMeta?.path ?? "/de/solutions/security",
    meta: securitylaORJlCGROMeta || {},
    alias: securitylaORJlCGROMeta?.alias || [],
    redirect: securitylaORJlCGROMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securitylaORJlCGROMeta?.name ?? "solutions-security___mx",
    path: securitylaORJlCGROMeta?.path ?? "/mx/soluciones/seguridad-patrimonial",
    meta: securitylaORJlCGROMeta || {},
    alias: securitylaORJlCGROMeta?.alias || [],
    redirect: securitylaORJlCGROMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securitylaORJlCGROMeta?.name ?? "solutions-security___fr",
    path: securitylaORJlCGROMeta?.path ?? "/fr/solutions/security",
    meta: securitylaORJlCGROMeta || {},
    alias: securitylaORJlCGROMeta?.alias || [],
    redirect: securitylaORJlCGROMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securitylaORJlCGROMeta?.name ?? "solutions-security___fr-ca",
    path: securitylaORJlCGROMeta?.path ?? "/fr-ca/solutions/security",
    meta: securitylaORJlCGROMeta || {},
    alias: securitylaORJlCGROMeta?.alias || [],
    redirect: securitylaORJlCGROMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: spaceVtDdKepVcEMeta?.name ?? "space___en-US",
    path: spaceVtDdKepVcEMeta?.path ?? "/space",
    meta: spaceVtDdKepVcEMeta || {},
    alias: spaceVtDdKepVcEMeta?.alias || [],
    redirect: spaceVtDdKepVcEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceVtDdKepVcEMeta?.name ?? "space___ca",
    path: spaceVtDdKepVcEMeta?.path ?? "/ca/space",
    meta: spaceVtDdKepVcEMeta || {},
    alias: spaceVtDdKepVcEMeta?.alias || [],
    redirect: spaceVtDdKepVcEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceVtDdKepVcEMeta?.name ?? "space___nl",
    path: spaceVtDdKepVcEMeta?.path ?? "/nl/space",
    meta: spaceVtDdKepVcEMeta || {},
    alias: spaceVtDdKepVcEMeta?.alias || [],
    redirect: spaceVtDdKepVcEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceVtDdKepVcEMeta?.name ?? "space___uk",
    path: spaceVtDdKepVcEMeta?.path ?? "/uk/space",
    meta: spaceVtDdKepVcEMeta || {},
    alias: spaceVtDdKepVcEMeta?.alias || [],
    redirect: spaceVtDdKepVcEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceVtDdKepVcEMeta?.name ?? "space___de",
    path: spaceVtDdKepVcEMeta?.path ?? "/de/space",
    meta: spaceVtDdKepVcEMeta || {},
    alias: spaceVtDdKepVcEMeta?.alias || [],
    redirect: spaceVtDdKepVcEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceVtDdKepVcEMeta?.name ?? "space___mx",
    path: spaceVtDdKepVcEMeta?.path ?? "/mx/space",
    meta: spaceVtDdKepVcEMeta || {},
    alias: spaceVtDdKepVcEMeta?.alias || [],
    redirect: spaceVtDdKepVcEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceVtDdKepVcEMeta?.name ?? "space___fr",
    path: spaceVtDdKepVcEMeta?.path ?? "/fr/space",
    meta: spaceVtDdKepVcEMeta || {},
    alias: spaceVtDdKepVcEMeta?.alias || [],
    redirect: spaceVtDdKepVcEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceVtDdKepVcEMeta?.name ?? "space___fr-ca",
    path: spaceVtDdKepVcEMeta?.path ?? "/fr-ca/space",
    meta: spaceVtDdKepVcEMeta || {},
    alias: spaceVtDdKepVcEMeta?.alias || [],
    redirect: spaceVtDdKepVcEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spark2kQlUUBMNnMeta?.name ?? "spark___en-US",
    path: spark2kQlUUBMNnMeta?.path ?? "/spark",
    meta: spark2kQlUUBMNnMeta || {},
    alias: spark2kQlUUBMNnMeta?.alias || [],
    redirect: spark2kQlUUBMNnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: spark2kQlUUBMNnMeta?.name ?? "spark___ca",
    path: spark2kQlUUBMNnMeta?.path ?? "/ca/spark",
    meta: spark2kQlUUBMNnMeta || {},
    alias: spark2kQlUUBMNnMeta?.alias || [],
    redirect: spark2kQlUUBMNnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: spark2kQlUUBMNnMeta?.name ?? "spark___nl",
    path: spark2kQlUUBMNnMeta?.path ?? "/nl/spark",
    meta: spark2kQlUUBMNnMeta || {},
    alias: spark2kQlUUBMNnMeta?.alias || [],
    redirect: spark2kQlUUBMNnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: spark2kQlUUBMNnMeta?.name ?? "spark___uk",
    path: spark2kQlUUBMNnMeta?.path ?? "/uk/spark",
    meta: spark2kQlUUBMNnMeta || {},
    alias: spark2kQlUUBMNnMeta?.alias || [],
    redirect: spark2kQlUUBMNnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: spark2kQlUUBMNnMeta?.name ?? "spark___de",
    path: spark2kQlUUBMNnMeta?.path ?? "/de/spark",
    meta: spark2kQlUUBMNnMeta || {},
    alias: spark2kQlUUBMNnMeta?.alias || [],
    redirect: spark2kQlUUBMNnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: spark2kQlUUBMNnMeta?.name ?? "spark___mx",
    path: spark2kQlUUBMNnMeta?.path ?? "/mx/spark",
    meta: spark2kQlUUBMNnMeta || {},
    alias: spark2kQlUUBMNnMeta?.alias || [],
    redirect: spark2kQlUUBMNnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: spark2kQlUUBMNnMeta?.name ?? "spark___fr",
    path: spark2kQlUUBMNnMeta?.path ?? "/fr/spark",
    meta: spark2kQlUUBMNnMeta || {},
    alias: spark2kQlUUBMNnMeta?.alias || [],
    redirect: spark2kQlUUBMNnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: spark2kQlUUBMNnMeta?.name ?? "spark___fr-ca",
    path: spark2kQlUUBMNnMeta?.path ?? "/fr-ca/spark",
    meta: spark2kQlUUBMNnMeta || {},
    alias: spark2kQlUUBMNnMeta?.alias || [],
    redirect: spark2kQlUUBMNnMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R3oXhyQPlZMeta?.name ?? "styles-slug___en-US",
    path: _91slug_93R3oXhyQPlZMeta?.path ?? "/styles/:slug()",
    meta: _91slug_93R3oXhyQPlZMeta || {},
    alias: _91slug_93R3oXhyQPlZMeta?.alias || [],
    redirect: _91slug_93R3oXhyQPlZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R3oXhyQPlZMeta?.name ?? "styles-slug___ca",
    path: _91slug_93R3oXhyQPlZMeta?.path ?? "/ca/styles/:slug()",
    meta: _91slug_93R3oXhyQPlZMeta || {},
    alias: _91slug_93R3oXhyQPlZMeta?.alias || [],
    redirect: _91slug_93R3oXhyQPlZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R3oXhyQPlZMeta?.name ?? "styles-slug___nl",
    path: _91slug_93R3oXhyQPlZMeta?.path ?? "/nl/styles/:slug()",
    meta: _91slug_93R3oXhyQPlZMeta || {},
    alias: _91slug_93R3oXhyQPlZMeta?.alias || [],
    redirect: _91slug_93R3oXhyQPlZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R3oXhyQPlZMeta?.name ?? "styles-slug___uk",
    path: _91slug_93R3oXhyQPlZMeta?.path ?? "/uk/styles/:slug()",
    meta: _91slug_93R3oXhyQPlZMeta || {},
    alias: _91slug_93R3oXhyQPlZMeta?.alias || [],
    redirect: _91slug_93R3oXhyQPlZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R3oXhyQPlZMeta?.name ?? "styles-slug___de",
    path: _91slug_93R3oXhyQPlZMeta?.path ?? "/de/styles/:slug()",
    meta: _91slug_93R3oXhyQPlZMeta || {},
    alias: _91slug_93R3oXhyQPlZMeta?.alias || [],
    redirect: _91slug_93R3oXhyQPlZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R3oXhyQPlZMeta?.name ?? "styles-slug___mx",
    path: _91slug_93R3oXhyQPlZMeta?.path ?? "/mx/styles/:slug()",
    meta: _91slug_93R3oXhyQPlZMeta || {},
    alias: _91slug_93R3oXhyQPlZMeta?.alias || [],
    redirect: _91slug_93R3oXhyQPlZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R3oXhyQPlZMeta?.name ?? "styles-slug___fr",
    path: _91slug_93R3oXhyQPlZMeta?.path ?? "/fr/styles/:slug()",
    meta: _91slug_93R3oXhyQPlZMeta || {},
    alias: _91slug_93R3oXhyQPlZMeta?.alias || [],
    redirect: _91slug_93R3oXhyQPlZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R3oXhyQPlZMeta?.name ?? "styles-slug___fr-ca",
    path: _91slug_93R3oXhyQPlZMeta?.path ?? "/fr-ca/styles/:slug()",
    meta: _91slug_93R3oXhyQPlZMeta || {},
    alias: _91slug_93R3oXhyQPlZMeta?.alias || [],
    redirect: _91slug_93R3oXhyQPlZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jmeC6ZuH7CMeta?.name ?? "support-prefix-slug___en-US",
    path: _91slug_93jmeC6ZuH7CMeta?.path ?? "/support/:prefix()/:slug()",
    meta: _91slug_93jmeC6ZuH7CMeta || {},
    alias: _91slug_93jmeC6ZuH7CMeta?.alias || [],
    redirect: _91slug_93jmeC6ZuH7CMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jmeC6ZuH7CMeta?.name ?? "support-prefix-slug___ca",
    path: _91slug_93jmeC6ZuH7CMeta?.path ?? "/ca/support/:prefix()/:slug()",
    meta: _91slug_93jmeC6ZuH7CMeta || {},
    alias: _91slug_93jmeC6ZuH7CMeta?.alias || [],
    redirect: _91slug_93jmeC6ZuH7CMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jmeC6ZuH7CMeta?.name ?? "support-prefix-slug___nl",
    path: _91slug_93jmeC6ZuH7CMeta?.path ?? "/nl/support/:prefix()/:slug()",
    meta: _91slug_93jmeC6ZuH7CMeta || {},
    alias: _91slug_93jmeC6ZuH7CMeta?.alias || [],
    redirect: _91slug_93jmeC6ZuH7CMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jmeC6ZuH7CMeta?.name ?? "support-prefix-slug___uk",
    path: _91slug_93jmeC6ZuH7CMeta?.path ?? "/uk/support/:prefix()/:slug()",
    meta: _91slug_93jmeC6ZuH7CMeta || {},
    alias: _91slug_93jmeC6ZuH7CMeta?.alias || [],
    redirect: _91slug_93jmeC6ZuH7CMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jmeC6ZuH7CMeta?.name ?? "support-prefix-slug___de",
    path: _91slug_93jmeC6ZuH7CMeta?.path ?? "/de/support/:prefix()/:slug()",
    meta: _91slug_93jmeC6ZuH7CMeta || {},
    alias: _91slug_93jmeC6ZuH7CMeta?.alias || [],
    redirect: _91slug_93jmeC6ZuH7CMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jmeC6ZuH7CMeta?.name ?? "support-prefix-slug___mx",
    path: _91slug_93jmeC6ZuH7CMeta?.path ?? "/mx/support/:prefix()/:slug()",
    meta: _91slug_93jmeC6ZuH7CMeta || {},
    alias: _91slug_93jmeC6ZuH7CMeta?.alias || [],
    redirect: _91slug_93jmeC6ZuH7CMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jmeC6ZuH7CMeta?.name ?? "support-prefix-slug___fr",
    path: _91slug_93jmeC6ZuH7CMeta?.path ?? "/fr/support/:prefix()/:slug()",
    meta: _91slug_93jmeC6ZuH7CMeta || {},
    alias: _91slug_93jmeC6ZuH7CMeta?.alias || [],
    redirect: _91slug_93jmeC6ZuH7CMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jmeC6ZuH7CMeta?.name ?? "support-prefix-slug___fr-ca",
    path: _91slug_93jmeC6ZuH7CMeta?.path ?? "/fr-ca/support/:prefix()/:slug()",
    meta: _91slug_93jmeC6ZuH7CMeta || {},
    alias: _91slug_93jmeC6ZuH7CMeta?.alias || [],
    redirect: _91slug_93jmeC6ZuH7CMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fLvU98zWRhMeta?.name ?? "support-slug___en-US",
    path: _91slug_93fLvU98zWRhMeta?.path ?? "/support/:slug()",
    meta: _91slug_93fLvU98zWRhMeta || {},
    alias: _91slug_93fLvU98zWRhMeta?.alias || [],
    redirect: _91slug_93fLvU98zWRhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fLvU98zWRhMeta?.name ?? "support-slug___ca",
    path: _91slug_93fLvU98zWRhMeta?.path ?? "/ca/support/:slug()",
    meta: _91slug_93fLvU98zWRhMeta || {},
    alias: _91slug_93fLvU98zWRhMeta?.alias || [],
    redirect: _91slug_93fLvU98zWRhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fLvU98zWRhMeta?.name ?? "support-slug___nl",
    path: _91slug_93fLvU98zWRhMeta?.path ?? "/nl/support/:slug()",
    meta: _91slug_93fLvU98zWRhMeta || {},
    alias: _91slug_93fLvU98zWRhMeta?.alias || [],
    redirect: _91slug_93fLvU98zWRhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fLvU98zWRhMeta?.name ?? "support-slug___uk",
    path: _91slug_93fLvU98zWRhMeta?.path ?? "/uk/support/:slug()",
    meta: _91slug_93fLvU98zWRhMeta || {},
    alias: _91slug_93fLvU98zWRhMeta?.alias || [],
    redirect: _91slug_93fLvU98zWRhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fLvU98zWRhMeta?.name ?? "support-slug___de",
    path: _91slug_93fLvU98zWRhMeta?.path ?? "/de/support/:slug()",
    meta: _91slug_93fLvU98zWRhMeta || {},
    alias: _91slug_93fLvU98zWRhMeta?.alias || [],
    redirect: _91slug_93fLvU98zWRhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fLvU98zWRhMeta?.name ?? "support-slug___mx",
    path: _91slug_93fLvU98zWRhMeta?.path ?? "/mx/support/:slug()",
    meta: _91slug_93fLvU98zWRhMeta || {},
    alias: _91slug_93fLvU98zWRhMeta?.alias || [],
    redirect: _91slug_93fLvU98zWRhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fLvU98zWRhMeta?.name ?? "support-slug___fr",
    path: _91slug_93fLvU98zWRhMeta?.path ?? "/fr/support/:slug()",
    meta: _91slug_93fLvU98zWRhMeta || {},
    alias: _91slug_93fLvU98zWRhMeta?.alias || [],
    redirect: _91slug_93fLvU98zWRhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fLvU98zWRhMeta?.name ?? "support-slug___fr-ca",
    path: _91slug_93fLvU98zWRhMeta?.path ?? "/fr-ca/support/:slug()",
    meta: _91slug_93fLvU98zWRhMeta || {},
    alias: _91slug_93fLvU98zWRhMeta?.alias || [],
    redirect: _91slug_93fLvU98zWRhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policywIVn9QhX5rMeta?.name ?? "support-apps-privacy-policy___en-US",
    path: apps_45privacy_45policywIVn9QhX5rMeta?.path ?? "/support/apps-privacy-policy",
    meta: apps_45privacy_45policywIVn9QhX5rMeta || {},
    alias: apps_45privacy_45policywIVn9QhX5rMeta?.alias || [],
    redirect: apps_45privacy_45policywIVn9QhX5rMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policywIVn9QhX5rMeta?.name ?? "support-apps-privacy-policy___ca",
    path: apps_45privacy_45policywIVn9QhX5rMeta?.path ?? "/ca/support/apps-privacy-policy",
    meta: apps_45privacy_45policywIVn9QhX5rMeta || {},
    alias: apps_45privacy_45policywIVn9QhX5rMeta?.alias || [],
    redirect: apps_45privacy_45policywIVn9QhX5rMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policywIVn9QhX5rMeta?.name ?? "support-apps-privacy-policy___nl",
    path: apps_45privacy_45policywIVn9QhX5rMeta?.path ?? "/nl/support/apps-privacy-policy",
    meta: apps_45privacy_45policywIVn9QhX5rMeta || {},
    alias: apps_45privacy_45policywIVn9QhX5rMeta?.alias || [],
    redirect: apps_45privacy_45policywIVn9QhX5rMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policywIVn9QhX5rMeta?.name ?? "support-apps-privacy-policy___uk",
    path: apps_45privacy_45policywIVn9QhX5rMeta?.path ?? "/uk/support/apps-privacy-policy",
    meta: apps_45privacy_45policywIVn9QhX5rMeta || {},
    alias: apps_45privacy_45policywIVn9QhX5rMeta?.alias || [],
    redirect: apps_45privacy_45policywIVn9QhX5rMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policywIVn9QhX5rMeta?.name ?? "support-apps-privacy-policy___de",
    path: apps_45privacy_45policywIVn9QhX5rMeta?.path ?? "/de/support/apps-privacy-policy",
    meta: apps_45privacy_45policywIVn9QhX5rMeta || {},
    alias: apps_45privacy_45policywIVn9QhX5rMeta?.alias || [],
    redirect: apps_45privacy_45policywIVn9QhX5rMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policywIVn9QhX5rMeta?.name ?? "support-apps-privacy-policy___mx",
    path: apps_45privacy_45policywIVn9QhX5rMeta?.path ?? "/mx/support/apps-privacy-policy",
    meta: apps_45privacy_45policywIVn9QhX5rMeta || {},
    alias: apps_45privacy_45policywIVn9QhX5rMeta?.alias || [],
    redirect: apps_45privacy_45policywIVn9QhX5rMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policywIVn9QhX5rMeta?.name ?? "support-apps-privacy-policy___fr",
    path: apps_45privacy_45policywIVn9QhX5rMeta?.path ?? "/fr/support/apps-privacy-policy",
    meta: apps_45privacy_45policywIVn9QhX5rMeta || {},
    alias: apps_45privacy_45policywIVn9QhX5rMeta?.alias || [],
    redirect: apps_45privacy_45policywIVn9QhX5rMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policywIVn9QhX5rMeta?.name ?? "support-apps-privacy-policy___fr-ca",
    path: apps_45privacy_45policywIVn9QhX5rMeta?.path ?? "/fr-ca/support/apps-privacy-policy",
    meta: apps_45privacy_45policywIVn9QhX5rMeta || {},
    alias: apps_45privacy_45policywIVn9QhX5rMeta?.alias || [],
    redirect: apps_45privacy_45policywIVn9QhX5rMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqgo4IN36HC7Meta?.name ?? "support-customer-referrals-faq___en-US",
    path: customer_45referrals_45faqgo4IN36HC7Meta?.path ?? "/support/customer-referrals-faq",
    meta: customer_45referrals_45faqgo4IN36HC7Meta || {},
    alias: customer_45referrals_45faqgo4IN36HC7Meta?.alias || [],
    redirect: customer_45referrals_45faqgo4IN36HC7Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqgo4IN36HC7Meta?.name ?? "support-customer-referrals-faq___ca",
    path: customer_45referrals_45faqgo4IN36HC7Meta?.path ?? "/ca/support/customer-referrals-faq",
    meta: customer_45referrals_45faqgo4IN36HC7Meta || {},
    alias: customer_45referrals_45faqgo4IN36HC7Meta?.alias || [],
    redirect: customer_45referrals_45faqgo4IN36HC7Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqgo4IN36HC7Meta?.name ?? "support-customer-referrals-faq___nl",
    path: customer_45referrals_45faqgo4IN36HC7Meta?.path ?? "/nl/support/customer-referrals-faq",
    meta: customer_45referrals_45faqgo4IN36HC7Meta || {},
    alias: customer_45referrals_45faqgo4IN36HC7Meta?.alias || [],
    redirect: customer_45referrals_45faqgo4IN36HC7Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqgo4IN36HC7Meta?.name ?? "support-customer-referrals-faq___uk",
    path: customer_45referrals_45faqgo4IN36HC7Meta?.path ?? "/uk/support/customer-referrals-faq",
    meta: customer_45referrals_45faqgo4IN36HC7Meta || {},
    alias: customer_45referrals_45faqgo4IN36HC7Meta?.alias || [],
    redirect: customer_45referrals_45faqgo4IN36HC7Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqgo4IN36HC7Meta?.name ?? "support-customer-referrals-faq___de",
    path: customer_45referrals_45faqgo4IN36HC7Meta?.path ?? "/de/support/customer-referrals-faq",
    meta: customer_45referrals_45faqgo4IN36HC7Meta || {},
    alias: customer_45referrals_45faqgo4IN36HC7Meta?.alias || [],
    redirect: customer_45referrals_45faqgo4IN36HC7Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqgo4IN36HC7Meta?.name ?? "support-customer-referrals-faq___mx",
    path: customer_45referrals_45faqgo4IN36HC7Meta?.path ?? "/mx/support/customer-referrals-faq",
    meta: customer_45referrals_45faqgo4IN36HC7Meta || {},
    alias: customer_45referrals_45faqgo4IN36HC7Meta?.alias || [],
    redirect: customer_45referrals_45faqgo4IN36HC7Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqgo4IN36HC7Meta?.name ?? "support-customer-referrals-faq___fr",
    path: customer_45referrals_45faqgo4IN36HC7Meta?.path ?? "/fr/support/customer-referrals-faq",
    meta: customer_45referrals_45faqgo4IN36HC7Meta || {},
    alias: customer_45referrals_45faqgo4IN36HC7Meta?.alias || [],
    redirect: customer_45referrals_45faqgo4IN36HC7Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqgo4IN36HC7Meta?.name ?? "support-customer-referrals-faq___fr-ca",
    path: customer_45referrals_45faqgo4IN36HC7Meta?.path ?? "/fr-ca/support/customer-referrals-faq",
    meta: customer_45referrals_45faqgo4IN36HC7Meta || {},
    alias: customer_45referrals_45faqgo4IN36HC7Meta?.alias || [],
    redirect: customer_45referrals_45faqgo4IN36HC7Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishu7oOu2TlMaMeta?.name ?? "support-driver-training-spanish___en-US",
    path: driver_45training_45spanishu7oOu2TlMaMeta?.path ?? "/support/driver-training-spanish",
    meta: driver_45training_45spanishu7oOu2TlMaMeta || {},
    alias: driver_45training_45spanishu7oOu2TlMaMeta?.alias || [],
    redirect: driver_45training_45spanishu7oOu2TlMaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishu7oOu2TlMaMeta?.name ?? "support-driver-training-spanish___ca",
    path: driver_45training_45spanishu7oOu2TlMaMeta?.path ?? "/ca/support/driver-training-spanish",
    meta: driver_45training_45spanishu7oOu2TlMaMeta || {},
    alias: driver_45training_45spanishu7oOu2TlMaMeta?.alias || [],
    redirect: driver_45training_45spanishu7oOu2TlMaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishu7oOu2TlMaMeta?.name ?? "support-driver-training-spanish___nl",
    path: driver_45training_45spanishu7oOu2TlMaMeta?.path ?? "/nl/support/driver-training-spanish",
    meta: driver_45training_45spanishu7oOu2TlMaMeta || {},
    alias: driver_45training_45spanishu7oOu2TlMaMeta?.alias || [],
    redirect: driver_45training_45spanishu7oOu2TlMaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishu7oOu2TlMaMeta?.name ?? "support-driver-training-spanish___uk",
    path: driver_45training_45spanishu7oOu2TlMaMeta?.path ?? "/uk/support/driver-training-spanish",
    meta: driver_45training_45spanishu7oOu2TlMaMeta || {},
    alias: driver_45training_45spanishu7oOu2TlMaMeta?.alias || [],
    redirect: driver_45training_45spanishu7oOu2TlMaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishu7oOu2TlMaMeta?.name ?? "support-driver-training-spanish___de",
    path: driver_45training_45spanishu7oOu2TlMaMeta?.path ?? "/de/support/driver-training-spanish",
    meta: driver_45training_45spanishu7oOu2TlMaMeta || {},
    alias: driver_45training_45spanishu7oOu2TlMaMeta?.alias || [],
    redirect: driver_45training_45spanishu7oOu2TlMaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishu7oOu2TlMaMeta?.name ?? "support-driver-training-spanish___mx",
    path: driver_45training_45spanishu7oOu2TlMaMeta?.path ?? "/mx/support/driver-training-spanish",
    meta: driver_45training_45spanishu7oOu2TlMaMeta || {},
    alias: driver_45training_45spanishu7oOu2TlMaMeta?.alias || [],
    redirect: driver_45training_45spanishu7oOu2TlMaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishu7oOu2TlMaMeta?.name ?? "support-driver-training-spanish___fr",
    path: driver_45training_45spanishu7oOu2TlMaMeta?.path ?? "/fr/support/driver-training-spanish",
    meta: driver_45training_45spanishu7oOu2TlMaMeta || {},
    alias: driver_45training_45spanishu7oOu2TlMaMeta?.alias || [],
    redirect: driver_45training_45spanishu7oOu2TlMaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishu7oOu2TlMaMeta?.name ?? "support-driver-training-spanish___fr-ca",
    path: driver_45training_45spanishu7oOu2TlMaMeta?.path ?? "/fr-ca/support/driver-training-spanish",
    meta: driver_45training_45spanishu7oOu2TlMaMeta || {},
    alias: driver_45training_45spanishu7oOu2TlMaMeta?.alias || [],
    redirect: driver_45training_45spanishu7oOu2TlMaMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingC0pZMUwy1sMeta?.name ?? "support-driver-training___en-US",
    path: driver_45trainingC0pZMUwy1sMeta?.path ?? "/support/driver-training",
    meta: driver_45trainingC0pZMUwy1sMeta || {},
    alias: driver_45trainingC0pZMUwy1sMeta?.alias || [],
    redirect: driver_45trainingC0pZMUwy1sMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingC0pZMUwy1sMeta?.name ?? "support-driver-training___ca",
    path: driver_45trainingC0pZMUwy1sMeta?.path ?? "/ca/support/driver-training",
    meta: driver_45trainingC0pZMUwy1sMeta || {},
    alias: driver_45trainingC0pZMUwy1sMeta?.alias || [],
    redirect: driver_45trainingC0pZMUwy1sMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingC0pZMUwy1sMeta?.name ?? "support-driver-training___nl",
    path: driver_45trainingC0pZMUwy1sMeta?.path ?? "/nl/support/driver-training",
    meta: driver_45trainingC0pZMUwy1sMeta || {},
    alias: driver_45trainingC0pZMUwy1sMeta?.alias || [],
    redirect: driver_45trainingC0pZMUwy1sMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingC0pZMUwy1sMeta?.name ?? "support-driver-training___uk",
    path: driver_45trainingC0pZMUwy1sMeta?.path ?? "/uk/support/driver-training",
    meta: driver_45trainingC0pZMUwy1sMeta || {},
    alias: driver_45trainingC0pZMUwy1sMeta?.alias || [],
    redirect: driver_45trainingC0pZMUwy1sMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingC0pZMUwy1sMeta?.name ?? "support-driver-training___de",
    path: driver_45trainingC0pZMUwy1sMeta?.path ?? "/de/support/driver-training",
    meta: driver_45trainingC0pZMUwy1sMeta || {},
    alias: driver_45trainingC0pZMUwy1sMeta?.alias || [],
    redirect: driver_45trainingC0pZMUwy1sMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingC0pZMUwy1sMeta?.name ?? "support-driver-training___mx",
    path: driver_45trainingC0pZMUwy1sMeta?.path ?? "/mx/support/driver-training",
    meta: driver_45trainingC0pZMUwy1sMeta || {},
    alias: driver_45trainingC0pZMUwy1sMeta?.alias || [],
    redirect: driver_45trainingC0pZMUwy1sMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingC0pZMUwy1sMeta?.name ?? "support-driver-training___fr",
    path: driver_45trainingC0pZMUwy1sMeta?.path ?? "/fr/support/driver-training",
    meta: driver_45trainingC0pZMUwy1sMeta || {},
    alias: driver_45trainingC0pZMUwy1sMeta?.alias || [],
    redirect: driver_45trainingC0pZMUwy1sMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingC0pZMUwy1sMeta?.name ?? "support-driver-training___fr-ca",
    path: driver_45trainingC0pZMUwy1sMeta?.path ?? "/fr-ca/support/driver-training",
    meta: driver_45trainingC0pZMUwy1sMeta || {},
    alias: driver_45trainingC0pZMUwy1sMeta?.alias || [],
    redirect: driver_45trainingC0pZMUwy1sMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: indexziXagsi3wqMeta?.name ?? "support___en-US",
    path: indexziXagsi3wqMeta?.path ?? "/support",
    meta: indexziXagsi3wqMeta || {},
    alias: indexziXagsi3wqMeta?.alias || [],
    redirect: indexziXagsi3wqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexziXagsi3wqMeta?.name ?? "support___ca",
    path: indexziXagsi3wqMeta?.path ?? "/ca/support",
    meta: indexziXagsi3wqMeta || {},
    alias: indexziXagsi3wqMeta?.alias || [],
    redirect: indexziXagsi3wqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexziXagsi3wqMeta?.name ?? "support___nl",
    path: indexziXagsi3wqMeta?.path ?? "/nl/support",
    meta: indexziXagsi3wqMeta || {},
    alias: indexziXagsi3wqMeta?.alias || [],
    redirect: indexziXagsi3wqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexziXagsi3wqMeta?.name ?? "support___uk",
    path: indexziXagsi3wqMeta?.path ?? "/uk/support",
    meta: indexziXagsi3wqMeta || {},
    alias: indexziXagsi3wqMeta?.alias || [],
    redirect: indexziXagsi3wqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexziXagsi3wqMeta?.name ?? "support___de",
    path: indexziXagsi3wqMeta?.path ?? "/de/support",
    meta: indexziXagsi3wqMeta || {},
    alias: indexziXagsi3wqMeta?.alias || [],
    redirect: indexziXagsi3wqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexziXagsi3wqMeta?.name ?? "support___mx",
    path: indexziXagsi3wqMeta?.path ?? "/mx/support",
    meta: indexziXagsi3wqMeta || {},
    alias: indexziXagsi3wqMeta?.alias || [],
    redirect: indexziXagsi3wqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexziXagsi3wqMeta?.name ?? "support___fr",
    path: indexziXagsi3wqMeta?.path ?? "/fr/support",
    meta: indexziXagsi3wqMeta || {},
    alias: indexziXagsi3wqMeta?.alias || [],
    redirect: indexziXagsi3wqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexziXagsi3wqMeta?.name ?? "support___fr-ca",
    path: indexziXagsi3wqMeta?.path ?? "/fr-ca/support",
    meta: indexziXagsi3wqMeta || {},
    alias: indexziXagsi3wqMeta?.alias || [],
    redirect: indexziXagsi3wqMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: industrialUhM70xIwxbMeta?.name ?? "support-industrial___en-US",
    path: industrialUhM70xIwxbMeta?.path ?? "/support/industrial",
    meta: industrialUhM70xIwxbMeta || {},
    alias: industrialUhM70xIwxbMeta?.alias || [],
    redirect: industrialUhM70xIwxbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialUhM70xIwxbMeta?.name ?? "support-industrial___ca",
    path: industrialUhM70xIwxbMeta?.path ?? "/ca/support/industrial",
    meta: industrialUhM70xIwxbMeta || {},
    alias: industrialUhM70xIwxbMeta?.alias || [],
    redirect: industrialUhM70xIwxbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialUhM70xIwxbMeta?.name ?? "support-industrial___nl",
    path: industrialUhM70xIwxbMeta?.path ?? "/nl/support/industrial",
    meta: industrialUhM70xIwxbMeta || {},
    alias: industrialUhM70xIwxbMeta?.alias || [],
    redirect: industrialUhM70xIwxbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialUhM70xIwxbMeta?.name ?? "support-industrial___uk",
    path: industrialUhM70xIwxbMeta?.path ?? "/uk/support/industrial",
    meta: industrialUhM70xIwxbMeta || {},
    alias: industrialUhM70xIwxbMeta?.alias || [],
    redirect: industrialUhM70xIwxbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialUhM70xIwxbMeta?.name ?? "support-industrial___de",
    path: industrialUhM70xIwxbMeta?.path ?? "/de/support/industrial",
    meta: industrialUhM70xIwxbMeta || {},
    alias: industrialUhM70xIwxbMeta?.alias || [],
    redirect: industrialUhM70xIwxbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialUhM70xIwxbMeta?.name ?? "support-industrial___mx",
    path: industrialUhM70xIwxbMeta?.path ?? "/mx/support/industrial",
    meta: industrialUhM70xIwxbMeta || {},
    alias: industrialUhM70xIwxbMeta?.alias || [],
    redirect: industrialUhM70xIwxbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialUhM70xIwxbMeta?.name ?? "support-industrial___fr",
    path: industrialUhM70xIwxbMeta?.path ?? "/fr/support/industrial",
    meta: industrialUhM70xIwxbMeta || {},
    alias: industrialUhM70xIwxbMeta?.alias || [],
    redirect: industrialUhM70xIwxbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialUhM70xIwxbMeta?.name ?? "support-industrial___fr-ca",
    path: industrialUhM70xIwxbMeta?.path ?? "/fr-ca/support/industrial",
    meta: industrialUhM70xIwxbMeta || {},
    alias: industrialUhM70xIwxbMeta?.alias || [],
    redirect: industrialUhM70xIwxbMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement5Fg9KyEb2fMeta?.name ?? "support-modern-slavery-statement___en-US",
    path: modern_45slavery_45statement5Fg9KyEb2fMeta?.path ?? "/support/modern-slavery-statement",
    meta: modern_45slavery_45statement5Fg9KyEb2fMeta || {},
    alias: modern_45slavery_45statement5Fg9KyEb2fMeta?.alias || [],
    redirect: modern_45slavery_45statement5Fg9KyEb2fMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement5Fg9KyEb2fMeta?.name ?? "support-modern-slavery-statement___ca",
    path: modern_45slavery_45statement5Fg9KyEb2fMeta?.path ?? "/ca/support/modern-slavery-statement",
    meta: modern_45slavery_45statement5Fg9KyEb2fMeta || {},
    alias: modern_45slavery_45statement5Fg9KyEb2fMeta?.alias || [],
    redirect: modern_45slavery_45statement5Fg9KyEb2fMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement5Fg9KyEb2fMeta?.name ?? "support-modern-slavery-statement___nl",
    path: modern_45slavery_45statement5Fg9KyEb2fMeta?.path ?? "/nl/support/modern-slavery-statement",
    meta: modern_45slavery_45statement5Fg9KyEb2fMeta || {},
    alias: modern_45slavery_45statement5Fg9KyEb2fMeta?.alias || [],
    redirect: modern_45slavery_45statement5Fg9KyEb2fMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement5Fg9KyEb2fMeta?.name ?? "support-modern-slavery-statement___uk",
    path: modern_45slavery_45statement5Fg9KyEb2fMeta?.path ?? "/uk/support/modern-slavery-statement",
    meta: modern_45slavery_45statement5Fg9KyEb2fMeta || {},
    alias: modern_45slavery_45statement5Fg9KyEb2fMeta?.alias || [],
    redirect: modern_45slavery_45statement5Fg9KyEb2fMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement5Fg9KyEb2fMeta?.name ?? "support-modern-slavery-statement___de",
    path: modern_45slavery_45statement5Fg9KyEb2fMeta?.path ?? "/de/support/modern-slavery-statement",
    meta: modern_45slavery_45statement5Fg9KyEb2fMeta || {},
    alias: modern_45slavery_45statement5Fg9KyEb2fMeta?.alias || [],
    redirect: modern_45slavery_45statement5Fg9KyEb2fMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement5Fg9KyEb2fMeta?.name ?? "support-modern-slavery-statement___mx",
    path: modern_45slavery_45statement5Fg9KyEb2fMeta?.path ?? "/mx/support/modern-slavery-statement",
    meta: modern_45slavery_45statement5Fg9KyEb2fMeta || {},
    alias: modern_45slavery_45statement5Fg9KyEb2fMeta?.alias || [],
    redirect: modern_45slavery_45statement5Fg9KyEb2fMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement5Fg9KyEb2fMeta?.name ?? "support-modern-slavery-statement___fr",
    path: modern_45slavery_45statement5Fg9KyEb2fMeta?.path ?? "/fr/support/modern-slavery-statement",
    meta: modern_45slavery_45statement5Fg9KyEb2fMeta || {},
    alias: modern_45slavery_45statement5Fg9KyEb2fMeta?.alias || [],
    redirect: modern_45slavery_45statement5Fg9KyEb2fMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement5Fg9KyEb2fMeta?.name ?? "support-modern-slavery-statement___fr-ca",
    path: modern_45slavery_45statement5Fg9KyEb2fMeta?.path ?? "/fr-ca/support/modern-slavery-statement",
    meta: modern_45slavery_45statement5Fg9KyEb2fMeta || {},
    alias: modern_45slavery_45statement5Fg9KyEb2fMeta?.alias || [],
    redirect: modern_45slavery_45statement5Fg9KyEb2fMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyNRPMP3MeeAMeta?.name ?? "support-privacy-apps-privacy-policy___en-US",
    path: apps_45privacy_45policyNRPMP3MeeAMeta?.path ?? "/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyNRPMP3MeeAMeta || {},
    alias: apps_45privacy_45policyNRPMP3MeeAMeta?.alias || [],
    redirect: apps_45privacy_45policyNRPMP3MeeAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyNRPMP3MeeAMeta?.name ?? "support-privacy-apps-privacy-policy___ca",
    path: apps_45privacy_45policyNRPMP3MeeAMeta?.path ?? "/ca/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyNRPMP3MeeAMeta || {},
    alias: apps_45privacy_45policyNRPMP3MeeAMeta?.alias || [],
    redirect: apps_45privacy_45policyNRPMP3MeeAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyNRPMP3MeeAMeta?.name ?? "support-privacy-apps-privacy-policy___nl",
    path: apps_45privacy_45policyNRPMP3MeeAMeta?.path ?? "/nl/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyNRPMP3MeeAMeta || {},
    alias: apps_45privacy_45policyNRPMP3MeeAMeta?.alias || [],
    redirect: apps_45privacy_45policyNRPMP3MeeAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyNRPMP3MeeAMeta?.name ?? "support-privacy-apps-privacy-policy___uk",
    path: apps_45privacy_45policyNRPMP3MeeAMeta?.path ?? "/uk/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyNRPMP3MeeAMeta || {},
    alias: apps_45privacy_45policyNRPMP3MeeAMeta?.alias || [],
    redirect: apps_45privacy_45policyNRPMP3MeeAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyNRPMP3MeeAMeta?.name ?? "support-privacy-apps-privacy-policy___de",
    path: apps_45privacy_45policyNRPMP3MeeAMeta?.path ?? "/de/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyNRPMP3MeeAMeta || {},
    alias: apps_45privacy_45policyNRPMP3MeeAMeta?.alias || [],
    redirect: apps_45privacy_45policyNRPMP3MeeAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyNRPMP3MeeAMeta?.name ?? "support-privacy-apps-privacy-policy___mx",
    path: apps_45privacy_45policyNRPMP3MeeAMeta?.path ?? "/mx/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyNRPMP3MeeAMeta || {},
    alias: apps_45privacy_45policyNRPMP3MeeAMeta?.alias || [],
    redirect: apps_45privacy_45policyNRPMP3MeeAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyNRPMP3MeeAMeta?.name ?? "support-privacy-apps-privacy-policy___fr",
    path: apps_45privacy_45policyNRPMP3MeeAMeta?.path ?? "/fr/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyNRPMP3MeeAMeta || {},
    alias: apps_45privacy_45policyNRPMP3MeeAMeta?.alias || [],
    redirect: apps_45privacy_45policyNRPMP3MeeAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyNRPMP3MeeAMeta?.name ?? "support-privacy-apps-privacy-policy___fr-ca",
    path: apps_45privacy_45policyNRPMP3MeeAMeta?.path ?? "/fr-ca/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyNRPMP3MeeAMeta || {},
    alias: apps_45privacy_45policyNRPMP3MeeAMeta?.alias || [],
    redirect: apps_45privacy_45policyNRPMP3MeeAMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: indexFXfw8wAngHMeta?.name ?? "support-privacy___en-US",
    path: indexFXfw8wAngHMeta?.path ?? "/support/privacy",
    meta: indexFXfw8wAngHMeta || {},
    alias: indexFXfw8wAngHMeta?.alias || [],
    redirect: indexFXfw8wAngHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexFXfw8wAngHMeta?.name ?? "support-privacy___ca",
    path: indexFXfw8wAngHMeta?.path ?? "/ca/support/privacy",
    meta: indexFXfw8wAngHMeta || {},
    alias: indexFXfw8wAngHMeta?.alias || [],
    redirect: indexFXfw8wAngHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexFXfw8wAngHMeta?.name ?? "support-privacy___nl",
    path: indexFXfw8wAngHMeta?.path ?? "/nl/support/privacy",
    meta: indexFXfw8wAngHMeta || {},
    alias: indexFXfw8wAngHMeta?.alias || [],
    redirect: indexFXfw8wAngHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexFXfw8wAngHMeta?.name ?? "support-privacy___uk",
    path: indexFXfw8wAngHMeta?.path ?? "/uk/support/privacy",
    meta: indexFXfw8wAngHMeta || {},
    alias: indexFXfw8wAngHMeta?.alias || [],
    redirect: indexFXfw8wAngHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexFXfw8wAngHMeta?.name ?? "support-privacy___de",
    path: indexFXfw8wAngHMeta?.path ?? "/de/support/privacy",
    meta: indexFXfw8wAngHMeta || {},
    alias: indexFXfw8wAngHMeta?.alias || [],
    redirect: indexFXfw8wAngHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexFXfw8wAngHMeta?.name ?? "support-privacy___mx",
    path: indexFXfw8wAngHMeta?.path ?? "/mx/support/privacy",
    meta: indexFXfw8wAngHMeta || {},
    alias: indexFXfw8wAngHMeta?.alias || [],
    redirect: indexFXfw8wAngHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexFXfw8wAngHMeta?.name ?? "support-privacy___fr",
    path: indexFXfw8wAngHMeta?.path ?? "/fr/support/privacy",
    meta: indexFXfw8wAngHMeta || {},
    alias: indexFXfw8wAngHMeta?.alias || [],
    redirect: indexFXfw8wAngHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexFXfw8wAngHMeta?.name ?? "support-privacy___fr-ca",
    path: indexFXfw8wAngHMeta?.path ?? "/fr-ca/support/privacy",
    meta: indexFXfw8wAngHMeta || {},
    alias: indexFXfw8wAngHMeta?.alias || [],
    redirect: indexFXfw8wAngHMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresy63xuBsXzoMeta?.name ?? "support-privacy-special-features___en-US",
    path: special_45featuresy63xuBsXzoMeta?.path ?? "/support/privacy/special-features",
    meta: special_45featuresy63xuBsXzoMeta || {},
    alias: special_45featuresy63xuBsXzoMeta?.alias || [],
    redirect: special_45featuresy63xuBsXzoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresy63xuBsXzoMeta?.name ?? "support-privacy-special-features___ca",
    path: special_45featuresy63xuBsXzoMeta?.path ?? "/ca/support/privacy/special-features",
    meta: special_45featuresy63xuBsXzoMeta || {},
    alias: special_45featuresy63xuBsXzoMeta?.alias || [],
    redirect: special_45featuresy63xuBsXzoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresy63xuBsXzoMeta?.name ?? "support-privacy-special-features___nl",
    path: special_45featuresy63xuBsXzoMeta?.path ?? "/nl/support/privacy/special-features",
    meta: special_45featuresy63xuBsXzoMeta || {},
    alias: special_45featuresy63xuBsXzoMeta?.alias || [],
    redirect: special_45featuresy63xuBsXzoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresy63xuBsXzoMeta?.name ?? "support-privacy-special-features___uk",
    path: special_45featuresy63xuBsXzoMeta?.path ?? "/uk/support/privacy/special-features",
    meta: special_45featuresy63xuBsXzoMeta || {},
    alias: special_45featuresy63xuBsXzoMeta?.alias || [],
    redirect: special_45featuresy63xuBsXzoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresy63xuBsXzoMeta?.name ?? "support-privacy-special-features___de",
    path: special_45featuresy63xuBsXzoMeta?.path ?? "/de/support/privacy/special-features",
    meta: special_45featuresy63xuBsXzoMeta || {},
    alias: special_45featuresy63xuBsXzoMeta?.alias || [],
    redirect: special_45featuresy63xuBsXzoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresy63xuBsXzoMeta?.name ?? "support-privacy-special-features___mx",
    path: special_45featuresy63xuBsXzoMeta?.path ?? "/mx/support/privacy/special-features",
    meta: special_45featuresy63xuBsXzoMeta || {},
    alias: special_45featuresy63xuBsXzoMeta?.alias || [],
    redirect: special_45featuresy63xuBsXzoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresy63xuBsXzoMeta?.name ?? "support-privacy-special-features___fr",
    path: special_45featuresy63xuBsXzoMeta?.path ?? "/fr/support/privacy/special-features",
    meta: special_45featuresy63xuBsXzoMeta || {},
    alias: special_45featuresy63xuBsXzoMeta?.alias || [],
    redirect: special_45featuresy63xuBsXzoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresy63xuBsXzoMeta?.name ?? "support-privacy-special-features___fr-ca",
    path: special_45featuresy63xuBsXzoMeta?.path ?? "/fr-ca/support/privacy/special-features",
    meta: special_45featuresy63xuBsXzoMeta || {},
    alias: special_45featuresy63xuBsXzoMeta?.alias || [],
    redirect: special_45featuresy63xuBsXzoMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listRKHpeLjTfuMeta?.name ?? "support-privacy-subprocessor-list___en-US",
    path: subprocessor_45listRKHpeLjTfuMeta?.path ?? "/support/privacy/subprocessor-list",
    meta: subprocessor_45listRKHpeLjTfuMeta || {},
    alias: subprocessor_45listRKHpeLjTfuMeta?.alias || [],
    redirect: subprocessor_45listRKHpeLjTfuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listRKHpeLjTfuMeta?.name ?? "support-privacy-subprocessor-list___ca",
    path: subprocessor_45listRKHpeLjTfuMeta?.path ?? "/ca/support/privacy/subprocessor-list",
    meta: subprocessor_45listRKHpeLjTfuMeta || {},
    alias: subprocessor_45listRKHpeLjTfuMeta?.alias || [],
    redirect: subprocessor_45listRKHpeLjTfuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listRKHpeLjTfuMeta?.name ?? "support-privacy-subprocessor-list___nl",
    path: subprocessor_45listRKHpeLjTfuMeta?.path ?? "/nl/support/privacy/subprocessor-list",
    meta: subprocessor_45listRKHpeLjTfuMeta || {},
    alias: subprocessor_45listRKHpeLjTfuMeta?.alias || [],
    redirect: subprocessor_45listRKHpeLjTfuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listRKHpeLjTfuMeta?.name ?? "support-privacy-subprocessor-list___uk",
    path: subprocessor_45listRKHpeLjTfuMeta?.path ?? "/uk/support/privacy/subprocessor-list",
    meta: subprocessor_45listRKHpeLjTfuMeta || {},
    alias: subprocessor_45listRKHpeLjTfuMeta?.alias || [],
    redirect: subprocessor_45listRKHpeLjTfuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listRKHpeLjTfuMeta?.name ?? "support-privacy-subprocessor-list___de",
    path: subprocessor_45listRKHpeLjTfuMeta?.path ?? "/de/support/privacy/subprocessor-list",
    meta: subprocessor_45listRKHpeLjTfuMeta || {},
    alias: subprocessor_45listRKHpeLjTfuMeta?.alias || [],
    redirect: subprocessor_45listRKHpeLjTfuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listRKHpeLjTfuMeta?.name ?? "support-privacy-subprocessor-list___mx",
    path: subprocessor_45listRKHpeLjTfuMeta?.path ?? "/mx/support/privacy/subprocessor-list",
    meta: subprocessor_45listRKHpeLjTfuMeta || {},
    alias: subprocessor_45listRKHpeLjTfuMeta?.alias || [],
    redirect: subprocessor_45listRKHpeLjTfuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listRKHpeLjTfuMeta?.name ?? "support-privacy-subprocessor-list___fr",
    path: subprocessor_45listRKHpeLjTfuMeta?.path ?? "/fr/support/privacy/subprocessor-list",
    meta: subprocessor_45listRKHpeLjTfuMeta || {},
    alias: subprocessor_45listRKHpeLjTfuMeta?.alias || [],
    redirect: subprocessor_45listRKHpeLjTfuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listRKHpeLjTfuMeta?.name ?? "support-privacy-subprocessor-list___fr-ca",
    path: subprocessor_45listRKHpeLjTfuMeta?.path ?? "/fr-ca/support/privacy/subprocessor-list",
    meta: subprocessor_45listRKHpeLjTfuMeta || {},
    alias: subprocessor_45listRKHpeLjTfuMeta?.alias || [],
    redirect: subprocessor_45listRKHpeLjTfuMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesh6atXs9FVhMeta?.name ?? "support-support-kb-articles___nl",
    path: support_45kb_45articlesh6atXs9FVhMeta?.path ?? "/nl/support/support-kb-articles",
    meta: support_45kb_45articlesh6atXs9FVhMeta || {},
    alias: support_45kb_45articlesh6atXs9FVhMeta?.alias || [],
    redirect: support_45kb_45articlesh6atXs9FVhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesh6atXs9FVhMeta?.name ?? "support-support-kb-articles___mx",
    path: support_45kb_45articlesh6atXs9FVhMeta?.path ?? "/mx/support/support-kb-articles",
    meta: support_45kb_45articlesh6atXs9FVhMeta || {},
    alias: support_45kb_45articlesh6atXs9FVhMeta?.alias || [],
    redirect: support_45kb_45articlesh6atXs9FVhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesh6atXs9FVhMeta?.name ?? "support-support-kb-articles___fr",
    path: support_45kb_45articlesh6atXs9FVhMeta?.path ?? "/fr/support/support-kb-articles",
    meta: support_45kb_45articlesh6atXs9FVhMeta || {},
    alias: support_45kb_45articlesh6atXs9FVhMeta?.alias || [],
    redirect: support_45kb_45articlesh6atXs9FVhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesh6atXs9FVhMeta?.name ?? "support-support-kb-articles___de",
    path: support_45kb_45articlesh6atXs9FVhMeta?.path ?? "/de/support/support-kb-articles",
    meta: support_45kb_45articlesh6atXs9FVhMeta || {},
    alias: support_45kb_45articlesh6atXs9FVhMeta?.alias || [],
    redirect: support_45kb_45articlesh6atXs9FVhMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageKa8mzY3j7nMeta?.name ?? "thank-you-page___en-US",
    path: thank_45you_45pageKa8mzY3j7nMeta?.path ?? "/thank-you-page",
    meta: thank_45you_45pageKa8mzY3j7nMeta || {},
    alias: thank_45you_45pageKa8mzY3j7nMeta?.alias || [],
    redirect: thank_45you_45pageKa8mzY3j7nMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageKa8mzY3j7nMeta?.name ?? "thank-you-page___ca",
    path: thank_45you_45pageKa8mzY3j7nMeta?.path ?? "/ca/thank-you-page",
    meta: thank_45you_45pageKa8mzY3j7nMeta || {},
    alias: thank_45you_45pageKa8mzY3j7nMeta?.alias || [],
    redirect: thank_45you_45pageKa8mzY3j7nMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageKa8mzY3j7nMeta?.name ?? "thank-you-page___nl",
    path: thank_45you_45pageKa8mzY3j7nMeta?.path ?? "/nl/thank-you-page",
    meta: thank_45you_45pageKa8mzY3j7nMeta || {},
    alias: thank_45you_45pageKa8mzY3j7nMeta?.alias || [],
    redirect: thank_45you_45pageKa8mzY3j7nMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageKa8mzY3j7nMeta?.name ?? "thank-you-page___uk",
    path: thank_45you_45pageKa8mzY3j7nMeta?.path ?? "/uk/thank-you-page",
    meta: thank_45you_45pageKa8mzY3j7nMeta || {},
    alias: thank_45you_45pageKa8mzY3j7nMeta?.alias || [],
    redirect: thank_45you_45pageKa8mzY3j7nMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageKa8mzY3j7nMeta?.name ?? "thank-you-page___de",
    path: thank_45you_45pageKa8mzY3j7nMeta?.path ?? "/de/thank-you-page",
    meta: thank_45you_45pageKa8mzY3j7nMeta || {},
    alias: thank_45you_45pageKa8mzY3j7nMeta?.alias || [],
    redirect: thank_45you_45pageKa8mzY3j7nMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageKa8mzY3j7nMeta?.name ?? "thank-you-page___mx",
    path: thank_45you_45pageKa8mzY3j7nMeta?.path ?? "/mx/thank-you-page",
    meta: thank_45you_45pageKa8mzY3j7nMeta || {},
    alias: thank_45you_45pageKa8mzY3j7nMeta?.alias || [],
    redirect: thank_45you_45pageKa8mzY3j7nMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageKa8mzY3j7nMeta?.name ?? "thank-you-page___fr",
    path: thank_45you_45pageKa8mzY3j7nMeta?.path ?? "/fr/thank-you-page",
    meta: thank_45you_45pageKa8mzY3j7nMeta || {},
    alias: thank_45you_45pageKa8mzY3j7nMeta?.alias || [],
    redirect: thank_45you_45pageKa8mzY3j7nMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageKa8mzY3j7nMeta?.name ?? "thank-you-page___fr-ca",
    path: thank_45you_45pageKa8mzY3j7nMeta?.path ?? "/fr-ca/thank-you-page",
    meta: thank_45you_45pageKa8mzY3j7nMeta || {},
    alias: thank_45you_45pageKa8mzY3j7nMeta?.alias || [],
    redirect: thank_45you_45pageKa8mzY3j7nMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingbKiYsogZviMeta?.name ?? "thankyou-recruiting___en-US",
    path: thankyou_45recruitingbKiYsogZviMeta?.path ?? "/thankyou-recruiting",
    meta: thankyou_45recruitingbKiYsogZviMeta || {},
    alias: thankyou_45recruitingbKiYsogZviMeta?.alias || [],
    redirect: thankyou_45recruitingbKiYsogZviMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingbKiYsogZviMeta?.name ?? "thankyou-recruiting___ca",
    path: thankyou_45recruitingbKiYsogZviMeta?.path ?? "/ca/thankyou-recruiting",
    meta: thankyou_45recruitingbKiYsogZviMeta || {},
    alias: thankyou_45recruitingbKiYsogZviMeta?.alias || [],
    redirect: thankyou_45recruitingbKiYsogZviMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingbKiYsogZviMeta?.name ?? "thankyou-recruiting___nl",
    path: thankyou_45recruitingbKiYsogZviMeta?.path ?? "/nl/thankyou-recruiting",
    meta: thankyou_45recruitingbKiYsogZviMeta || {},
    alias: thankyou_45recruitingbKiYsogZviMeta?.alias || [],
    redirect: thankyou_45recruitingbKiYsogZviMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingbKiYsogZviMeta?.name ?? "thankyou-recruiting___uk",
    path: thankyou_45recruitingbKiYsogZviMeta?.path ?? "/uk/thankyou-recruiting",
    meta: thankyou_45recruitingbKiYsogZviMeta || {},
    alias: thankyou_45recruitingbKiYsogZviMeta?.alias || [],
    redirect: thankyou_45recruitingbKiYsogZviMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingbKiYsogZviMeta?.name ?? "thankyou-recruiting___de",
    path: thankyou_45recruitingbKiYsogZviMeta?.path ?? "/de/thankyou-recruiting",
    meta: thankyou_45recruitingbKiYsogZviMeta || {},
    alias: thankyou_45recruitingbKiYsogZviMeta?.alias || [],
    redirect: thankyou_45recruitingbKiYsogZviMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingbKiYsogZviMeta?.name ?? "thankyou-recruiting___mx",
    path: thankyou_45recruitingbKiYsogZviMeta?.path ?? "/mx/thankyou-recruiting",
    meta: thankyou_45recruitingbKiYsogZviMeta || {},
    alias: thankyou_45recruitingbKiYsogZviMeta?.alias || [],
    redirect: thankyou_45recruitingbKiYsogZviMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingbKiYsogZviMeta?.name ?? "thankyou-recruiting___fr",
    path: thankyou_45recruitingbKiYsogZviMeta?.path ?? "/fr/thankyou-recruiting",
    meta: thankyou_45recruitingbKiYsogZviMeta || {},
    alias: thankyou_45recruitingbKiYsogZviMeta?.alias || [],
    redirect: thankyou_45recruitingbKiYsogZviMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingbKiYsogZviMeta?.name ?? "thankyou-recruiting___fr-ca",
    path: thankyou_45recruitingbKiYsogZviMeta?.path ?? "/fr-ca/thankyou-recruiting",
    meta: thankyou_45recruitingbKiYsogZviMeta || {},
    alias: thankyou_45recruitingbKiYsogZviMeta?.alias || [],
    redirect: thankyou_45recruitingbKiYsogZviMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralLcJ5Ao10z1Meta?.name ?? "thankyou-referral___en-US",
    path: thankyou_45referralLcJ5Ao10z1Meta?.path ?? "/thankyou-referral",
    meta: thankyou_45referralLcJ5Ao10z1Meta || {},
    alias: thankyou_45referralLcJ5Ao10z1Meta?.alias || [],
    redirect: thankyou_45referralLcJ5Ao10z1Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralLcJ5Ao10z1Meta?.name ?? "thankyou-referral___ca",
    path: thankyou_45referralLcJ5Ao10z1Meta?.path ?? "/ca/thankyou-referral",
    meta: thankyou_45referralLcJ5Ao10z1Meta || {},
    alias: thankyou_45referralLcJ5Ao10z1Meta?.alias || [],
    redirect: thankyou_45referralLcJ5Ao10z1Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralLcJ5Ao10z1Meta?.name ?? "thankyou-referral___nl",
    path: thankyou_45referralLcJ5Ao10z1Meta?.path ?? "/nl/thankyou-referral",
    meta: thankyou_45referralLcJ5Ao10z1Meta || {},
    alias: thankyou_45referralLcJ5Ao10z1Meta?.alias || [],
    redirect: thankyou_45referralLcJ5Ao10z1Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralLcJ5Ao10z1Meta?.name ?? "thankyou-referral___uk",
    path: thankyou_45referralLcJ5Ao10z1Meta?.path ?? "/uk/thankyou-referral",
    meta: thankyou_45referralLcJ5Ao10z1Meta || {},
    alias: thankyou_45referralLcJ5Ao10z1Meta?.alias || [],
    redirect: thankyou_45referralLcJ5Ao10z1Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralLcJ5Ao10z1Meta?.name ?? "thankyou-referral___de",
    path: thankyou_45referralLcJ5Ao10z1Meta?.path ?? "/de/thankyou-referral",
    meta: thankyou_45referralLcJ5Ao10z1Meta || {},
    alias: thankyou_45referralLcJ5Ao10z1Meta?.alias || [],
    redirect: thankyou_45referralLcJ5Ao10z1Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralLcJ5Ao10z1Meta?.name ?? "thankyou-referral___mx",
    path: thankyou_45referralLcJ5Ao10z1Meta?.path ?? "/mx/thankyou-referral",
    meta: thankyou_45referralLcJ5Ao10z1Meta || {},
    alias: thankyou_45referralLcJ5Ao10z1Meta?.alias || [],
    redirect: thankyou_45referralLcJ5Ao10z1Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralLcJ5Ao10z1Meta?.name ?? "thankyou-referral___fr",
    path: thankyou_45referralLcJ5Ao10z1Meta?.path ?? "/fr/thankyou-referral",
    meta: thankyou_45referralLcJ5Ao10z1Meta || {},
    alias: thankyou_45referralLcJ5Ao10z1Meta?.alias || [],
    redirect: thankyou_45referralLcJ5Ao10z1Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralLcJ5Ao10z1Meta?.name ?? "thankyou-referral___fr-ca",
    path: thankyou_45referralLcJ5Ao10z1Meta?.path ?? "/fr-ca/thankyou-referral",
    meta: thankyou_45referralLcJ5Ao10z1Meta || {},
    alias: thankyou_45referralLcJ5Ao10z1Meta?.alias || [],
    redirect: thankyou_45referralLcJ5Ao10z1Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyouHQkxHZKgKlMeta?.name ?? "thankyou___en-US",
    path: thankyouHQkxHZKgKlMeta?.path ?? "/thankyou",
    meta: thankyouHQkxHZKgKlMeta || {},
    alias: thankyouHQkxHZKgKlMeta?.alias || [],
    redirect: thankyouHQkxHZKgKlMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouHQkxHZKgKlMeta?.name ?? "thankyou___ca",
    path: thankyouHQkxHZKgKlMeta?.path ?? "/ca/thankyou",
    meta: thankyouHQkxHZKgKlMeta || {},
    alias: thankyouHQkxHZKgKlMeta?.alias || [],
    redirect: thankyouHQkxHZKgKlMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouHQkxHZKgKlMeta?.name ?? "thankyou___nl",
    path: thankyouHQkxHZKgKlMeta?.path ?? "/nl/thankyou",
    meta: thankyouHQkxHZKgKlMeta || {},
    alias: thankyouHQkxHZKgKlMeta?.alias || [],
    redirect: thankyouHQkxHZKgKlMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouHQkxHZKgKlMeta?.name ?? "thankyou___uk",
    path: thankyouHQkxHZKgKlMeta?.path ?? "/uk/thankyou",
    meta: thankyouHQkxHZKgKlMeta || {},
    alias: thankyouHQkxHZKgKlMeta?.alias || [],
    redirect: thankyouHQkxHZKgKlMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouHQkxHZKgKlMeta?.name ?? "thankyou___de",
    path: thankyouHQkxHZKgKlMeta?.path ?? "/de/thankyou",
    meta: thankyouHQkxHZKgKlMeta || {},
    alias: thankyouHQkxHZKgKlMeta?.alias || [],
    redirect: thankyouHQkxHZKgKlMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouHQkxHZKgKlMeta?.name ?? "thankyou___mx",
    path: thankyouHQkxHZKgKlMeta?.path ?? "/mx/thankyou",
    meta: thankyouHQkxHZKgKlMeta || {},
    alias: thankyouHQkxHZKgKlMeta?.alias || [],
    redirect: thankyouHQkxHZKgKlMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouHQkxHZKgKlMeta?.name ?? "thankyou___fr",
    path: thankyouHQkxHZKgKlMeta?.path ?? "/fr/thankyou",
    meta: thankyouHQkxHZKgKlMeta || {},
    alias: thankyouHQkxHZKgKlMeta?.alias || [],
    redirect: thankyouHQkxHZKgKlMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouHQkxHZKgKlMeta?.name ?? "thankyou___fr-ca",
    path: thankyouHQkxHZKgKlMeta?.path ?? "/fr-ca/thankyou",
    meta: thankyouHQkxHZKgKlMeta || {},
    alias: thankyouHQkxHZKgKlMeta?.alias || [],
    redirect: thankyouHQkxHZKgKlMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: eliteE2HtOhZ235Meta?.name ?? "tiers-elite___uk",
    path: eliteE2HtOhZ235Meta?.path ?? "/uk/tiers/elite",
    meta: eliteE2HtOhZ235Meta || {},
    alias: eliteE2HtOhZ235Meta?.alias || [],
    redirect: eliteE2HtOhZ235Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: eliteE2HtOhZ235Meta?.name ?? "tiers-elite___en-US",
    path: eliteE2HtOhZ235Meta?.path ?? "/tiers/elite",
    meta: eliteE2HtOhZ235Meta || {},
    alias: eliteE2HtOhZ235Meta?.alias || [],
    redirect: eliteE2HtOhZ235Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: eliteE2HtOhZ235Meta?.name ?? "tiers-elite___ca",
    path: eliteE2HtOhZ235Meta?.path ?? "/ca/tiers/elite",
    meta: eliteE2HtOhZ235Meta || {},
    alias: eliteE2HtOhZ235Meta?.alias || [],
    redirect: eliteE2HtOhZ235Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: plusavIWDQLuvMMeta?.name ?? "tiers-plus___uk",
    path: plusavIWDQLuvMMeta?.path ?? "/uk/tiers/plus",
    meta: plusavIWDQLuvMMeta || {},
    alias: plusavIWDQLuvMMeta?.alias || [],
    redirect: plusavIWDQLuvMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: plusavIWDQLuvMMeta?.name ?? "tiers-plus___en-US",
    path: plusavIWDQLuvMMeta?.path ?? "/tiers/plus",
    meta: plusavIWDQLuvMMeta || {},
    alias: plusavIWDQLuvMMeta?.alias || [],
    redirect: plusavIWDQLuvMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: plusavIWDQLuvMMeta?.name ?? "tiers-plus___ca",
    path: plusavIWDQLuvMMeta?.path ?? "/ca/tiers/plus",
    meta: plusavIWDQLuvMMeta || {},
    alias: plusavIWDQLuvMMeta?.alias || [],
    redirect: plusavIWDQLuvMMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: premierIz1ydV3RnWMeta?.name ?? "tiers-premier___uk",
    path: premierIz1ydV3RnWMeta?.path ?? "/uk/tiers/premier",
    meta: premierIz1ydV3RnWMeta || {},
    alias: premierIz1ydV3RnWMeta?.alias || [],
    redirect: premierIz1ydV3RnWMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: premierIz1ydV3RnWMeta?.name ?? "tiers-premier___en-US",
    path: premierIz1ydV3RnWMeta?.path ?? "/tiers/premier",
    meta: premierIz1ydV3RnWMeta || {},
    alias: premierIz1ydV3RnWMeta?.alias || [],
    redirect: premierIz1ydV3RnWMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: premierIz1ydV3RnWMeta?.name ?? "tiers-premier___ca",
    path: premierIz1ydV3RnWMeta?.path ?? "/ca/tiers/premier",
    meta: premierIz1ydV3RnWMeta || {},
    alias: premierIz1ydV3RnWMeta?.alias || [],
    redirect: premierIz1ydV3RnWMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: starterrNqrp9oCYZMeta?.name ?? "tiers-starter___uk",
    path: starterrNqrp9oCYZMeta?.path ?? "/uk/tiers/starter",
    meta: starterrNqrp9oCYZMeta || {},
    alias: starterrNqrp9oCYZMeta?.alias || [],
    redirect: starterrNqrp9oCYZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: starterrNqrp9oCYZMeta?.name ?? "tiers-starter___en-US",
    path: starterrNqrp9oCYZMeta?.path ?? "/tiers/starter",
    meta: starterrNqrp9oCYZMeta || {},
    alias: starterrNqrp9oCYZMeta?.alias || [],
    redirect: starterrNqrp9oCYZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: starterrNqrp9oCYZMeta?.name ?? "tiers-starter___ca",
    path: starterrNqrp9oCYZMeta?.path ?? "/ca/tiers/starter",
    meta: starterrNqrp9oCYZMeta || {},
    alias: starterrNqrp9oCYZMeta?.alias || [],
    redirect: starterrNqrp9oCYZMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: process3XQYl9WadEMeta?.name ?? "trial-process___en-US",
    path: process3XQYl9WadEMeta?.path ?? "/trial/process",
    meta: process3XQYl9WadEMeta || {},
    alias: process3XQYl9WadEMeta?.alias || [],
    redirect: process3XQYl9WadEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: process3XQYl9WadEMeta?.name ?? "trial-process___ca",
    path: process3XQYl9WadEMeta?.path ?? "/ca/trial/process",
    meta: process3XQYl9WadEMeta || {},
    alias: process3XQYl9WadEMeta?.alias || [],
    redirect: process3XQYl9WadEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: process3XQYl9WadEMeta?.name ?? "trial-process___nl",
    path: process3XQYl9WadEMeta?.path ?? "/nl/trial/process",
    meta: process3XQYl9WadEMeta || {},
    alias: process3XQYl9WadEMeta?.alias || [],
    redirect: process3XQYl9WadEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: process3XQYl9WadEMeta?.name ?? "trial-process___uk",
    path: process3XQYl9WadEMeta?.path ?? "/uk/trial/process",
    meta: process3XQYl9WadEMeta || {},
    alias: process3XQYl9WadEMeta?.alias || [],
    redirect: process3XQYl9WadEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: process3XQYl9WadEMeta?.name ?? "trial-process___de",
    path: process3XQYl9WadEMeta?.path ?? "/de/trial/process",
    meta: process3XQYl9WadEMeta || {},
    alias: process3XQYl9WadEMeta?.alias || [],
    redirect: process3XQYl9WadEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: process3XQYl9WadEMeta?.name ?? "trial-process___mx",
    path: process3XQYl9WadEMeta?.path ?? "/mx/trial/process",
    meta: process3XQYl9WadEMeta || {},
    alias: process3XQYl9WadEMeta?.alias || [],
    redirect: process3XQYl9WadEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: process3XQYl9WadEMeta?.name ?? "trial-process___fr",
    path: process3XQYl9WadEMeta?.path ?? "/fr/trial/process",
    meta: process3XQYl9WadEMeta || {},
    alias: process3XQYl9WadEMeta?.alias || [],
    redirect: process3XQYl9WadEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: process3XQYl9WadEMeta?.name ?? "trial-process___fr-ca",
    path: process3XQYl9WadEMeta?.path ?? "/fr-ca/trial/process",
    meta: process3XQYl9WadEMeta || {},
    alias: process3XQYl9WadEMeta?.alias || [],
    redirect: process3XQYl9WadEMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: supportfGi45KLfv3Meta?.name ?? "warranty-support___uk",
    path: supportfGi45KLfv3Meta?.path ?? "/uk/warranty/support",
    meta: supportfGi45KLfv3Meta || {},
    alias: supportfGi45KLfv3Meta?.alias || [],
    redirect: supportfGi45KLfv3Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportfGi45KLfv3Meta?.name ?? "warranty-support___en-US",
    path: supportfGi45KLfv3Meta?.path ?? "/warranty/support",
    meta: supportfGi45KLfv3Meta || {},
    alias: supportfGi45KLfv3Meta?.alias || [],
    redirect: supportfGi45KLfv3Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportfGi45KLfv3Meta?.name ?? "warranty-support___ca",
    path: supportfGi45KLfv3Meta?.path ?? "/ca/warranty/support",
    meta: supportfGi45KLfv3Meta || {},
    alias: supportfGi45KLfv3Meta?.alias || [],
    redirect: supportfGi45KLfv3Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportfGi45KLfv3Meta?.name ?? "warranty-support___ie",
    path: supportfGi45KLfv3Meta?.path ?? "/ie/warranty/support",
    meta: supportfGi45KLfv3Meta || {},
    alias: supportfGi45KLfv3Meta?.alias || [],
    redirect: supportfGi45KLfv3Meta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jIPtLgs49lMeta?.name ?? "webinars-slug___en-US",
    path: _91slug_93jIPtLgs49lMeta?.path ?? "/webinars/:slug()",
    meta: _91slug_93jIPtLgs49lMeta || {},
    alias: _91slug_93jIPtLgs49lMeta?.alias || [],
    redirect: _91slug_93jIPtLgs49lMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jIPtLgs49lMeta?.name ?? "webinars-slug___ca",
    path: _91slug_93jIPtLgs49lMeta?.path ?? "/ca/webinars/:slug()",
    meta: _91slug_93jIPtLgs49lMeta || {},
    alias: _91slug_93jIPtLgs49lMeta?.alias || [],
    redirect: _91slug_93jIPtLgs49lMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jIPtLgs49lMeta?.name ?? "webinars-slug___nl",
    path: _91slug_93jIPtLgs49lMeta?.path ?? "/nl/webinars/:slug()",
    meta: _91slug_93jIPtLgs49lMeta || {},
    alias: _91slug_93jIPtLgs49lMeta?.alias || [],
    redirect: _91slug_93jIPtLgs49lMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jIPtLgs49lMeta?.name ?? "webinars-slug___uk",
    path: _91slug_93jIPtLgs49lMeta?.path ?? "/uk/webinars/:slug()",
    meta: _91slug_93jIPtLgs49lMeta || {},
    alias: _91slug_93jIPtLgs49lMeta?.alias || [],
    redirect: _91slug_93jIPtLgs49lMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jIPtLgs49lMeta?.name ?? "webinars-slug___de",
    path: _91slug_93jIPtLgs49lMeta?.path ?? "/de/webinars/:slug()",
    meta: _91slug_93jIPtLgs49lMeta || {},
    alias: _91slug_93jIPtLgs49lMeta?.alias || [],
    redirect: _91slug_93jIPtLgs49lMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jIPtLgs49lMeta?.name ?? "webinars-slug___mx",
    path: _91slug_93jIPtLgs49lMeta?.path ?? "/mx/webinars/:slug()",
    meta: _91slug_93jIPtLgs49lMeta || {},
    alias: _91slug_93jIPtLgs49lMeta?.alias || [],
    redirect: _91slug_93jIPtLgs49lMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jIPtLgs49lMeta?.name ?? "webinars-slug___fr",
    path: _91slug_93jIPtLgs49lMeta?.path ?? "/fr/webinars/:slug()",
    meta: _91slug_93jIPtLgs49lMeta || {},
    alias: _91slug_93jIPtLgs49lMeta?.alias || [],
    redirect: _91slug_93jIPtLgs49lMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jIPtLgs49lMeta?.name ?? "webinars-slug___fr-ca",
    path: _91slug_93jIPtLgs49lMeta?.path ?? "/fr-ca/webinars/:slug()",
    meta: _91slug_93jIPtLgs49lMeta || {},
    alias: _91slug_93jIPtLgs49lMeta?.alias || [],
    redirect: _91slug_93jIPtLgs49lMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexJAaPmQQsuwMeta?.name ?? "webinars___en-US",
    path: indexJAaPmQQsuwMeta?.path ?? "/webinars",
    meta: indexJAaPmQQsuwMeta || {},
    alias: indexJAaPmQQsuwMeta?.alias || [],
    redirect: indexJAaPmQQsuwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexJAaPmQQsuwMeta?.name ?? "webinars___ca",
    path: indexJAaPmQQsuwMeta?.path ?? "/ca/webinars",
    meta: indexJAaPmQQsuwMeta || {},
    alias: indexJAaPmQQsuwMeta?.alias || [],
    redirect: indexJAaPmQQsuwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexJAaPmQQsuwMeta?.name ?? "webinars___nl",
    path: indexJAaPmQQsuwMeta?.path ?? "/nl/webinars",
    meta: indexJAaPmQQsuwMeta || {},
    alias: indexJAaPmQQsuwMeta?.alias || [],
    redirect: indexJAaPmQQsuwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexJAaPmQQsuwMeta?.name ?? "webinars___uk",
    path: indexJAaPmQQsuwMeta?.path ?? "/uk/webinars",
    meta: indexJAaPmQQsuwMeta || {},
    alias: indexJAaPmQQsuwMeta?.alias || [],
    redirect: indexJAaPmQQsuwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexJAaPmQQsuwMeta?.name ?? "webinars___de",
    path: indexJAaPmQQsuwMeta?.path ?? "/de/webinars",
    meta: indexJAaPmQQsuwMeta || {},
    alias: indexJAaPmQQsuwMeta?.alias || [],
    redirect: indexJAaPmQQsuwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexJAaPmQQsuwMeta?.name ?? "webinars___mx",
    path: indexJAaPmQQsuwMeta?.path ?? "/mx/webinars",
    meta: indexJAaPmQQsuwMeta || {},
    alias: indexJAaPmQQsuwMeta?.alias || [],
    redirect: indexJAaPmQQsuwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexJAaPmQQsuwMeta?.name ?? "webinars___fr",
    path: indexJAaPmQQsuwMeta?.path ?? "/fr/webinars",
    meta: indexJAaPmQQsuwMeta || {},
    alias: indexJAaPmQQsuwMeta?.alias || [],
    redirect: indexJAaPmQQsuwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexJAaPmQQsuwMeta?.name ?? "webinars___fr-ca",
    path: indexJAaPmQQsuwMeta?.path ?? "/fr-ca/webinars",
    meta: indexJAaPmQQsuwMeta || {},
    alias: indexJAaPmQQsuwMeta?.alias || [],
    redirect: indexJAaPmQQsuwMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SkmiIySTmkMeta?.name ?? "webinars-thank-you-slug___en-US",
    path: _91slug_93SkmiIySTmkMeta?.path ?? "/webinars/thank-you/:slug()",
    meta: _91slug_93SkmiIySTmkMeta || {},
    alias: _91slug_93SkmiIySTmkMeta?.alias || [],
    redirect: _91slug_93SkmiIySTmkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SkmiIySTmkMeta?.name ?? "webinars-thank-you-slug___ca",
    path: _91slug_93SkmiIySTmkMeta?.path ?? "/ca/webinars/thank-you/:slug()",
    meta: _91slug_93SkmiIySTmkMeta || {},
    alias: _91slug_93SkmiIySTmkMeta?.alias || [],
    redirect: _91slug_93SkmiIySTmkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SkmiIySTmkMeta?.name ?? "webinars-thank-you-slug___nl",
    path: _91slug_93SkmiIySTmkMeta?.path ?? "/nl/webinars/thank-you/:slug()",
    meta: _91slug_93SkmiIySTmkMeta || {},
    alias: _91slug_93SkmiIySTmkMeta?.alias || [],
    redirect: _91slug_93SkmiIySTmkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SkmiIySTmkMeta?.name ?? "webinars-thank-you-slug___uk",
    path: _91slug_93SkmiIySTmkMeta?.path ?? "/uk/webinars/thank-you/:slug()",
    meta: _91slug_93SkmiIySTmkMeta || {},
    alias: _91slug_93SkmiIySTmkMeta?.alias || [],
    redirect: _91slug_93SkmiIySTmkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SkmiIySTmkMeta?.name ?? "webinars-thank-you-slug___de",
    path: _91slug_93SkmiIySTmkMeta?.path ?? "/de/webinars/thank-you/:slug()",
    meta: _91slug_93SkmiIySTmkMeta || {},
    alias: _91slug_93SkmiIySTmkMeta?.alias || [],
    redirect: _91slug_93SkmiIySTmkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SkmiIySTmkMeta?.name ?? "webinars-thank-you-slug___mx",
    path: _91slug_93SkmiIySTmkMeta?.path ?? "/mx/webinars/thank-you/:slug()",
    meta: _91slug_93SkmiIySTmkMeta || {},
    alias: _91slug_93SkmiIySTmkMeta?.alias || [],
    redirect: _91slug_93SkmiIySTmkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SkmiIySTmkMeta?.name ?? "webinars-thank-you-slug___fr",
    path: _91slug_93SkmiIySTmkMeta?.path ?? "/fr/webinars/thank-you/:slug()",
    meta: _91slug_93SkmiIySTmkMeta || {},
    alias: _91slug_93SkmiIySTmkMeta?.alias || [],
    redirect: _91slug_93SkmiIySTmkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SkmiIySTmkMeta?.name ?? "webinars-thank-you-slug___fr-ca",
    path: _91slug_93SkmiIySTmkMeta?.path ?? "/fr-ca/webinars/thank-you/:slug()",
    meta: _91slug_93SkmiIySTmkMeta || {},
    alias: _91slug_93SkmiIySTmkMeta?.alias || [],
    redirect: _91slug_93SkmiIySTmkMeta?.redirect,
    component: () => import("/codebuild/output/src2560279378/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  }
]